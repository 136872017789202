import { v4 as uuid } from 'uuid';
import {
  ADD_NOTIFICATION,
} from '../actionTypes';

const addNotification = (type, text, colorScheme = 'white') => ({
  type: ADD_NOTIFICATION,
  payload: {
    id: uuid(),
    type,
    text,
    colorScheme,
    expire: new Date().getTime() + 5000,
    visible: true,
  },
});

export default addNotification;
