import ProfiledChargingSettings from '../config/ProfiledCharging.config';

export default function useChargingProfile(settings) {
  /* Get charging profile */
  const enabledProfiles = [];

  Object.entries(settings).forEach((item) => {
    Object.keys(ProfiledChargingSettings).forEach((ChargingProfile) => {
      if (item[0] === ChargingProfile) {
        if (item[1].value === true) {
          enabledProfiles.push(item[0]);
        }
      }
    });
  });

  /* Disable all profiles when exceeding profile allowed enabled profile limit */
  if (enabledProfiles.length === 0) return null;

  /* This line will lead to bugs because if we return null we assume there is no profile selected */
  /* But in this case this also mean there are multiple profiles enabled */
  if (enabledProfiles.length > 1) return null;

  return enabledProfiles[0];
}
