import * as React from 'react';
import {
  useNavigate,
} from 'react-router-dom';

import {
  Colors,
  Lang,
  Breakpoint,

  useDimensions,

  Button,
  Input,
} from '@bluecurrent/web-component-lib';

export default function Controls(props) {
  const navigate = useNavigate();
  const size = useDimensions();

  const { _ } = Lang.useTranslation();

  const [, setFilterModalOpen] = props.useFilterModalOpen;

  return (
    <div
      style={{
        width: size.width < Breakpoint.md ? 'auto' : '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: (size.width < 1300) ? 5 : 15,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: size.width < Breakpoint.fd ? 'flex-start' : 'space-between',
          width: size.width < Breakpoint.md ? 'auto' : '100%',
          gap: (size.width < 1300) ? 5 : 15,
        }}
      >
        {
          size.width >= Breakpoint.md && (
            <div
              style={{
                width: '100%',
              }}
            >
              <Input
                type="fromToDate"
                align="horizontal"
                start={{
                  value: props.startDate,
                  onChange: (date) => {
                    props.setStartDate(date);
                    if (date > props.endDate) props.setEndDate(date);
                    props.setPage(1);
                  },
                  maxDate: new Date(),
                }}
                end={{
                  value: props.endDate,
                  onChange: (data) => {
                    props.setEndDate(data);
                    props.setPage(1);
                  },
                  minDate: props.startDate,
                  maxDate: new Date(),
                }}
                format={{
                  day: 'numeric',
                  month: size.width < 1300 ? 'short' : 'long',
                  year: 'numeric',
                }}
                onClear={() => {
                  props.setStartDate(null);
                  props.setEndDate(new Date());
                  props.setPage(1);
                }}
                borderRadius={7}
              />
            </div>
          )
        }
        <div
          style={{
            minWidth: 50,
          }}
        >
          <Button
            type="icon"
            icon={{
              name: 'Filter',
              height: 23,
              width: 23,
              iconSet: 'Feather',
              color: Colors.GREY,
            }}
            borderRadius={7}
            colorScheme="white"
            onClick={() => {
              setFilterModalOpen(true);
            }}
          />
        </div>
      </div>
      {
        size.width < 1200 ? (
          <div
            style={{
              height: 50,
            }}
          >
            <Input
              type="dropdown"
              colorScheme="white"
              id="transactions-options"
              icon={{
                name: 'Ellipsis-H',
                width: 30,
                height: 30,
                color: Colors.GREY,
                iconSet: 'FA',
              }}
              text=""
              right
              borderRadius={7}
              items={[
                {
                  id: 0,
                  text: _('download', { ns: 'ui' }),
                  onClick: props.onClickDownload,
                  disabled: props.downloading,
                },
                {
                  id: 1,
                  text: _('declarationOverview', { ns: 'finance' }),
                  onClick: () => navigate('/transactions/declaration-overview'),
                },
                {
                  id: 2,
                  text: _('group.groups', { ns: 'ui' }),
                  onClick: () => props.setGroupModal(true),
                },
              ]}
            />
          </div>
        ) : (
          <>
            <div
              style={{
                width: 50,
              }}
            >
              <Button
                type="icon"
                icon={{
                  name: 'Download',
                  height: 25,
                  width: 25,
                  iconSet: 'Feather',
                  color: Colors.GREY,
                }}
                borderRadius={7}
                colorScheme="white"
                onClick={props.onClickDownload}
                loading={props.downloading}
              />
            </div>
            <div
              style={{
                width: 'auto',
                minWidth: 225,
              }}
            >
              <Button
                text={_('declarationOverview', { ns: 'finance' })}
                colorScheme="blue"
                onClick={() => navigate('/transactions/declaration-overview')}
                borderRadius={7}
              />
            </div>
          </>
        )
      }
    </div>
  );
}
