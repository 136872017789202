import {
  Colors,
} from '@bluecurrent/web-component-lib';

export default [
  {
    name: 'House',
    type: 'light',
    width: 25,
    height: 25,
    color: Colors.GREY,
    iconSet: 'FA',
  },
  {
    name: 'BuildingWithCar',
    type: 'light',
    width: 25,
    height: 25,
    color: Colors.GREY,
    iconSet: 'FA',
  },
  {
    name: 'Garage',
    type: 'light',
    width: 25,
    height: 25,
    color: Colors.GREY,
    iconSet: 'FA',
  },
  {
    name: 'Parking',
    type: 'light',
    width: 25,
    height: 25,
    color: Colors.GREY,
    iconSet: 'FA',
  },
  {
    name: 'Warehouse',
    type: 'light',
    width: 25,
    height: 25,
    color: Colors.GREY,
    iconSet: 'FA',
  },
  {
    name: 'WarehouseBoxes',
    type: 'light',
    width: 25,
    height: 25,
    color: Colors.GREY,
    iconSet: 'FA',
  },
];
