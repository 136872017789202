import * as React from 'react';
import { useDispatch } from 'react-redux';

import {
  Lang,
  Colors,
  Breakpoint,

  useDimensions,

  Item,
  Text,
} from '@bluecurrent/web-component-lib';

import { SET_LED_INTERACTION } from '../../../../api/types/ApiMessages';

const LedInteractionSetting = (props) => {
  const { _ } = Lang.useTranslation();
  const size = useDimensions();
  const dispatch = useDispatch();
  const [updatedSettings] = props.useUpdatedSettings;
  const value = updatedSettings.led_interaction ?? props.evseSettings.led_interaction?.value;
  const permission = props.evseSettings.led_interaction?.permission;

  const onSwitch = () => {
    const payload = {
      led_interaction: {
        value: !value,
        permission: props.evseSettings.led_interaction?.permission,
      },
    };

    props.simpleValueChange(
      SET_LED_INTERACTION,
      'led_interaction',
      !value,
      true,
      (success) => {
        if (success) {
          dispatch({
            type: 'UPDATE_SETTINGS',
            id: props.evseSettings.evse_id,
            payload,
          });
        }
      },
    );
  };

  if (permission === 'none') return null;

  return (
    <>
      <div
        className="DisableSelection"
        style={{
          padding: 10,
          height: 'auto',
          minHeight: 55,
          backgroundColor: Colors.LIGHT_GREY,
          border: `solid 2px ${Colors.MEDIUM_WHITE}`,
          borderRadius: 10,
          marginTop: 10,
        }}
      >
        <Item
          type="boolean"
          title={_('settings.ledInteraction', { ns: 'chargepoint' })}
          value={value}
          noPermission={permission !== 'write'}
          onSwitch={onSwitch}
          disabled={props.evseSettings.activity === 'charging'}
          customTooltip={{
            visible: props.evseSettings.activity === 'charging',
            text: _('settingDisabledDuringChargeSession', { ns: 'chargepoint' }),
          }}
          tooltip={{
            error: _('failedToLoadSetting', { ns: 'settings' }),
            permission: size.width < Breakpoint.sm ? false : _('noPermissionToChangeSetting', { ns: 'settings' }),
          }}
        />
      </div>
      {
        size.width < Breakpoint.sm && permission !== 'write' && (
          <div
            style={{
              marginTop: 5,
              marginBottom: 20,
            }}
          >
            <Text
              fontSize="0.9em"
              color={Colors.DARK_GREY}
            >
              {_('noPermissionToChangeSetting', { ns: 'settings' })}
            </Text>
          </div>
        )
      }
    </>
  );
};

export default LedInteractionSetting;
