import * as React from 'react';
import {
  Colors,
  Text,
  Lang,
  Button,
  Confetti,
  Logo,

  Breakpoint,

  useDimensions,
} from '@bluecurrent/web-component-lib';

export default function ErrorPage(props) {
  const size = useDimensions();
  const { _ } = Lang.useTranslation();
  const [loading, setLoading] = React.useState(false);

  const retry = () => {
    setLoading(true);
    window.location.reload();
  };

  return (
    <div style={{
      width: '100vw',
      height: '100vh',
      backgroundColor: size.width < Breakpoint.xs ? Colors.WHITE : Colors.GRADIENT,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      zIndex: 1000000,
    }}
    >
      <div
        style={{
          width: size.width < Breakpoint.xs ? '100%' : 400,
          height: size.width < Breakpoint.xs ? '100vh' : 'auto',
          background: Colors.WHITE,
          borderRadius: size.width < Breakpoint.xs ? 0 : 10,
          padding: 20,
          position: 'relative',
        }}
      >
        {
          size.width < Breakpoint.xs && (
            <div
              style={{
                height: 60,
                width: '100%',
                marginBottom: 50,
              }}
            >
              <Logo
                type="Normal"
                width={200}
                height={60}
              />
            </div>
          )
        }
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div style={{
            marginTop: 10,
            marginBottom: 15,
          }}
          >
            <Text
              fontSize="1.3em"
              fontWeight={500}
              textAlign="center"
            >
              { props.title ?? _('errors.somethingWrong', { ns: 'ui' }) }
            </Text>
          </div>
          <Text
            fontSize="1.1em"
            textAlign="center"
          >
            { props.description ?? _('errors.noConnectionBackend', { ns: 'ui' }) }
          </Text>
        </div>
        <div
          style={{
            marginTop: 100,
          }}
        >
          <Button
            text="Retry"
            colorScheme="white"
            onClick={retry}
            loading={loading}
          />
          <div
            style={{
              borderTop: `2px solid ${Colors.MEDIUM_WHITE}`,
              marginTop: 10,
              paddingTop: 10,
            }}
          >
            <Text
              fontSize="1em"
              textAlign="left"
              fontWeight={100}
              color={Colors.MEDIUM_GREY}
            >
              BCU:BO
              { process.env.REACT_APP_VERSION }
              :
              { props.errorCode ?? '0' }
            </Text>
          </div>
        </div>
      </div>
      <div
        style={{
          position: 'absolute',
          zIndex: -1,
        }}
      >
        <Confetti
          type="Stripes"
          height={150}
          width={150}
          position="absolute"
          marginLeft={159}
          marginTop={-150}
          color={Colors.PURPLE}
        />
        <Confetti
          type="Oval"
          height={100}
          width={100}
          position="absolute"
          marginLeft={-250}
          marginTop={0}
          color={Colors.YELLOW}
        />
      </div>
      <div
        style={{
          position: 'absolute',
          zIndex: -2,
          height: '100%',
          width: '100%',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <Confetti
          type="DotsPath"
          height={1000}
          width={1000}
          color={Colors.WHITE}
          position="absolute"
          left={-300}
          top={-500}
          transform="rotate(0deg)"
          opacity={0.5}
        />
        <Confetti
          type="DotsPath"
          height={1200}
          width={1200}
          color={Colors.WHITE}
          position="absolute"
          right={-300}
          bottom={-500}
          transform="rotate(130deg)"
          opacity={0.5}
        />
      </div>
    </div>
  );
}
