import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Sentry from '@sentry/browser';

import {
  Lang,
  Colors,

  Button,
} from '@bluecurrent/web-component-lib';

import executeCommand from '../../../../../api/executeCommand';
import {
  ADD_GROUP,
} from '../../../../../api/types/ApiMessages';
import addNotification from '../../../../../redux/creators/notifications';

import SYMBOLS from '../../../Groups/icons';

import Information from './Information';
import ChargePoints from './ChargePoints';

export default function CreateGroup({
  setTitle,
  setSubTitle,
  onClose,
}) {
  const { _ } = Lang.useTranslation();
  const dispatch = useDispatch();

  const chargePoints = useSelector((state) => state.ChargePoints);

  const [page, setPage] = React.useState(0);
  const [isLoading, setLoading] = React.useState(false);

  const [name, setName] = React.useState('');
  const [symbol, setSymbol] = React.useState('House');
  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    setLoading(false);
    setName('');
    setSymbol('House');
    setItems([]);
  }, []);

  React.useEffect(() => {
    if (page === 0) {
      setTitle(_('group.create', { ns: 'ui' }));
      setSubTitle('');
    }
    if (page === 1) {
      setTitle(name);
      setSubTitle(_('group.create', { ns: 'ui' }));
    }
  }, [page, name]);

  const onCreate = () => {
    const icon = SYMBOLS.find((data) => data.name === symbol);

    setLoading(true);

    executeCommand(ADD_GROUP, {
      body: {
        name,
        icon: {
          name: icon.name,
          width: icon.width,
          height: icon.height,
        },
        chargepoints: items,
      },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'ADD_GROUP',
            payload: {
              group: {
                id: response.data.result.group_id,
                name,
                icon: {
                  name: icon.name,
                  width: icon.width,
                  height: icon.height,
                },
                chargepoints: items,
              },
            },
          });

          onClose();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        dispatch(
          addNotification(
            'failed',
            _('error.somethingWrong', { ns: 'chargepoint' }),
            'red',
          ),
        );
      });
  };

  return (
    <>
      <Information
        isVisible={page === 0}
        name={name}
        setName={setName}
        symbol={symbol}
        setSymbol={setSymbol}
      />
      <ChargePoints
        isVisible={page === 1}
        items={items}
        setItems={setItems}
        chargePoints={chargePoints}
        onClose={onClose}
      />
      <div
        style={{
          paddingTop: 15,
          paddingLeft: 15,
          paddingRight: 15,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: page === 0 ? 'flex-end' : 'space-between',
          borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
          marginTop: -2,
          position: 'relative',
          zIndex: 3,
          backgroundcolor: Colors.WHITE,
        }}
      >
        {
          page >= 1 && (
            <div
              style={{
                width: 'auto',
              }}
            >
              <Button
                text={_('back', { ns: 'ui' })}
                colorScheme="white"
                onClick={() => setPage(page - 1)}
              />
            </div>
          )
        }
        <div
          style={{
            width: 'auto',
            marginBottom: 15,
          }}
        >
          {
            page === 0 && (
              <Button
                disabled={!name || !symbol}
                text={_('continue', { ns: 'ui' })}
                onClick={() => setPage(page + 1)}
                colorScheme={!name || !symbol ? 'grey' : 'blue'}
              />
            )
          }
          {
            page === 1 && (
              <Button
                type="submit"
                text={_('group.create', { ns: 'ui' })}
                loading={isLoading}
                onClick={() => onCreate()}
                disabled={items.length === 0}
                colorScheme={items.length === 0 ? 'grey' : 'blue'}
              />
            )
          }
        </div>
      </div>
    </>
  );
}
