import * as React from 'react';

import {
  Colors,

  Item,
} from '@bluecurrent/web-component-lib';

export default function DoubleItemDummy(props) {
  return (
    <div
      style={{
        padding: 10,
        height: 'auto',
        minHeight: 55,
        backgroundColor: Colors.LIGHT_GREY,
        border: `solid 2px ${Colors.MEDIUM_WHITE}`,
        borderRadius: 10,
        marginTop: 10,
      }}
    >
      {
        props.content && (
          <>
            <div
              className="DisableSelection"
            >
              <Item
                type={props.content[0].type}
                title={props.content[0].title}
                value={props.content[0].value}
                onSwitch={props.content[0].type === 'boolean'}
              />
            </div>
            <div
              style={{
                borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
                marginTop: 10,
                paddingTop: 10,
              }}
            >
              <Item
                type={props.content[1].type}
                title={props.content[1].title}
                suffix={props.content[1].value}
                onSwitch={props.content[1].type === 'boolean'}
              />
            </div>
          </>
        )
      }
    </div>
  );
}
