import {
  USER_LOGIN,
  USER_UPDATE,
  USER_LOGOUT,
} from '../actionTypes';

const initialState = {
  logged_in: false,
  full_name: null,
  email: null,
  tel: null,
  first_login_app: null,
  login: null,
  developer_mode_enabled: false,
};

// eslint-disable-next-line default-param-last
const update = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return { ...state, ...action.payload };
    case USER_UPDATE:
      return { ...state, ...action.payload };
    case USER_LOGOUT:
      return initialState;

    default: return state;
  }
};

export default update;
