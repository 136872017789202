import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Lang,
} from '@bluecurrent/web-component-lib';

import Modal from '../Modal/index';

const DeclarationOverview = () => {
  const navigate = useNavigate();
  const { _ } = Lang.useTranslation();

  const [visible, setVisible] = React.useState(true);

  const onClose = () => {
    setVisible(false);
    setTimeout(() => navigate('../'), 500);
  };

  return (
    <Modal
      type="declarationForm"
      onClose={onClose}
      visible={visible}
      title={_('declarationOverview', { ns: 'finance' })}
    />
  );
};

export default DeclarationOverview;
