import * as React from 'react';

import {
  Colors,
  Lang,

  Text,
  Item,
} from '@bluecurrent/web-component-lib';

import SettingsTitle from '../../Modules/SettingsTitle';

/* eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member */
import useStorage from '../../../hooks/useStorage';

export default function Privacy() {
  const { _ } = Lang.useTranslation();
  const storage = useStorage();

  const [analyticsAccepted, setAnalyticsAccepted] = React.useState(storage.getItem('analytics_accepted'));
  const [cookiesAccepted, setCookiesAccepted] = React.useState(storage.getItem('cookies_accepted'));

  let analyticsAcceptedTemp = false;

  if (analyticsAccepted === 'true') analyticsAcceptedTemp = true;
  if (cookiesAccepted === 'true' && analyticsAccepted === undefined) analyticsAcceptedTemp = true;

  /* Detect changes in Storage so we can enable the switch when cookies are accepted */
  const storageEvent = () => {
    setAnalyticsAccepted(storage.getItem('analytics_accepted'));
    setCookiesAccepted(storage.getItem('cookies_accepted'));
  };

  React.useEffect(() => {
    window.addEventListener('storage', storageEvent);

    return () => window.removeEventListener('storage', storageEvent);
  }, []);
  /* END */

  const useSwitch = () => {
    setAnalyticsAccepted(analyticsAccepted === 'true' ? 'false' : 'true');
    storage.setItem('analytics_accepted', analyticsAccepted === 'true' ? 'false' : 'true');
  };

  return (
    <div
      className="FadeIn"
      style={{
        position: 'relative',
        zIndex: 0,
      }}
    >
      <SettingsTitle
        title={_('privacy', { ns: 'settings' })}
      />
      <div
        style={{
          marginTop: 15,
          marginBottom: 60,
        }}
      >
        <div
          style={{
            marginTop: 15,
            marginBottom: 15,
          }}
        >
          <Item
            type="switch"
            title={_('analytics', { ns: 'settings' })}
            value={analyticsAcceptedTemp}
            disabled={storage.getItem('cookies_accepted') !== 'true'}
            onSwitch={useSwitch}
          />
        </div>
        <Text
          fontSize="1em"
          color={Colors.DARK_GREY}
        >
          {_('analyticsDescription', { ns: 'settings' })}
        </Text>
      </div>
    </div>
  );
}
