class ResponseType {
  static STATUS_PASSWORD = 'STATUS_PASSWORD';

  static STATUS = 'STATUS';

  static CHARGE_CARDS = 'CHARGE_CARDS';

  static CHARGE_POINTS = 'CHARGE_POINTS';

  static SUSTAINABILITY_STATUS = 'SUSTAINABILITY_STATUS';

  static CH_STATUS = 'CH_STATUS';

  static SOFT_RESET = 'SOFT_RESET';

  static REBOOT = 'REBOOT';

  static AVAILABLE = 'AVAILABLE';

  static PUBLIC_CHARGING = 'PUBLIC_CHARGING';

  static PLUG_AND_CHARGE = 'PLUG_AND_CHARGE';

  static PLUG_AND_CHARGE_NOTIFICATION = 'PLUG_AND_CHARGE_NOTIFICATION';

  static DELAYED_CHARGING = 'DELAYED_CHARGING';

  static SAVE_SCHEDULE_DELAYED_CHARGING = 'SAVE_SCHEDULE_DELAYED_CHARGING';

  static OVERRIDE_DELAYED_CHARGING_TIMEOUT = 'OVERRIDE_DELAYED_CHARGING_TIMEOUT';

  static DELAYED_CHARGING_SCHEDULE = 'DELAYED_CHARGING_SCHEDULE';

  static DEFAULT_CHARGE_CARD = 'DEFAULT_CHARGE_CARD';

  static CHARGE_CARD_NAME = 'CHARGE_CARD_NAME';

  static REMOVE_CHARGE_CARD = 'REMOVE_CHARGE_CARD';

  static ADD_CHARGE_CARD = 'ADD_CHARGE_CARD';

  static CH_SETTINGS = 'CH_SETTINGS';

  static GRID_STATUS = 'GRID_STATUS';

  static ACCOUNT = 'ACCOUNT';

  static STATUS_API_TOKEN = 'STATUS_API_TOKEN';

  static ERROR = 'ERROR';
}

Object.freeze(ResponseType);

export default ResponseType;
