import * as React from 'react';

import {
  Colors,

  Text,
} from '@bluecurrent/web-component-lib';

export default function GroupedItemsDummy(props) {
  return (
    <>
      {
        props.label && (
          <Text
            fontSize="1em"
            color={Colors.DARK_GREY}
            fontWeight={500}
          >
            {props.label}
          </Text>
        )
      }
      <div
        style={{
          width: '100%',
          height: 15,
          backgroundColor: Colors.LIGHT_GREY,
          border: `solid 2px ${Colors.MEDIUM_WHITE}`,
          borderRadius: 10,
          marginTop: 10,
          marginBottom: 5,
        }}
      />
    </>
  );
}
