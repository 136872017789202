import * as React from 'react';

import {
  Breakpoint,
  Lang,

  useDimensions,

  Text,
} from '@bluecurrent/web-component-lib';

import PlaceholderCards from '../../../assets/svg/Placeholders/Cards';

export default function Transactions({ embedded }) {
  const size = useDimensions();
  const { _ } = Lang.useTranslation();

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: embedded ? 0 : 20,
        paddingBottom: 20,
        marginTop: size.width < Breakpoint.xs ? -75 : -50,
      }}
    >
      <div
        style={{
          height: 300,
          width: '100%',
        }}
      >
        <PlaceholderCards />
      </div>
      <div
        style={{
          marginTop: -30,
          height: '20%',
        }}
      >
        <Text
          fontWeight={500}
          fontSize="1.3em"
          textAlign="center"
        >
          {_('noTransactionsFound', { ns: 'finance' })}
        </Text>
      </div>
    </div>
  );
}
