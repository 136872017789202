import * as React from 'react';
import {
  motion,
  AnimatePresence,
} from 'framer-motion';

import {
  Colors,
  Lang,
  Breakpoint,
  Backgrounds,

  useDimensions,

  Text,
  Button,
  Confetti,
} from '@bluecurrent/web-component-lib';

import Together from '../../../assets/img/together.png';

export default function RequestChargeCard() {
  const size = useDimensions();
  const { _ } = Lang.useTranslation();

  return (
    <div
      style={{
        borderRadius: size.width < 1200 ? 10 : 15,
        height: size.width < 1200 ? 'auto' : '100%',
        minHeight: 210,
        width: '100%',
        background: Backgrounds.ORANGE,
        display: 'flex',
        flexDirection: size.width >= Breakpoint.xs && size.width < 1200 ? 'row' : 'column',
        justifyContent: 'space-between',
        position: 'relative',
        overflow: 'hidden',
        zIndex: 0,
      }}
    >

      <div
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          opacity: 0.9,
          paddingTop: size.width < 1200 ? 20 : 30,
          paddingBottom: size.width < 1200 ? 15 : 0,
        }}
      >
        <Text
          fontWeight={500}
          fontSize={size.width < 1200 ? '1.3em' : '1.5em'}
          color={Colors.WHITE}
        >
          { _('requestChargeCard', { ns: 'ui' }) }
        </Text>
        <div
          style={{
            paddingTop: size.width < 1200 ? 10 : 15,
            opacity: 0.9,
            width: 'auto',
          }}
        >
          <Text
            fontWeight={300}
            fontSize={size.width < 1200 ? '1.15em' : '1.25em'}
            color={Colors.WHITE}
          >
            { _('arrangeThisYourselfOnline', { ns: 'ui' }) }
          </Text>
        </div>
        <div
          style={{
            width: '100%',
            paddingTop: size.width < 1200 ? 15 : 25,
          }}
        >
          <Button
            text={_('requestChargeCard', { ns: 'ui' })}
            type="button"
            styling
            colorScheme="custom-yellow-orange"
            onClick={() => window.open('https://bo.bluecurrent.nl/app/f?p=101:110:::NO:RP,110')}
          />
        </div>
      </div>
      <div
        style={{
          paddingLeft: 50,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        {
          size.width >= Breakpoint.sm && (
            <>
              {
                size.height >= 485 && (
                  <Confetti
                    type="DotsPath"
                    transform={`scaleX(-1) scale(1.5) ${size.height < 640 ? 'rotate(-20deg)' : ''}`}
                    position="absolute"
                    right={-100}
                    bottom={
                      size.height < 640
                        ? -100
                        : size.width < 1200
                          ? -100
                          : -40
                    }
                    width={600}
                    opacity={1}
                  />
                )
              }
              {
                size.height >= 640 && (
                  <Confetti
                    type="Figure"
                    color={Colors.YELLOW}
                    position="absolute"
                    bottom={10}
                    right={30}
                    height={100}
                    width={100}
                  />
                )
              }
            </>
          )
        }
        <AnimatePresence>
          {
            size.width >= 1200 && size.height >= 730 && (
              <motion.img
                src={Together}
                alt="together"
                height="auto"
                width="90%"
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
                exit={{
                  opacity: 0,
                }}
                style={{

                }}
              />
            )
          }
        </AnimatePresence>
      </div>
    </div>
  );
}
