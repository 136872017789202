const useUserIdentity = () => (user) => {
  /* Combine user data with Hubspot widgets */
  const conversationsSettingsHandler = (email, token) => {
    window.hsConversationsSettings = {
      ...window.hsConversationsSettings,
      identificationEmail: email,
      identificationToken: token,
    };

    window.HubSpotConversations.resetAndReloadWidget();
  };

  if (user && user.email && window.HubSpotConversations) {
    window.HubSpotConversations.widget.load();

    conversationsSettingsHandler(user.email, user.hubspot_user_identity);

    return user.email;
  }

  if (!window.HubSpotConversations) {
    window.hsConversationsOnReady = [
      () => {
        window.HubSpotConversations.widget.load();

        if (user && user.email) {
          conversationsSettingsHandler(user.email, user.hubspot_user_identity);
        }
      },
    ];
  } else if (window.hsConversationsSettings.identificationToken) {
    delete window.hsConversationsSettings.identificationEmail;
    delete window.hsConversationsSettings.identificationToken;
    window.HubSpotConversations.resetAndReloadWidget();
  }

  return null;
};

export default useUserIdentity;
