import {
  SET_DELAYED_CHARGING,
  SET_PRICE_BASED_CHARGING,
  SET_SOLAR_BASED_CHARGING,
} from '../api/types/ApiMessages';

/* Javascript object for profiled charging with key, value and api's */
const ProfiledChargingSettings = {
  price_based_charging: {
    apiMessage: SET_PRICE_BASED_CHARGING,
    titleTranslation: [
      'chargingProfiles.priceBasedCharging',
      'chargepoint',
    ],
  },
  delayed_charging: {
    apiMessage: SET_DELAYED_CHARGING,
    titleTranslation: [
      'chargingProfiles.delayedCharging',
      'chargepoint',
    ],
  },
  solar_based_charging: {
    apiMessage: SET_SOLAR_BASED_CHARGING,
    titleTranslation: [
      'chargingProfiles.solarBasedCharging',
      'chargepoint',
    ],
  },
};

Object.freeze(ProfiledChargingSettings);

export default ProfiledChargingSettings;
