import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Sentry from '@sentry/browser';

import {
  Lang,
  Colors,

  Button,
  ConfirmDialog,
} from '@bluecurrent/web-component-lib';

import executeCommand from '../../../../../api/executeCommand';
import {
  EDIT_GROUP,
  DELETE_GROUP,
} from '../../../../../api/types/ApiMessages';
import addNotification from '../../../../../redux/creators/notifications';

import SYMBOLS from '../../../Groups/icons';

import Information from './Information';
import ChargePoints from './ChargePoints';

export default function EditGroup({
  data,
  setTitle,
  setSubTitle,
  onClose,
}) {
  const { _ } = Lang.useTranslation();
  const dispatch = useDispatch();

  const chargePoints = useSelector((state) => state.ChargePoints);

  const [page, setPage] = React.useState(0);
  const [isLoading, setLoading] = React.useState(false);
  const [isDeleteLoading, setDeleteLoading] = React.useState(false);
  const [isDeleteDialogVisible, setDeleteDialogVisible] = React.useState(false);

  const [id, setId] = React.useState(null);
  const [name, setName] = React.useState('');
  const [symbol, setSymbol] = React.useState('House');
  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    setLoading(false);
    setId(data.id);
    setName(data.name);
    setSymbol(data.icon.name);
    setItems(data.chargepoints);
  }, []);

  React.useEffect(() => {
    if (page === 0) {
      setTitle(_('group.edit', { ns: 'ui' }));
      setSubTitle('');
    }
    if (page === 1) {
      setTitle(name);
      setSubTitle(_('group.edit', { ns: 'ui' }));
    }
  }, [page, name]);

  const handleError = (error) => {
    Sentry.captureException(error);
    dispatch(
      addNotification(
        'failed',
        _('error.somethingWrong', { ns: 'chargepoint' }),
        'red',
      ),
    );
  };

  const onEdit = () => {
    const icon = SYMBOLS.find((x) => x.name === symbol);

    setLoading(true);

    executeCommand(EDIT_GROUP, {
      body: {
        name,
        id,
        icon: {
          name: icon.name,
          width: icon.width,
          height: icon.height,
        },
        chargepoints: items,
      },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'UPDATE_GROUP',
            id,
            payload: {
              id,
              name,
              icon: {
                name: icon.name,
                width: icon.width,
                height: icon.height,
              },
              chargepoints: items,
            },
          });

          onClose();
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const onConfirmDelete = () => {
    setDeleteDialogVisible(false);
    setDeleteLoading(true);

    executeCommand(DELETE_GROUP, { body: { id } })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: 'DELETE_GROUP',
            id,
          });

          setDeleteLoading(false);

          onClose();
        } else {
          setDeleteLoading(false);
          handleError(response.data);
        }
      })
      .catch((error) => {
        setDeleteLoading(false);
        handleError(error);
      });
  };

  return (
    <>
      <Information
        isVisible={page === 0}
        name={name}
        setName={setName}
        symbol={symbol}
        setSymbol={setSymbol}
      />
      <ChargePoints
        isVisible={page === 1}
        items={items}
        setItems={setItems}
        chargePoints={chargePoints}
        onClose={onClose}
      />
      <div
        style={{
          paddingTop: 15,
          paddingLeft: 15,
          paddingRight: 15,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
          marginTop: -2,
          position: 'relative',
          zIndex: 3,
          backgroundcolor: Colors.WHITE,
        }}
      >
        {
          page >= 1 ? (
            <div
              style={{
                width: 'auto',
              }}
            >
              <Button
                text={_('back', { ns: 'ui' })}
                colorScheme="white"
                onClick={() => setPage(page - 1)}
              />
            </div>
          ) : (
            <Button
              type="icon"
              colorScheme={isDeleteLoading ? 'grey' : 'white'}
              onClick={() => setDeleteDialogVisible(true)}
              loading={isDeleteLoading}
              disabled={isDeleteLoading}
              icon={{
                name: 'Bin',
                height: 20,
                width: 20,
                color: Colors.RED,
                iconSet: 'FA',
              }}
              border={`2px solid ${Colors.RED}`}
            />
          )
        }
        <div
          style={{
            width: 'auto',
            marginBottom: 15,
          }}
        >
          {
            page === 0 && (
              <Button
                disabled={!name || !symbol}
                text={_('continue', { ns: 'ui' })}
                onClick={() => setPage(page + 1)}
                colorScheme={!name || !symbol ? 'grey' : 'blue'}
              />
            )
          }
          {
            page === 1 && (
              <Button
                type="submit"
                text={_('group.edit', { ns: 'ui' })}
                loading={isLoading}
                onClick={() => onEdit()}
                disabled={items.length === 0}
                colorScheme={items.length === 0 ? 'grey' : 'blue'}
              />
            )
          }
        </div>
      </div>
      <ConfirmDialog
        visible={isDeleteDialogVisible}
        title={_('delete', { ns: 'ui' })}
        description={_('group.deleteConfirmDialog', { ns: 'ui' })}
        accept={{
          text: _('delete', { ns: 'ui' }),
          onClick: () => onConfirmDelete(),
          colorScheme: 'red',
        }}
        cancel={{
          onClick: () => setDeleteDialogVisible(false),
        }}
      />
    </>
  );
}
