import * as React from 'react';
import {
  useParams,
  useSearchParams,
  useNavigate,
} from 'react-router-dom';
import { Lang } from '@bluecurrent/web-component-lib';
import Modal from '../Modal/index';

const ExcludedOnChargePoints = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const { _ } = Lang.useTranslation();

  const [visible, setVisible] = React.useState(true);

  const onClose = () => {
    setVisible(false);
    setTimeout(() => navigate('../'), 500);
  };

  return (
    <Modal
      type="excludedOnChargePoints"
      data={id}
      onClose={onClose}
      visible={visible}
      title={_('excludedOnChargePoints', { ns: 'chargecard' })}
      subTitle={id}
      status={query.get('s')}
    />
  );
};

export default ExcludedOnChargePoints;
