import * as React from 'react';

import {
  Colors,

  Loader,
} from '@bluecurrent/web-component-lib';

export default function SumWrapper(props) {
  return (
    <div
      style={{
        height: 'auto',
        border: `solid 2px ${Colors.MEDIUM_WHITE}`,
        backgroundColor: Colors.LIGHT_GREY,
        marginTop: 15,
        marginBottom: 15,
        borderRadius: 7.5,
        padding: 15,
        transition: 'all .2s linear',
      }}
    >
      {
        props.isLoading && (
          <div
            style={{
              height: 100,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Loader />
          </div>
        )
      }
      {props.children}
    </div>
  );
}
