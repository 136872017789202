import * as React from 'react';

import {
  Colors,

  Text,
} from '@bluecurrent/web-component-lib';

function DataModule(props) {
  const [hover, setHover] = React.useState(false);

  return (
    <button
      type="button"
      onClick={props.onClick ?? null}
      className={props.onClick && 'Hover'}
      onMouseEnter={() => {
        if (props.onClick) setHover(true);
      }}
      onMouseLeave={() => {
        if (props.onClick) setHover(false);
      }}
      style={{
        width: props.width ?? 'calc(50% - 5px)',
        height: 'auto',
        minHeight: 60,
        backgroundColor: hover ? Colors.LIGHT_GREY : Colors.WHITE,
        marginRight: 5,
        border: `solid 2px ${Colors.MEDIUM_WHITE}`,
        borderRadius: 10,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        paddingRight: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        fontSize: '1em',
        transition: 'all .2s linear',
      }}
    >
      <div
        style={{
          width: 25,
          minWidth: 25,
          height: 40,
          marginRight: 10,
          backgroundImage: `url('${props.image}')`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      />
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Text
          fontSize="1.1em"
        >
          {props.name}
          :
        </Text>
        <Text
          fontSize="1.1em"
        >
          <span
            style={{
              fontWeight: 700,
            }}
          >
            {props.value}
          </span>
          {' '}
          <span
            style={{
              fontSize: '0.9em',
            }}
          >
            {props.unit}
          </span>
        </Text>
      </div>
    </button>
  );
}

export default React.memo(DataModule);
