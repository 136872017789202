import * as React from 'react';

import {
  Colors,
  Lang,

  useDimensions,
  counter,

  Text,
  Confetti,
} from '@bluecurrent/web-component-lib';

import LandscapeBackground from '../../../assets/svg/LandscapeBackground.svg';
import Tree from '../../../assets/svg/Tree';

export default function TotalTrees(props) {
  const size = useDimensions();
  const { _ } = Lang.useTranslation();

  const calcTrees = (e) => {
    let result = e.toString();
    const a = result.length;
    if (a >= 7) {
      result = {
        count: result.substring(0, result.length - 6),
        unit: 'M',
      };
    } else if (a >= 4) {
      result = {
        count: result.substring(0, result.length - 3),
        unit: 'k',
      };
    } else {
      result = {
        count: result,
        unit: '',
      };
    }

    return result;
  };

  return (
    <div
      className="Hover"
      style={{
        width: '100%',
        height: '100%',
        border: `solid 2px ${Colors.MEDIUM_WHITE}`,
        borderRadius: 10,
        background: 'linear-gradient(180deg, rgba(229,244,251,1) 50%, rgba(255,255,255,1) 100%)',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
        }}
      >
        <Confetti
          type="DotsPath"
          height={600}
          width={600}
          color={Colors.WHITE}
          position="absolute"
          top={-120}
          right={-50}
          transform="rotate(-50deg)"
          opacity={0.7}
        />
      </div>
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'absolute',
          backgroundColor: 'transparent',
        }}
      >
        {
          size.width > 480 && (
            <div
              style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                zIndex: 4,
              }}
            >
              <Tree
                height="120%"
                marginLeft={size.width < 525 ? -165 : -115}
                transform="rotate(5deg)"
                marginTop={-40}
              />
            </div>
          )
        }
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            zIndex: 3,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: size.width < 480 ? 'flex-start' : 'flex-end',
            bottom: -70,
            right: size.width < 480 ? 'initial' : 20,
            left: size.width < 480 ? 10 : 'initial',
          }}
        >
          <Text
            textAlign={size.width < 480 ? 'left' : 'right'}
            whiteSpace="normal"
          >
            {_('totalTrees', { ns: 'ui' })}
          </Text>
        </div>
        <div
          style={{
            height: '100%',
            width: 550,
            marginTop: 30,
            position: 'absolute',
            backgroundImage: `url('${LandscapeBackground}')`,
            backgroundPosition: 'bottom',
            backgroundRepeat: 'repeat-x',
            transform: 'scale(-1, 1)',
            zIndex: 2,
          }}
        />
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: size.width < 480 ? 'flex-start' : 'flex-end',
            bottom: size.width < 480 ? -90 : -87,
            right: size.width < 480 ? 'initial' : 20,
            left: size.width < 480 ? 10 : 'initial',
          }}
        >
          <Text
            fontSize={size.width < 480 ? '3em' : '5em'}
            fontWeight={500}
            textAlign="right"
          >
            {`${counter(0, parseInt(calcTrees(props.total).count, NaN), 1)}${calcTrees(props.total)?.unit}`}
          </Text>
        </div>
      </div>
    </div>
  );
}
