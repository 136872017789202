import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import {
  motion,
  AnimatePresence,
} from 'framer-motion';

import {
  Text,
  Button,
  Lang,
  Colors,
  DropdownMenu,

  useDataAttributes,
  useOutsideClick,
} from '@bluecurrent/web-component-lib';

import symbols from './icons';

export default function Groups(props) {
  const { _ } = Lang.useTranslation();
  const groups = useSelector((state) => state.Groups.groups);
  const loaded = useSelector((state) => state.Groups.loaded);
  const dispatch = useDispatch();

  const dropdownRef = React.useRef();
  const dataRef = React.useRef();

  const [isDropdownVisible, setDropdownVisible] = React.useState(false);

  useDataAttributes(dataRef, props.dataAttributes, {
    all: {
      id: props.id,
      outsideClickExemption: true,
    },
  });

  useDataAttributes(dropdownRef, undefined, {
    single: {
      id: props.id,
    },
  }, isDropdownVisible);

  useOutsideClick(dropdownRef, () => setDropdownVisible(false));

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        duration: 0.2,
      }}
    >
      <div
        style={{
          paddingBottom: 7,
          borderBottom: `solid 2px ${Colors.MEDIUM_WHITE}`,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: 'auto',
          }}
        >
          <Text fontSize="1em" fontWeight={500}>
            {`${_('group.groups', { ns: 'ui' })}:`}
          </Text>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div
            ref={dataRef}
            className="Hover"
            style={{
              width: 'auto',
              marginLeft: 5,
              position: 'relative',
            }}
          >
            <Button
              type="icon"
              size="medium"
              colorScheme="white"
              icon={{
                name: 'Ellipsis-H',
                width: 35,
                height: 35,
                color: Colors.GREY,
                iconSet: 'FA',
              }}
              onClick={() => {
                setDropdownVisible(!isDropdownVisible);
              }}
            />
            {
              isDropdownVisible && (
                <div
                  ref={dropdownRef}
                  style={{
                    position: 'absolute',
                    height: 'auto',
                    width: 'auto',
                    zIndex: 1,
                    right: 240,
                    top: -25,
                  }}
                >
                  <DropdownMenu
                    onClose={() => {
                      setDropdownVisible(false);
                    }}
                    items={[
                      {
                        id: 0,
                        title: _('group.create', { ns: 'ui' }),
                        onClick: () => dispatch({
                          type: 'OVERLAY',
                          payload: {
                            layout: 'groupsCreate',
                            display: true,
                            title: _('group.create', { ns: 'ui' }),
                            data: null,
                          },
                        }),
                      },
                      {
                        id: 1,
                        title: _('edit', { ns: 'ui' }),
                        onClick: () => dispatch({
                          type: 'OVERLAY',
                          payload: {
                            layout: 'groupsListEdit',
                            display: true,
                            title: _('group.create', { ns: 'ui' }),
                            data: null,
                          },
                        }),
                        disabled: groups.length === 0,
                      },
                    ]}
                    right
                  />
                </div>
              )
            }
          </div>
        </div>
      </div>
      <div
        style={{
          paddingTop: 7,
          height: 'calc(100vh - 254px)',
          backgroundColor: Colors.WHITE,
          overflowY: 'scroll',
          overflowX: 'hidden',
          paddingRight: 7,
        }}
      >
        <motion.div
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
            marginBottom: 5,
          }}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 1,
            delay: 0.2,
          }}
        >
          {
            groups.length > 0 && (
              <Button
                type="link"
                text={_('allChargePoints', { ns: 'ui' })}
                icon={{
                  name: 'UMOVE',
                  width: 25,
                  height: 25,
                  color: Colors.GREY,
                  iconSet: 'BlueCurrent',
                }}
                onClick={() => props.onClickGroup(null, null, null)}
                selected={
                  props.selected === null && (props.filter === undefined || props.filter === null)
                }
              />
            )
          }
        </motion.div>
        <AnimatePresence>
          {
            loaded
              ? groups.map(({
                group: {
                  id, name, icon, chargepoints,
                },
              }, index) => {
                const symbol = symbols.find(({ name: iconName }) => iconName === icon.name);

                return (
                  <motion.div
                    key={id}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      position: 'relative',
                      marginBottom: 5,
                    }}
                    initial={{
                      opacity: 0,
                    }}
                    animate={{
                      opacity: 1,
                    }}
                    transition={{
                      duration: 0.2,
                      delay: 0.4 + index * 0.1,
                    }}
                  >
                    <Button
                      type="link"
                      text={name}
                      icon={{
                        name: symbol?.name ?? 'Square',
                        type: symbol?.type ?? 'solid',
                        width: symbol?.width ?? 25,
                        height: symbol?.height ?? 25,
                        color: symbol?.color ?? Colors.MEDIUM_GREY,
                        iconSet: symbol?.iconSet ?? 'FA',
                      }}
                      selected={props.selected === id}
                      disabled={chargepoints.length === 0}
                      onClick={
                        () => props.onClickGroup(id, name, chargepoints)
                      }
                    />
                  </motion.div>
                );
              })
              : [...Array(4)].map((event, index) => (
                <motion.div
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={index}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    paddingRight: 7,
                  }}
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                  }}
                  transition={{
                    duration: `0.${index + 2}`,
                    delay: `0.${index + 4}`,
                  }}
                >
                  <Skeleton
                    height={40}
                    width={40}
                  />
                  <Skeleton
                    height={30}
                    width={315}
                    style={{
                      marginLeft: 15,
                    }}
                  />
                </motion.div>
              ))
          }
        </AnimatePresence>
        {
          groups.length === 0 && loaded && (
            <div
              style={{
                marginTop: 20,
                paddingBottom: 10,
                paddingTop: 3,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingBottom: 5,
                  alignItems: 'center',
                }}
              >
                <Text fontSize="1em" fontWeight={500}>
                  {_('group.groups', { ns: 'ui' })}
                </Text>
              </div>
              <div
                style={{
                  marginBottom: 15,
                }}
              >
                <Text fontSize="1em">{_('group.none', { ns: 'ui' })}</Text>
              </div>
              <div
                style={{
                  marginTop: 10,
                }}
              >
                <Button
                  text={_('group.create', { ns: 'ui' })}
                  size="medium"
                  onClick={() => dispatch({
                    type: 'OVERLAY',
                    payload: {
                      layout: 'groupsCreate',
                      display: true,
                      title: _('group.create', { ns: 'ui' }),
                      data: null,
                    },
                  })}
                />
              </div>
            </div>
          )
        }
      </div>
    </motion.div>
  );
}
