import React from 'react';
import Leaves from './Leaves';
import Branches from './Branches';
import Decoration from './Decoration';

export default function Tree(props) {
  return (
    <g style={{ transform: 'translate(1700px, 2000px) rotate(-100deg)' }}>
      <Leaves />
      <Branches />
      <Decoration data={props.data} />
    </g>
  );
}
