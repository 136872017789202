import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  AnimatePresence,
  motion,
} from 'framer-motion';
import {
  useNavigate,
} from 'react-router-dom';

import {
  useDimensions,
} from '@bluecurrent/web-component-lib';

import Tour from '../../../config/Tour.config';

import TourHint from './Hint';

export default function TourItem(props) {
  const size = useDimensions();
  const navigate = useNavigate();

  const chargePointData = useSelector((state) => state.ChargePoints);
  const permissions = useSelector((state) => state.Permissions);

  const active = props.active - 1;

  // Item
  const [height, setHeight] = React.useState(0);
  const [width, setWidth] = React.useState(0);
  const [left, setLeft] = React.useState(0);
  const [top, setTop] = React.useState(0);
  const [right, setRight] = React.useState(0);

  // Hint
  const [hintHeight, setHintHeight] = React.useState(0);
  const [hintTop, setHintTop] = React.useState(0);
  const [hintBottom, setHintBottom] = React.useState(0);
  const [hintLeft, setHintLeft] = React.useState(0);
  const [hintRight, setHintRight] = React.useState(0);

  const [isImageVisible, setImageVisible] = React.useState(true);

  const filterFunction = (step) => {
    if (!step.permissionHandler) return true;
    return step.permissionHandler(permissions);
  };

  const pathname = () => {
    const { url } = Tour[active].location;

    switch (Tour[active].location.type) {
      case 'evse_id':
        return url.replace(':id', chargePointData[0].evse_id);
      default:
        return Tour[active].location.url;
    }
  };

  const calculateRight = () => {
    if (window.location.pathname === pathname()) {
      setRight(
        size.width - document
          .getElementById(Tour[active].target)
          .getBoundingClientRect().right,
      );
    }
  };

  const calculateHintTop = () => {
    if ((top + hintHeight) >= size.height) {
      setHintTop(top - (hintHeight / 4));
    } else {
      setHintTop(top);
    }
  };

  const noSpaceHandler = () => {
    if ((top + height) >= size.height) {
      setHintLeft(10);
      setHintBottom(10);
      setHintTop('auto');
      setHintRight('auto');
    }
  };

  React.useEffect(() => {
    if (!props.fullscreen) setImageVisible(true);
  }, [
    active,
    size,
    props.fullscreen,
  ]);

  React.useEffect(async () => {
    if (!props.fullscreen) {
      await navigate(pathname());

      const onPageLoad = async () => {
        await document.getElementById(Tour[active].target).scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      };

      if (document.readyState === 'complete') {
        return onPageLoad();
      }
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
    return null;
  }, [
    active,
    size,
    props.fullscreen,
  ]);

  /* Get component dimensions */
  React.useEffect(() => {
    if (!props.fullscreen) {
      setTimeout(() => {
        if (window.location.pathname === pathname()) {
          setHeight(document.getElementById(Tour[active].target).clientHeight);
          setWidth(document.getElementById(Tour[active].target).clientWidth);
          setLeft(document.getElementById(Tour[active].target).getBoundingClientRect().left);
          setTop(document.getElementById(Tour[active].target).getBoundingClientRect().top);
        }
      }, 500);
    }

    props.setVisible();
  }, [
    size,
    active,
    props.fullscreen,
  ]);

  /* Get hint dimensions */
  React.useEffect(() => {
    if (!props.fullscreen) {
      setTimeout(() => {
        if (window.location.pathname === pathname()) {
          setHintHeight(document.getElementById(`Tour_Hint${active}`).clientHeight);
        }
      }, 500);
    }
  }, [
    size,
    active,
    props.fullscreen,
  ]);

  React.useEffect(async () => {
    if (!props.fullscreen) {
      await calculateRight();

      const requiredSpace = size.width < 900 ? 345 : 405;

      if (
        (
          (right + width) < (size.width / 2)
          || (right + width) > (size.width / 2)
        ) && (left > requiredSpace)
      ) {
        /* Left of spotlight */
        calculateHintTop();
        setHintLeft('auto');
        setHintBottom('auto');
        setHintRight(right + width + 15);
      } else if (
        (
          (left + width) < (size.width / 2)
          || (left + width) > (size.width / 2)
        ) && (right > requiredSpace)
      ) {
        /* Right of spotlight */
        calculateHintTop();
        setHintLeft(left + width + 15);
        setHintBottom('auto');
        setHintRight('auto');
      } else if (
        ((right + width + 15) > (size.width - requiredSpace))
        || ((left + width + 15) > (size.width - requiredSpace))
      ) {
        /* Under spotlight */
        if (((top + height + hintHeight) >= size.height)) {
          setImageVisible(false);
          return noSpaceHandler();
        }
        setHintTop(top + height + 15);
        setHintLeft(left);
        setHintBottom('auto');
        setHintRight('auto');
      }
    } else {
      setHintTop(0);
      setHintLeft(0);
      setHintBottom(0);
      setHintRight(0);
    }
    return null;
  }, [
    top,
    left,
    right,
    height,
    width,
    size,
    props.fullscreen,
  ]);

  return (
    <AnimatePresence>
      {
        (props.fullscreen || props.visible) && (
          <>
            {
              !props.fullscreen && (
                <motion.div
                  style={{
                    backgroundColor: 'transparent',
                    position: 'absolute',
                    height,
                    width,
                    left,
                    top,
                    transition: 'all .1s linear',
                  }}
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                  }}
                  exit={{
                    opacity: 0,
                  }}
                  transition={{
                    duration: 0.3,
                    delay: 0.5,
                  }}
                >
                  {props.children}
                  {
                    !Tour[active].interactive && (
                      <div
                        style={{
                          height: '100%',
                          width: '100%',
                          backgroundColor: 'transparent',
                          position: 'absolute',
                          top: 0,
                          zIndex: 2,
                          borderRadius: 10,
                        }}
                      />
                    )
                  }
                </motion.div>
              )
            }
            <TourHint
              visible={props.visible}
              active={active}
              length={Tour.filter(filterFunction).length}
              spacing={{
                left: hintLeft,
                right: hintRight,
                top: hintTop,
                bottom: hintBottom,
              }}
              content={{
                title: Tour[active].content.title,
                text: Tour[active].content.text,
                image: Tour[active].content.image,
              }}
              image={isImageVisible}
              onNext={props.onNext}
              onFinish={props.onFinish}
              fullscreen={props.fullscreen}
            />
          </>
        )
      }
    </AnimatePresence>
  );
}
