import {
  UPDATE_GRIDS,
  ADD_GRID,
  SET_SELECTED_GRID,
  SET_ENERGY_USAGE,
  USER_LOGOUT,
} from '../actionTypes';

const initialState = {
  grids: [],
  selectedGrid: null,
};

// eslint-disable-next-line default-param-last
const update = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_GRIDS:
      return { ...state, ...action.payload };

    case ADD_GRID:
      return { ...state, grids: [...state.grids, action.payload] };

    case SET_SELECTED_GRID:
      return { ...state, selectedGrid: action.payload.id };

    case SET_ENERGY_USAGE: {
      return {
        ...state,
        grids: state.grids.map((i) => {
          if (i.id === action.id) {
            return {
              ...i,
              energy_usage: action.payload,
            };
          }
          return i;
        }),
      };
    }

    case USER_LOGOUT:
      return initialState;

    default: return state;
  }
};

export default update;
