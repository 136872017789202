import * as React from 'react';
import {
  motion,
  AnimatePresence,
} from 'framer-motion';

import {
  useDimensions,

  Colors,
  Lang,
  Backgrounds,

  Confetti,
  Icon,
  Text,
  Badge,
} from '@bluecurrent/web-component-lib';

import AppScreen from '../../../../assets/img/app.png';

export default function App() {
  const { _ } = Lang.useTranslation();
  const size = useDimensions();

  const [hover, setHover] = React.useState(false);

  function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
      'MacIntel',
      'Macintosh',
      'MacPPC',
      'Mac68K',
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  }

  return (
    <button
      type="button"
      className={size.width < 1078 ? 'Hover' : ''}
      onClick={() => {
        if (size.width < 1078) {
          if (iOS()) {
            window.open(
              'https://apps.apple.com/nl/app/bluecurrent/id1447733712',
              '_blank',
            );
          } else {
            window.open(
              'https://play.google.com/store/apps/details?id=com.bluecurrent.bcuweb',
              '_blank',
            );
          }
        }
      }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'columns',
        alignItems: 'flex-end',
        position: 'relative',
        overflow: 'hidden',
        border: 'none',
        backgroundColor: 'transparent',
        fontSize: '1em',
        padding: 0,
      }}
    >
      <div
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          transform: 'scaleX(-1)',
          right: size.width >= 1078 ? -120 : 0,
          opacity: 0.3,
        }}
      >
        <Confetti
          type="DotsPath"
        />
      </div>
      <div
        style={{
          height: size.width < 1610 ? '100%' : '90%',
          width: '100%',
          background: Backgrounds.BLUE,
          borderRadius: 10,
        }}
      >
        <div
          style={{
            width: size.width < 1610 ? '100%' : '55%',
            height: '95%',
            paddingTop: size.width < 1078 ? 0 : 20,
            paddingBotton: size.width < 1078 ? 0 : 15,
            paddingLeft: 20,
            paddingRight: 15,
            position: 'relative',
            display: 'flex',
            flexDirection: size.width < 1078 ? 'row' : 'column',
            alignItems: size.width < 1078 ? 'center' : 'flex-start',
            justifyContent: 'space-between',
            marginTop: 2,
          }}
        >
          <div>
            <Text
              fontWeight={500}
              fontSize="1.3em"
              color={Colors.WHITE}
            >
              {_('chargeEvenFaster', { ns: 'ui' })}
            </Text>
            <div
              style={{
                marginTop: 5,
              }}
            >
              <Text
                fontWeight={300}
                fontSize="1.1em"
                color={Colors.WHITE}
              >
                {_('downloadApp', { ns: 'ui' })}
              </Text>
            </div>
          </div>
          {
            size.width >= 1078 && (
              <div>
                <div
                  style={{
                    width: 'auto',
                    height: 65,
                  }}
                >
                  <Badge
                    store="PlayStore"
                    interactive
                  />
                </div>
                <div
                  style={{
                    width: 'auto',
                    height: 43,
                    marginBottom: 3,
                  }}
                >
                  <Badge
                    store="AppStore"
                    height={43}
                    width="auto"
                    interactive
                  />
                </div>
              </div>
            )
          }
          {
            size.width < 1078 && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: 20,
                }}
              >
                <AnimatePresence>
                  {
                    (hover && size.width >= 1078) && (
                      <motion.div
                        style={{
                          marginTop: 1,
                        }}
                        initial={{
                          opacity: 0,
                          marginLeft: 0,
                        }}
                        animate={{
                          opacity: 1,
                          marginLeft: 10,
                        }}
                        exit={{
                          opacity: 0,
                          marginLeft: 40,
                        }}
                      >
                        <Icon
                          name="ArrowRight"
                          height={15}
                          width={15}
                          color={Colors.WHITE}
                          iconSet="FA"
                        />
                      </motion.div>
                    )
                  }
                </AnimatePresence>
                {
                  size.width < 1078 && (
                    <div
                      style={{
                        marginTop: 1,
                        marginLeft: 10,
                      }}
                    >
                      <Icon
                        name="ArrowRight"
                        height={
                          ((size.width >= 340 && size.width < 570) || size.width >= 770)
                            ? 15
                            : 20
                        }
                        width={
                          ((size.width >= 340 && size.width < 570) || size.width >= 770)
                            ? 15
                            : 20
                        }
                        color={Colors.WHITE}
                        iconSet="FA"
                      />
                    </div>
                  )
                }
              </div>
            )
          }
        </div>
      </div>
      {
        size.width >= 1610 && (
          <div
            style={{
              position: 'absolute',
              height: '100%',
              width: '50%',
              right: 0,
              backgroundColor: 'transparent',
              overflow: 'hidden',
            }}
          >
            <img
              src={AppScreen}
              alt="App"
              height="auto"
              width="100%"
            />
          </div>
        )
      }
    </button>
  );
}
