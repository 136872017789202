import axios from 'axios';

import { BASE_URI } from './types/ApiMessages';
import useCommands from '../hooks/useCommands';
import { SocketMessage } from './SocketMessage';
import store from '../redux/store';
import { registerFlow, finishFlow } from '../redux/creators/flows';
/* eslint-disable-next-line import/no-named-as-default-member, import/no-named-as-default */
import useStorage from '../hooks/useStorage';

const storage = useStorage();
const commands = useCommands();

const socketCommand = (request, data) => new Promise((resolve, reject) => {
  let receivedCommandSuccesful = null;

  /* eslint-disable-next-line prefer-promise-reject-errors */
  const timer = setTimeout(() => reject({ request, data, receivedCommandSuccesful }), 15000);

  let flow;
  if (request.async) {
    flow = registerFlow(data.evse_id, request.command);

    store.dispatch(flow);
  }

  const msg = new SocketMessage(request.command, {
    flow_id: flow?.id ?? null,
    ...data,
  });

  commands.send(msg);

  /* callback RESULT */
  const resultCallback = (event) => {
    if (event.flow_id === flow.id) {
      store.dispatch(finishFlow(event.flow_id));
      if (event.success === false || event.error) {
        clearTimeout(timer);
        /* eslint-disable-next-line prefer-promise-reject-errors */
        reject({
          receivedCommandSuccesful, latterObjectSuccesful: false, request, data, event,
        });
      } else {
        clearTimeout(timer);
        resolve({
          receivedCommandSuccesful,
          result: event,
        });
      }
    }
  };

  commands.once(request.object, (event) => {
    if (event.flow_id === flow?.id || !request.async) {
      receivedCommandSuccesful = event.success && !event.error;
      if (event.success === false || event.error) {
        commands.off(request.latterObject, resultCallback);
        clearTimeout(timer);
        /* eslint-disable-next-line prefer-promise-reject-errors */
        reject({
          receivedCommandSuccesful: false, result: event, request, data,
        });

        store.dispatch(finishFlow(event.flow_id));
      } else if (!request.async) {
        clearTimeout(timer);
        resolve({
          receivedCommandSuccesful: true,
          result: event,
        });
      }
    }
  });

  if (request.async) commands.once(request.latterObject, resultCallback);
});

const restCall = (request, data) => new Promise((resolve, reject) => {
  const headers = {
    Authorization: `Token ${storage.getOriginalState('token')}`,
    ...data.headers,
  };

  if (request.method === 'POST' || request.method === 'PUT') headers['Content-Type'] = 'application/json';

  const requestObject = {
    method: request.method,
    url: (request.url.startsWith('https://') ? '' : BASE_URI) + request.url,
    headers,
    params: data.params,
  };

  if (data.body && Object.keys(data.body) !== 0) requestObject.data = data.body;

  axios(requestObject)
    .then((response) => resolve(response))
    .catch((e) => reject(e));
});

const executeCommand = (request, data = {}) => {
  if (request.type === 'socket') {
    return socketCommand(request, data);
  } if (request.type === 'rest') {
    return restCall(request, data);
  }

  return null;
};

export default executeCommand;
