const useTextSearch = () => (searchString, fields) => (row) => {
  if (searchString === '' || !searchString) return true;

  let includes = false;

  fields.forEach((field) => {
    const childrenSteps = field.split('.');
    let foundField = row;

    childrenSteps.forEach((child) => {
      if (foundField) {
        foundField = foundField[child];
      }
    });

    if (
      foundField
      && (
        foundField.toLowerCase().replaceAll(' ', '').includes(searchString.toLowerCase().replaceAll(' ', ''))
           || searchString.toLowerCase().replaceAll(' ', '').includes(foundField.toLowerCase().replaceAll(' ', ''))
      )
    ) {
      includes = true;
    }
  });

  return includes;
};

export default useTextSearch;
