import * as Sentry from '@sentry/browser';

import store from '../../redux/store';
import addNotification from '../../redux/creators/notifications';

import executeCommand from '../../api/executeCommand';
import { STOP_SESSION } from '../../api/types/ApiMessages';

const stopSession = (evseId, _) => {
  store.dispatch(
    addNotification(
      'info',
      _('updates.stopCharging', { ns: 'chargepoint', id: evseId }),
      'blue',
    ),
  );

  store.dispatch({
    type: 'UPDATE_SETTINGS',
    id: evseId,
    payload: {
      notConfirmedSession: 'STOP_SESSION',
    },
  });

  executeCommand(STOP_SESSION, { evse_id: evseId })
    .then(() => {
      store.dispatch(
        addNotification(
          'success',
          _('updates.stopChargingSuccess', { ns: 'chargepoint', id: evseId }),
          'green',
        ),
      );
    })
    .catch((err) => {
      Sentry.captureException(err);
      store.dispatch(
        addNotification(
          'failed',
          _('error.stopChargingFailed', { ns: 'chargepoint', id: evseId }),
          'red',
        ),
      );
    });
};

export default stopSession;
