import * as React from 'react';

import {
  ModuleHeader,

  Colors,
  Breakpoint,

  useDimensions,

  Lang,
} from '@bluecurrent/web-component-lib';
import { DateTime } from 'luxon';
import Skeleton from 'react-loading-skeleton';
import {
  GET_UPCOMING_CALENDAR_EVENT,
} from '../../../api/types/ApiMessages';
import executeCommand from '../../../api/executeCommand';

export default function UpcomingEvent() {
  const size = useDimensions();
  const { _, i18n } = Lang.useTranslation();

  const [title, setTitle] = React.useState('');
  const [date, setDate] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    executeCommand(GET_UPCOMING_CALENDAR_EVENT)
      .then((res) => {
        setTitle(res.data.event_name);
        setDate(res.data.event_at);
        setLoading(false);
      });
  }, []);

  let splitted;
  let ns;
  let key;
  try {
    splitted = title.split('.');

    [ns, ...key] = splitted;

    key = key.join('.');
  } catch (e) {
    //
  }

  let dateJs;
  if (date) {
    dateJs = DateTime.fromFormat(date, 'dd/LL/y TT');
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        border: `solid 2px ${Colors.MEDIUM_WHITE}`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 10,
        paddingRight: 5,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: size.width < Breakpoint.fd ? 15 : 5,
        backgroundColor: Colors.WHITE,
        overflow: 'hidden',
      }}
    >
      <ModuleHeader
        title={
          loading
            ? <Skeleton width={90} />
            : (key && ns ? `${_(key, { ns })}:` : false) || _('noUpcomingEvent', { ns: 'ui' })
        }
        subTitle={
          loading
            ? <Skeleton width={120} />
            : dateJs
              ? dateJs.setLocale(i18n.language).toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })
              : false
        }
        icon={{
          name: 'CalendarCheck',
          height: 40,
          width: 40,
          color: Colors.GREY,
        }}
      />
    </div>
  );
}
