import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  USER_LOGOUT,
} from '../actionTypes';

const initialState = {
  notifications: [],
};

// eslint-disable-next-line default-param-last
const update = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      };
    case REMOVE_NOTIFICATION: {
      let found = false;
      return {
        ...state,
        notifications: state.notifications.map((notification) => {
          if (!found && notification.visible) {
            found = true;
            return { ...notification, visible: false };
          }
          return notification;
        }),
      };
    }
    case USER_LOGOUT:
      return initialState;

    default: return state;
  }
};

export default update;
