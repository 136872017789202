import * as React from 'react';

import {
  Colors,
  Lang,

  Button,
  OverlaySearch,
  Text,
} from '@bluecurrent/web-component-lib';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { useNavigate } from 'react-router-dom';
import useTextSearch from '../../../hooks/useTextSearch';
import executeCommand from '../../../api/executeCommand';
import {
  GET_CHARGE_POINTS,
  SET_FREE_PAYING_CHARGE_CARD,
} from '../../../api/types/ApiMessages';
import PlaceholderChargePoints from '../../Modules/Placeholders/ChargePoints';
import addNotification from '../../../redux/creators/notifications';

export default function ExcludedOnChargePoints(props) {
  const { _ } = Lang.useTranslation();
  const textSearch = useTextSearch();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [search, setSearch] = React.useState('');
  const [evses, setEvses] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [buttonLoading, setButtonLoading] = React.useState(false);

  const [excluded, setExcluded] = React.useState([]);
  const [unExcluded, setUnExcluded] = React.useState([]);

  const card = useSelector(
    (state) => state.ChargeCards.chargeCards.find((i) => i.id === props.id),
  );

  const statusNew = props.status === 'new';

  React.useEffect(() => {
    setLoading(true);
    if (card) {
      executeCommand(GET_CHARGE_POINTS, {
        uid: card.uid,
      })
        .then(({ result }) => {
          setEvses(result.data);
          setLoading(false);
        })
        .catch((err) => {
          Sentry.captureException(err);
          dispatch(addNotification('failed', _('errors.somethingWrong', { ns: 'ui' }), 'red'));
        });
    }
  }, [card]);

  const onClick = (isExcluded, item) => {
    if (isExcluded) {
      if (unExcluded.includes(item.evse_id)) {
        setUnExcluded((prev) => prev.filter((i) => i !== item.evse_id));
      } else {
        setUnExcluded((prev) => [...prev, item.evse_id]);
      }
    } else if (excluded.includes(item.evse_id)) {
      setExcluded((prev) => prev.filter((i) => i !== item.evse_id));
    } else {
      setExcluded((prev) => [...prev, item.evse_id]);
    }
  };

  const save = () => {
    if (statusNew && excluded.length < 1 && unExcluded.length < 1) {
      navigate(`/chargecards/${card.id}`);
      return;
    }

    setButtonLoading(true);
    Promise.all(
      [
        ...excluded.map((id) => executeCommand(SET_FREE_PAYING_CHARGE_CARD, {
          id: card.id,
          evse_id: id,
          free_or_paying: 'free',
        })),
        ...unExcluded.map((id) => executeCommand(SET_FREE_PAYING_CHARGE_CARD, {
          id: card.id,
          evse_id: id,
          free_or_paying: 'paying',
        })),
      ],
    )
      .then(() => {
        setButtonLoading(false);
        setEvses((prev) => prev.map((i) => {
          if (excluded.includes(i.evse_id)) {
            return {
              ...i,
              selected_card: i.selected_card.map((c) => {
                if (c.id === card.id) {
                  return {
                    ...c,
                    free_or_paying: 'free',
                  };
                }

                return c;
              }),
            };
          }
          if (unExcluded.includes(i.evse_id)) {
            return {
              ...i,
              selected_card: i.selected_card.map((c) => {
                if (c.id === card.id) {
                  return {
                    ...c,
                    free_or_paying: 'paying',
                  };
                }

                return c;
              }),
            };
          }

          return i;
        }));
        setExcluded([]);
        setUnExcluded([]);

        if (statusNew) navigate(`/chargecards/${card.id}`);
      })
      .catch((err) => {
        Sentry.captureException(err);
        dispatch(addNotification('failed', _('errors.somethingWrong', { ns: 'ui' })));
      });
  };

  return (
    <>
      {
            statusNew && (
            <div
              style={{
                height: 'auto',
                width: '100%',
                borderBottom: `solid 2px ${Colors.MEDIUM_WHITE}`,
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              <Text>{_('excludedOnExplanation', { ns: 'chargecard' })}</Text>
            </div>
            )
       }
      <OverlaySearch
        onSearch={(e) => setSearch(e.target.value)}
        loading={loading}
        data={evses
          .filter(textSearch(search, ['evse_id', 'name', 'model_type', 'chargepoint_type']))
          .map((item) => {
            const chargepoint = evses.find((i) => i.evse_id === item.evse_id);
            const isExcluded = chargepoint.selected_card.find((i) => i.id === card.id).free_or_paying === 'free';
            const selected = excluded.includes(item.evse_id)
              ? true
              : unExcluded.includes(item.evse_id)
                ? false
                : isExcluded;

            return {
              id: item.evse_id,
              type: 'chargepoint',
              serial: item.name && item.evse_id,
              name: item.name || item.evse_id,
              selected: selected && _('excluded', { ns: 'chargecard' }),
              onClick: () => onClick(isExcluded, item),
            };
          })}
        empty={{
          condition: !loading && evses.length < 1,
          component: <PlaceholderChargePoints />,
        }}
      />
      <div
        style={{
          height: 'auto',
          width: '100%',
          backgroundColor: Colors.WHITE,
          borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
          padding: 15,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <div
          style={{
            width: 'auto',
          }}
        >
          <Button
            text={_('save', { ns: 'ui' })}
            colorScheme={(!statusNew && excluded.length < 1 && unExcluded.length < 1) ? 'grey' : 'blue'}
            loading={buttonLoading}
            onClick={save}
            disabled={!statusNew && excluded.length < 1 && unExcluded.length < 1}
          />
        </div>
      </div>
    </>
  );
}
