import React, {
  Suspense,
  useState,
  useEffect,
} from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import * as Sentry from '@sentry/browser';

import {
  Lang,
} from '@bluecurrent/web-component-lib';

/* eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member */
import useStorage from './hooks/useStorage';
import useCommands from './hooks/useCommands';

/* eslint-disable-next-line */
import Socket from './api/Socket';

import useLoggedIn from './hooks/useLoggedIn';
import fetchStartData from './hooks/flows/fetchStartData';
import detectLanguage from './hooks/flows/detectLanguage';
import handleUpdate from './hooks/flows/handleUpdate';
import useUpdateLanguage from './hooks/useUpdateLanguage';

import Hubspot from './services/Hubspot/script';
import LiveChat from './services/Hubspot/chat';
import useUserIdentity from './services/Hubspot/useUserIdentity';

import Auth from './components/Auth';
import Notifications from './components/Notifications';
import Tour from './components/Tour';

import Setup from './config/Setup.config';
import Pages from './config/Pages.config';

import FullLoader from './components/Sections/FullLoader';
import ErrorPage from './components/Sections/ErrorPage';
import Layout from './components/Layout';

import './styles/Global.css';
import './styles/Components.css';
import './styles/Hubspot.css';
import './styles/Calendar.css';
import './styles/Map.css';
import './styles/Tour.css';

import 'react-loading-skeleton/dist/skeleton.css';

export default function App() {
  const { _, i18n } = Lang.useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.User);
  const identifyUser = useUserIdentity();

  const updateLanguage = useUpdateLanguage(i18n);
  const storage = useStorage();
  const isLoggedIn = useLoggedIn(dispatch);
  const commands = useCommands();

  const [loading, setLoading] = useState(true);
  const [displayErrorScreen, setDisplayErrorScreen] = useState(false);

  const checkLogin = async () => {
    isLoggedIn().then(
      ({ data: bcus, logged_in: loggedIn }) => {
        if (loggedIn) {
          fetchStartData(dispatch, bcus);
        }

        setLoading(false);
      },
    )
      .catch((err) => {
        Sentry.captureException(err);
        setDisplayErrorScreen(true);
      });
  };

  useEffect(() => {
    if (storage.getStore() === sessionStorage) {
      storage.copySessionToLocal();
    }
    window.dispatchEvent(new Event('storage'));
  }, []);

  useEffect(() => {
    identifyUser(user);
  }, [user]);

  const handleClose = () => {
    if (loading) setDisplayErrorScreen(true);
  };

  useEffect(() => {
    commands.on('WS_CLOSE', handleClose);

    return () => commands.off('WS_CLOSE', handleClose);
  }, [loading]);

  useEffect(() => {
    detectLanguage(i18n);
    document.documentElement.setAttribute('lang', i18n.language);
    document.documentElement.setAttribute('xml:lang', i18n.language);
    handleUpdate(dispatch, _);

    if (Setup.PSEUDO && process.env.NODE_ENV === 'development') {
      updateLanguage('pseudo');
    }
  }, []);

  return (
    <>
      <Socket onFirstConnect={checkLogin} />
      <Notifications />
      <Hubspot />
      <LiveChat />
      {
        displayErrorScreen
          ? <ErrorPage errorCode="1" />
          : loading
            ? <FullLoader />
            : (
              <BrowserRouter>
                <Suspense fallback={<FullLoader />}>
                  <Routes>
                    {
                      Pages.map(({
                        key,
                        path,
                        authenticated,
                        name,
                        component: Component,
                        children,
                      }) => (
                        <Route
                          key={key}
                          path={path}
                          element={
                            authenticated
                              ? (
                                <Auth>
                                  <Tour />
                                  <Layout
                                    title={Array.isArray(name) ? _(...name) : name}
                                    path={path}
                                  >
                                    <Component />
                                  </Layout>
                                </Auth>
                              )
                              : (
                                <Component />
                              )
                            }
                        >
                          {
                            children && children.map(
                              ({ key: childKey, path: childPath, component: ChildComponent }) => (
                                <Route
                                  key={childKey}
                                  path={path + childPath}
                                  element={<ChildComponent />}
                                />
                              ),
                            )
                          }
                        </Route>
                      ))
                    }
                  </Routes>
                </Suspense>
              </BrowserRouter>
            )
      }
    </>
  );
}
