import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { useNavigate } from 'react-router-dom';

import {
  Lang,
  Colors,

  Text,
  Input,
  Item,
} from '@bluecurrent/web-component-lib';

import languages from '../../../config/Language.config';

import executeCommand from '../../../api/executeCommand';
import { EDIT_ACCOUNT } from '../../../api/types/ApiMessages';
import addNotification from '../../../redux/creators/notifications';

/* eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member */
import useStorage from '../../../hooks/useStorage';
import useUpdateLanguage from '../../../hooks/useUpdateLanguage';
import useDeveloperMode from '../../../hooks/useDeveloperMode';
import useCommands from '../../../hooks/useCommands';

import SettingsTitle from '../../Modules/SettingsTitle';
import NotAFan from '../../Modules/NotAFan';

export default function Preferences() {
  const { _, i18n } = Lang.useTranslation();
  const updateLanguage = useUpdateLanguage(i18n);
  const storage = useStorage();
  const navigate = useNavigate();
  const user = useSelector((state) => state.User);
  const dispatch = useDispatch();
  const commands = useCommands();
  const developerMode = useDeveloperMode();

  const [currentLanguage, setCurrentLanguage] = React.useState(storage.getItem('lang'));

  const changeLanguage = (iso) => {
    updateLanguage(iso);
    setCurrentLanguage(iso);
    document.documentElement.setAttribute('lang', iso);
    document.documentElement.setAttribute('xml:lang', iso);
  };

  const updateDeveloperMode = () => {
    const newValue = !developerMode.enabled;

    dispatch({
      type: 'USER_UPDATE',
      payload: {
        developer_mode_enabled: newValue,
      },
    });

    executeCommand(EDIT_ACCOUNT, {
      body: {
        developer_mode_enabled: newValue,
      },
    })
      .then(() => {})
      .catch((error) => {
        Sentry.captureException(error);
        dispatch(addNotification('failed', _('errors.somethingWrong', { ns: 'ui' }), 'red'));
        dispatch({
          type: 'USER_UPDATE',
          payload: {
            developer_mode_enabled: !newValue,
          },
        });
      });
  };

  return (
    <div
      className="FadeIn"
      style={{
        position: 'relative',
        zIndex: 1,
      }}
    >
      <SettingsTitle
        title={_('preferences', { ns: 'settings' })}
      />
      <div
        style={{
          marginTop: 30,
          marginBottom: 45,
        }}
      >
        <Text
          fontSize="1em"
          color={Colors.DARK_GREY}
          fontWeight={500}
        >
          {_('language', { ns: 'settings' })}
        </Text>
        <div
          style={{
            marginTop: 15,
          }}
        >
          <Input
            type="dropdown"
            text={_(currentLanguage, { ns: 'settings' })}
            id="settings-language-select"
            items={
              languages.map((lang) => ({
                key: lang.iso,
                text: _(lang.iso, { ns: 'settings' }),
                type: 'radio',
                selected: i18n.language === lang.iso,
                onClick: () => changeLanguage(lang.iso),
              }))
            }
          />
        </div>
        <div
          style={{
            marginTop: 15,
          }}
        >
          <Text
            fontSize="1em"
            color={Colors.DARK_GREY}
          >
            {_('languageDescription', { ns: 'settings' })}
          </Text>
        </div>
      </div>
      <div
        style={{
          height: 'auto',
          width: '100%',
          marginBottom: 30,
        }}
      >
        <Text
          fontSize="1em"
          color={Colors.DARK_GREY}
          fontWeight={500}
        >
          {_('advanced', { ns: 'ui' })}
        </Text>
        <div
          style={{
            marginTop: 10,
            marginBottom: 15,
          }}
        >
          <Item
            type="button"
            title={_('startTour', { ns: 'ui' })}
            onClick={() => {
              navigate('/');
              commands.emit('START_TOUR');
            }}
          />
        </div>
        <div
          style={{
            marginTop: 10,
            marginBottom: 15,
          }}
        >
          <Item
            type="switch"
            title={_('developerMode', { ns: 'settings' })}
            value={developerMode.enabled}
            onSwitch={updateDeveloperMode}
          />
        </div>
      </div>
      <div
        style={{
          marginBottom: 25,
        }}
      >
        <NotAFan
          email={user.email}
        />
      </div>
    </div>
  );
}
