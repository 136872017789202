import * as Sentry from '@sentry/browser';

import store from '../../redux/store';
import addNotification from '../../redux/creators/notifications';

import executeCommand from '../../api/executeCommand';
import {
  SOFT_RESET,
} from '../../api/types/ApiMessages';

const softReset = (evseId, _, finish = null) => {
  store.dispatch(
    addNotification(
      'info',
      _('updates.resetting', { ns: 'chargepoint', id: evseId }),
      'blue',
    ),
  );
  store.dispatch({
    type: 'UPDATE_SETTINGS',
    id: evseId,
    payload: {
      notConfirmedSession: 'SOFT_RESET',
    },
  });
  executeCommand(SOFT_RESET, { evse_id: evseId })
    .then(() => {
      store.dispatch(
        addNotification(
          'success',
          _('updates.resetSuccess', { ns: 'chargepoint', id: evseId }),
          'green',
        ),
      );
      if (finish) finish();
    })
    .catch((err) => {
      Sentry.captureException(err);
      store.dispatch(
        addNotification(
          'failed',
          _('error.resetFailed', { ns: 'chargepoint', id: evseId }),
          'red',
        ),
      );
      if (finish) finish();
    });
};

export default softReset;
