import * as React from 'react';

import {
  Colors,
  Lang,

  Text,
  Icon,
} from '@bluecurrent/web-component-lib';

export default function ChargeCardItemDummy() {
  const { _ } = Lang.useTranslation();

  return (
    <div
      style={{
        minHeight: 60,
        height: 'auto',
        width: '100%',
        backgroundColor: Colors.WHITE,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: 5,
        paddingRight: 5,
      }}
    >
      <div
        style={{
          paddingTop: 7,
          paddingBottom: 7,
        }}
      >
        <Text>{_('example', { ns: 'ui' })}</Text>
        <div
          style={{
            paddingTop: 2,
          }}
        >
          <Text
            fontWeight={300}
            fontSize="0.9em"
            color={Colors.MEDIUM_GREY}
            textTransform="uppercase"
          >
            NL-ABC-123456-1
          </Text>
        </div>
      </div>
      <div
        style={{
          height: 60,
          width: 60,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Icon
          name="Ellipsis-H"
          height={35}
          width={35}
          color={Colors.MEDIUM_GREY}
          iconSet="FA"
        />
      </div>
    </div>
  );
}
