import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { useDispatch } from 'react-redux';

import {
  Lang,
  Colors,

  Input,
  Button,
  Text,
} from '@bluecurrent/web-component-lib';

import executeCommand from '../../../api/executeCommand';
import {
  GENERATE_HOME_AUTOMATION_KEY,
  GET_HOME_AUTOMATION_KEY,
  INVALIDATE_HOME_AUTOMATION_KEY,
} from '../../../api/types/ApiMessages';
import addNotification from '../../../redux/creators/notifications';

import Url from '../../../config/Links.config';

import SettingsTitle from '../../Modules/SettingsTitle';

export default function Developers() {
  const { _ } = Lang.useTranslation();
  const dispatch = useDispatch();

  const [haKey, setHaKey] = React.useState(null);
  const [generating, setGenerating] = React.useState(false);
  const [disabling, setDisabling] = React.useState(false);

  React.useEffect(() => {
    executeCommand(GET_HOME_AUTOMATION_KEY)
      .then(({ data }) => {
        if (data.key) setHaKey(data.key);
      })
      .catch((error) => {
        Sentry.captureException(error);
        dispatch(addNotification('failed', _('errors.somethingWrong', { ns: 'ui' }), 'red'));
      });
  }, []);

  const generateKey = () => {
    setGenerating(true);
    executeCommand(GENERATE_HOME_AUTOMATION_KEY)
      .then(({ data }) => {
        setGenerating(false);
        setHaKey(data.key);
      })
      .catch((error) => {
        setGenerating(false);
        Sentry.captureException(error);
        dispatch(addNotification('failed', _('errors.somethingWrong', { ns: 'ui' }), 'red'));
      });
  };

  const disableKey = () => {
    setDisabling(true);
    executeCommand(INVALIDATE_HOME_AUTOMATION_KEY)
      .then(({ data }) => {
        setHaKey(data.key);
        setDisabling(false);
      })
      .catch((error) => {
        Sentry.captureException(error);
        dispatch(addNotification('failed', _('errors.somethingWrong', { ns: 'ui' }), 'red'));
      });
  };

  return (
    <div
      className="FadeIn"
      style={{
        position: 'relative',
        zIndex: 1,
      }}
    >
      <SettingsTitle
        title={_('homeAssistant', { ns: 'settings' })}
      />
      <div
        style={{
          marginTop: 30,
          marginBottom: 45,
        }}
      >
        <Input
          type="copy"
          label={_('apiKey', { ns: 'settings' })}
          value={haKey}
        />
        <div
          style={{
            marginTop: 15,
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              width: 'auto',
            }}
          >
            <Button
              type="button"
              text={_('generateApiKey', { ns: 'settings' })}
              onClick={generateKey}
              loading={generating}
              disabled={disabling}
            />
          </div>
          <div
            style={{
              marginLeft: 15,
            }}
          >
            {
              haKey && (
                <Button
                  disabled={generating}
                  onClick={disableKey}
                  loading={disabling}
                  type="icon"
                  icon={{
                    name: 'Bin',
                    height: 20,
                    width: 20,
                    iconSet: 'Feather',
                    color: Colors.RED,
                  }}
                  colorScheme="white"
                />
              )
            }
          </div>
        </div>
        <div
          style={{
            marginTop: 15,
          }}
        >
          <a
            href={Url.HOME_ASSISTANT_ARTICLE}
            target="_blank"
            rel="noreferrer"
          >
            <Text
              color={Colors.BLUE}
            >
              {_('moreInformation', { ns: 'ui' })}
            </Text>
          </a>
        </div>
      </div>
    </div>
  );
}
