import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { useDispatch } from 'react-redux';

import {
  Lang,
  Colors,

  useCurrencyFormat,

  Button,
  Text,
} from '@bluecurrent/web-component-lib';

import {
  EDIT_REIMBURSEMENT_TARIFF,
  GET_REIMBURSEMENT_FEES,
} from '../../../../api/types/ApiMessages';

import executeCommand from '../../../../api/executeCommand';

import addNotification from '../../../../redux/creators/notifications';

import Description from './Description';
import SumWrapper from './SumWrapper';
import SumItem from './SumWrapper/Item';

import handleCompensationInputValidation from './functions/handleCompensationInputValidation';

export default function RoamingTariff(props) {
  const { i18n, _ } = Lang.useTranslation();
  const currencyFormat = useCurrencyFormat(i18n.language);
  const dispatch = useDispatch();

  const currency = props.evseSettings.tariff?.currency !== '' || props.evseSettings.tariff?.currency !== undefined
    ? props.evseSettings.tariff.currency
    : 'EUR';

  console.log('LOG: ', currency);

  const [data, setData] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const [isLoadingData, setLoadingData] = React.useState(false);
  const [isInError, setInError] = React.useState(false);
  const [incomingData, setIncomingData] = React.useState({});
  const [
    reimbursementValue,
    setReimbursementValue,
  ] = React.useState(props.evseSettings.tariff.price_ex_vat);
  const [reimbursementErrorData] = React.useState({
    min: 0.01,
    max: 1.00,
    currency: 'EUR',
  });

  React.useEffect(() => {
    setLoadingData(true);

    executeCommand(GET_REIMBURSEMENT_FEES, {
      body: {
        evse_id: props.evseSettings.evse_id,
      },
    })
      .then((result) => {
        setTimeout(() => {
          setLoadingData(false);
          setIncomingData(result.data);
        }, 500);
      });
  }, []);

  const handleCalculation = () => {
    const array = [
      ...incomingData.items.map((item) => item.tariff.amount),
      Number(reimbursementValue),
    ];

    return array.reduce((accumulator, currentValue) => accumulator + currentValue);
  };

  React.useEffect(() => {
    if (!isLoading && incomingData?.items !== undefined) {
      const array = [
        {
          id: 'reimbursement',
          text: _('compensation', { ns: 'finance' }),
          type: 'input',
          input: {
            min: reimbursementErrorData.min,
            max: incomingData.max_amount,
            step: '.01',
            setInError: (e) => setInError(e),
            isInError,
          },
        },
        {
          type: 'hr',
        },
        {
          text: _('roamingTariff.chargedToChargingCardSupplier', { ns: 'chargepoint' }),
          type: 'text',
          tariff: {
            amount: handleCalculation(),
            currency: 'EUR',
          },
        },
      ];

      array.unshift(...incomingData.items);

      setData(array);
    }
  }, [
    reimbursementValue,
    isLoading,
    incomingData,
  ]);

  const errorData = {
    min: currencyFormat(reimbursementErrorData.min, currency),
    max: currencyFormat(incomingData.max_amount, currency),
    value: currencyFormat(reimbursementValue, currency),
  };

  const onSave = () => {
    setLoading(true);

    if (
      handleCompensationInputValidation(
        reimbursementValue,
        reimbursementErrorData.min,
        incomingData.max_amount,
      )
    ) {
      setInError(true);
      setLoading(false);

      return;
    }

    setTimeout(() => {
      const payload = {
        tariff: {
          value: true,
          price_ex_vat: reimbursementValue,
          permission: props.evseSettings.tariff?.permission,
          currency,
        },
      };

      executeCommand(EDIT_REIMBURSEMENT_TARIFF, {
        body: {
          evse_id: props.evseSettings.evse_id,
          amount: reimbursementValue,
          currency,
        },
      })
        .then((result) => {
          setLoading(false);

          if (result.data.success) {
            dispatch({
              type: 'UPDATE_SETTINGS',
              id: props.evseSettings.evse_id,
              payload,
            });

            dispatch(
              addNotification(
                'success',
                _('roamingTariff.reimbursementChanged', {
                  ns: 'chargepoint',
                  value: errorData.value,
                }),
                'green',
              ),
            );
            setLoading(false);
            props.onClose();
          } else {
            dispatch(addNotification('failed', _('errors.somethingWrong', { ns: 'ui' }), 'red'));
            setLoading(false);
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          dispatch(addNotification('failed', _('errors.somethingWrong', { ns: 'ui' }), 'red'));
          setLoading(false);
        });
    }, 1000);
  };

  return (
    <>
      <div
        style={{
          height: '100%',
          padding: 15,
        }}
      >
        <Description />
        <SumWrapper
          isLoading={isLoadingData}
        >
          {
            data && data.map((item, index) => (
              <SumItem
                /* eslint-disable-next-line react/no-array-index-key */
                key={index}
                text={item.text}
                type={item.type}
                tariff={
                  item?.tariff ? {
                    amount: item.tariff.amount,
                    currency: item.tariff.currency,
                  } : false
                }
                input={{
                  ...item?.input,
                  value: reimbursementValue,
                  onChange: (e) => {
                    setReimbursementValue(e.target.value);
                  },
                  currency: incomingData?.currency,
                  isInError,
                }}
              />
            ))
          }
        </SumWrapper>
        <div
          style={{
            marginTop: -7.5,
          }}
        >
          <Text
            color={isInError ? Colors.RED : Colors.DARK_GREY}
            fontSize="1.1em"
          >
            {
              isInError
                ? _('roamingTariff.minMaxError', {
                  ns: 'chargepoint',
                  minValue: errorData.min,
                  maxValue: errorData.max,
                })
                : _('roamingTariff.allAmountsAreExclVAT', { ns: 'chargepoint' })
            }
          </Text>
        </div>
      </div>
      <div
        style={{
          height: 'auto',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          padding: 15,
          borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
        }}
      >
        <div
          style={{
            width: 'auto',
          }}
        >
          <Button
            text={_('back', { ns: 'ui' })}
            colorScheme="white"
            onClick={props.onClose}
          />
        </div>
        <div
          style={{
            width: 'auto',
          }}
        >
          <Button
            text={_('save', { ns: 'ui' })}
            colorScheme={isLoading || isInError ? 'grey' : 'blue'}
            loading={isLoading}
            disabled={isLoading || isInError}
            onClick={() => onSave()}
          />
        </div>
      </div>
    </>
  );
}
