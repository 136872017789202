import * as React from 'react';

import Sedan from './Sedan';

export default function Car(props) {
  const render = () => {
    switch (props.type) {
      case 'Sedan':
        return (
          <Sedan
            data={props.data}
          />
        );
      default:
        return (
          <Sedan
            data={props.data}
          />
        );
    }
  };

  return (
    <>
      {render()}
    </>
  );
}
