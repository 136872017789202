import * as React from 'react';

import {
  Colors,
  Breakpoint,

  useDimensions,

  Text,
} from '@bluecurrent/web-component-lib';

import TextEnd from './TextEnd';
import InputEnd from './InputEnd';
import HrEnd from './HrEnd';

export default function Item(props) {
  const size = useDimensions();

  const height = props.type === 'input'
    ? size.width < Breakpoint.md ? 80 : 60
    : 45;

  const handleType = () => {
    switch (props.type) {
      case 'input':
        return (
          <InputEnd
            text={props.text}
            input={props.input}
          />
        );
      case 'hr':
        return (
          <HrEnd />
        );
      default:
        /* props.type === 'text' */
        return (
          <TextEnd
            tariff={props.tariff}
            text={props.text}
          />
        );
    }
  };

  return (
    <div
      style={{
        minHeight: height,
        height: 'auto',
        paddingTop: size.width < Breakpoint.md ? 10 : 0,
        paddingBottom: size.width < Breakpoint.md ? 10 : 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 15,
      }}
    >
      {
        size.width >= Breakpoint.md && (
          <>
            <div
              style={{
                width: '100%',
              }}
            >
              {
                props.type === 'hr'
                  ? (
                    <hr
                      style={{
                        width: '100%',
                        border: `solid 1px ${Colors.MEDIUM_GREY}`,
                      }}
                    />
                  ) : (
                    <Text>
                      {`${props.text}:`}
                    </Text>
                  )
              }
            </div>
            <div
              style={{
                minWidth: 2,
                backgroundColor: Colors.MEDIUM_WHITE,
                height,
              }}
            />
          </>
        )
      }
      <div
        style={{
          minWidth: size.width < Breakpoint.md ? '100%' : 240,
          width: 'auto',
          display: 'flex',
          flexDirection: size.width < Breakpoint.xs ? 'column' : 'row',
          alignItems: size.width < Breakpoint.xs
            ? 'flex-start'
            : 'center',
          justifyContent: size.width < Breakpoint.md
            ? size.width < Breakpoint.xs
              ? 'flex-start'
              : 'space-between'
            : 'flex-end',
          gap: 7.5,
          height: 'auto',
        }}
      >
        {
          (
            props.tariff
            || props.type === 'input'
            || props.type === 'hr'
          ) && handleType()
        }
      </div>
    </div>
  );
}
