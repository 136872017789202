import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';

import {
  Lang,
  Colors,
  Breakpoint,

  useDimensions,

  Text,
  Input,
  Icon,
} from '@bluecurrent/web-component-lib';

import { SET_PRICE_BASED_SETTINGS } from '../../../../../api/types/ApiMessages';

import executeCommand from '../../../../../api/executeCommand';
import addNotification from '../../../../../redux/creators/notifications';

export default function PriceBasedCharging(props) {
  const { _ } = Lang.useTranslation();
  const size = useDimensions();
  const dispatch = useDispatch();

  const handleTimeToFormat = (time) => DateTime.fromFormat(time, 'HH:mm');
  const handleTimeFromFormat = (time) => DateTime.fromISO(time).toFormat('HH:mm');

  const defaultDepartureTime = (
    props.evseSettings.price_based_charging?.expected_leave_time === ''
      ? '04:00'
      : props.evseSettings.price_based_charging.expected_leave_time
  );
  const isMobile = size.width < Breakpoint.xs;

  const [departureTime, setDepartureTime] = React.useState(defaultDepartureTime);
  const [kwhValue, setKwhValue] = React.useState(
    props.evseSettings.price_based_charging?.expected_kwh ?? 25,
  );
  const [minimumKwhValue, setMinimumKwhValue] = React.useState(
    props.evseSettings.price_based_charging?.minimum_kwh ?? 10,
  );
  const [priceBasedTimeout, setPriceBasedTimeout] = React.useState(null);
  const [isTimeInError, setTimeInError] = React.useState(false);
  const [isKwhInError, setKwhInError] = React.useState(false);
  const [isMinimumKwhInError, setMinimumKwhInError] = React.useState(false);
  const [isUpdated, setUpdated] = React.useState(false);
  const [isHyperlinkHover, setHyperlinkHover] = React.useState(false);

  const TIME_INPUTS = [
    {
      id: 0,
      value: departureTime,
      step: 300,
      label: `${_('priceBasedCharging.departureTime', { ns: 'chargepoint' })}:`,
      onChange: (event) => {
        setUpdated(true);
        setDepartureTime(handleTimeToFormat(event.target.value));
      },
    },
  ];

  const KWH_INPUTS = [
    {
      id: 0,
      value: kwhValue,
      label: `${_('priceBasedCharging.expectedSizeOfChargingSession', { ns: 'chargepoint' })}:`,
      onChange: (event) => {
        setUpdated(true);
        setKwhValue(event.target.value);
      },
      error: isKwhInError,
    },
    {
      id: 1,
      value: minimumKwhValue,
      label: `${_('priceBasedCharging.immediatelyCharge', { ns: 'chargepoint' })}:`,
      onChange: (event) => {
        setUpdated(true);
        setMinimumKwhValue(event.target.value);
      },
      error: isMinimumKwhInError,
    },
  ];

  const handleUpdate = () => {
    if (priceBasedTimeout) {
      clearTimeout(priceBasedTimeout);
      setPriceBasedTimeout(null);
    }

    setPriceBasedTimeout(setTimeout(() => {
      executeCommand(SET_PRICE_BASED_SETTINGS, {
        body: {
          evse_id: props.evseSettings.evse_id,
          expected_departure_time: handleTimeFromFormat(departureTime),
          expected_kwh: kwhValue,
          minimum_kwh: minimumKwhValue,
        },
      })
        .then(() => {
          dispatch({
            type: 'UPDATE_SETTINGS',
            id: props.evseSettings.evse_id,
            payload: {
              price_based_charging: {
                value: true,
                expected_leave_time: handleTimeFromFormat(departureTime),
                expected_kwh: kwhValue,
                minimum_kwh: minimumKwhValue,
                permission: props.evseSettings.price_based_charging.permission,
              },
            },
          });

          return dispatch(addNotification('success', _('settingsSaved', { ns: 'settings' }), 'green'));
        })
        .catch((error) => {
          dispatch(addNotification('failed', _('errors.somethingWrong', { ns: 'ui' }), 'red'));
          Sentry.captureException(error);
        });
    }, 1500));
  };

  React.useEffect(() => {
    if (departureTime === 0) return setTimeInError(true);
    if (kwhValue < 1 || kwhValue > 80) return setKwhInError(true);
    if (minimumKwhValue < 1 || minimumKwhValue > 80) return setMinimumKwhInError(true);

    if (isUpdated) {
      setUpdated(false);
      setTimeInError(false);
      setKwhInError(false);
      setMinimumKwhInError(false);

      return handleUpdate();
    }

    return null;
  }, [
    departureTime,
    kwhValue,
    minimumKwhValue,
    isUpdated,
  ]);

  return (
    <>
      <div
        className="DisableSelection"
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 15,
          paddingBottom: 10,
          backgroundColor: Colors.LIGHT_GREY,
          border: `solid 2px ${Colors.MEDIUM_WHITE}`,
          borderRadius: 10,
          display: 'flex',
          flexDirection: 'column',
          gap: 15,
        }}
      >
        <Text
          color={props.isDisabled ? Colors.MEDIUM_GREY : Colors.GREY}
        >
          {_('chargingProfiles.priceBasedCharging', { ns: 'chargepoint' })}
        </Text>
        <Text
          fontSize="1em"
          fontWeight={300}
          color={props.isDisabled ? Colors.MEDIUM_GREY : Colors.DARK_WHITE}
          lineHeight={1.4}
        >
          {_('priceBasedCharging.longExplanation', { ns: 'chargepoint' })}
        </Text>
        <Text
          fontSize="1em"
          fontWeight={300}
          color={Colors.DARK_WHITE}
        >
          {`${_('moreInformation', { ns: 'ui' })}:`}
          <a
            target="_blank"
            href="https://help.bluecurrent.nl/nl/knowledge/laden-op-prijs"
            rel="noreferrer"
            className="Hover"
            onMouseEnter={() => {
              setHyperlinkHover(true);
            }}
            onMouseLeave={() => {
              setHyperlinkHover(false);
            }}
            style={{
              paddingLeft: 5,
              color: isHyperlinkHover ? '#0089C5' : Colors.BLUE,
              textDecoration: 'underline',
              textDecorationColor: isHyperlinkHover ? '#0089C5' : Colors.BLUE,
            }}
          >
            {_('chargingProfiles.priceBasedCharging', { ns: 'chargepoint' })}
          </a>
        </Text>
        {
          TIME_INPUTS.map((item) => (
            <div
              key={item.id}
              style={{
                borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: 10,
                gap: 15,
                marginTop: -5,
              }}
            >
              <div
                style={{
                  width: isMobile ? '100%' : '120%',
                }}
              >
                <Text
                  color={props.isDisabled ? Colors.MEDIUM_GREY : Colors.GREY}
                  lineHeight={1.3}
                >
                  {item.label}
                </Text>
              </div>
              <Input
                type="time"
                value={handleTimeFromFormat(item.value)}
                styling
                error={isTimeInError}
                onChange={item.onChange}
                min={item.min}
                max={item.max}
                step={item.step}
                disabled={props.isDisabled}
                suffix={(
                  <Icon
                    name="Clock"
                    height={20}
                    width={20}
                    color={Colors.DARK_GREY}
                  />
                )}
              />
            </div>
          ))
        }
        {
          KWH_INPUTS.map((item) => (
            <div
              key={item.id}
              style={{
                borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: 10,
                gap: 15,
                marginTop: -5,
              }}
            >
              <div
                style={{
                  width: isMobile ? '100%' : '120%',
                }}
              >
                <Text
                  color={props.isDisabled ? Colors.MEDIUM_GREY : Colors.GREY}
                  lineHeight={1.3}
                >
                  {item.label}
                </Text>
              </div>
              <Input
                type="number"
                value={item.value}
                styling
                error={!props.isDisabled && item.error ? _('priceBasedCharging.minMaxKwhError', { ns: 'chargepoint' }) : false}
                min={1}
                max={80}
                onChange={item.onChange}
                disabled={props.isDisabled}
                suffix={(
                  <Text
                    color={Colors.DARK_GREY}
                    fontSize="1em"
                  >
                    {_('units.kwh', { ns: 'chargepoint' })}
                  </Text>
                )}
              />
            </div>
          ))
        }
      </div>
      {
        (props.isDisabled) && (
          <Text
            fontSize="0.9em"
            color={Colors.DARK_GREY}
          >
            {_('noPermissionToChangeSetting', { ns: 'settings' })}
          </Text>
        )
      }
    </>
  );
}
