import FaviconNormal from '../assets/favicons/favicon_package_v0_normal/favicon.ico';
import Favicon1 from '../assets/favicons/favicon_package_v0_1/favicon.ico';
import Favicon2 from '../assets/favicons/favicon_package_v0_2/favicon.ico';
import Favicon3 from '../assets/favicons/favicon_package_v0_3/favicon.ico';
import Favicon4 from '../assets/favicons/favicon_package_v0_4/favicon.ico';
import Favicon5 from '../assets/favicons/favicon_package_v0_5/favicon.ico';
import Favicon6 from '../assets/favicons/favicon_package_v0_6/favicon.ico';
import Favicon7 from '../assets/favicons/favicon_package_v0_7/favicon.ico';
import Favicon8 from '../assets/favicons/favicon_package_v0_8/favicon.ico';
import Favicon9 from '../assets/favicons/favicon_package_v0_9/favicon.ico';
import FaviconDot from '../assets/favicons/favicon_package_v0_dot/favicon.ico';

const favicons = [
  FaviconNormal,
  Favicon1,
  Favicon2,
  Favicon3,
  Favicon4,
  Favicon5,
  Favicon6,
  Favicon7,
  Favicon8,
  Favicon9,
  FaviconDot,
];

const useBrowserTitle = () => (page = null, notifications = null) => {
  const splitted = document.title.split(' • ');

  const currentPage = splitted[0];

  const title = [];

  if (notifications !== null && notifications !== undefined) {
    const el = document.getElementById('favicon');

    const favicon = favicons[notifications === false ? 0 : notifications > 10 ? 10 : notifications];

    el.href = favicon;
  }

  title.push(page ?? currentPage);

  title.push(process.env.REACT_APP_BROWSER_TITLE);

  document.title = title.join(' • ');

  return title.join(' • ');
};

export default useBrowserTitle;
