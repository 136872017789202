import * as React from 'react';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { DateTime } from 'luxon';

import {
  Lang,

  Colors,
  Breakpoint,

  useDimensions,

  Text,
  Button,
  Input,
  Icon,
} from '@bluecurrent/web-component-lib';

import { SAVE_SCHEDULE_DELAYED_CHARGING } from '../../../../../api/types/ApiMessages';

import executeCommand from '../../../../../api/executeCommand';
import addNotification from '../../../../../redux/creators/notifications';

export default function DelayedCharging(props) {
  const { _ } = Lang.useTranslation();
  const dispatch = useDispatch();
  const size = useDimensions();

  const handleTimeToFormat = (time) => DateTime.fromFormat(time, 'HH:mm');
  const handleTimeFromFormat = (time) => (time.invalid === null ? DateTime.fromISO(time).toFormat('HH:mm') : '--:--');

  /* Array of day integer (Monday = [2]) etc. */
  const defaultSelectedDays = (props.evseSettings.delayed_charging.selected_days !== undefined)
    ? props.evseSettings.delayed_charging.selected_days : [1, 2, 3, 4, 5];
  const defaultStartTime = (props.evseSettings.delayed_charging.start_time !== undefined) ? props.evseSettings.delayed_charging.start_time : '18:00';
  const defaultEndTime = (props.evseSettings.delayed_charging.end_time !== undefined) ? props.evseSettings.delayed_charging.end_time : '23:00';
  const isMobile = size.width < Breakpoint.xs;

  const [selected, setSelected] = React.useState(
    props.evseSettings.delayed_charging.selected_days ?? defaultSelectedDays,
  );
  const [startTime, setStartTime] = React.useState(
    handleTimeToFormat(props.evseSettings.delayed_charging.start_time ?? defaultStartTime),
  );
  const [endTime, setEndTime] = React.useState(
    handleTimeToFormat(props.evseSettings.delayed_charging.end_time ?? defaultEndTime),
  );
  const [delayedTimeout, setDelayedTimeout] = React.useState(null);
  const [isInError, setInError] = React.useState(false);
  const [isTimeInError, setTimeInError] = React.useState(false);
  const [isUpdated, setUpdated] = React.useState(false);
  const [isHyperlinkHover, setHyperlinkHover] = React.useState(false);

  const DAYS = [
    { id: 1, text: ['weekdays.mon', { ns: 'dates' }] },
    { id: 2, text: ['weekdays.tue', { ns: 'dates' }] },
    { id: 3, text: ['weekdays.wed', { ns: 'dates' }] },
    { id: 4, text: ['weekdays.thur', { ns: 'dates' }] },
    { id: 5, text: ['weekdays.fri', { ns: 'dates' }] },
    { id: 6, text: ['weekdays.sat', { ns: 'dates' }] },
    { id: 7, text: ['weekdays.sun', { ns: 'dates' }] },
  ];

  const handleUpdate = () => {
    if (delayedTimeout) {
      clearTimeout(delayedTimeout);
      setDelayedTimeout(null);
    }

    setDelayedTimeout(setTimeout(() => {
      executeCommand(SAVE_SCHEDULE_DELAYED_CHARGING, {
        body: {
          evse_id: props.evseSettings.evse_id,
          start_time: handleTimeFromFormat(startTime),
          end_time: handleTimeFromFormat(endTime),
          days: JSON.stringify(selected),
        },
      })
        .then((response) => {
          if (!response.data.success) {
            Sentry.captureException(response.data.error);
            return dispatch(addNotification('failed', _('error.somethingWrong', { ns: 'chargepoint', id: props.evseSettings.evse_id }), 'red'));
          }

          dispatch({
            type: 'UPDATE_SETTINGS',
            id: props.evseSettings.evse_id,
            payload: {
              delayed_charging: {
                value: true,
                start_time: handleTimeFromFormat(startTime),
                end_time: handleTimeFromFormat(endTime),
                selected_days: selected,
                permission: props.evseSettings.delayed_charging.permission,
              },
            },
          });

          return dispatch(addNotification('success', _('actions.offPeakChargingSuccess', { ns: 'chargepoint', id: props.evseSettings.evse_id }), 'green'));
        });
    }, 1500));
  };

  const INPUTS = [
    {
      id: 0,
      value: endTime,
      label: `${_('delayFrom', { ns: 'ui' })}:`,
      onChange: (event) => {
        setUpdated(true);
        setEndTime(handleTimeToFormat(event.target.value));
      },
    },
    {
      id: 1,
      value: startTime,
      label: `${_('delayUntil', { ns: 'ui' })}:`,
      onChange: (event) => {
        setUpdated(true);
        setStartTime(handleTimeToFormat(event.target.value));
      },
    },
  ];

  React.useEffect(() => {
    if (isInError || isTimeInError) {
      clearTimeout(delayedTimeout);
      setDelayedTimeout(null);
    }
  }, [isInError, isTimeInError]);

  React.useEffect(() => {
    if (startTime.invalid !== null || endTime.invalid !== null) return setTimeInError(true);
    if (startTime === endTime) return setTimeInError(true);
    if (selected.length === 0) return setInError(true);

    if (isUpdated) {
      setTimeInError(false);
      setInError(false);

      return handleUpdate();
    }

    return null;
  }, [
    selected,
    startTime,
    endTime,
    isUpdated,
  ]);

  const onClick = (item) => {
    setInError(false);

    if (selected.includes(item.id)) setSelected((event) => event.filter((i) => i !== item.id));
    else setSelected((event) => [...event, item.id]);

    setUpdated(true);
  };

  return (
    <>
      <div
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 15,
          paddingBottom: 10,
          backgroundColor: Colors.LIGHT_GREY,
          border: `solid 2px ${Colors.MEDIUM_WHITE}`,
          borderRadius: 10,
          display: 'flex',
          flexDirection: 'column',
          gap: 15,
        }}
      >
        <Text
          color={props.isDisabled ? Colors.MEDIUM_GREY : Colors.GREY}
        >
          {_('chargingProfiles.delayedCharging', { ns: 'chargepoint' })}
        </Text>
        <Text
          fontSize="1em"
          fontWeight={300}
          color={props.isDisabled ? Colors.MEDIUM_GREY : Colors.DARK_WHITE}
          lineHeight={1.4}
        >
          {_('delayedCharging.longExplanation', { ns: 'chargepoint', from: handleTimeFromFormat(startTime), until: handleTimeFromFormat(endTime) })}
        </Text>
        <Text
          fontSize="1em"
          fontWeight={300}
          color={Colors.DARK_WHITE}
        >
          {`${_('moreInformation', { ns: 'ui' })}:`}
          <a
            target="_blank"
            href="https://help.bluecurrent.nl/nl/knowledge/uitgesteld-laden"
            rel="noreferrer"
            className="Hover"
            onMouseEnter={() => {
              setHyperlinkHover(true);
            }}
            onMouseLeave={() => {
              setHyperlinkHover(false);
            }}
            style={{
              paddingLeft: 5,
              color: isHyperlinkHover ? '#0089C5' : Colors.BLUE,
              textDecoration: 'underline',
              textDecorationColor: isHyperlinkHover ? '#0089C5' : Colors.BLUE,
            }}
          >
            {_('chargingProfiles.delayedCharging', { ns: 'chargepoint' })}
          </a>
        </Text>
        <div
          style={{
            borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
            paddingTop: 15,
          }}
        >
          <Text
            color={props.isDisabled ? Colors.MEDIUM_GREY : Colors.GREY}
          >
            {`${_('repeatEach', { ns: 'ui' })}:`}
          </Text>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              width: '100%',
              gap: 5,
              marginTop: 15,
            }}
          >
            {
              DAYS.map((item) => (
                <Button
                  key={item.id}
                  type="select"
                  text={_(item.text[0], { ns: 'dates' })}
                  colorScheme={selected.includes(item.id) ? 'blue' : 'white'}
                  size="small"
                  onClick={() => onClick(item)}
                  selected={selected.includes(item.id)}
                  disabled={props.isDisabled}
                  error={isInError}
                />
              ))
            }
          </div>
          {
            selected.length === 0 && (
              <div
                style={{
                  marginTop: 7.5,
                }}
              >
                <Text
                  color={Colors.RED}
                  fontSize="0.9em"
                >
                  {_('delayedCharging.selectAtLeastOneDay', { ns: 'chargepoint' })}
                </Text>
              </div>
            )
          }
        </div>
        {
          INPUTS.map((item) => (
            <div
              key={item.id}
              style={{
                borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: 10,
                gap: 15,
                marginTop: -5,
              }}
            >
              <div
                style={{
                  width: isMobile ? '100%' : '120%',
                }}
              >
                <Text
                  color={props.isDisabled ? Colors.MEDIUM_GREY : Colors.GREY}
                  lineHeight={1.3}
                >
                  {item.label}
                </Text>
              </div>
              <Input
                type="time"
                value={handleTimeFromFormat(item.value)}
                styling
                error={isTimeInError}
                onChange={item.onChange}
                min={item.min}
                max={item.max}
                disabled={props.isDisabled}
                suffix={(
                  <Icon
                    name="Clock"
                    height={20}
                    width={20}
                    color={Colors.DARK_GREY}
                  />
                )}
              />
            </div>
          ))
        }
        <div
          style={{
            borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
            paddingTop: 10,
          }}
        >
          <Text
            color={Colors.DARK_GREY}
            fontSize="0.9em"
          >
            {_('delayedCharging.additionalInformation', { ns: 'chargepoint', from: handleTimeFromFormat(endTime), until: handleTimeFromFormat(startTime) })}
          </Text>
        </div>
      </div>
      {
        (props.isDisabled) && (
          <Text
            fontSize="0.9em"
            color={Colors.DARK_GREY}
          >
            {_('noPermissionToChangeSetting', { ns: 'settings' })}
          </Text>
        )
      }
    </>
  );
}
