import * as React from 'react';
import { useDispatch } from 'react-redux';

import {
  Lang,
  Colors,

  Button,
} from '@bluecurrent/web-component-lib';

export default function EditControls({
  isVisible,
  isEditMode,
  setEditMode,
}) {
  const { _ } = Lang.useTranslation();
  const dispatch = useDispatch();

  const onCreate = () => {
    dispatch({
      type: 'OVERLAY',
      payload: {
        layout: 'groupsCreate',
        display: true,
        title: _('group.create', { ns: 'ui' }),
        data: null,
      },
    });
  };

  return isVisible && (
    <div
      style={{
        paddingLeft: 15,
        paddingRight: 15,
        borderBottom: `solid 2px ${Colors.MEDIUM_WHITE}`,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: 5,
        paddingTop: 5,
        paddingBottom: 5,
      }}
    >
      <Button
        type="icon"
        icon={{
          name: 'Plus',
          height: 23,
          width: 23,
          color: Colors.GREY,
          iconSet: 'FA',
        }}
        colorScheme="white"
        borderRadius={7}
        onClick={() => onCreate()}
      />
      <Button
        type="icon"
        icon={{
          name: isEditMode ? 'List' : 'Edit',
          height: 23,
          width: 23,
          color: Colors.GREY,
          iconSet: 'Feather',
        }}
        colorScheme="white"
        borderRadius={7}
        onClick={() => setEditMode()}
      />
    </div>
  );
}
