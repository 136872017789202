import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  motion,
  AnimatePresence,
} from 'framer-motion';
import {
  useNavigate,
} from 'react-router-dom';

import {
  Colors,
  Breakpoint,
  Lang,

  useDimensions,

  Icon,
  ConfirmDialog,
} from '@bluecurrent/web-component-lib';

import ChargePointData from './Sections/Dashboard/ChargePointData';
import UpcomingEvent from './Sections/Dashboard/UpcomingEvent';
import { ChargePointItem } from './Sections/ChargePoints/Layout/List';
import TransactionsControls from './Sections/Transactions/Controls';

import ChargePointSettingsDummy from './Modules/Tour/Dummy/ChargePointSettings';
import ChargeCardItemDummy from './Modules/Tour/Dummy/ChargeCardItem';
import TransactionItemDummy from './Modules/Tour/Dummy/TransactionItem';

import TourItem from './Modules/Tour/Item';
import TourIntro from './Modules/Tour/Intro';
import TourLang from './Modules/Tour/Language';

import tourConfig from '../config/Tour.config';

/* eslint-disable-next-line import/no-named-as-default-member, import/no-named-as-default */
import useStorage from '../hooks/useStorage';
import useCommands from '../hooks/useCommands';

export default function Tour() {
  const { _ } = Lang.useTranslation();
  const navigate = useNavigate();
  const size = useDimensions();
  const storage = useStorage();
  const commands = useCommands();

  const chargePointData = useSelector((state) => state.ChargePoints);
  const permissions = useSelector((state) => state.Permissions);

  const fullscreen = size.width < Breakpoint.sm;

  const [tour, setTour] = React.useState(false);
  const [active, setActive] = React.useState(0);
  const [visible, setVisible] = React.useState(false);
  const [confirmSkip, setConfirmSkip] = React.useState(false);

  React.useEffect(() => {
    /*
     * if (!storage.getItem('completed-tour')) {
     *   setTour(true);
     * }
     */

    storage.setItem('completed-tour', true);

    const onStartTour = () => setTour(true);

    commands.on('START_TOUR', onStartTour);
    return () => commands.off('START_TOUR', onStartTour);
  }, []);

  const renderTourItem = () => {
    switch (active) {
      case 1:
        return (
          <ChargePointData />
        );
      case 2:
        return (
          <UpcomingEvent />
        );
      case 3:
        return (
          <ChargePointItem
            key={0}
            event={chargePointData[0]}
            index={1}
          />
        );
      case 4:
        return <ChargePointSettingsDummy />;
      case 5:
        return <ChargeCardItemDummy />;
      case 6:
        return <TransactionItemDummy />;
      case 7:
        return (
          <div
            style={{
              width: '100%',
              height: 80,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <TransactionsControls
              startDate={false}
              setStartDate={false}
              endDate={new Date()}
              setEndDate={false}
              page={1}
              setPage={false}
              groupModal={false}
              setGroupModal={false}
              useSearch={['', '']}
              useFilterModalOpen={[0, 0]}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const onClose = () => {
    setConfirmSkip(true);
  };

  const onCloseConfirm = () => {
    setTour(false);
    setActive(0);
    setConfirmSkip(false);
  };

  const onSkip = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
      storage.setItem('completed-tour', true);
    }
  };

  const onNext = () => {
    const delay = fullscreen ? 200 : 900;

    setVisible(false);

    const firstStepWithPermission = tourConfig
      .slice(active, tourConfig.length)
      .findIndex((step) => {
        if (!step.permissionHandler) return true;
        return step.permissionHandler(permissions);
      });

    setTimeout(() => {
      setActive(active + (firstStepWithPermission + 1));
    }, delay);
  };

  const onNextLang = () => {
    const delay = fullscreen ? 200 : 0;

    setTimeout(() => {
      setActive(0.5);
    }, delay);
  };

  const onNextIntro = () => {
    const delay = fullscreen ? 200 : 0;

    setTimeout(() => {
      setActive(1);
    }, delay);
  };

  const onFinish = () => {
    navigate('/');
    setTour(false);
    setActive(0);
    storage.setItem('completed-tour', true);
  };

  return (
    <>
      <AnimatePresence>
        {
          tour && (
            <motion.div
              style={{
                height: '100vh',
                width: '100%',
                position: 'fixed',
                zIndex: 2147483004,
              }}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
              transition={{
                duration: 0.5,
              }}
            >
              <AnimatePresence>
                <div
                  style={{
                    height: '100vh',
                    width: '100%',
                    position: 'fixed',
                    zIndex: 200,
                  }}
                >
                  <motion.div
                    type="button"
                    onMouseDown={onSkip}
                    style={{
                      height: '100%',
                      width: '100%',
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 0,
                      margin: 0,
                      border: 'solid 0px transparent',
                      mixBlendMode: 'multiply',
                      inset: 0,
                      fontSize: '1em',
                    }}
                    initial={{
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                    }}
                    animate={{
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    }}
                    exit={{
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                    }}
                    transition={{
                      duration: 0.5,
                    }}
                  >
                    <button
                      type="button"
                      onMouseDown={onClose}
                      className="Hover"
                      aria-label={_('close', { ns: 'ui' })}
                      style={{
                        position: 'absolute',
                        right: 15,
                        top: 15,
                        height: 40,
                        width: 40,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'transparent',
                        border: 'solid 0px transparent',
                        fontSize: '1em',
                        zIndex: 3,
                      }}
                    >
                      <Icon
                        name="Times"
                        height={25}
                        width={25}
                        color={Colors.LIGHT_GREY}
                        iconSet="Feather"
                      />
                    </button>
                    {
                      active === 0 && (
                        <TourLang
                          onNext={onNextLang}
                          onSkip={onSkip}
                          fullscreen={fullscreen}
                        />
                      )
                    }
                    {
                      active === 0.5 && (
                        <TourIntro
                          onNext={onNextIntro}
                          onSkip={onSkip}
                          fullscreen={fullscreen}
                        />
                      )
                    }
                    {
                      active > 0.5 && (
                        <TourItem
                          active={active}
                          visible={active > 0 && visible}
                          setVisible={() => setVisible(true)}
                          onNext={onNext}
                          onFinish={onFinish}
                          fullscreen={fullscreen}
                        >
                          {renderTourItem()}
                        </TourItem>
                      )
                    }
                  </motion.div>
                </div>
              </AnimatePresence>
            </motion.div>
          )
        }
      </AnimatePresence>
      <ConfirmDialog
        visible={confirmSkip}
        title={_('skipTour', { ns: 'ui' })}
        description={_('alwaysStartTourInSettings', { ns: 'ui' })}
        accept={{
          text: _('gotIt', { ns: 'ui' }),
          onClick: () => onCloseConfirm(),
        }}
      />
    </>
  );
}
