import * as React from 'react';

import { useNavigate } from 'react-router-dom';

import {
  Colors,
  Lang,

  useDimensions,

  ModuleHeader,
  Text,
  Icon,
} from '@bluecurrent/web-component-lib';

import * as Sentry from '@sentry/browser';
import { useDispatch, useSelector } from 'react-redux';
import Item from '../Transactions/List/Item';
import executeCommand from '../../../api/executeCommand';
import { GET_TRANSACTIONS } from '../../../api/types/ApiMessages';
import addNotification from '../../../redux/creators/notifications';
import ListLoader from '../../Modules/ListLoader';
import PlaceholderTransactions from '../../Modules/Placeholders/Transactions';

export default function LatestTransactions() {
  const { _ } = Lang.useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const size = useDimensions();

  const [isHover, setHover] = React.useState(false);

  const chargePoints = useSelector((state) => state.ChargePoints);
  const permissions = useSelector((state) => state.Permissions);

  const [transactions, setTransactions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (permissions.transactions) {
      setLoading(true);
      executeCommand(GET_TRANSACTIONS, {
        params: {
          page: 1,
          sort_field_order: 'DESC',
          sort_field: 'stoppedtimestamp',
        },
        body: {
          chargepoints: chargePoints.map((cp) => ({ chargepoint_id: cp.evse_id })),
        },
      })
        .then(({ data }) => {
          setLoading(false);
          setTransactions(data.data.transactions.slice(0, 7));
        })
        .catch((err) => {
          Sentry.captureException(err);
          dispatch(addNotification('failed', _('errors.somethingWrong', { ns: 'ui' }), 'red'));
        });
    }
  }, [permissions.transactions]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: Colors.WHITE,
        borderRadius: 10,
        border: `solid 2px ${Colors.MEDIUM_WHITE}`,
        overflow: 'hidden',
        paddingRight: 5,
        paddingLeft: 5,
        paddingTop: 5,
      }}
    >
      <div
        style={{
          paddingLeft: 10,
        }}
      >
        <ModuleHeader
          title={_('transactions', { ns: 'ui' })}
        />
      </div>
      {
        loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100% - 60px)',
          }}
        >
          <ListLoader />
        </div>
        )
      }
      {
        transactions.length === 0 && (
          <PlaceholderTransactions
            embedded
          />
        )
      }
      {
        !loading && (
          <>
            <div
              style={{
                height: size.width < 592 ? 'calc(80% - 60px)' : 'calc(100% - 60px)',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: transactions.length < 7 ? 'flex-start' : 'space-between',
                paddingLeft: 5,
                paddingRight: 5,
                marginTop: -10,
                marginBottom: 10,
              }}
            >
              {
                size.width < 592
                  ? (
                    transactions.slice(0, 3).map((item, index) => (
                      <Item
                      /* eslint-disable-next-line react/no-array-index-key */
                        key={item.chargepoint_id + index}
                        data={item}
                        index={index}
                        type="small"
                        align="horizontal"
                        last={transactions.length >= 7 && transactions.length === index + 1}
                      />
                    )))
                  : (
                    transactions.map((item, index) => (
                      <Item
                      /* eslint-disable-next-line react/no-array-index-key */
                        key={item.chargepoint_id + index}
                        data={item}
                        index={index}
                        type="small"
                        align="horizontal"
                        last={transactions.length >= 7 && transactions.length === index + 1}
                      />
                    )))
              }
            </div>
            <button
              type="button"
              className="Hover"
              onClick={() => {
                navigate('/transactions');
              }}
              onMouseEnter={() => {
                setHover(true);
              }}
              onMouseLeave={() => {
                setHover(false);
              }}
              style={{
                width: 'calc(100% - 10px)',
                minHeight: 50,
                height: 'auto',
                backgroundColor: isHover ? Colors.LIGHT_BLUE : Colors.BLUE,
                fontSize: '1em',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginRight: 5,
                marginLeft: 5,
                paddingLeft: 15,
                border: isHover ? `2px solid ${Colors.BLUE}` : '2px solid transparent',
                borderRadius: 10,
                transition: 'all 0.3s linear',
              }}
            >
              <Text
                fontWeight={300}
                fontSize="1.2em"
                color={isHover ? Colors.BLUE : Colors.WHITE}
              >
                {_('seeAll', { ns: 'ui' })}
              </Text>
              <Icon
                name="AngleRight"
                height={30}
                width={30}
                color={isHover ? Colors.BLUE : Colors.WHITE}
              />
            </button>
          </>
        )
       }
    </div>
  );
}
