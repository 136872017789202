import * as React from 'react';
import {
  Colors,
  Lang,
  Breakpoint,

  truncate,
  useDimensions,

  Item,
  Text,
} from '@bluecurrent/web-component-lib';
import { useDispatch } from 'react-redux';
import { SET_PLUG_AND_CHARGE } from '../../../../api/types/ApiMessages';

const PlugAndChargeSetting = (props) => {
  const { _ } = Lang.useTranslation();
  const size = useDimensions();
  const dispatch = useDispatch();
  const [updatedSettings] = props.useUpdatedSettings;
  const value = updatedSettings.plug_and_charge
        ?? (props.evseSettings.plug_and_charge?.value ?? false);
  const permission = props.evseSettings.plug_and_charge?.permission ?? 'error';

  const onSwitch = () => {
    const payload = {
      plug_and_charge: {
        value: !value,
        permission: props.evseSettings.plug_and_charge?.permission ?? 'error',
      },
    };

    /* Only opening when enabling Plug & Charge */
    if (!value === true) {
      props.setSelectPlugAndChargeCard('enabling');
    } else {
      props.simpleValueChange(
        SET_PLUG_AND_CHARGE,
        'plug_and_charge',
        !value,
        true,
        (success) => {
          if (success) {
            dispatch({
              type: 'UPDATE_SETTINGS',
              id: props.evseSettings.evse_id,
              payload,
            });
          }
        },
      );
    }
  };

  const parseName = () => {
    if (props.evseSettings.plug_and_charge_charge_card?.name) {
      return props.evseSettings.plug_and_charge_charge_card?.name;
    }

    if (props.evseSettings.plug_and_charge_charge_card?.id === 'BCU-APP'
      || !props.evseSettings.plug_and_charge_charge_card) return _('noChargeCard', { ns: 'ui' });

    return props.evseSettings.plug_and_charge_charge_card?.id;
  };

  if (permission === 'none') return null;

  return (
    <>
      <div
        className="DisableSelection"
        style={{
          padding: 10,
          height: 'auto',
          minHeight: 55,
          backgroundColor: Colors.LIGHT_GREY,
          border: `solid 2px ${Colors.MEDIUM_WHITE}`,
          borderRadius: 10,
        }}
      >
        <Item
          type="boolean"
          title={_('settings.plugCharge', { ns: 'chargepoint' })}
          value={value}
          error={permission === 'error'}
          tooltip={{
            error: _('failedToLoadSetting', { ns: 'settings' }),
            permission: size.width < Breakpoint.sm ? false : _('noPermissionToChangeSetting', { ns: 'settings' }),
          }}
          customTooltip={{
            visible: props.evseSettings.activity === 'charging',
            text: _('settingDisabledDuringChargeSession', { ns: 'chargepoint' }),
          }}
          disabled={permission === 'error' || props.evseSettings.activity === 'charging'}
          noPermission={permission !== 'write'}
          onSwitch={onSwitch}
        />
        {
          value && (
            <div
              style={{
                borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
                marginTop: 10,
                paddingTop: 10,
              }}
            >
              <Item
                type="button"
                title={_('plugAndChargeCard', { ns: 'chargecard' })}
                suffix={truncate(parseName() ?? 'Error', 25)}
                onClick={() => props.setSelectPlugAndChargeCard(true)}
                disabled={props.evseSettings.activity === 'charging'}
                tooltip={{
                  error: _('failedToLoadSetting', { ns: 'settings' }),
                  permission: size.width < Breakpoint.sm ? false : _('noPermissionToChangeSetting', { ns: 'settings' }),
                }}
              />
            </div>
          )
        }
      </div>
      {
        size.width < Breakpoint.sm && permission !== 'write' && (
          <div
            style={{
              marginTop: 5,
              marginBottom: 20,
            }}
          >
            <Text
              fontSize="0.9em"
              color={Colors.DARK_GREY}
            >
              {_('noPermissionToChangeSetting', { ns: 'settings' })}
            </Text>
          </div>
        )
      }
    </>
  );
};

export default PlugAndChargeSetting;
