export default function handleCompensationInputValidation(value, min, max) {
  const checkRegExZero = /^0[0-9].*$/;

  if (
    value > max
    || value < min
    || checkRegExZero.test(value)
  ) {
    return true;
  }

  return false;
}
