import * as React from 'react';

import {
  Lang,

  Text,
} from '@bluecurrent/web-component-lib';

import PlaceholderCards from '../../../assets/svg/Placeholders/Cards';

export default function ChargePoints() {
  const { _ } = Lang.useTranslation();

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      <div
        style={{
          height: 400,
          width: '100%',
        }}
      >
        <PlaceholderCards />
      </div>
      <div
        style={{
          marginTop: -30,
          height: '20%',
        }}
      >
        <Text
          fontWeight={500}
          fontSize="1.3em"
          textAlign="center"
        >
          {_('noChargePointsFound', { ns: 'ui' })}
        </Text>
      </div>
    </div>
  );
}
