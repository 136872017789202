import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Sentry from '@sentry/browser';

import {
  Colors,
  Lang,

  Button,
  Text,
} from '@bluecurrent/web-component-lib';

import executeCommand from '../../../api/executeCommand';
import addNotification from '../../../redux/creators/notifications';

import ProfiledChargingSettings from '../../../config/ProfiledCharging.config';

import SelectionItem from '../../Modules/SelectionItem';
import useChargingProfile from '../../../hooks/useChargingProfile';

export default function ProfiledCharging(props) {
  const { _ } = Lang.useTranslation();
  const dispatch = useDispatch();

  const serialNumber = window.location.pathname
    .split('/')
    .filter((e) => e.length > 0)
    .slice(-1)
    .toString();

  const evseSettings = useSelector(
    (state) => state.ChargePoints.find((i) => i.evse_id === serialNumber),
  );
  const chargingProfile = useChargingProfile(evseSettings);

  const [selectedItem, setSelectedItem] = React.useState(chargingProfile);

  const onClick = (value) => {
    setSelectedItem(value);
  };

  /* onSave function for Set Up button */
  const onSave = (selectedSetting) => {
    if (chargingProfile !== null) {
      executeCommand(
        ProfiledChargingSettings[chargingProfile].apiMessage,
        {
          body: {
            evse_id: serialNumber,
            value: false,
          },
        },
      )
        .then(() => {
          dispatch({
            type: 'UPDATE_SETTINGS',
            id: serialNumber,
            payload: {
              [chargingProfile]: {
                ...evseSettings[chargingProfile],
                value: false,
              },
            },
          });
        })
        .catch((err) => {
          dispatch(addNotification('failed', _('error.somethingWrong', { ns: 'chargepoint' }), 'red'));
          Sentry.captureException(err);
        });
    }

    executeCommand(
      ProfiledChargingSettings[selectedSetting].apiMessage,
      {
        body: {
          evse_id: serialNumber,
          value: true,
        },
      },
    )
      .then(() => {
        dispatch({
          type: 'UPDATE_SETTINGS',
          id: serialNumber,
          payload: {
            [selectedSetting]: {
              ...evseSettings[selectedSetting],
              value: true,
            },
          },
        });
      })
      .catch((err) => {
        dispatch(addNotification('failed', _('error.somethingWrong', { ns: 'chargepoint' }), 'red'));
        Sentry.captureException(err);
      });
    props.setProfiledChargingModal(false);
  };

  const PROFILES = [
    {
      id: 'price_based_charging',
      isSelected: selectedItem === 'price_based_charging',
      onClick: () => onClick('price_based_charging'),
      title: _('chargingProfiles.priceBasedCharging', { ns: 'chargepoint' }),
      description: _('priceBasedCharging.shortExplanation', { ns: 'chargepoint' }),
      isVisible: props.evseSettings?.price_based_charging?.permission !== 'none',
      isReadOnly: props.evseSettings?.price_based_charging?.permission !== 'write' && props.evseSettings?.price_based_charging?.permission !== 'none',
    },
    {
      id: 'delayed_charging',
      isSelected: selectedItem === 'delayed_charging',
      onClick: () => onClick('delayed_charging'),
      title: _('chargingProfiles.delayedCharging', { ns: 'chargepoint' }),
      description: _('delayedCharging.shortExplanation', { ns: 'chargepoint' }),
      isVisible: props.evseSettings?.delayed_charging?.permission !== 'none',
      isReadOnly: props.evseSettings?.delayed_charging?.permission !== 'write' && props.evseSettings?.delayed_charging?.permission !== 'none',
    },
    {
      id: 'solar_based_charging',
      isSelected: selectedItem === 'solar_based_charging',
      onClick: () => onClick('solar_based_charging'),
      title: _('chargingProfiles.solarBasedCharging', { ns: 'chargepoint' }),
      description: _('solarBasedCharging.description', { ns: 'chargepoint' }),
      isVisible: props.evseSettings?.solar_based_charging?.permission !== 'none',
      isReadOnly: props.evseSettings?.solar_based_charging?.permission !== 'write' && props.evseSettings?.solar_based_charging?.permission !== 'none',
    },
  ];

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          height: '100%',
          overflowY: 'scroll',
          gap: 15,
          padding: 15,
        }}
      >
        {
          PROFILES.filter((item) => item.isVisible).map((item) => (
            <SelectionItem
              key={item.id}
              type={item.id}
              isSelected={item.isSelected}
              onClick={item.onClick}
              title={item.title}
              subTitle={item.description}
              isReadOnly={item.isReadOnly}
            />
          ))
        }
        {
          PROFILES.some((item) => item.isReadOnly === true) && (
            <Text
              fontSize="0.9em"
              color={Colors.DARK_GREY}
            >
              {_('chargingProfiles.profilesDisabledNoPermissionToChangeSetting', { ns: 'chargepoint' })}
            </Text>
          )
        }
      </div>
      <div
        style={{
          height: 'auto',
          width: '100%',
          backgroundColor: Colors.WHITE,
          borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
          padding: 15,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            width: 'auto',
            marginRight: 15,
          }}
        >
          <Button
            type="icon"
            icon={{
              name: 'ArrowLeft',
              height: 20,
              width: 20,
              color: Colors.GREY,
              iconSet: 'FA',
            }}
            colorScheme="white"
            onClick={props.onClose}
          />
        </div>
        <div
          style={{
            width: 'auto',
          }}
        >
          <Button
            text={_('changeProfile', { ns: 'ui' })}
            colorScheme={selectedItem ? 'blue' : 'grey'}
            disabled={!selectedItem}
            onClick={() => {
              onSave(selectedItem);
            }}
          />
        </div>
      </div>
    </>
  );
}
