/* eslint-disable-next-line import/no-named-as-default-member, import/no-named-as-default */
import useStorage from './useStorage';

const useUpdateLanguage = (i18n) => (iso) => {
  const storage = useStorage();

  i18n.changeLanguage(iso);
  storage.setItem('lang', iso);
};

export default useUpdateLanguage;
