import * as React from 'react';
import {
  motion,
  AnimatePresence,
} from 'framer-motion';

import {
  Breakpoint,

  useDimensions,

  ChargePointGridItem,
} from '@bluecurrent/web-component-lib';

import getSortBy from './getSortBy';
import useTextSearch from '../../../../hooks/useTextSearch';

export default function Grid(props) {
  const size = useDimensions();
  const textSearch = useTextSearch();

  const [rows, setRows] = React.useState([]);

  const responsive = () => {
    if (size.width > Breakpoint.sm && size.width <= Breakpoint.xl) return 3;
    if (size.width > Breakpoint.xs && size.width <= Breakpoint.sm) return 2;
    if (size.width > 355 && size.width <= Breakpoint.xs) return 2;
    if (size.width <= 355) return 1;
    return 4;
  };

  React.useEffect(() => {
    const array = [];

    let chargePoints = props.data.filter((item) => props.filter.includes('all') || props.filter.includes(item.activity));
    chargePoints = chargePoints.filter((item) => (
      props.group ? props.group.includes(item.evse_id) : true
    ));
    chargePoints = chargePoints.filter(textSearch(props.search, ['evse_id', 'name', 'model_type', 'chargepoint_type']));
    chargePoints = chargePoints.sort(
      (a, b) => getSortBy.indexOf(a.activity) - getSortBy.indexOf(b.activity),
    );

    for (let i = 0; i < chargePoints.length; i += responsive()) {
      array.push(chargePoints.slice(i, i + responsive()));
    }

    setRows(array);
  }, [
    props.data,
    props.filter,
    props.group,
    size,
    props.search,
  ]);

  return (
    <AnimatePresence>
      {
        rows.map((columns, index) => (
          <motion.div
            /* eslint-disable-next-line react/no-array-index-key */
            key={index}
            style={{
              paddingTop: index === 0 ? 15 : 0,
              marginBottom: 15,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: columns.length < responsive() ? 'flex-start' : 'space-between',
            }}
          >
            {
              columns.map((item) => (
                <div
                  key={item.evse_id}
                  style={{
                    marginRight: columns.length < responsive() ? 15 : 'initial',
                  }}
                >
                  <ChargePointGridItem
                    key={item.evse_id}
                    name={item.name === '' ? item.evse_id : item.name}
                    type={item.chargepoint_type}
                    activity={item.activity}
                    id={item.evse_id}
                    width={(props.width - (15 * (responsive() - 1))) / responsive()}
                    responsive={responsive()}
                  />
                </div>
              ))
            }
          </motion.div>
        ))
      }
    </AnimatePresence>
  );
}
