import * as React from 'react';
import {
  Lang,
  Colors,
  Breakpoint,

  useDimensions,

  Item,
  Text,
} from '@bluecurrent/web-component-lib';
import { useDispatch } from 'react-redux';
import { SET_PUBLISH_LOCATION } from '../../../../api/types/ApiMessages';

const PublicChargingSetting = (props) => {
  const { _, i18n } = Lang.useTranslation();
  const size = useDimensions();
  const dispatch = useDispatch();
  const [updatedSettings] = props.useUpdatedSettings;
  const value = updatedSettings.publish_location ?? props.evseSettings.publish_location?.value;
  const permission = props.evseSettings.publish_location?.permission;

  const onSwitch = () => {
    const payload = {
      publish_location: {
        value: !value,
        permission: props.evseSettings.publish_location?.permission,
      },
    };

    props.simpleValueChange(
      SET_PUBLISH_LOCATION,
      'publish_location',
      !value,
      true,
      (success) => {
        if (success) {
          dispatch({
            type: 'UPDATE_SETTINGS',
            id: props.evseSettings.evse_id,
            payload,
          });
        }
      },
    );
  };

  if (permission === 'none') return null;

  return (
    <>
      {
        (
          (props.evseSettings.location?.street !== 'unknown'
          || props.evseSettings.location?.housenumber !== 'unknown'
          || props.evseSettings.location?.zipcode !== ''
          || props.evseSettings.location?.city !== 'unknown')
          && props.evseSettings.location
        ) && (
          <div
            className="DisableSelection"
            style={{
              padding: 10,
              height: 'auto',
              minHeight: 55,
              backgroundColor: Colors.LIGHT_GREY,
              border: `solid 2px ${Colors.MEDIUM_WHITE}`,
              borderRadius: 10,
              marginBottom: 10,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingBottom: 10,
                minHeight: 45,
              }}
            >
              <Text>{`${_('address', { ns: 'ui' })}:`}</Text>
              <Text>{`${props.evseSettings.location.street} ${props.evseSettings.location.housenumber}`}</Text>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
                paddingTop: 15,
                paddingBottom: 15,
                minHeight: 45,
              }}
            >
              <Text>{`${_('zipCode', { ns: 'ui' })}:`}</Text>
              <Text>{`${props.evseSettings.location.zipcode}, ${props.evseSettings.location.city}`}</Text>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
                paddingTop: 10,
                minHeight: 45,
              }}
            >
              <Text>{`${_('country', { ns: 'ui' })}:`}</Text>
              <Text>{new Intl.DisplayNames([i18n.language], { type: 'region' }).of(props.evseSettings.location.country)}</Text>
            </div>
          </div>
        )
      }
      <div
        className="DisableSelection"
        style={{
          padding: 10,
          height: 'auto',
          minHeight: 55,
          backgroundColor: Colors.LIGHT_GREY,
          border: `solid 2px ${Colors.MEDIUM_WHITE}`,
          borderRadius: 10,
        }}
      >
        <Item
          type="boolean"
          title={_('settings.publishLocation', { ns: 'chargepoint' })}
          value={value}
          noPermission={permission !== 'write'}
          tooltip={{
            error: _('failedToLoadSetting', { ns: 'settings' }),
            permission: size.width < Breakpoint.sm ? false : _('noPermissionToChangeSetting', { ns: 'settings' }),
          }}
          onSwitch={onSwitch}
        />
      </div>
      {
        size.width < Breakpoint.sm && permission !== 'write' && (
          <div
            style={{
              marginTop: 5,
              marginBottom: 20,
            }}
          >
            <Text
              fontSize="0.9em"
              color={Colors.DARK_GREY}
            >
              {_('noPermissionToChangeSetting', { ns: 'settings' })}
            </Text>
          </div>
        )
      }
    </>
  );
};

export default PublicChargingSetting;
