import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import {
  useDimensions,

  Lang,
  Colors,
  Breakpoint,

  Carousel,
} from '@bluecurrent/web-component-lib';

import {
  GET_QUICK_ACCESS,
} from '../../../../api/types/ApiMessages';
import executeCommand from '../../../../api/executeCommand';

import Card from './Card';

export default function QuickAccess() {
  const size = useDimensions();
  const { _ } = Lang.useTranslation();
  const navigate = useNavigate();

  const wrapperRef = React.useRef(0);
  const [wrapperHeight, setWrapperHeight] = React.useState(0);
  const [buttons, setButtons] = React.useState([]);

  React.useEffect(() => {
    setWrapperHeight(wrapperRef.current.clientHeight);
  }, [wrapperRef.current.clientWidth]);

  const buttonInfo = {
    declaration_insight: {
      id: 0,
      name: _('declarationOverview', { ns: 'finance' }),
      description: _('insightIntoTransactions', { ns: 'ui' }),
      icon: {
        name: 'Ballot',
        color: Colors.PURPLE,
        height: 35,
        width: 35,
        iconSet: 'FA',
      },
      onClick: () => navigate('/transactions/declaration-overview'),
    },
    account_settings: {
      id: 1,
      name: `${_('accountInformation', { ns: 'authentication' })}`,
      description: _('changeAccountInfoHere', { ns: 'ui' }),
      icon: {
        name: 'User',
        color: Colors.GREEN,
        height: 35,
        width: 35,
        iconSet: 'FA',
      },
      onClick: () => navigate('/account'),
    },
    chargecards: {
      id: 2,
      name: _('chargeCard', { ns: 'ui' }),
      description: _('addSeeChangeYourChargeCard', { ns: 'ui' }),
      icon: {
        name: 'Card',
        color: Colors.ORANGE,
        height: 40,
        width: 40,
        iconSet: 'FA',
      },
      onClick: () => navigate('/chargecards'),
    },
    chargepoints: {
      id: 3,
      name: _('chargePoints', { ns: 'ui' }),
      description: _('seeYourChargePoints', { ns: 'ui' }),
      icon: {
        name: 'UMOVE',
        color: Colors.BLUE,
        height: 40,
        width: 40,
        iconSet: 'BlueCurrent',
      },
      onClick: () => navigate('/chargepoints'),
    },
    agreement: {
      id: 4,
      name: _('agreement', { ns: 'settings' }),
      description: _('allContractInfoInOnePlace', { ns: 'ui' }),
      icon: {
        name: 'Scroll',
        color: Colors.YELLOW,
        height: 40,
        width: 40,
        iconSet: 'FA',
      },
      onClick: () => navigate('/agreement'),
    },
    settings: {
      id: 5,
      name: _('appSettings', { ns: 'settings' }),
      description: _('changeYourSettingsHere', { ns: 'ui' }),
      icon: {
        name: 'Cog',
        color: Colors.GREY,
        height: 40,
        width: 40,
        iconSet: 'FA',
      },
      onClick: () => navigate('/settings'),
    },
  };

  const responsive = () => {
    if (size.width < Breakpoint.fd) return 1;
    if (size.width < Breakpoint.xs) return 1;
    if (size.width < Breakpoint.sm) return 3;
    if (size.width < Breakpoint.md) return 3;
    if (size.width < Breakpoint.xl) return 4;
    if (size.width < Breakpoint.max) return 4;
    return 4;
  };

  useEffect(() => {
    executeCommand(GET_QUICK_ACCESS)
      .then((res) => {
        setButtons(res.data.QuickAccess.map((i) => ({
          id: buttonInfo[i.type].id,
          name: buttonInfo[i.type].name,
          description: buttonInfo[i.type].description,
          icon: buttonInfo[i.type].icon,
          onClick: buttonInfo[i.type].onClick,
        })));
      });
  }, []);

  return (
    <div
      ref={wrapperRef}
      style={{
        height: '100%',
        overflow: 'hidden',
      }}
    >
      {
        size.width > 592 ? (
          <Carousel
            height={wrapperHeight}
            width="100%"
            maxItems={responsive()}
            colorScheme="white"
            fade
            disable={buttons.length <= 0}
            visibleArrows
          >
            {
              buttons.length > 0
                ? (
                  buttons.map((e) => (
                    <div
                      key={e.id}
                      style={{
                        height: '100%',
                        width: '100%',
                      }}
                    >
                      <Card
                        data={e}
                      />
                    </div>
                  ))
                ) : [...Array(5)].map((event, index) => (
                  /* eslint-disable react/no-array-index-key */
                  <div
                    key={index}
                    style={{
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    <Skeleton
                      key={index}
                      width="100%"
                      height="100%"
                      inline
                      borderRadius={10}
                      style={{
                        marginRight: 10,
                      }}
                    />
                  </div>
                ))
          }
          </Carousel>
        ) : (
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'grid',
              gridTemplateColumns: size.width < 470 ? '1fr' : 'repeat(2, minmax(0, 1fr))',
              gap: 10,
            }}
          >
            {
              buttons.length > 0 && size.width >= 470
                ? (
                  buttons.map((e) => (
                    <div
                      key={e.id}
                      style={{
                        height: '100%',
                        width: '100%',
                      }}
                    >
                      <Card
                        data={e}
                      />
                    </div>
                  ))
                ) : buttons.length > 0 && size.width < 470
                  ? (
                    buttons.slice(0, 3).map((e) => (
                      <div
                        key={e.id}
                        style={{
                          height: '100%',
                          width: '100%',
                        }}
                      >
                        <Card
                          data={e}
                        />
                      </div>
                    ))
                  ) : [...Array(5)].map((event, index) => (
                  /* eslint-disable react/no-array-index-key */
                    <div
                      key={index}
                      style={{
                        height: '100%',
                        width: '100%',
                      }}
                    >
                      <Skeleton
                        key={index}
                        width="100%"
                        height="100%"
                        inline
                        borderRadius={10}
                        style={{
                          marginRight: 10,
                        }}
                      />
                    </div>
                  ))
              }
          </div>
        )
      }
    </div>
  );
}
