const useBestAddress = () => (address) => {
  if (address?.street && address?.street !== 'unknown') return `${address.street} ${(address.housenumber && address.housenumber !== 'unknown') ? address.housenumber : ''}`;

  if (address?.city && address?.city !== 'unknown') return address.city;

  if (address?.postal_code && address?.postal_code !== 'unknown') return `${address.city} ${(address.country && address.country !== 'unknown') ? address.country : ''}`;

  if (address?.region && address?.region !== 'unknown') return address.region;

  return false;
};

export default useBestAddress;
