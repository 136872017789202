import * as React from 'react';

import { useSelector } from 'react-redux';
import {
  useNavigate,

  Navigate,
} from 'react-router-dom';

import {
  Title,
  NavigationItem,
  Breakpoint,
  Colors,

  truncate,
  useDimensions,

  Icon,

  Lang,
} from '@bluecurrent/web-component-lib';

import useDeveloperMode from '../../../hooks/useDeveloperMode';

export default function Header(props) {
  const size = useDimensions();
  const navigate = useNavigate();
  const developerMode = useDeveloperMode();

  const user = useSelector((state) => state.User);
  const { _ } = Lang.useTranslation(['authentication', 'settings']);

  const [redirect, setRedirect] = React.useState(null);
  const [, setLogoutDialog] = props.useLogoutDialog;

  const getUserName = () => {
    if (user.full_name === null || user.full_name === '') {
      return (
        <Icon
          name="User"
          height={25}
          width={25}
          color={Colors.BLUE}
        />
      );
    }

    return truncate(user.full_name.split(' ')[0], 25);
  };

  React.useEffect(() => {
    setRedirect(null);
  }, [redirect]);

  if (redirect) return <Navigate to={redirect} />;

  const dropdownMenu = [
    {
      id: 0,
      title: _('account', { ns: 'authentication' }),
      onClick: () => navigate('/account'),
    },
    // {
    //   id: 1,
    //   title: _('agreement', { ns: 'settings' }),
    //   onClick: () => navigate('/agreement'),
    // },
    {
      id: 2,
      title: _('appSettings', { ns: 'settings' }),
      onClick: () => navigate('/settings'),
    },
  ];

  if (developerMode.enabled) {
    dropdownMenu.push(
      {
        id: 3,
        hr: true,
      },
      {
        id: 4,
        title: _('developers', { ns: 'settings' }),
        onClick: () => navigate('/developers'),
      },
    );
  }

  dropdownMenu.push(
    {
      id: 5,
      hr: true,
    },
    {
      id: 6,
      title: _('logout', { ns: 'authentication' }),
      color: Colors.RED,
      onClick: () => setLogoutDialog(true),
    },
  );

  return (
    <div
      style={{
        height: size.width < Breakpoint.sm ? 60 : 80,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}
      >
        <Title
          fontSize={
            size.width <= Breakpoint.xs
              ? size.width < 400
                ? '1.25em'
                : '1.5em'
              : '2em'
          }
        >
          {props.title ?? _('dashboard', { ns: 'ui' })}
        </Title>
      </div>
      <div
        style={{
          width: size.width <= Breakpoint.xs ? 65 : '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            marginLeft: 30,
            width: 'auto',
          }}
        >
          <NavigationItem
            id="header-dropdown"
            icon={size.width <= Breakpoint.xs && {
              name: 'User',
              height: 25,
              width: 25,
              color: Colors.BLUE,
            }}
            title={getUserName()}
            dropdownMenu={dropdownMenu}
          />
        </div>
      </div>
    </div>
  );
}
