import * as React from 'react';
import { useSelector } from 'react-redux';

import List from './List';
import Grid from './Grid';
import Map from './Map';

export default function Layout(props) {
  const data = useSelector((state) => state.ChargePoints);

  const renderLayout = () => {
    switch (props.layout) {
      case 'list':
        return (
          <List
            data={data}
            width={props.width}
            filter={props.filter}
            group={props.group}
            search={props.search}
          />
        );
      case 'symbols':
        return (
          <Grid
            data={data}
            width={props.width}
            filter={props.filter}
            group={props.group}
            search={props.search}
          />
        );
      case 'map':
        return (
          <Map
            data={data}
            width={props.width}
            filter={props.filter}
            group={props.group}
          />
        );
      default:
        return (
          <List
            data={data}
            width={props.width}
            filter={props.filter}
            group={props.group}
          />
        );
    }
  };

  return (
    <div
      style={{
        width: '100%',
        height: 'auto',
      }}
    >
      {
        renderLayout()
      }
    </div>
  );
}
