import React from 'react';
import {
  AnimatePresence,
  motion,
} from 'framer-motion';

import {
  Colors,
  Lang,
  Breakpoint,

  useDimensions,

  Container,
  Input,
  Text,
  Loader,
} from '@bluecurrent/web-component-lib';

export default function Information(props) {
  const { _, i18n } = Lang.useTranslation();
  const size = useDimensions();

  const [rateFocus, setRateFocus] = React.useState(false);

  return (
    <>
      <Input
        type="text"
        label={_('company', { ns: 'ui' })}
        name="company"
        onChange={props.onChangeCompany}
        value={props.company}
        styling
      />
      <div
        style={{
          marginTop: 15,
        }}
      >
        <AnimatePresence>
          {
            props.editAddress && (
              <motion.div
                style={{
                  marginBottom: 15,
                }}
                initial={{
                  height: 0,
                }}
                animate={{
                  height: 'auto',
                }}
                exit={{
                  height: 0,
                }}
              >
                <Container
                  type="row"
                  share={[50, 50]}
                >
                  <div
                    style={{
                      marginRight: 7.5,
                    }}
                  >
                    <Input
                      type="text"
                      label={_('street', { ns: 'ui' })}
                      name="street"
                      onChange={props.onChangeStreet}
                      value={props.street}
                      styling
                      required
                    />
                  </div>
                  <div
                    style={{
                      marginLeft: 7.5,
                    }}
                  >
                    <Input
                      type="text"
                      label={_('houseNumber', { ns: 'ui' })}
                      name="houseNumber"
                      onChange={props.onChangeHouseNumber}
                      value={props.houseNumber}
                      styling
                      required
                    />
                  </div>
                </Container>
                <div
                  style={{
                    marginTop: 15,
                  }}
                >
                  <Input
                    type="text"
                    label={_('houseNumberAdditions', { ns: 'ui' })}
                    name="houseNumberAdditions"
                    onChange={props.onChangeHouseNumberExtension}
                    value={props.houseNumberExtension}
                    styling
                  />
                </div>
                <div
                  style={{
                    marginTop: 15,
                  }}
                >
                  <Input
                    type="text"
                    label={_('place', { ns: 'ui' })}
                    name="place"
                    onChange={props.setPlace}
                    value={props.place}
                    styling
                    required
                  />
                </div>
              </motion.div>
            )
          }
        </AnimatePresence>
        <Input
          type="text"
          label={_('zipCode', { ns: 'ui' })}
          name="zipCode"
          onChange={props.onChangeZipCode}
          value={props.zipCode}
          styling
          required
        />
        <div
          style={{
            marginTop: 15,
          }}
        >
          {
            props.editAddress ? (
              <Input
                type="text"
                label={_('countryCode', { ns: 'ui' })}
                name="countryCode"
                onChange={props.onChangeCountry}
                value={props.country}
                styling
                required
              />
            ) : (
              <Container
                type="row"
                share={[50, 50]}
              >
                <div
                  style={{
                    marginRight: size.width < Breakpoint.xs ? 0 : 7.5,
                  }}
                >
                  <Input
                    type="text"
                    label={_('houseNumber', { ns: 'ui' })}
                    name="houseNumber"
                    onChange={props.onChangeHouseNumber}
                    value={props.houseNumber}
                    styling
                    required
                  />
                </div>
                <div
                  style={{
                    marginRight: size.width < Breakpoint.xs ? 0 : 7.5,
                    marginTop: size.width < Breakpoint.xs ? 15 : 0,
                  }}
                >
                  <Input
                    type="text"
                    label={_('houseNumberAdditions', { ns: 'ui' })}
                    name="houseNumberAdditions"
                    onChange={props.onChangeHouseNumberExtension}
                    value={props.houseNumberExtension}
                    styling
                  />
                </div>
              </Container>
            )
          }
        </div>
        {
          !props.editAddress && (
            <div
              style={{
                paddingTop: 5,
                paddingBottom: 5,
              }}
            >
              {
                ((props.street && props.place && props.houseNumber) || props.addressLoading) && (
                  <Text
                    fontSize="1em"
                  >
                    {
                      props.addressLoading ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          {`${_('address', { ns: 'ui' })}:`}
                          <div
                            style={{
                              marginLeft: 10,
                            }}
                          >
                            <Loader
                              size={15}
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          {`${_('address', { ns: 'ui' })}: ${props.street} ${props.houseNumber}${props.houseNumberExtension && ' '}${props.houseNumberExtension}, ${props.place}`}
                          <button
                            type="button"
                            className="Hover"
                            onClick={() => props.setEditAddress(true)}
                            style={{
                              borderLeft: 'none',
                              borderRight: 'none',
                              borderTop: 'none',
                              borderBottom: `solid 1px ${Colors.GREY}`,
                              backgroundColor: 'transparent',
                              fontSize: '0.8em',
                              padding: 0,
                              marginLeft: 10,
                              color: Colors.GREY,
                              fontFamily: 'Museo-Sans',
                            }}
                          >
                            {_('edit', { ns: 'ui' })}
                          </button>
                        </>
                      )
                    }
                  </Text>
                )
            }
            </div>
          )
        }
        <div
          style={{
            marginTop: 15,
          }}
        >
          <Input
            type="text"
            label={_('reference', { ns: 'ui' })}
            name="reference"
            onChange={props.onChangeReference}
            value={props.reference}
            styling
          />
        </div>
        <div
          style={{
            marginTop: 15,
            marginBottom: 15,
          }}
        >
          <Input
            type="text"
            label={`${_('tariff', { ns: 'finance' })} ${_('declaration', { ns: 'finance' }).toLocaleLowerCase(i18n.languages)} ${_('incl', { ns: 'ui' }).toLocaleLowerCase(i18n.languages)} ${_('vat', { ns: 'finance' })}`}
            name="rate"
            onChange={props.onChangeRate}
            value={rateFocus ? props.rate : props.rate && Number.parseFloat(props.rate).toLocaleString(i18n.language, { style: 'currency', currency: 'EUR' })}
            error={Number.isNaN(Number.parseFloat(props.rate)) && props.rate !== ''}
            styling
            required
            onFocus={() => setRateFocus(true)}
            onBlur={() => setRateFocus(false)}
          />
        </div>
      </div>
    </>
  );
}
