/* USER */
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_UPDATE = 'USER_UPDATE';

/* CHARGE_POINTS */
export const SET_CHARGE_POINTS = 'SET_CHARGE_POINTS';
export const ADD_CHARGE_POINT = 'ADD_CHARGE_POINT';
export const SET_CHARGE_POINT_STATUS = 'SET_CHARGE_POINT_STATUS';
export const SET_SELECTED_CHARGEPOINT = 'SET_SELECTED_CHARGEPOINT';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';

/* OVERLAY */
export const OVERLAY = 'OVERLAY';

/* GRID */
export const UPDATE_GRIDS = 'UPDATE_GRIDS';
export const ADD_GRID = 'ADD_GRID';
export const SET_SELECTED_GRID = 'SET_SELECTED_GRID';
export const SET_ENERGY_USAGE = 'SET_ENERGY_USAGE';

/* CHARGE_CARDS */
export const UPDATE_CHARGE_CARD = 'UPDATE_CHARGE_CARD';
export const ADD_CHARGE_CARD = 'ADD_CHARGE_CARD';
export const EDIT_CHARGE_CARD = 'EDIT_CHARGE_CARD';
export const REMOVE_CHARGE_CARD = 'REMOVE_CHARGE_CARD';

/* GROUPS */
export const SET_GROUPS = 'SET_GROUPS';
export const ADD_GROUP = 'ADD_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const SET_GROUP_EVSES = 'SET_GROUP_EVSES';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const SET_GROUPS_LOADING = 'SET_GROUPS_LOADING';

/* NOTIFICATIONS */
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

/* FLOWS */
export const REGISTER_FLOW = 'REGISTER_FLOW';
export const FINISH_FLOW = 'FINISH_FLOW';

/* PERMISSIONS */
export const PERMISSION_UPDATE = 'PERMISSION_UPDATE';
