import * as React from 'react';

import {
  Colors,
  Lang,
  Breakpoint,

  useDimensions,

  ContentContainer,

  Text,
  Title,
  Confetti,
} from '@bluecurrent/web-component-lib';

export default function Wrapper(props) {
  const { _ } = Lang.useTranslation();
  const size = useDimensions();

  return (
    <section
      style={{
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
        minHeight: '100svh',
      }}
    >
      <ContentContainer>
        <div
          style={{
            height: 75,
            marginTop: size.width < Breakpoint.lg
              ? size.width < Breakpoint.sm
                ? 100
                : 120
              : 170,
          }}
        >
          <Text
            textAlign="left"
            fontWeight={500}
            color={Colors.DARK_WHITE}
          >
            {_('parking', { ns: 'ui' })}
          </Text>
          <Title>
            {props.title}
          </Title>
        </div>
        <div>
          {props.children}
        </div>
      </ContentContainer>
      {
        size.width >= Breakpoint.xxl && (
          <>
            <Confetti
              type="Figure"
              height={150}
              width={150}
              color={Colors.PURPLE}
              position="absolute"
              zIndex={1}
              bottom={400}
              right={50}
            />
            <Confetti
              type="DotsPath"
              height={1000}
              width={1000}
              color={Colors.MEDIUM_WHITE}
              position="absolute"
              zIndex={0}
              bottom={-200}
              left={-450}
              transform="rotate(35deg)"
            />
          </>
        )
      }
    </section>
  );
}
