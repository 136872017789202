import * as React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import {
  Colors,

  Text,

  Icon,
} from '@bluecurrent/web-component-lib';

export default function QuickAccessCard(props) {
  const [hover, setHover] = React.useState(false);

  return (
    <button
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={props.data.onClick}
      className="Hover"
      type="button"
      style={{
        height: '100%',
        borderRadius: 10,
        backgroundColor: Colors.WHITE,
        border: `solid 2px ${Colors.MEDIUM_WHITE}`,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        fontSize: '1em',
      }}
    >
      {
        props.data.icon && (
          <div
            style={{
              backgroundColor: `${props.data.icon.color}25`,
              width: 60,
              height: 60,
              borderRadius: 10,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 5,
              paddingRight: 5,
              marginLeft: 15,
              marginTop: 20,
            }}
          >
            <Icon
              name={props.data.icon.name}
              width={props.data.icon.width}
              height={props.data.icon.height}
              color={props.data.icon.color}
              iconSet={props.data.icon.iconSet}
            />
          </div>
        )
      }
      <div
        style={{
          paddingLeft: 15,
          paddingTop: 15,
          paddingRight: 15,
        }}
      >
        <Text
          color={Colors.GREY}
          fontWeight={500}
          fontSize="1.25em"
          textTransform="Capitalize"
        >
          {props.data.name}
        </Text>
        <div
          style={{
            paddingTop: 10,
          }}
        >
          <Text
            color={`${Colors.GREY}90`}
            fontWeight={300}
            fontSize="1.1em"
          >
            {props.data.description}
          </Text>
        </div>
      </div>
      <AnimatePresence>
        {
          hover && (
            <motion.div
              style={{
                position: 'absolute',
                bottom: 10,
              }}
              initial={{
                right: 30,
                opacity: 0,
              }}
              animate={{
                right: 20,
                opacity: 1,
              }}
              exit={{
                right: 30,
                opacity: 0,
              }}
              transition={{
                duration: 0.2,
              }}
            >
              <Icon
                name="ArrowRight"
                height={20}
                width={20}
                color={Colors.GREY}
              />
            </motion.div>
          )
        }
      </AnimatePresence>
    </button>
  );
}
