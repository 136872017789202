import * as React from 'react';

import {
  Text,

  Colors,
  Icon,
} from '@bluecurrent/web-component-lib';

export default function Item(props) {
  const [hover, setHover] = React.useState(false);

  return (
    props.href
      ? (
        <a
          href={props.href}
          target={props.target}
          className="Hover"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          style={{
            marginLeft: 4,
            textDecoration: hover ? 'underline' : 'none',
          }}
        >
          <Text
            fontWeight={100}
            fontSize="1em"
            color={hover ? Colors.GREY : props.color ?? Colors.GREY}
            textTransform="capitalize"
            textAlign={props.textAlign ?? 'left'}
          >
            {props.title}
          </Text>
        </a>
      ) : (
        <button
          type="button"
          className="Hover"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={props.onClick}
          style={{
            padding: 0,
            textDecoration: hover ? 'underline' : 'none',
            border: 'solid 0px transparent',
            backgroundColor: 'transparent',
            fontSize: '1em',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {
          props.icon && (
            <Icon
              name={props.icon.name}
              height={props.icon.height}
              width={props.icon.width}
              color={hover ? Colors.GREY : props.icon.color}
              iconSet={props.icon.iconSet}
            />
          )
        }
          <Text
            fontWeight={100}
            fontSize="1em"
            color={hover ? Colors.GREY : props.color ?? Colors.GREY}
            textTransform="capitalize"
          >
            {props.title}
          </Text>
        </button>
      )
  );
}
