import * as React from 'react';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/browser';

import {
  Colors,
  Lang,

  Text,
  Input,
  Button,
  OverlaySearch,
} from '@bluecurrent/web-component-lib';

import { useNavigate } from 'react-router-dom';
import {
  ADD_CHARGE_CARD,
  GET_CHARGE_CARDS_PAGED,
  REMOVE_CHARGE_CARD,
} from '../../../api/types/ApiMessages';
import executeCommand from '../../../api/executeCommand';
import addNotification from '../../../redux/creators/notifications';

import ListLoader from '../../Modules/ListLoader';
import PlaceholderChargeCards from '../../Modules/Placeholders/ChargeCards';

export default function LinkedChargeCards(props) {
  const { _ } = Lang.useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [nextPage, setNextPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [cards, setCards] = React.useState([]);

  const [newCardSerial, setNewCardSerial] = React.useState('');
  const [newCardError, setNewCardError] = React.useState(false);
  const [newCardLoading, setNewCardLoading] = React.useState(false);

  const [deleteButtonsLoading, setDeleteButtonsLoading] = React.useState([]);

  const deleteCard = (id) => {
    setDeleteButtonsLoading((prev) => [...prev, id]);
    executeCommand(REMOVE_CHARGE_CARD, {
      id,
      evse_id: props.data,
    })
      .then(() => {
        setDeleteButtonsLoading((prev) => prev.filter((i) => i !== id));
        setCards((prev) => prev.filter((i) => i.serial !== id));
      })
      .catch((err) => {
        Sentry.captureException(err);
        dispatch(addNotification('failed', _('error.somethingWrong', { ns: 'chargepoint' }), 'red'));
      });
  };

  const openCard = (id) => {
    navigate(`/chargecards/${id}`);
  };

  const loadPage = (page = nextPage) => {
    setLoading(true);
    executeCommand(GET_CHARGE_CARDS_PAGED, {
      params: {
        page,
        max_per_page: 25,
        other: 'A',
        evse_id: props.data,
      },
    })
      .then(({ data }) => {
        setNextPage(data);
        setLoading(false);
        setCards(data.cards.map(({ name, id }, index) => ({
          id: `${id}-${index}`,
          name,
          serial: id,
          type: 'chargecard',
          onUnlink: () => deleteCard(id),
          onClick: () => openCard(id),
        })));
      })
      .catch((err) => {
        Sentry.captureException(err);
        dispatch(addNotification('failed', _('error.somethingWrong', { ns: 'chargepoint' }), 'red'));
      });
  };

  const addChargeCards = () => {
    setNewCardError(false);
    if (newCardSerial === '') {
      setNewCardError(_('serialNumberEmpty', { ns: 'chargecard' }));
      return;
    }
    setNewCardLoading(true);
    executeCommand(ADD_CHARGE_CARD, {
      id: newCardSerial,
      evse_id: props.data,
    })
      .then(({ result }) => {
        setNewCardLoading(false);
        setNewCardSerial('');
        setCards((prev) => [...prev, {
          id: result.data.id,
          name: result.data.name,
          serial: result.data.id,
          type: 'chargecard',
          onUnlink: (event) => {
            event.stopPropagation();
            event.preventDefault();
            deleteCard(result.data.id);
          },
          onClick: (event) => {
            event.stopPropagation();
            event.preventDefault();
            openCard(result.data.id);
          },
        }]);
      })
      .catch(({ result }) => {
        Sentry.captureException(result);
        setNewCardLoading(false);
        switch (result.result.result) {
          case 0:
            setNewCardError(_('invalidCardNumber', { ns: 'chargecard' }));
            break;
          case 2:
            setNewCardError(_('duplicateCard', { ns: 'chargecard' }));
            break;
          default:
            setNewCardError(_('errors.somethingWrong', { ns: 'ui' }));
        }
      });
  };

  React.useEffect(() => {
    loadPage();
  }, []);

  return (
    <>
      <OverlaySearch
        data={cards.map((item) => ({
          ...item,
          unlinkLoading: deleteButtonsLoading.includes(item.serial),
        }))}
        empty={{
          condition: !loading && cards.length < 1,
          component: <PlaceholderChargeCards />,
        }}
      />
      {
        loading && (
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1,
            }}
          >
            <ListLoader />
          </div>
        )
      }
      <div
        style={{
          height: 'auto',
          width: '100%',
          backgroundColor: Colors.WHITE,
          borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
          padding: 15,
        }}
      >
        <Text>
          {_('chargeCardNotOnThisList', { ns: 'ui' })}
          :
        </Text>
        <div
          style={{
            paddingTop: 15,
          }}
        >
          <Input
            type="text"
            label={`${_('serialNumber', { ns: 'ui' })}:`}
            styling
            required
            value={newCardSerial}
            onChange={(e) => setNewCardSerial(e.target.value.toUpperCase())}
            error={newCardError}
          />
        </div>
        <div
          style={{
            marginTop: 15,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}
        >
          <div
            style={{
              width: 'auto',
            }}
          >
            <Button
              text={_('add', { ns: 'ui' })}
              onClick={addChargeCards}
              loading={newCardLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
}
