import { combineReducers } from 'redux';
import User from './reducers/user';
import ChargePoints from './reducers/chargepoints';
import Overlay from './reducers/overlay';
import Grids from './reducers/grids';
import ChargeCards from './reducers/chargecards';
import Groups from './reducers/groups';
import Notifications from './reducers/notifications';
import Permissions from './reducers/permissions';

const reducers = combineReducers({
  User,
  ChargePoints,
  Overlay,
  Grids,
  ChargeCards,
  Groups,
  Notifications,
  Permissions,
});

export default reducers;
