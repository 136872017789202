import {
  SET_CHARGE_POINTS,
  ADD_CHARGE_POINT,
  SET_CHARGE_POINT_STATUS,
  SET_SELECTED_CHARGEPOINT,
  UPDATE_SETTINGS,
  USER_LOGOUT,
} from '../actionTypes';

const initialState = [];

// eslint-disable-next-line default-param-last
const update = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHARGE_POINTS:
      return action.payload;

    case ADD_CHARGE_POINT:
      return [...state, action.payload];

    case SET_CHARGE_POINT_STATUS: {
      return state.map((evse) => {
        if (evse.evse_id !== action.evseId) return evse;

        return {
          ...evse,
          ...action.payload.data,
        };
      });
    }

    case SET_SELECTED_CHARGEPOINT:
      return state.map((i) => {
        if (i.evse_id === action.payload.id) {
          return {
            ...i,
            selected: true,
          };
        }

        return {
          ...i,
          selected: false,
        };
      });

    case UPDATE_SETTINGS:
      return state.map((i) => {
        if (i.evse_id === action.id) {
          return {
            ...i,
            ...action.payload,
          };
        }
        return i;
      });

    case USER_LOGOUT:
      return initialState;

    default: return state;
  }
};

export default update;
