import { v4 as uuid } from 'uuid';

import {
  REGISTER_FLOW,
  FINISH_FLOW,
} from '../actionTypes';

export const registerFlow = (evseId, action) => ({
  type: REGISTER_FLOW,
  id: uuid(),
  payload: {
    evseId,
    action,
  },
});

export const finishFlow = (evseId) => ({
  type: FINISH_FLOW,
  payload: evseId,
});
