/* eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member */
import useStorage from '../useStorage';
import useUpdateLanguage from '../useUpdateLanguage';

const detectLanguage = (i18n) => {
  const updateLanguage = useUpdateLanguage(i18n);
  const storage = useStorage();

  if (storage.getItem('lang')) {
    i18n.changeLanguage(storage.getItem('lang'));
  } else {
    const navigatorLang = navigator.language || navigator.userLanguage;
    const lang = navigatorLang.split('-')[0];

    switch (lang) {
      case 'nl':
        updateLanguage('nl');
        break;
      case 'en':
        updateLanguage('en');
        break;
      default:
        updateLanguage('en');
    }
  }
};

export default detectLanguage;
