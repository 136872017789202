import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import ReactGA from 'react-ga4';

import {
  Colors,
  Breakpoint,
  Lang,

  useDimensions,

  ConfirmDialog,
} from '@bluecurrent/web-component-lib';

import Header from './Sections/Header';
import ErrorBar from './Sections/Header/ErrorBar';
import Footer from './Sections/Footer';
import Modal from './Sections/Modal';

import Navigation from './Navigation';

import pages from '../config/Pages.config';

import useBrowserTitle from '../hooks/useBrowserTitle';
/* eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member */
import useStorage from '../hooks/useStorage';

export default function Layout(props) {
  const size = useDimensions();
  const modal = useSelector((state) => state.Overlay);
  const dispatch = useDispatch();
  const setBrowserTitle = useBrowserTitle();
  const storage = useStorage();
  const navigate = useNavigate();
  const location = useLocation();
  const { _ } = Lang.useTranslation();

  const [errorBar, setErrorBar] = React.useState(false);
  const [title, setTitle] = React.useState(null);
  const [width, setWidth] = React.useState(0);

  const [logoutDialog, setLogoutDialog] = React.useState(false);

  const getErrorBarData = (element) => {
    setErrorBar(element);
  };

  React.useEffect(() => {
    if (props.title !== title) {
      setTitle(props.title);
      setBrowserTitle(props.title);
      window.scrollTo(0, 0);
    }
  }, [props.title]);

  React.useEffect(() => {
    setWidth(size.width < Breakpoint.sm ? '100%' : `calc(100% - ${size.width >= 1700 ? 240 : 70}px)`);
  }, [size]);

  const logout = () => {
    storage.removeOriginalStateItem('token');
    dispatch({
      type: 'USER_LOGOUT',
    });
    navigate('/login');
  };

  const filterBCU = (input) => {
    /* eslint-disable-next-line */
    const regex = new RegExp('/BCU\d+', 'g');

    return input.replace(/BCU\d+/g, '[PLACEHOLDER-BCU-NUMBER]');
  };

  React.useEffect(() => {
    if (
      storage.getItem('analytics_accepted') === 'true'
        && storage.getItem('cookies_accepted') === 'true'
        && (
          process.env.NODE_ENV !== 'development'
            || process.env.NODE_ENV === 'preview-deployment'
        )
    ) {
      ReactGA.send({ hitType: 'pageview', page: filterBCU(location.pathname) });
    }
  }, [location]);

  return (
    <>
      <div
        style={{
          width: '100vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <div
          style={{
            width: '100%',
            maxWidth: '100%',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <ErrorBar
            getErrorBarData={getErrorBarData}
          />
          <Navigation />
          <div
            style={{
              height: size.width < Breakpoint.sm ? 60 : 80,
              width,
              maxWidth: '100%',
              backgroundColor: Colors.WHITE,
              position: 'fixed',
              zIndex: 100,
              marginLeft: size.width < Breakpoint.sm ? 0 : size.width >= 1700 ? 240 : 70,
              transition: 'margin-top linear .2s',
              borderBottom: `solid 2px ${Colors.MEDIUM_WHITE}`,
              marginTop: errorBar ? 30 : 0,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                width: '100%',
                maxWidth: 1600,
              }}
            >
              <Header
                title={props.title}
                useLogoutDialog={[logoutDialog, setLogoutDialog]}
              />
            </div>
          </div>
          <main
            id="main"
            style={{
              width: size.width < Breakpoint.sm ? '100%' : `calc(100% - ${size.width >= 1700 ? 240 : 70}px)`,
              height: size.width < Breakpoint.sm ? 'auto' : `calc(100vh - ${
                errorBar
                  ? pages.find((e) => e.path === props.path).footer === 'relative'
                    ? 110
                    : 155
                  : pages.find((e) => e.path === props.path).footer === 'relative'
                    ? 80
                    : 125
              }px)`,
              marginTop: size.width < Breakpoint.sm
                ? errorBar
                  ? 90
                  : 60
                : errorBar
                  ? 110
                  : 80,
              transition: 'margin-top linear .2s',
              position: 'relative',
              overflowY: 'scroll',
              overflowX: 'hidden',
              marginLeft: size.width < Breakpoint.sm ? 0 : size.width >= 1700 ? 240 : 70,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginBottom: size.width < Breakpoint.sm ? 80 : 0,
            }}
          >
            <div
              style={{
                width: '100%',
                maxWidth: 1600,
              }}
            >
              {
                props.children
              }
              <Footer
                border
                position={props.path === '/' ? 'relative' : 'fixed'}
              />
            </div>
          </main>
          {
            /*
             * Global Elements
             *
             * Add here global elements used on all pages or elements
             * that are accessible on all pages.
             */
          }
          <Modal
            type={modal.layout}
            onClose={() => {
              dispatch({
                type: 'OVERLAY',
                payload: {
                  display: false,
                },
              });
            }}
            data={modal.data}
            title={modal.title}
            subTitle={modal.subTitle}
            visible={modal.display}
            settings={modal.settings}
            serial={modal.serial}
          />
          { /* End Global Elements */ }
        </div>
      </div>
      <ConfirmDialog
        visible={logoutDialog}
        title={_('logOut', { ns: 'authentication' })}
        description={_('areYouSureLogout', { ns: 'authentication' })}
        accept={{
          text: _('logout', { ns: 'authentication' }),
          onClick: logout,
        }}
        cancel={{
          onClick: () => setLogoutDialog(false),
        }}
      />
    </>
  );
}
