import * as React from 'react';

import {
  Lang,
  Breakpoint,
  Colors,

  useCurrencyFormat,
  useDimensions,

  Text,
} from '@bluecurrent/web-component-lib';

export default function TextEnd(props) {
  const { i18n, _ } = Lang.useTranslation();
  const currencyFormat = useCurrencyFormat(i18n.language);
  const size = useDimensions();

  return (
    <>
      {
        size.width < Breakpoint.md && (
          <Text
            textAlign="left"
            fontSize={size.width < Breakpoint.xs ? '1.1em' : '1.25em'}
            color={size.width < Breakpoint.xs ? Colors.DARK_GREY : Colors.GREY}
          >
            {`${props.text}:`}
          </Text>
        )
      }
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: size.width < Breakpoint.xs ? 'flex-start' : 'flex-end',
          gap: 7.5,
          minWidth: 150,
          height: '100%',
        }}
      >
        <Text
          textAlign={size.width < Breakpoint.xs ? 'left' : 'right'}
        >
          {
          currencyFormat(
            props.tariff.amount || 0,
            props.tariff.currency || 'EUR',
          )
        }
        </Text>
        <div
          style={{
            minWidth: 40,
          }}
        >
          <Text
            textAlign={size.width < Breakpoint.xs ? 'left' : 'right'}
          >
            {`/ ${_('units.kwh', { ns: 'chargepoint' })}`}
          </Text>
        </div>
      </div>
    </>
  );
}
