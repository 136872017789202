import * as React from 'react';

import {
  Colors,

  Title,
} from '@bluecurrent/web-component-lib';

export default function SettingsTitle(props) {
  return (
    <div
      style={{
        borderBottom: `solid 2px ${Colors.MEDIUM_WHITE}`,
        paddingBottom: 15,
      }}
    >
      <Title>{props.title}</Title>
    </div>
  );
}
