import * as React from 'react';

import {
  Colors,
  Breakpoint,
  Lang,

  useDimensions,
  counter,

  Text,
} from '@bluecurrent/web-component-lib';

import LandscapeBackground from '../../../assets/svg/LandscapeBackground.svg';
import WindmillBase from '../../../assets/svg/Windmill/WindmillBase';
import WindmillBlades from '../../../assets/svg/Windmill/WindmillBlades';

export default function TotalCO2Emissions(props) {
  const size = useDimensions();
  const { _ } = Lang.useTranslation();

  const amount = props.total;
  let unit = '';
  let unitRounded = '';
  let result = 0;

  // Kilogram
  if (amount < 1) {
    result = amount * 1000;
    unit = _('units.kilogram', { ns: 'ui' });
    unitRounded = _('units.kilogramRounded', { ns: 'ui' });
    result = result.toFixed(0);
  }

  // Ton
  if (amount >= 1 && amount < 1000) {
    result = amount;
    unit = _('units.ton', { ns: 'ui' });
    unitRounded = _('units.tonRounded', { ns: 'ui' });
    result = result.toFixed(0);
  }

  // Kiloton
  if (amount >= 1000) {
    result = amount * 0.001;
    unit = _('units.kiloton', { ns: 'ui' });
    unitRounded = _('units.kilotonRounded', { ns: 'ui' });
    result = result.toFixed(1);
  }

  // Megaton
  if (amount >= 1000000) {
    result = amount * 0.000001;
    unit = _('units.megaton', { ns: 'ui' });
    unitRounded = _('units.megatonRounded', { ns: 'ui' });
    result = result.toFixed(1);
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        border: `solid 2px ${Colors.MEDIUM_WHITE}`,
        borderRadius: 10,
        background: 'linear-gradient(180deg, rgba(229,244,251,1) 50%, rgba(255,255,255,1) 100%)',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'absolute',
          backgroundColor: 'transparent',
          borderRadius: 7,
        }}
      >
        <div
          style={{
            height: '100%',
            width: 550,
            marginTop: 50,
            position: 'absolute',
            backgroundImage: `url('${LandscapeBackground}')`,
            backgroundPosition: 'bottom',
            backgroundRepeat: 'repeat-x',
            zIndex: 1,
            borderRadius: 20,
          }}
        />

        {
          size.width > 480 && (
            <div
              style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  left: size.width < Breakpoint.xs ? -370 : -350,
                  bottom: -20,
                  zIndex: 2,
                }}
              >
                <WindmillBase
                  height={300}
                  width={800}
                  marginTop={0}
                />
              </div>
              <div
                style={{
                  position: 'absolute',
                  left: size.width < Breakpoint.xs ? -170 : -150,
                  bottom: ((size.width > Breakpoint.xs) && (size.width < 1063)) ? 74 : 158,
                  height: '100%',
                  width: '100%',
                  zIndex: 2,
                }}
              >
                <WindmillBlades
                  height={400}
                  width={400}
                  start={50}
                  end={-410}
                />
              </div>
            </div>
          )
        }

        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
          }}
        >
          <div
            style={{
              position: 'absolute',
              left: -70,
              bottom: ((size.width > Breakpoint.xs) && (size.width < 1063)) ? 60 : 25,
              zIndex: 0,
            }}
          >
            <WindmillBase
              height={100}
              width={300}
              marginTop={0}
            />
          </div>
          <div
            style={{
              position: 'absolute',
              left: 5,
              bottom: ((size.width > Breakpoint.xs) && (size.width < 1063)) ? -115 : -65,
              height: '100%',
              width: '100%',
              zIndex: 0,
            }}
          >
            <WindmillBlades
              height={150}
              width={150}
            />
          </div>
        </div>

        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '90%',
            bottom: size.width < 480 ? -65 : -85,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: size.width < 480 ? 'flex-start' : 'flex-end',
            right: size.width < 480 ? 'initial' : 20,
            left: size.width < 480 ? 10 : 'initial',
          }}
        >
          <Text
            textAlign={size.width < 480 ? 'left' : 'right'}
          >
            {_('totalCO2Emission', { ns: 'ui' })}
          </Text>
          <div
            style={{
              marginTop: size.width < 480 ? 0 : -7,
            }}
          >
            <Text>
              <span
                style={{
                  fontSize: size.width < 480 ? '3em' : '4em',
                  fontWeight: 500,
                }}
              >
                {counter(0, parseInt(result, NaN), 2)}
              </span>
              <span
                style={{
                  fontSize: '1.25em',
                  fontWeight: 500,
                }}
              >
                {size.width < 426 ? unitRounded : unit}
              </span>
            </Text>
          </div>
        </div>

      </div>
    </div>
  );
}
