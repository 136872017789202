import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Breakpoint,
  Lang,

  useDimensions,

  SideBarNavigation,
  TabNavigation,
} from '@bluecurrent/web-component-lib';

import Pages from '../config/Pages.config';

export default function Navigation() {
  const size = useDimensions();
  const navigate = useNavigate();
  const params = useParams();
  const { _ } = Lang.useTranslation();

  const permissions = useSelector((state) => state.Permissions);

  const isActive = (path, children) => {
    const allPaths = [
      path,
      `${path}/`,
      ...(
        children
          ? children.map((i) => {
            let checkedPath = path + i.path;

            /* Make sure we check the actual URL instead of the one with variable placeholders */
            Object.keys(params).forEach((key) => {
              checkedPath = checkedPath.replace(`:${key}`, params[key]);
            });

            return checkedPath;
          })
          : []
      ),
    ];

    return allPaths.includes(window.location.pathname);
  };

  return (
    size.width < Breakpoint.sm ? (
      <div
        style={{
          height: 60,
          width: '100%',
          position: 'fixed',
          zIndex: 101,
          bottom: 0,
        }}
      >
        <TabNavigation
          items={
            Pages.filter(({ showInSidebar }) => showInSidebar).map(
              ({
                name, path, icon, colorScheme,
              }) => (
                {
                  title: Array.isArray(name) ? _(...name) : name,
                  onClick: () => navigate(path, { replace: true }),
                  icon,
                  active: window.location.pathname === path,
                  'aria-label': name,
                  colorScheme,
                }
              ),
            )
          }
        />
      </div>
    ) : (
      <div
        style={{
          width: size.width >= 1700 ? 240 : 70,
          minWidth: size.width >= 1700 ? 240 : 70,
          position: 'fixed',
          height: '100vh',
          zIndex: 101,
          transition: 'all linear .2s',
        }}
      >
        <SideBarNavigation
          alwaysOpen={size.width >= 1700}
          items={
            Pages.filter(({ showInSidebar, permission }) => showInSidebar && ['read', 'write', undefined].includes(permissions[permission])).map(
              ({
                name, path, icon, colorScheme, children,
              }) => (
                {
                  title: Array.isArray(name) ? _(...name) : name,
                  path,
                  icon,
                  active: isActive(path, children),
                  colorScheme,
                  onClick: () => navigate(path, { replace: true }),
                }
              ),
            )
          }
        />
      </div>
    )
  );
}
