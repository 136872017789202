import * as React from 'react';

import {
  Colors,
  Backgrounds,
  Breakpoint,

  useDimensions,

  Icon,
} from '@bluecurrent/web-component-lib';

export default function LiveChat() {
  const size = useDimensions();

  const ref = React.useRef();

  const [hover, setHover] = React.useState(false);
  const [active, setActive] = React.useState(false);
  const [position, setPosition] = React.useState(false);

  React.useEffect(() => {
    const { pathname } = window.location;
    if (pathname.startsWith('/parking/')) setPosition(true);
  }, [size, window]);

  // Handle outside clicks
  React.useEffect(() => {
    function outsideClickHandler(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setActive(false);
      }
    }

    document.addEventListener('mousedown', outsideClickHandler);
    return () => document.removeEventListener('mouseover', outsideClickHandler);
  }, [ref]);

  return (
    <div
      ref={ref}
      style={{
        position: 'fixed',
        height: 65,
        width: 65,
        bottom: size.width < (position ? Breakpoint.lg : Breakpoint.sm) ? 75 : 25,
        right: size.width < Breakpoint.sm ? 7.5 : 25,
        zIndex: 102,
      }}
    >
      <button
        type="button"
        className="Hover"
        aria-label="Chat"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => setActive(!active)}
        style={{
          positiom: 'relative',
          borderRadius: '100%',
          boxShadow: hover || active ? `0px 0px 20px 0px ${Colors.GREY}50` : `0px 0px 25px 0px ${Colors.GREY}25`,
          border: 'solid 0px transparent',
          transition: 'all .2s linear',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          background: Backgrounds.BLUE,
          height: 65,
          width: 65,
        }}
      >
        <Icon
          name={active ? 'Times' : 'Comments'}
          type={active ? 'light' : 'solid'}
          height={35}
          width={35}
          color={Colors.WHITE}
          iconSet={active ? 'Feather' : 'FA'}
        />
        <div
          title="hubspot-chat"
          id="hubspot-chat"
          style={{
            height: size.width < Breakpoint.sm ? 'calc(100vh - 200px)' : 450,
            width: size.width < Breakpoint.sm ? 'calc(100vw - 10px)' : 400,
            position: 'absolute',
            right: 0,
            bottom: 70,
            zIndex: 1000,
            border: 'solid 0px transparent',
            visibility: active ? 'visible' : 'hidden',
            opacity: active ? 1 : 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'all .2s linear',
          }}
        />
      </button>
    </div>
  );
}
