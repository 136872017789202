import * as React from 'react';

import {
  Colors,
  Breakpoint,

  truncate,
  useDimensions,

  Text,
  Icon,
} from '@bluecurrent/web-component-lib';

export default function Item({
  name,
  icon,
  items,
  isEditMode,
  isDisabled,
  onClick,
}) {
  const size = useDimensions();

  const [isHover, setHover] = React.useState(false);

  return (
    <button
      type="button"
      className={isDisabled ? 'Disabled' : 'Hover'}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={isDisabled ? null : onClick}
      style={{
        height: 70,
        width: '100%',
        backgroundColor: !isDisabled && isHover
          ? Colors.LIGHT_GREY
          : Colors.WHITE,
        border: `${isEditMode ? 'dashed' : 'solid'} 2px ${Colors.MEDIUM_WHITE}`,
        borderRadius: 10,
        padding: 15,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 15,
        fontSize: '1em',
      }}
    >
      <div
        style={{
          width: 45,
          minWidth: 45,
          height: 45,
          backgroundColor: Colors.WHITE,
          marginRight: 15,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          border: `solid 2px ${Colors.MEDIUM_WHITE}`,
          borderRadius: 10,
        }}
      >
        <Icon
          name={icon.name}
          height={icon.height}
          width={icon.width}
          iconSet={icon.iconSet ?? 'FA'}
          color={Colors.GREY}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 5,
        }}
      >
        <Text>
          {truncate(name, size.width < Breakpoint.fd ? 11 : 30)}
        </Text>
        <Text
          fontSize="0.9em"
          color={Colors.MEDIUM_GREY}
        >
          {
            items === undefined || items.length < 1
              ? ''
              : truncate(`${items[0]} ${items[1] ? `, ${items[1]}` : ''}`, size.width < Breakpoint.fd ? 13 : 18)
          }
        </Text>
      </div>
    </button>
  );
}
