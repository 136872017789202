import * as React from 'react';

import {
  Lang,
} from '@bluecurrent/web-component-lib';

import Item from '../../../Sections/Transactions/List/Item';

export default function TransactionItemDummy() {
  const { _ } = Lang.useTranslation();

  return (
    <div
      style={{
        height: 60,
      }}
    >
      <Item
        data={{
          transactions_id: 0,
          evse_name: _('example', { ns: 'ui' }),
          chargepoint_type: 'UMOVE',
          chargepoint_id: `BCU-${_('example', { ns: 'ui' }).toLowerCase()}`,
          card_name: _('example', { ns: 'ui' }),
          card_id: 'NL-ABC-123456-1',
          kwh: 1,
          total_costs: 0.00,
          reason_no_settlement: 'STATION IS FREE',
          started_at: '13-12-2021 11:40:00',
          end_time: '13-12-2021 12:15:00',
          currency: 'EUR',
          total_costs_ex_vat: 0,
          vat: 21,
        }}
        index={0}
      />
    </div>
  );
}
