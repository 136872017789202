import * as sjcl from '../assets/js/sjcl';

class CryptoService {
  static encryptPassword(readablePassword) {
    return sjcl.encrypt(process.env.REACT_APP_SJCL_PASSWORD, readablePassword);
  }
}

const useCyrptoService = () => CryptoService;

export default useCyrptoService;
