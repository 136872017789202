import * as React from 'react';

import List from './List';

export default function Layout(props) {
  return (
    <List
      width={props.width}
      data={props.data}
      loading={props.loading}
      lastElement={props.lastElement}
      mode={props.mode}
      page={props.page}
      loadData={props.loadData}
      nextPage={props.nextPage}
      totalPages={props.totalPages}
    />
  );
}
