import * as React from 'react';

import {
  Colors,
  Lang,
  Confetti,

  Text,
} from '@bluecurrent/web-component-lib';

import Image from '../../../../assets/img/orangeGirl.jpg';

export default function Tariff(props) {
  const { _ } = Lang.useTranslation();

  return (
    <div
      style={{
        height: '100%',
        overflowY: 'scroll',
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 30,
        paddingBottom: 30,
      }}
    >
      <div
        style={{
          height: 300,
          width: '100%',
          backgroundColor: Colors.ORANGE,
          borderRadius: 10,
          backgroundImage: `url('${Image}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'right top',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Confetti
          type="DotsPath"
          color={Colors.WHITE}
          height={600}
          width={600}
          left={-50}
          position="absolute"
        />
        <Confetti
          type="Stripes"
          color={Colors.YELLOW}
          height={110}
          width={110}
          top={180}
          left={80}
          position="absolute"
        />
      </div>
      <div
        style={{
          marginTop: 30,
        }}
      >
        <Text
          fontWeight={100}
        >
          {_('warning.tariff', {
            ns: 'chargepoint',
            model: props.currentChargePoint,
            url: '/chargecards',
          })}
        </Text>
      </div>
    </div>
  );
}
