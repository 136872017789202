import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Lang,

  useDimensions,

  Colors,
} from '@bluecurrent/web-component-lib';

import Item from '../Item';
import Controls from './Controls';

export default function EditList({
  isEdit,
  onClickGroup,
  onClose,
}) {
  const { _ } = Lang.useTranslation();
  const dispatch = useDispatch();
  const size = useDimensions();

  const GROUPS = useSelector((state) => state.Groups.groups);

  const [isEditMode, setEditMode] = React.useState(isEdit ?? false);

  const onPressGroup = (item) => {
    if (isEditMode) {
      dispatch({
        type: 'OVERLAY',
        payload: {
          layout: 'groupsEdit',
          display: true,
          title: _('group.edit', { ns: 'ui' }),
          data: item,
        },
      });
    } else {
      onClickGroup(item.id, item.name, item.chargepoints);
      onClose();
    }
  };

  const onPressAllChargePoints = () => {
    onClickGroup(null, null, null);
    onClose();
  };

  return (
    <>
      <Controls
        isVisible={!isEditMode || size.width <= 1200}
        isEditMode={isEditMode}
        setEditMode={() => setEditMode(!isEditMode)}
      />
      <div
        style={{
          paddingLeft: 15,
          paddingRight: 15,
          paddingTop: 15,
        }}
      >
        {
          !isEditMode && (
            <Item
              name={_('allChargePoints', { ns: 'ui' })}
              icon={{
                name: 'UMOVE',
                height: 27,
                width: 27,
                color: Colors.GREY,
                iconSet: 'BlueCurrent',
              }}
              onClick={() => onPressAllChargePoints()}
            />
          )
        }
        {
          GROUPS.map((item) => (
            <Item
              key={item.group.id}
              name={item.group.name}
              icon={item.group.icon}
              items={item.group.chargepoints}
              onClick={() => onPressGroup(item.group)}
              isEditMode={isEditMode}
            />
          ))
        }
      </div>
    </>
  );
}
