import {
  PERMISSION_UPDATE,
  USER_LOGOUT,
} from '../actionTypes';

const initialState = {
  transactions: true,
};

// eslint-disable-next-line default-param-last
const update = (state = initialState, action) => {
  switch (action.type) {
    case PERMISSION_UPDATE:
      return { ...state, ...action.payload };
    case USER_LOGOUT:
      return initialState;

    default: return state;
  }
};

export default update;
