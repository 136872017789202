import * as React from 'react';

import {
  Colors,

  Text,
} from '@bluecurrent/web-component-lib';

export default function PaginationButton(props) {
  const [hover, setHover] = React.useState(false);

  return (
    <button
      type="button"
      className={props.disabled || props.active ? 'DisableSelection' : 'Hover'}
      style={{
        height: 45,
        width: 45,
        backgroundColor: (!props.disabled && hover) || props.active ? Colors.MEDIUM_WHITE : 'transparent',
        border: 'solid 0px transparent',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1em',
        borderRadius: (!props.disabled && hover) || props.active ? 5 : 0,
        marginLeft: 5,
        marginRight: 5,
      }}
      onClick={props.disabled ? null : props.onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Text
        textAlign="center"
        color={props.disabled ? Colors.MEDIUM_GREY : Colors.GREY}
      >
        {props.index ?? ''}
      </Text>
    </button>
  );
}
