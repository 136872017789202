import * as React from 'react';
import * as Sentry from '@sentry/browser';
import humanizeDuration from 'humanize-duration';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime, Interval } from 'luxon';

import {
  Colors,
  Lang,

  useSweetName,
  useCurrencyFormat,

  Text,
} from '@bluecurrent/web-component-lib';

import executeCommand from '../../../api/executeCommand';
import { GET_TRANSACTION } from '../../../api/types/ApiMessages';
import addNotification from '../../../redux/creators/notifications';
import ListLoader from '../../Modules/ListLoader';

export default function Transaction(props) {
  const { _, i18n } = Lang.useTranslation();
  const dispatch = useDispatch();
  const sweetName = useSweetName(_);
  const navigate = useNavigate();
  const currencyFormat = useCurrencyFormat(i18n.language);

  const [loading, setLoading] = React.useState(false);
  const [transaction, setTransaction] = React.useState({});
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [duration, setDuration] = React.useState(null);

  const chargepoint = useSelector(
    (state) => state.ChargePoints.find((i) => i.evse_id === transaction.chargepoint_id),
  );

  const renderCosts = currencyFormat(
    transaction.reason_no_settlement
      ? 0
      : transaction.total_costs_ex_vat
        ?? 0,
    transaction.currency ?? 'EUR',
  );

  const renderTariff = currencyFormat(transaction.reimbursement_tariff_ex_vat, transaction.currency ?? 'EUR');

  const renderReimbursementTariff = transaction.reimbursement_tariff_ex_vat === 0
    ? _('free', { ns: 'ui' })
    : `${renderTariff} /kWh`;

  React.useEffect(() => {
    setLoading(true);
    executeCommand(GET_TRANSACTION, {
      params: {
        id: props.id,
      },
    })
      .then(({ data }) => {
        setLoading(false);
        setTransaction(data.data);
      })
      .catch((err) => {
        if (err.response?.status === 404) {
          navigate('/transactions');
        } else {
          Sentry.captureException(err);
          dispatch(addNotification('failed', _('error.somethingWrong', { ns: 'chargepoint' }), 'red'));
        }
      });
  }, []);

  React.useEffect(() => {
    if (transaction.started_at && transaction.end_time) {
      const start = DateTime.fromFormat(transaction.started_at, 'dd-MM-yyyy TT');
      const end = DateTime.fromFormat(transaction.end_time, 'dd-MM-yyyy TT');

      setStartDate(
        start.setLocale(i18n.language),
      );
      setEndDate(
        end.setLocale(i18n.language),
      );

      const diff = Interval.fromDateTimes(start, end);
      const milliseconds = diff.length('milliseconds');

      setDuration(
        humanizeDuration(milliseconds, { language: i18n.language }),
      );
    }
  }, [transaction]);

  const getReasonNoSettlement = React.useCallback(() => {
    switch (transaction.reason_no_settlement) {
      case 'USAGE TOO LOW':
        return _('reasonNoSettlement.usageTooLow', { ns: 'finance' });
      case 'INVALID CHARGECARD':
        return _('reasonNoSettlement.invalidChargecard', { ns: 'finance' });
      case 'PRICE TOO LOW':
        return _('reasonNoSettlement.priceTooLow', { ns: 'finance' });
      case 'STARTED WITH APP':
        return _('reasonNoSettlement.startedWithApp', { ns: 'finance' });
      case 'STARTED WITH PLUG AND CHARGE':
        return _('reasonNoSettlement.startedWithPlugAndCharge', { ns: 'finance' });
      case 'CARD IS FREE':
        return _('reasonNoSettlement.cardIsFree', { ns: 'finance' });
      case 'STATION IS FREE':
        return _('reasonNoSettlement.stationIsFree', { ns: 'finance' });
      default:
        /* When we don't know, don't show a message */
        return null;
    }
  }, [transaction.reason_no_settlement]);

  const data = [
    {
      id: 0,
      title: _('information', { ns: 'ui' }),
      items: [
        {
          id: 0,
          text: _('chargePoint', { ns: 'ui' }),
          content: chargepoint && (chargepoint.name || sweetName(chargepoint.chargepoint_type)),
        },
        {
          id: 1,
          text: _('bcuNumber', { ns: 'chargepoint' }),
          content: transaction.chargepoint_id,
        },
        {
          id: 2,
          text: _('chargeCard', { ns: 'ui' }),
          content: transaction.card_name !== ''
            ? transaction.card_name
            : transaction.card_id === 'BCU-APP' || transaction.card_id === '' ? _('noChargeCard', { ns: 'ui' }) : transaction.card_id,
        },
      ],
    },
    {
      id: 1,
      title: _('dateTime', { ns: 'dates' }),
      items: [
        {
          id: 0,
          text: _('startDate', { ns: 'dates' }),
          content: startDate && startDate.toLocaleString({
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          }),
        },
        {
          id: 1,
          text: _('startTime', { ns: 'dates' }),
          content: startDate && startDate.toFormat('HH:mm'),
        },
        {
          id: 2,
          text: _('endDate', { ns: 'dates' }),
          content: endDate && endDate.toLocaleString({
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          }),
        },
        {
          id: 3,
          text: _('endTime', { ns: 'dates' }),
          content: endDate && endDate.toFormat('HH:mm'),
        },
      ],
    },
    {
      id: 2,
      title: _('reimbursement', { ns: 'finance' }),
      items: [
        {
          id: 0,
          text: _('charged', { ns: 'chargepoint' }),
          content: `${transaction.kwh} kWh`,
        },
        {
          id: 1,
          text: _('duration', { ns: 'ui' }),
          content: duration ?? '-',
        },
        {
          id: 2,
          text: _('tariff', { ns: 'finance' }),
          content: renderReimbursementTariff,
        },
        {
          id: 4,
          text: _('upload.total', { ns: 'ui' }),
          content: renderCosts,
          note: getReasonNoSettlement(transaction.reason_no_settlement),
        },
      ],
    },
  ];

  return (
    <div
      style={{
        paddingLeft: 15,
        paddingRight: 15,
        overflowY: 'scroll',
        height: '100%',
        paddingTop: 30,
      }}
    >
      {
        loading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100% - 60px)',
            }}
          >
            <ListLoader />
          </div>
        )
      }
      {
        !loading
        && data.map((category, index) => (
          <div
            key={category.id}
            style={{
              marginTop: index === 0 ? 0 : 8,
              width: '100%',
              height: 'auto',
            }}
          >
            <Text
              fontSize="1em"
              color={Colors.DARK_GREY}
              fontWeight={500}
            >
              {category.title}
            </Text>
            <div
              style={{
                paddingTop: 10,
                paddingBottom: 5,
              }}
            >
              <div
                className="DisableSelection"
                style={{
                  paddingLeft: 10,
                  paddingTop: 10,
                  paddingRight: 10,
                  height: 'auto',
                  backgroundColor: Colors.LIGHT_GREY,
                  border: `solid 2px ${Colors.MEDIUM_WHITE}`,
                  borderRadius: 10,
                }}
              >
                <div
                  style={{
                    marginTop: 7.5,
                  }}
                >
                  {
                    category.items && category.items.map((item, i) => (
                      <div
                        key={item.id}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'flex-start',
                          justifyContent: 'space-between',
                          borderBottom: category.items.length === (i + 1) ? 'solid 2px transparant' : `solid 2px ${Colors.MEDIUM_WHITE}`,
                          height: 'auto',
                          paddingTop: i === 0 ? 0 : 15,
                          paddingBottom: 15,
                        }}
                      >
                        <div
                          style={{
                            height: '100%',
                          }}
                        >
                          <Text>{`${item.text}:`}</Text>
                        </div>
                        <div
                          style={{
                            height: '100%',
                          }}
                        >
                          <Text
                            textAlign="right"
                          >
                            {item.content}
                          </Text>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
              <div
                style={{
                  paddingTop: 5,
                  paddingBottom: 15,
                }}
              >
                {
              category.items && category.items.map((item) => (
                <Text
                  key={item.id}
                  color={Colors.DARK_GREY}
                  fontSize="1em"
                >
                  {item.note}
                </Text>
              ))
              }
              </div>
            </div>
          </div>
        ))
      }
    </div>
  );
}
