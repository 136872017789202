import * as React from 'react';

import {
  Lang,
  Breakpoint,
  Colors,

  useDimensions,
  getCurrency,

  Text,
  Input,
} from '@bluecurrent/web-component-lib';

import handleCompensationInputValidation from '../../functions/handleCompensationInputValidation';

export default function InputEnd(props) {
  const { _ } = Lang.useTranslation();
  const size = useDimensions();
  const currency = getCurrency(props.input.currency, 'symbol');

  const [isInError, setInError] = React.useState(props.input.isInError);

  const validateCompensationValue = () => {
    setInError(false);
    props.input.setInError(false);

    if (
      handleCompensationInputValidation(
        props.input.value,
        props.input.min,
        props.input.max,
      )
    ) {
      setInError(true);
      props.input.setInError(true);
    }
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {
        size.width < Breakpoint.md && (
          <div
            style={{
              paddingBottom: 7.5,
            }}
          >
            <Text
              fontSize="1.1em"
              fontWeight={300}
              color={Colors.DARK_GREY}
            >
              {`${props.text}:`}
            </Text>
          </div>
        )
      }
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: 7.5,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            height: '100%',
          }}
        >
          <Text>{currency}</Text>
        </div>
        <Input
          type="number"
          suffix={(
            <div
              style={{
                minWidth: 65,
                paddingRight: 10,
              }}
            >
              <Text
                textAlign="right"
                fontSize="1.1em"
                fontWeight={300}
              >
                {`/ ${_('units.kwh', { ns: 'chargepoint' })}`}
              </Text>
            </div>
          )}
          min={props.input.min}
          max={props.input.max}
          step={props.input.step}
          value={
            props.input.value
          }
          onChange={(e) => {
            props.input.onChange(e);
          }}
          onBlur={() => {
            validateCompensationValue();
          }}
          error={isInError}
          required
          styling
        />
      </div>
    </div>
  );
}
