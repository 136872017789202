export default [
  {
    type: 'all',
    title: ['all', { ns: 'ui' }],
  },
  {
    type: 'available',
    title: ['status.available', { ns: 'chargepoint' }],
  },
  {
    type: 'charging',
    title: ['status.charging', { ns: 'chargepoint' }],
  },
  {
    type: 'error',
    title: ['status.error', { ns: 'chargepoint' }],
  },
  {
    type: 'offline',
    title: ['status.offline', { ns: 'chargepoint' }],
  },
  {
    type: 'unavailable',
    title: ['status.unavailable', { ns: 'chargepoint' }],
  },
];
