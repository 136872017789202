import * as React from 'react';
import {
  AnimatePresence,
  motion,
} from 'framer-motion';

import {
  Lang,
  Colors,

  Text,
  Icon,
} from '@bluecurrent/web-component-lib';

export default function Item(props) {
  const { _, i18n } = Lang.useTranslation();

  const [hover, setHover] = React.useState(false);

  return (
    <button
      type="button"
      className={i18n.language === props.lang.iso ? 'Disabled' : 'Hover'}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={props.onClick}
      style={{
        width: '100%',
        height: 50,
        textAlign: 'left',
        paddingLeft: 10,
        paddingRight: 10,
        border: i18n.language === props.lang.iso ? `solid 2px ${Colors.BLUE}` : `solid 2px ${Colors.MEDIUM_WHITE}`,
        borderRadius: 10,
        backgroundColor: 'transparent',
        fontSize: '1em',
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Text
        color={i18n.language === props.lang.iso ? Colors.BLUE : Colors.GREY}
      >
        {_(props.lang.iso, { ns: 'settings' })}
      </Text>
      <AnimatePresence>
        {
          (i18n.language !== props.lang.iso && hover) && (
            <motion.div
              style={{
                position: 'absolute',
                paddingTop: 3,
              }}
              initial={{
                right: 40,
                opacity: 0,
              }}
              animate={{
                right: 10,
                opacity: 1,
              }}
              exit={{
                right: 40,
                opacity: 0,
              }}
              transition={{
                duration: 0.2,
              }}
            >
              <Icon
                name="ArrowRight"
                width={15}
                height={15}
                color={props.color ?? Colors.GREY}
              />
            </motion.div>
          )
        }
      </AnimatePresence>
    </button>
  );
}
