import * as React from 'react';
import {
  motion,
} from 'framer-motion';

import {
  Colors,
  Lang,

  Title,
  Button,
} from '@bluecurrent/web-component-lib';

import languages from '../../../config/Language.config';

import useUpdateLanguage from '../../../hooks/useUpdateLanguage';

export default function Language(props) {
  const { _, i18n } = Lang.useTranslation();
  const updateLanguage = useUpdateLanguage(i18n);

  const changeLanguage = (iso) => {
    updateLanguage(iso);
    document.documentElement.setAttribute('lang', iso);
    document.documentElement.setAttribute('xml:lang', iso);
  };

  return (
    <motion.div
      style={{
        height: props.fullscreen ? '100vh' : 'auto',
        maxHeight: props.fullscreen ? '100vh' : 500,
        width: props.fullscreen ? '100%' : 700,
        backgroundColor: Colors.BLUE,
        borderRadius: props.fullscreen ? 0 : 10,
        overflow: 'hidden',
        position: 'relative',
        paddingTop: 20,
        paddingBottom: 15,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
      transition={{
        duration: 0.5,
      }}
    >
      <div
        style={{
          paddingLeft: 15,
          paddingRight: 15,
          paddingBottom: 15,
        }}
      >
        <Title
          color={Colors.WHITE}
        >
          {_('language', { ns: 'settings' })}
        </Title>
      </div>
      <div
        style={{
          maxHeight: 400,
          height: 'auto',
          backgroundColor: Colors.BLUE,
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 10,
          paddingBottom: 20,
          display: 'flex',
          flexDirection: 'column',
          gap: 15,
        }}
      >
        {
          languages.map((lang) => (
            <Button
              key={lang.iso}
              subText={_(lang.iso, { ns: 'settings' })}
              type="action"
              selected
              color={Colors.BLUE}
              colorScheme="white-alt"
              onClick={() => {
                changeLanguage(lang.iso);
                props.onNext();
              }}
            />
          ))
        }
      </div>
    </motion.div>
  );
}
