import * as React from 'react';

import {
  Lang,
} from '@bluecurrent/web-component-lib';

import languages from '../../../../config/Language.config';

import Item from './Item';
import useUpdateLanguage from '../../../../hooks/useUpdateLanguage';

export default function Language(props) {
  const { i18n } = Lang.useTranslation();
  const updateLanguage = useUpdateLanguage(i18n);

  return (
    <div
      style={{
        height: '100%',
        overflowY: 'scroll',
        padding: 15,
      }}
    >
      {
        languages.map((lang) => (
          <Item
            key={lang.iso}
            lang={lang}
            onClick={() => {
              updateLanguage(lang.iso);
              props.onClose();
            }}
          />
        ))
      }

    </div>
  );
}
