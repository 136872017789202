import * as React from 'react';
import { useSelector } from 'react-redux';

import { Navigate } from 'react-router-dom';

export default function Auth(props) {
  const userLoggedIn = useSelector((state) => state.User.logged_in);

  if (!userLoggedIn && window.location.pathname !== '/login') {
    return <Navigate to={`/login?redirect=${window.location.pathname}`} />;
  }

  return props.children;
}
