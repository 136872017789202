import * as React from 'react';
import { useSelector } from 'react-redux';

export default function handleAccountName() {
  const user = useSelector((state) => state.User);

  const [firstnameSplitted, ...lastnameSplitted] = user.full_name.split(' ');

  const [firstname, setFirstname] = React.useState(firstnameSplitted);
  const [lastname, setLastname] = React.useState(lastnameSplitted.join(' '));

  const fullName = `${firstname ?? firstnameSplitted} ${lastname ?? lastnameSplitted.join(' ')}`;

  return {
    firstname,
    setFirstname,
    lastname,
    setLastname,
    firstnameSplitted,
    lastnameSplitted,
    fullName,
  };
}
