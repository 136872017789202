import * as React from 'react';

import {
  Colors,
  Lang,
  Breakpoint,

  Text,

  useDimensions,
} from '@bluecurrent/web-component-lib';

import ItemLink from '../../Modules/Link';

export default function Footer(props) {
  const size = useDimensions();
  const { _ } = Lang.useTranslation();

  const links = [
    {
      id: 0,
      title: _('privacyPolicy', { ns: 'legal' }),
      href: 'https://www.bluecurrent.nl/legal/privacy-statement',
      target: '_blank',
    },
    {
      id: 1,
      title: _('termsConditions', { ns: 'legal' }),
      href: 'https://www.bluecurrent.nl/legal/terms-and-conditions',
      target: '_blank',
    },
  ];

  return (
    <div
      style={{
        height: 'auto',
        minHeight: size.width < Breakpoint.md
          ? 65
          : 45,
        width: props.position === 'relative'
          ? '100%'
          : size.width < Breakpoint.sm
            ? '100%'
            : `calc(100% - ${size.width >= 1700 ? 240 : 70}px)`,
        borderTop: props.border ? `solid 2px ${Colors.MEDIUM_WHITE}` : 'solid 0px transparent',
        display: 'flex',
        flexDirection: size.width < Breakpoint.md ? 'column-reverse' : 'row',
        alignItems: size.width < Breakpoint.md ? 'center' : 'flex-end',
        paddingTop: size.width < Breakpoint.sm ? 10 : 0,
        paddingBottom: 12.5,
        justifyContent: 'space-between',
        marginLeft: props.position === 'relative' ? 0 : size.width < Breakpoint.sm ? 0 : size.width >= 1700 ? 240 : 70,
        position: size.width < Breakpoint.sm ? 'relative' : props.position ?? 'fixed',
        bottom: size.width < Breakpoint.sm ? 4 : 0,
        left: 0,
        backgroundColor: Colors.WHITE,
        zIndex: 1,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          paddingLeft: 10,
          paddingRight: 10,
          width: '100%',
        }}
      >
        <div
          style={{
            maxWidth: 1600,
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: size.width < Breakpoint.md ? 'column-reverse' : 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                paddingTop: size.width < Breakpoint.md ? 3 : 0,
                marginTop: size.width < 370 ? 5 : 0,
              }}
            >
              <Text
                fontWeight={100}
                fontSize="1em"
                textAlign={size.width < Breakpoint.md ? 'center' : 'left'}
              >
                &copy;
                {` ${new Date().getFullYear()} ${_('blueCurrent', { ns: 'branding' })}, ${_('allRightsReserved', { ns: 'legal' })}`}
              </Text>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: size.width < 370 ? 'column' : 'row',
              }}
            >
              {
                links.map((e) => (
                  <React.Fragment
                    key={e.id}
                  >
                    {
                      (size.width >= Breakpoint.md) ? (
                        <div
                          style={{
                            marginLeft: 8,
                            marginRight: 4,
                          }}
                        >
                          <Text
                            fontWeight={100}
                            fontSize="1em"
                          >
                            •
                          </Text>
                        </div>
                      ) : (
                        size.width >= 370 && e.id !== 0 && (
                          <div
                            style={{
                              marginLeft: 8,
                              marginRight: 4,
                            }}
                          >
                            <Text
                              fontWeight={100}
                              fontSize="1em"
                            >
                              •
                            </Text>
                          </div>
                        )
                      )
                    }
                    <ItemLink
                      title={e.title}
                      href={e.href}
                      target={e.target}
                      textAlign={size.width < 370 ? 'center' : 'left'}
                    />
                  </React.Fragment>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
