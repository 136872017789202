import React from 'react';

export default function Leaves() {
  return (
    <>
      <g id="Leaves">
        <path d="M1303.38,533.166C1300.54,527.924 1295.18,524.532 1289.23,524.207C1283.28,523.883 1277.59,526.672 1274.19,531.574C1080.1,813.198 954.41,1110.8 894.088,1423.66C876.759,1513.84 899.442,1607.11 956.254,1679.26C1013.07,1751.41 1098.41,1795.34 1190.14,1799.65C1219.76,1801.11 1249.73,1802.52 1279.29,1803.9C1371.61,1808.24 1461.23,1772.13 1524.75,1705C1588.27,1637.88 1619.38,1546.4 1609.95,1454.46C1580.18,1162.16 1477.09,854.869 1303.38,533.166Z" style={{ fill: 'rgb(0,202,139)' }} />
      </g>
      <g id="Leaves1">
        <path d="M1419.02,783.438C1417.19,777.78 1412.57,773.471 1406.8,772.049C1401.03,770.628 1394.93,772.297 1390.69,776.46C1204.48,959.451 1061.05,1135.2 961.522,1339.28C921.036,1422.24 918.241,1518.62 953.853,1603.8C989.465,1688.97 1060.03,1754.68 1147.52,1784.13L1147.52,1784.14L1147.52,1784.14C1226.45,1813.12 1314.36,1803.87 1385.53,1759.1C1456.7,1714.33 1503.09,1639.09 1511.13,1555.39C1534.03,1319.41 1508.48,1062.52 1419.02,783.438Z" style={{ fill: 'rgb(2,222,153)' }} />
      </g>
    </>
  );
}
