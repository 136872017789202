import {
  SET_GROUPS,
  ADD_GROUP,
  DELETE_GROUP,
  SET_GROUP_EVSES,
  UPDATE_GROUP,
  USER_LOGOUT,
  SET_GROUPS_LOADING,
} from '../actionTypes';

const initialState = {
  groups: [],
  loaded: false,
};

// eslint-disable-next-line default-param-last
const update = (state = initialState, action) => {
  switch (action.type) {
    case SET_GROUPS:
      return {
        ...state,
        groups: action.payload,
      };
    case ADD_GROUP:
      return {
        ...state,
        groups: [...state.groups, action.payload],
      };
    case DELETE_GROUP:
      return {
        ...state,
        groups: state.groups.filter(({ group: { id } }) => id !== action.id),
      };
    case SET_GROUP_EVSES:
      return {
        ...state,
        groups: state.groups.map(({ group: data }) => {
          if (data.id !== action.id) return data;
          return {
            group: {
              ...data,
              chargepoints: action.payload,
            },
          };
        }),
      };
    case UPDATE_GROUP:
      return {
        ...state,
        groups: state.groups.map(({ group: data }) => {
          if (data.id !== action.id) return { group: data };
          return {
            group: {
              ...data,
              ...action.payload,
            },
          };
        }),
      };
    case SET_GROUPS_LOADING:
      return {
        ...state,
        loaded: action.payload,
      };
    case USER_LOGOUT:
      return initialState;

    default: return state;
  }
};

export default update;
