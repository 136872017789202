import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';

import {
  Colors,
  Lang,

  OverlaySearch,

  Button,
  Text,
} from '@bluecurrent/web-component-lib';

import { useNavigate } from 'react-router-dom';
import {
  GET_CHARGE_POINTS,
  ADD_CHARGE_CARD,
  REMOVE_CHARGE_CARD,
} from '../../../api/types/ApiMessages';
import executeCommand from '../../../api/executeCommand';
import addNotification from '../../../redux/creators/notifications';

import ListLoader from '../../Modules/ListLoader';
import PlaceholderChargePoints from '../../Modules/Placeholders/ChargePoints';
import useTextSearch from '../../../hooks/useTextSearch';

export default function LinkedChargePoints(props) {
  const { _ } = Lang.useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const textSearch = useTextSearch();

  const chargePoints = useSelector((state) => state.ChargePoints);
  const cardFromRedux = useSelector(
    (state) => state.ChargeCards.chargeCards.find((i) => i.id === props.data.id),
  );
  const card = props.data && (props.data.data.find((i) => i.id === props.data.id) ?? cardFromRedux);

  const [linked, setLinked] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [search, setSearch] = React.useState('');

  const [removed, setRemoved] = React.useState([]);
  const [added, setAdded] = React.useState([]);

  const statusNew = props.status === 'new';

  React.useEffect(() => {
    if (card?.uid) {
      executeCommand(GET_CHARGE_POINTS, {
        uid: card.uid,
      })
        .then(({ result }) => {
          setLoading(false);
          setLinked(result.data);
        })
        .catch((err) => {
          Sentry.captureException(err);
          dispatch(addNotification('failed', _('error.somethingWrong', { ns: 'chargepoint' }), 'red'));
        });
    }
  }, [card?.uid]);

  const onClick = (isLinked, item) => {
    if (isLinked) {
      if (removed.includes(item.evse_id)) {
        setRemoved((prev) => prev.filter((i) => i !== item.evse_id));
      } else {
        setRemoved((prev) => [...prev, item.evse_id]);
      }
    } else if (added.includes(item.evse_id)) {
      setAdded((prev) => prev.filter((i) => i !== item.evse_id));
    } else {
      setAdded((prev) => [...prev, item.evse_id]);
    }
  };

  const save = () => {
    setButtonLoading(true);
    Promise.all(
      [
        ...added.map((id) => executeCommand(ADD_CHARGE_CARD, {
          id: card.id,
          evse_id: id,
        })),
        ...removed.map((id) => executeCommand(REMOVE_CHARGE_CARD, {
          id: card.id,
          evse_id: id,
        })),
      ],
    )
      .then(() => {
        setButtonLoading(false);
        setLinked((prev) => [
          ...prev.filter((i) => !removed.includes(i.evse_id)),
          ...chargePoints.filter((i) => added.includes(i.evse_id)),
        ]);
        setAdded([]);
        setRemoved([]);

        if (statusNew) navigate(`/chargecards/${card.id}/excluded-on?s=new`);
      })
      .catch((err) => {
        Sentry.captureException(err);
        dispatch(addNotification('failed', _('errors.somethingWrong', { ns: 'ui' }), 'red'));
      });
  };

  return (
    <>
      {
        statusNew && (
        <div
          style={{
            height: 'auto',
            width: '100%',
            borderBottom: `solid 2px ${Colors.MEDIUM_WHITE}`,
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <Text>{_('linkedChargePointsExplanation', { ns: 'chargecard' })}</Text>
        </div>
        )
      }
      <OverlaySearch
        onSearch={(e) => setSearch(e.target.value)}
        data={chargePoints
          .filter(textSearch(search, ['evse_id', 'name', 'model_type', 'chargepoint_type']))
          .map((item) => {
            const isLinked = linked.find((l) => l.evse_id === item.evse_id);
            const selected = added.includes(item.evse_id)
              ? true
              : removed.includes(item.evse_id)
                ? false
                : isLinked;

            return {
              id: item.evse_id,
              type: 'chargepoint',
              serial: item.name && item.evse_id,
              name: item.name || item.evse_id,
              selected: selected && _('connected', { ns: 'ui' }),
              onClick: () => onClick(isLinked, item),
            };
          })}
        empty={{
          condition: !loading && chargePoints.length < 1,
          component: <PlaceholderChargePoints />,
        }}
      />
      {
        loading && (
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1,
            }}
          >
            <ListLoader />
          </div>
        )
      }
      <div
        style={{
          height: 'auto',
          width: '100%',
          backgroundColor: Colors.WHITE,
          borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
          padding: 15,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <div
          style={{
            width: 'auto',
          }}
        >
          <Button
            loading={buttonLoading}
            text={
                statusNew
                  ? _('continue', { ns: 'ui' })
                  : _('save', { ns: 'ui' })
            }
            onClick={save}
            colorScheme={(!statusNew && added.length === 0 && removed.length === 0) ? 'grey' : 'blue'}
            disabled={!statusNew && added.length === 0 && removed.length === 0}
          />
        </div>
      </div>
    </>
  );
}
