import * as React from 'react';
import { DateTime } from 'luxon';

import { useNavigate, useLocation } from 'react-router-dom';

import {
  Lang,
  Colors,

  Input,
  Item,
  Text,
  InfoBlock,
} from '@bluecurrent/web-component-lib';

export default function AddChargeCard(props) {
  const { _, i18n } = Lang.useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [dateState, setDateState] = React.useState('');
  const [readableDate, setReadableDate] = React.useState('');

  React.useEffect(() => {
    if (state !== undefined && state !== null) {
      setDateState(JSON.stringify(state));

      if (state.dateBecameInvalid) {
        setReadableDate(
          DateTime
            .fromISO(state.dateBecameInvalid)
            .setLocale(i18n.language)
            .toLocaleString({
              month: 'long',
              day: 'numeric',
              year: 'numeric',
            }),
        );
      }
    }
  }, [state]);

  const emptyDateState = JSON.stringify({
    dateBecameInvalid: '',
    validChargeCard: false,
  });

  return (
    <div
      style={{
        paddingLeft: 15,
        paddingRight: 15,
        overflowY: 'scroll',
        height: '100%',
        paddingTop: 15,
      }}
    >
      {
        (state !== undefined && state !== null)
        && !state.validChargeCard
        && (
          <InfoBlock
            type="NB"
            warningType={
              dateState === emptyDateState
                ? 'authenticationWarning'
                : 'expiredCardWarning'
            }
            readableDate={readableDate}
          />
        )
      }
      <div
        style={{
          paddingTop: 15,
        }}
      >
        <Input
          type="text"
          label={`${_('chargeCard', { ns: 'ui' })} ${_('name', { ns: 'ui' }).toLocaleLowerCase(i18n.languages)}`}
          name="charge-card-name"
          styling
          max={
            props.onChange.max ? 20 : undefined
          }
          onChange={props.onChange.name}
          value={props.chargeCard.name}
          hint={props.savedName && _('saved', { ns: 'ui' })}
        />
      </div>
      <div
        style={{
          paddingTop: 15,
        }}
      >
        <Input
          type="text"
          label={_('serialNumber', { ns: 'ui' })}
          name="charge-card-serial"
          styling
          required
          value={props.chargeCard.serial}
          disabled={props.chargeCard.data}
          onChange={props.onChange.serial}
          error={props.error.serial}
          placeholder="XX-XXX-000000-0"
        />
      </div>
      {
        props.chargeCard.data && (
          <div
            style={{
              marginTop: 30,
              marginBottom: 30,
            }}
          >
            <Text
              fontSize="1em"
              color={Colors.DARK_GREY}
              fontWeight={500}
            >
              {_('shortcuts', { ns: 'ui' })}
            </Text>
            <div
              className="DisableSelection"
              style={{
                padding: 10,
                height: 'auto',
                minHeight: 55,
                backgroundColor: Colors.LIGHT_GREY,
                border: `solid 2px ${Colors.MEDIUM_WHITE}`,
                borderRadius: 10,
                marginTop: 10,
              }}
            >
              <Item
                type="button"
                title={_('linkedChargePoints', { ns: 'ui' })}
                onClick={() => navigate(`/chargecards/${props.chargeCard.serial}/linked-chargepoints`)}
              />
              <div
                style={{
                  borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
                  marginTop: 10,
                  paddingTop: 10,
                }}
              >
                <Item
                  type="button"
                  title={_('excludedOnChargePoints', { ns: 'chargecard' })}
                  onClick={() => navigate(`/chargecards/${props.chargeCard.serial}/excluded-on`)}
                />
              </div>
            </div>
            <div
              style={{
                marginTop: 15,
              }}
            >
              {/* {
                (state !== undefined && state !== null)
                && !state.validChargeCard
                && (
                  <InfoBlock
                    type="NB"
                    warningType={
                      dateState === emptyDateState
                        ? 'authenticationWarning'
                        : 'expiredCardWarning'
                    }
                    readableDate={readableDate}
                  />
                )
              } */}
            </div>
          </div>
        )
      }
    </div>
  );
}
