import * as React from 'react';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/browser';

import {
  Lang,

  OverlaySearch,

  Loader,
} from '@bluecurrent/web-component-lib';

import {
  GET_CHARGE_CARDS_PAGED,
} from '../../../api/types/ApiMessages';
import executeCommand from '../../../api/executeCommand';
import addNotification from '../../../redux/creators/notifications';

export default function DefaultChargeCard(props) {
  const { _ } = Lang.useTranslation();
  const dispatch = useDispatch();

  const [nextPage, setNextPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [cards, setCards] = React.useState([]);

  const loadPage = (page = nextPage) => {
    setLoading(true);
    executeCommand(GET_CHARGE_CARDS_PAGED, {
      params: {
        page,
        max_per_page: 25,
        other: 'N',
        evse_id: props.data,
      },
    })
      .then(({ data }) => {
        setNextPage(data);
        setLoading(false);
        setCards(data.cards.map(({ name, id }) => ({
          name,
          serial: id,
          type: 'chargecard',
        })));
      })
      .catch((err) => {
        Sentry.captureException(err);
        dispatch(addNotification('failed', _('error.somethingWrong', { ns: 'chargepoint' }), 'red'));
      });
  };

  React.useEffect(() => {
    loadPage();
  }, []);

  return (
    <>
      <OverlaySearch
        data={cards}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          paddingBottom: 15,
        }}
      >
        {
          loading && <Loader />
        }
      </div>
    </>
  );
}
