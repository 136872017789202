export const BASE_URI = process.env.REACT_APP_API_URI;

export const VALIDATE_PASSWORD = {
  type: 'socket',
  async: false,
  command: 'VALIDATE_PASSWORD',
  object: 'STATUS_PASSWORD',
  latterObject: null,
};

export const GET_CHARGE_POINTS = {
  type: 'socket',
  async: false,
  command: 'GET_CHARGE_POINTS',
  object: 'CHARGE_POINTS',
  latterObject: null,
};

export const GET_STATUS = {
  type: 'socket',
  async: false,
  command: 'GET_STATUS',
  object: 'STATUS',
  latterObject: null,
};

export const GET_CHARGE_CARDS = {
  type: 'socket',
  async: false,
  command: 'GET_CHARGE_CARDS',
  object: 'CHARGE_CARDS',
  latterObject: null,
};

export const GET_SUSTAINABILITY_STATUS = {
  type: 'socket',
  async: false,
  command: 'GET_SUSTAINABILITY_STATUS',
  object: 'SUSTAINABILITY_STATUS',
  latterObject: null,
};

export const START_SESSION = {
  type: 'socket',
  async: true,
  command: 'START_SESSION',
  object: 'RECEIVED_START_SESSION',
  latterObject: 'STATUS_START_SESSION',
};

export const STOP_SESSION = {
  type: 'socket',
  async: true,
  command: 'STOP_SESSION',
  object: 'RECEIVED_STOP_SESSION',
  latterObject: 'STATUS_STOP_SESSION',
};

export const GET_SESSIONS = {
  type: 'socket',
  async: false,
  command: 'GET_SESSIONS',
  object: 'SESSIONS',
  latterObject: null,
};

export const UNLOCK_CONNECTOR = {
  type: 'socket',
  async: true,
  command: 'UNLOCK_CONNECTOR',
  object: 'RECEIVED_UNLOCK_CONNECTOR',
  latterObject: 'STATUS_UNLOCK_CONNECTOR',
};

export const SOFT_RESET = {
  type: 'socket',
  async: true,
  command: 'SOFT_RESET',
  object: 'RECEIVED_SOFT_RESET',
  latterObject: 'STATUS_SOFT_RESET',
};

export const REBOOT = {
  type: 'socket',
  async: true,
  command: 'REBOOT',
  object: 'RECEIVED_REBOOT',
  latterObject: 'STATUS_REBOOT',
};

export const SET_OPERATIVE = {
  type: 'socket',
  async: true,
  command: 'SET_OPERATIVE',
  object: 'RECEIVED_SET_OPERATIVE',
  latterObject: 'STATUS_SET_OPERATIVE',
};

export const SET_INOPERATIVE = {
  type: 'socket',
  async: true,
  command: 'SET_INOPERATIVE',
  object: 'RECEIVED_SET_INOPERATIVE',
  latterObject: 'STATUS_SET_INOPERATIVE',
};

export const SET_PUBLIC_CHARGING = {
  type: 'socket',
  async: true,
  command: 'SET_PUBLIC_CHARGING',
  object: 'RECEIVED_SET_PUBLIC_CHARGING',
  latterObject: 'STATUS_SET_PUBLIC_CHARGING',
};

export const SET_PLUG_AND_CHARGE = {
  type: 'socket',
  async: true,
  command: 'SET_PLUG_AND_CHARGE',
  object: 'RECEIVED_SET_PLUG_AND_CHARGE',
  latterObject: 'STATUS_SET_PLUG_AND_CHARGE',
};

export const SET_PLUG_AND_CHARGE_NOTIFICATION = {
  type: 'socket',
  async: true,
  command: 'SET_PLUG_AND_CHARGE_NOTIFICATION',
  object: 'RECEIVED_SET_PLUG_AND_CHARGE_NOTIFICATION',
  latterObject: 'STATUS_SET_PLUG_AND_CHARGE_NOTIFICATION',
};

/* DEPRECATED */
export const SET_DEFAULT_CHARGE_CARD = {
  type: 'socket',
  async: false,
  command: 'SET_DEFAULT_CHARGE_CARD',
  object: 'STATUS_SET_DEFAULT_CHARGE_CARD',
  latterObject: null,
};

export const SET_PLUG_AND_CHARGE_CHARGE_CARD = {
  type: 'socket',
  async: false,
  command: 'SET_PLUG_AND_CHARGE_CHARGE_CARD',
  object: 'STATUS_SET_PLUG_AND_CHARGE_CHARGE_CARD',
  latterObject: null,
};

export const SET_CHARGE_CARD_NAME = {
  type: 'socket',
  async: false,
  command: 'SET_CHARGE_CARD_NAME',
  object: 'STATUS_SET_CHARGE_CARD_NAME',
  latterObject: null,
};

export const REMOVE_CHARGE_CARD = {
  type: 'socket',
  async: false,
  command: 'REMOVE_CHARGE_CARD',
  object: 'STATUS_REMOVE_CHARGE_CARD',
  latterObject: null,
};

export const ADD_CHARGE_CARD = {
  type: 'socket',
  async: false,
  command: 'ADD_CHARGE_CARD',
  object: 'STATUS_ADD_CHARGE_CARD',
  latterObject: null,
};

export const GET_CH_SETTINGS = {
  type: 'socket',
  async: false,
  command: 'GET_CH_SETTINGS',
  object: 'CH_SETTINGS',
  latterObject: null,
};

export const GET_GRID_STATUS = {
  type: 'socket',
  async: false,
  command: 'GET_GRID_STATUS',
  object: 'GRID_STATUS',
  latterObject: null,
};

export const GET_ACCOUNT = {
  type: 'socket',
  async: false,
  command: 'GET_ACCOUNT',
  object: 'ACCOUNT',
  latterObject: null,
};

export const VALIDATE_API_TOKEN = {
  type: 'socket',
  async: false,
  command: 'VALIDATE_API_TOKEN',
  object: 'STATUS_API_TOKEN',
  latterObject: null,
};

export const SET_CHARGE_POINT_NAME = {
  type: 'socket',
  async: false,
  command: 'SET_CHARGE_POINT_NAME',
  object: 'STATUS_SET_CHARGE_POINT_NAME',
  latterObject: null,
};

export const SET_LED_INTERACTION = {
  type: 'socket',
  async: true,
  command: 'SET_LED_INTERACTION',
  object: 'RECEIVED_SET_LED_INTERACTION',
  latterObject: 'STATUS_SET_LED_INTERACTION',
};

export const SET_PUBLISH_LOCATION = {
  type: 'socket',
  async: true,
  command: 'SET_PUBLISH_LOCATION',
  object: 'RECEIVED_SET_PUBLISH_LOCATION',
  latterObject: 'STATUS_SET_PUBLISH_LOCATION',
};

// Delayed charging
export const SET_DELAYED_CHARGING = {
  type: 'rest',
  url: '/setdelayedcharging',
  method: 'POST',
};

export const SAVE_SCHEDULE_DELAYED_CHARGING = {
  type: 'rest',
  url: '/savescheduledelayedcharging',
  method: 'POST',
};

export const OVERRIDE_DELAYED_CHARGING_TIMEOUT = {
  type: 'rest',
  url: '/overridedelayedchargingtimeout',
  method: 'POST',
};

export const SET_FREE_PAYING_CHARGE_CARD = {
  type: 'socket',
  async: false,
  command: 'SET_FREE_PAYING_CHARGE_CARD',
  object: 'STATUS_SET_FREE_PAYING_CHARGE_CARD',
  latterObject: null,
};

export const GET_GRIDS = {
  type: 'rest',
  url: '/getgrids',
  method: 'GET',
};

export const GET_UPCOMING_CALENDAR_EVENT = {
  type: 'rest',
  url: '/upcomingcalendarevent',
  method: 'GET',
};

export const GET_TOTAL_ENERGY_USAGE = {
  type: 'rest',
  url: '/totalenergyusage',
  method: 'GET',
};

export const GET_QUICK_ACCESS = {
  type: 'rest',
  url: '/quickaccess',
  method: 'GET',
};

export const GENERATE_HOME_AUTOMATION_KEY = {
  type: 'rest',
  url: '/generatehomeautomationkey',
  method: 'POST',
};

export const GET_HOME_AUTOMATION_KEY = {
  type: 'rest',
  url: '/gethomeautomationkey',
  method: 'GET',
};

export const GET_CH_STATUS = {
  type: 'rest',
  url: '/chargepointstatus',
  method: 'GET',
};

export const GET_TRANSACTIONS = {
  type: 'rest',
  url: '/gettransactions',
  method: 'POST',
};

export const GET_GROUPS = {
  type: 'rest',
  url: '/getgroups',
  method: 'GET',
};

export const ADD_GROUP = {
  type: 'rest',
  url: '/addgroup',
  method: 'POST',
};

export const DELETE_GROUP = {
  type: 'rest',
  url: '/deletegroup',
  method: 'POST',
};

export const EDIT_GROUP = {
  type: 'rest',
  url: '/editgroup',
  method: 'POST',
};

export const GET_DECLARATION_OVERVIEW = {
  type: 'rest',
  url: '/declarationoverview',
  method: 'GET',
};

export const GET_BY_POSTAL_CODE = {
  type: 'rest',
  url: '/getaddressbasedonzip',
  method: 'GET',
};

export const GET_CHARGE_CARDS_PAGED = {
  type: 'rest',
  url: '/getchargecards',
  method: 'POST',
};

export const VALIDATE_CHANGE_PASSWORD = {
  type: 'rest',
  url: '/validatechangepassword',
  method: 'POST',
};

export const RESET_PASSWORD_REQUEST = {
  type: 'rest',
  url: '/resetpasswordrequest',
  method: 'POST',
};

export const VALIDATE_RESET_PASSWORD = {
  type: 'rest',
  url: '/validateresetpassword',
  method: 'POST',
};

export const EDIT_ACCOUNT = {
  type: 'rest',
  url: '/editaccount',
  method: 'POST',
};

export const GENERATE_TRANSACTIONS_OVERVIEW = {
  type: 'rest',
  url: '/transactionsoverview',
  method: 'GET',
};

export const GET_TARIFFS = {
  type: 'rest',
  url: '/gettariffs',
  method: 'GET',
};

export const EDIT_TARIFF = {
  type: 'rest',
  url: '/edittariff',
  method: 'POST',
};

export const GET_DECLARATION_SETTINGS = {
  type: 'rest',
  url: '/getdeclarationsettings',
  method: 'GET',
};

export const EDIT_DECLARATION_SETTINGS = {
  type: 'rest',
  url: '/editdeclarationsettings',
  method: 'POST',
};

export const INVALIDATE_HOME_AUTOMATION_KEY = {
  type: 'rest',
  url: '/invalidatehomeautomationkey',
  method: 'POST',
};

export const GET_TRANSACTION = {
  type: 'rest',
  url: '/gettransaction',
  method: 'GET',
};

export const EDIT_CONTRACT = {
  type: 'rest',
  url: '/editcontract',
  method: 'POST',
};

export const GET_CONTRACTS = {
  type: 'rest',
  url: '/getcontracts',
  method: 'GET',
};

export const GET_CARDS_DECLARATION_OVERVIEW = {
  type: 'rest',
  url: '/getcardsdeclarationoverview',
  method: 'GET',
};

export const GET_CARDS_TRANSACTION_OVERVIEW = {
  type: 'rest',
  url: '/getcardstransactionoverview',
  method: 'GET',
};

export const SET_PRICE_BASED_CHARGING = {
  type: 'rest',
  url: '/setpricebasedcharging',
  method: 'POST',
};

export const SET_PRICE_BASED_SETTINGS = {
  type: 'rest',
  url: '/setpricebasedsettings',
  method: 'POST',
};

export const SET_PRICE_BASED_MINIMUM_KWH = {
  type: 'rest',
  url: '/setpricebasedminimumkwh',
  method: 'POST',
};

export const OVERRIDE_CHARGING_PROFILES = {
  type: 'rest',
  url: '/overridechargingprofiles',
  method: 'POST',
};

export const SET_CAPACITY_TARIFF = {
  type: 'rest',
  url: '/setcapacitytariff',
  method: 'POST',
};

export const EDIT_REIMBURSEMENT_TARIFF = {
  type: 'rest',
  url: '/editreimbursementtariff',
  method: 'POST',
};

export const GET_REIMBURSEMENT_FEES = {
  type: 'rest',
  url: '/getreimbursementfees',
  method: 'GET',
};

export const SET_SOLAR_BASED_CHARGING = {
  type: 'rest',
  url: '/setecocharging',
  method: 'POST',
};

export const SET_SOLAR_BASED_SETTINGS = {
  type: 'rest',
  url: '/setstartstopecocharging',
  method: 'POST',
};
