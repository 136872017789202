import * as React from 'react';

import {
  useNavigate,

  Link,
} from 'react-router-dom';

import {
  Colors,
  Breakpoint,
  Lang,

  truncate,
  useDimensions,

  Text,
  Icon,
  ItemMenu,
} from '@bluecurrent/web-component-lib';

import getItemMenu from './getItemMenu';

import ListLoader from '../../../Modules/ListLoader';

import PaginationArrow from '../../Transactions/List/Pagination/Arrow';
import PaginationButton from '../../Transactions/List/Pagination/Button';

const InvalidIcon = (props) => {
  if (props.event.valid === 1) return null;

  return (
    <div
      style={{
        height: 40,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <div
        style={{
          height: 40,
          width: 40,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <div
          style={{
            height: 40,
            width: 40,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
          }}
        >
          <Icon
            name="ExclamationCircle"
            height={21}
            width={21}
            color={Colors.RED}
            iconSet="FA"
          />
        </div>
      </div>
    </div>
  );
};

export const ChargeCardItem = (props) => {
  const size = useDimensions();
  const navigate = useNavigate();
  const itemMenu = getItemMenu(props.event, props.mode);
  const { _ } = Lang.useTranslation();

  const className = 'ChargeCardItem Hover';

  const [hover, setHover] = React.useState(false);

  const name = (getName = true) => {
    if (props.event.name && getName) return props.event.name;

    if (props.event.id === 'BCU-APP') return _('noChargeCard', { ns: 'ui' });

    return props.event.id;
  };

  return (
    <button
      type="button"
      className={className}
      onClick={(e) => {
        if (e.target.className === className) {
          if (props.mode !== 'Y') {
            navigate(
              `/chargecards/${props.event.id}`,
              {
                state: {
                  dateBecameInvalid: props.event.date_became_invalid,
                  validChargeCard: props.event.valid === 1,
                },
              },
            );
          } else {
            navigate(`/chargecards/${props.event.id}/linked-chargepoints`);
          }
        }
      }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      style={{
        minHeight: 50,
        height: 'auto',
        borderTop: 'solid 0px transparent',
        borderLeft: 'solid 0px transparent',
        borderRight: 'solid 0px transparent',
        borderBottom: `solid 2px ${Colors.MEDIUM_WHITE}`,
        backgroundColor: hover ? Colors.LIGHT_GREY : Colors.WHITE,
        fontSize: '1em',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: 0,
        padding: 0,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Link
          to={props.mode !== 'Y' ? `/chargecards/${props.event.id}` : `/chargecards/${props.event.id}/linked-chargepoints`}
          className="Hover"
          style={{
            paddingTop: 7,
            paddingBottom: 7,
            width: 'auto',
            marginLeft: 5,
          }}
        >
          <Text>{ truncate(name(), size.width < Breakpoint.fd ? 14 : 20)}</Text>
          {
            props.event.name !== '' && (
              <div
                style={{
                  paddingTop: 2,
                }}
              >
                <Text
                  fontWeight={300}
                  fontSize="0.9em"
                  color={Colors.MEDIUM_GREY}
                  textTransform="uppercase"
                >
                  { name(false) }
                </Text>
              </div>
            )
          }
        </Link>
      </div>
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <InvalidIcon
          event={props.event}
        />
        {
          size.width >= Breakpoint.sm ? (
            <div
              style={{
                marginLeft: 0,
              }}
            >
              <ItemMenu
                id={`charge-card-menu-${props.index}`}
                items={itemMenu}
                right
              />
            </div>
          ) : (
            <div
              style={{
                height: 60,
                width: 40,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Icon
                name="AngleRight"
                height={30}
                width={30}
                color={Colors.GREY}
              />
            </div>
          )
        }
      </div>
    </button>
  );
};

export default function List(props) {
  const size = useDimensions();

  const empty = props.data.length < 1;

  return (
    <>
      <div
        style={{
          width: '100%',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <div
          id="Tour_Step5"
          style={{
            height: '100%',
            minHeight: '65vh',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            marginBottom: size.width >= Breakpoint.sm && size.width < 1200 ? 78 : 0,
          }}
        >
          {
            !props.loading && props.data.map((event, index) => (
              <ChargeCardItem
                /* eslint-disable-next-line react/no-array-index-key */
                key={event.uid + index}
                event={event}
                index={index}
                mode={props.mode}
              />
            ))
          }
        </div>
        {
          props.loading && (
            <div
              style={{
                width: '100%',
                height: size.width < 1200 ? '50vh' : 'calc(100vh - 235px)',
                position: 'absolute',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1,
              }}
            >
              <ListLoader />
            </div>
          )
        }
      </div>
      <div
        style={{
          height: 60,
          width: size.width < Breakpoint.sm ? '100%' : props.width,
          marginBottom: size.width < Breakpoint.md
            ? size.width < Breakpoint.sm ? -26 : 30
            : 10,
          marginTop: size.width < Breakpoint.sm ? 30 : 0,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: size.width < Breakpoint.sm ? 'center' : 'flex-start',
          alignItems: 'center',
          backgroundColor: Colors.WHITE,
          position: size.width < Breakpoint.sm ? 'relative' : 'fixed',
          zIndex: 100,
          bottom: size.width < Breakpoint.sm ? 30 : 35,
          borderTop: `solid ${size.width < Breakpoint.sm ? '0px transparent' : `2px ${Colors.MEDIUM_WHITE}`}`,
        }}
      >
        <div
          style={{
            marginRight: 15,
          }}
        >
          <PaginationArrow
            previous
            disabled={
              empty
                ? true
                : props.page === 1
            }
            onClick={() => props.loadData(props.page - 1)}
          />
        </div>
        <PaginationButton
          index={
            empty || props.page === 1
              ? 1
              : props.page - 1
          }
          active={
            empty
              ? true
              : props.page === 1
          }
          onClick={() => props.loadData(props.page === 1 ? 1 : props.page - 1)}
        />
        <PaginationButton
          index={
            empty
              ? 2
              : props.page === 1
                ? props.page + 1
                : props.page
          }
          active={
            empty
              ? false
              : props.page !== 1 && !(!props.nextPage && props.page > 2)
          }
          disabled={
            empty
              ? true
              : !props.nextPage && props.page === 1
          }
          onClick={() => props.loadData(props.page === 1 ? props.page + 1 : props.page)}
        />
        <PaginationButton
          index={
            props.data.length < 1
              ? 3
              : props.page === 1
                ? props.page + 2
                : props.page + 1
          }
          active={
            empty
              ? false
              : !props.nextPage && props.page > 2
          }
          disabled={
            empty
              ? true
              : (!props.nextPage && props.page < 3)
                || (props.page === 1
                  ? props.page + 2
                  : props.page + 1) > props.totalPages
          }
          onClick={
            () => props.loadData(
              props.page === 1
                ? props.page + 2
                : props.page + 1,
            )
          }
        />
        <div
          style={{
            marginLeft: 15,
          }}
        >
          <PaginationArrow
            next
            disabled={!props.nextPage}
            onClick={() => props.loadData(props.nextPage)}
          />
        </div>
      </div>
    </>
  );
}
