import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';

import {
  Colors,
  Lang,
  Breakpoint,

  useDimensions,

  Text,
  Input,
  Button, Icon,
} from '@bluecurrent/web-component-lib';

import handleAccountName from '../../../functions/handleAccountName';

import {
  VALIDATE_CHANGE_PASSWORD,
  RESET_PASSWORD_REQUEST, EDIT_ACCOUNT,
} from '../../../api/types/ApiMessages';
import executeCommand from '../../../api/executeCommand';

import SettingsTitle from '../../Modules/SettingsTitle';
import addNotification from '../../../redux/creators/notifications';

export default function Account() {
  const { _ } = Lang.useTranslation();
  const size = useDimensions();
  const user = useSelector((state) => state.User);
  const dispatch = useDispatch();

  const {
    firstname,
    setFirstname,
    lastname,
    setLastname,
    fullName,
    firstnameSplitted,
    lastnameSplitted,
  } = handleAccountName();

  const [phone, setPhone] = React.useState(user.tel);

  const [accountUpdateLoading, setAccountUpdateLoading] = React.useState(false);
  const [accountUpdateError, setAccountUpdateError] = React.useState(null);

  const [forgotPassword, setForgotPassword] = React.useState(null);
  const [passEmail, setPassEmail] = React.useState(null);
  const [oldPassword, setOldPassword] = React.useState(null);
  const [newPassword, setNewPassword] = React.useState(null);
  const [confirmPassword, setConfirmPassword] = React.useState(null);
  const [errors, setErrors] = React.useState([]);
  const [changePasswordMsg, setChangePasswordMsg] = React.useState(null);
  const [isPasswordLoading, setPasswordLoading] = React.useState(false);

  const [error, setError] = React.useState(null);

  const updateAccount = () => {
    setAccountUpdateError(null);

    setAccountUpdateLoading(true);
    executeCommand(EDIT_ACCOUNT, {
      body: {
        full_name: fullName,
        tel: phone ?? user.tel,
      },
    })
      .then(() => {
        dispatch(addNotification('success', _('accountUpdated', { ns: 'authentication' }), 'green'));
        setAccountUpdateLoading(false);

        dispatch({
          type: 'USER_UPDATE',
          payload: {
            full_name: fullName,
            tel: phone ?? user.tel,
          },
        });
      })
      .catch((err) => {
        Sentry.captureException(err);
        setAccountUpdateError(_('errors.somethingWrong', { ns: 'ui' }));
        setAccountUpdateLoading(false);
      });
  };

  const updatePassword = () => {
    setError(null);

    const errorsArr = [];
    if (!passEmail) errorsArr.push({ field: 'passEmail', error: _('errors.email-empty', { ns: 'ui' }) });
    if (!forgotPassword) {
      if (!oldPassword) errorsArr.push({ field: 'oldPassword', error: _('errors.password-empty', { ns: 'ui' }) });
      if (!newPassword) errorsArr.push({ field: 'newPassword', error: _('errors.password-empty', { ns: 'ui' }) });
      if (!confirmPassword) errorsArr.push({ field: 'confirmPassword', error: _('errors.password-empty', { ns: 'ui' }) });

      if (newPassword !== confirmPassword) errorsArr.push({ field: 'confirmPassword', error: _('errors.passwords-match', { ns: 'ui' }) });
    }

    setErrors(errorsArr);

    if (errorsArr.length > 0) return;

    setPasswordLoading(true);

    if (forgotPassword) {
      setPasswordLoading(true);
      executeCommand(RESET_PASSWORD_REQUEST, {
        body: {
          login: passEmail,
        },
      })
        .then(({ data }) => {
          setPasswordLoading(false);
          if (data.success) {
            setChangePasswordMsg(_('emailSentIfExist', { ns: 'ui' }));
          } else {
            Sentry.captureException(data);
            setError(_('errors.somethingWrong', { ns: 'ui' }));
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          setError(_('errors.somethingWrong', { ns: 'ui' }));
        });
    } else {
      executeCommand(VALIDATE_CHANGE_PASSWORD, {
        body: {
          login: passEmail,
          old_password: oldPassword,
          new_password: newPassword,
        },
      }).then(({ data }) => {
        setPasswordLoading(false);
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
        if (data.success) {
          setPassEmail('');
          setChangePasswordMsg(_('passwordUpdated', { ns: 'ui' }));
        } else {
          Sentry.captureException(data);
          setError(_('errors.somethingWrong', { ns: 'ui' }));
        }
      })
        .catch((err) => {
          Sentry.captureException(err);
          setPasswordLoading(false);
          setOldPassword('');
          setNewPassword('');
          setConfirmPassword('');
          setError(_('errors.somethingWrong', { ns: 'ui' }));
        });
    }
  };

  const hasChanged = firstname !== firstnameSplitted
    || lastname !== lastnameSplitted.join(' ')
    || phone !== user.tel;

  return (
    <div
      className="FadeIn"
    >
      <SettingsTitle
        title={_('personalInformation', { ns: 'settings' })}
      />
      <div
        style={{
          marginTop: 15,
          marginBottom: 60,
        }}
      >
        <div
          style={{
            marginTop: 15,
            display: 'flex',
            flexDirection: size.width < Breakpoint.sm ? 'column' : 'row',
            width: '100%',
          }}
        >
          <div
            style={{
              marginRight: 15,
              marginBottom: size.width < Breakpoint.sm ? 15 : 0,
              width: '70%',
            }}
          >
            <Input
              type="text"
              label={_('firstName', { ns: 'settings' })}
              name="first-name"
              styling
              value={firstname ?? firstnameSplitted}
              onChange={(e) => setFirstname(e.target.value)}
            />
          </div>
          <Input
            type="text"
            label={_('lastName', { ns: 'settings' })}
            name="last-name"
            styling
            value={lastname ?? lastnameSplitted.join(' ')}
            onChange={(e) => setLastname(e.target.value)}
          />
        </div>
        <div
          style={{
            marginTop: 45,
          }}
        >
          <Input
            type="text"
            label={_('username', { ns: 'authentication' })}
            name="username"
            disabled
            styling
            value={user.login}
          />
        </div>
        <div
          style={{
            marginTop: 15,
          }}
        >
          <Input
            type="tel"
            label={_('phone', { ns: 'authentication' })}
            name="phone"
            styling
            value={phone ?? user.tel}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>

        <div style={{ marginTop: 15, width: '50%' }}>
          <div style={{ marginBottom: 15 }}>
            {
              accountUpdateError && (
                <div
                  style={{
                    height: 'auto',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    marginBottom: 15,
                  }}
                >
                  <div
                    style={{
                      height: 21,
                      width: 21,
                      marginRight: 7,
                    }}
                  >
                    <Icon
                      name="ExclamationCircle"
                      height={21}
                      width={21}
                      color={Colors.RED}
                      iconSet="FA"
                    />
                  </div>
                  <Text
                    color={Colors.RED}
                    fontWeight={100}
                  >
                    {accountUpdateError}
                  </Text>
                </div>
              )
            }
          </div>
          <Button
            text={_('save', { ns: 'ui' })}
            onClick={updateAccount}
            loading={accountUpdateLoading}
            disabled={!hasChanged}
            colorScheme={hasChanged ? 'blue' : 'grey'}
          />
        </div>

        <div
          style={{
            marginTop: 60,
            marginBottom: 15,
          }}
        >
          <Text
            fontSize="1em"
            color={Colors.DARK_GREY}
            fontWeight={500}
          >
            {_('changePassword', { ns: 'settings' })}
          </Text>
          <div
            style={{
              marginTop: 30,
            }}
          >
            <Input
              type="email"
              label={_('username', { ns: 'authentication' })}
              name="email"
              required
              styling
              value={passEmail}
              onChange={(e) => setPassEmail(e.target.value)}
              error={errors.find((i) => i.field === 'passEmail')?.error}
            />
          </div>
          {
            !forgotPassword && (
              <>
                <div
                  style={{
                    marginTop: 15,
                  }}
                >
                  <Input
                    type="password"
                    label={_('oldPassword', { ns: 'authentication' })}
                    name="password"
                    autocomplete="current-password"
                    required
                    styling
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    error={errors.find((i) => i.field === 'oldPassword')?.error}
                  />
                </div>
                <div
                  style={{
                    marginTop: 15,
                    marginBottom: 15,
                  }}
                >
                  <Input
                    type="password"
                    label={_('newPassword', { ns: 'authentication' })}
                    name="new-password"
                    autocomplete="new-password"
                    required
                    styling
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    error={errors.find((i) => i.field === 'newPassword')?.error}
                  />
                </div>
                <Input
                  type="password"
                  label={_('confirmNewPassword', { ns: 'authentication' })}
                  name="confirm-password"
                  autocomplete="new-password"
                  required
                  styling
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  error={errors.find((i) => i.field === 'confirmPassword')?.error}
                />
              </>
            )
          }
          <div
            style={{
              marginTop: 30,
              display: 'grid',
              gridTemplateColumns: size.width < Breakpoint.sm ? 'auto' : 'auto 156px',
              gap: 15,
              alignItems: 'center',
            }}
          >
            <div
              style={{
                marginRight: size.width < Breakpoint.sm ? 0 : 15,
                width: forgotPassword ? '50%' : '100%',
              }}
            >
              <Button
                text={forgotPassword ? _('send', { ns: 'ui' }) : _('updatePassword', { ns: 'authentication' })}
                onClick={updatePassword}
                loading={isPasswordLoading}
              />
            </div>
            {
              !forgotPassword && (
                <Button
                  type="link"
                  text={`${_('forgotPassword', { ns: 'authentication' })}?`}
                  onClick={setForgotPassword}
                  height="inherit"
                  width={size.width < Breakpoint.sm ? '156px' : 'auto'}
                  color={Colors.BLUE}
                  removeArrow
                  textDecoration="underline"
                />
              )
            }
          </div>
          {
            error && (
            <div
              style={{
                height: 'auto',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginBottom: 20,
                marginTop: 20,
              }}
            >
              <div
                style={{
                  height: 21,
                  width: 21,
                  marginRight: 7,
                }}
              >
                <Icon
                  name="ExclamationCircle"
                  height={21}
                  width={21}
                  color={Colors.RED}
                  iconSet="FA"
                />
              </div>
              <Text
                color={Colors.RED}
                fontWeight={100}
              >
                {error}
              </Text>
            </div>
            )
          }
          {
            changePasswordMsg && (
              <div style={{ marginTop: 15 }}>
                <Text>{changePasswordMsg}</Text>
              </div>
            )
          }
        </div>

      </div>
    </div>
  );
}
