import * as React from 'react';
import { Loader } from '@bluecurrent/web-component-lib';

export default function FullLoader() {
  return (
    <div style={{
      width: '100vw',
      height: '100vh',
      background: '#ffffff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      zIndex: 1000000,
    }}
    >
      <Loader />
    </div>
  );
}
