import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  DateTime,
} from 'luxon';
import {
  useNavigate,
  Link,
} from 'react-router-dom';

import {
  Colors,
  Lang,
  Breakpoint,

  truncate,
  useDimensions,
  useSweetName,

  Text,
  Status,
  ItemMenu,
  Icon,
} from '@bluecurrent/web-component-lib';

import getItemMenu from './getItemMenu';
import getSortBy from './getSortBy';
import useTextSearch from '../../../../hooks/useTextSearch';

export const ChargePointItem = (props) => {
  const { _, i18n } = Lang.useTranslation();
  const size = useDimensions();
  const sweetName = useSweetName(_);
  const navigate = useNavigate();

  const itemMenu = getItemMenu(props.event);

  const [hover, setHover] = React.useState(false);

  const className = 'ChargePointItem Hover';

  const plannedCharge = props.event.vehicle_status === 'C' && (
    props.event.smart_current_heartbeat_timeout !== undefined
      ? props.event.smart_current_heartbeat_timeout > 0
      : props.event.delayed_charging.smart_current_heartbeat_timeout > 0
  );

  return (
    <button
      id={props.index === 0 ? 'Tour_Step3' : undefined}
      type="button"
      className={className}
      onClick={(e) => {
        if (size.width < Breakpoint.sm || e.target.className === className) {
          navigate(`/chargepoints/${props.event.evse_id}`);
        }
      }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      style={{
        minHeight: 50,
        height: 'auto',
        borderTop: 'solid 0px transparent',
        borderLeft: 'solid 0px transparent',
        borderRight: 'solid 0px transparent',
        borderBottom: `solid 2px ${Colors.MEDIUM_WHITE}`,
        backgroundColor: hover ? Colors.LIGHT_GREY : Colors.WHITE,
        fontSize: '1em',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: 0,
        padding: 0,
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Link
          to={`/chargepoints/${props.event.evse_id}`}
          className="Hover"
          style={{
            paddingTop: 7,
            paddingBottom: 7,
            width: 'auto',
            marginLeft: 5,
            backgroundColor: 'transparent',
            border: 'solid 0px transparent',
            fontSize: '1em',
          }}
        >
          <Text>
            {truncate(props.event.name ? props.event.name : `${sweetName(props.event.chargepoint_type)}`, size.width < Breakpoint.fd ? 14 : 20)}
          </Text>
          <div
            style={{
              paddingTop: 2,
            }}
          >
            <Text
              fontWeight={300}
              fontSize="0.9em"
              color={Colors.MEDIUM_GREY}
              textTransform="uppercase"
            >
              {props.evse_id}
            </Text>
          </div>
        </Link>
      </div>
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        {
          props.event.activity === 'offline'
          && props.event.offline_since !== ''
          && size.width >= 500
          && (
            <div
              style={{
                width: 'auto',
                marginRight: 30,
                paddingTop: 7,
                paddingBottom: 7,
              }}
            >
              <Text
                fontWeight={500}
                fontSize="1.2em"
              >
                {`${_('offline.lastOnline', { ns: 'chargepoint' })}:`}
              </Text>
              <div
                style={{
                  paddingTop: 2,
                }}
              >
                <Text
                  fontWeight={300}
                  fontSize="0.9em"
                  color={Colors.MEDIUM_GREY}
                >
                  {
                    props.event.offline_since
                      ? DateTime
                        .fromFormat(props.event.offline_since, 'yMMdd TT')
                        .setLocale(i18n.language)
                        .toLocaleString({
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                        })
                      : (
                        <Skeleton
                          width={120}
                        />
                      )
                    }
                </Text>
              </div>
            </div>
          )
        }
        <Status
          status={
            plannedCharge
              ? 'delayed'
              : props.event.activity
          }
        />
        {
          size.width >= Breakpoint.sm ? (
            <div
              style={{
                marginLeft: 0,
              }}
            >
              <ItemMenu
                id={`chargepoint-menu-${props.index}`}
                items={itemMenu.filter((items) => items.permissions !== 'none')}
                right
              />
            </div>
          ) : (
            <div
              style={{
                height: 60,
                width: 40,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Icon
                name="AngleRight"
                height={30}
                width={30}
                color={Colors.GREY}
              />
            </div>
          )
        }
      </div>
    </button>
  );
};

export default function List(props) {
  const textSearch = useTextSearch();

  const handleSocketId = (socketId) => {
    const set = new Set();
    const duplicates = [];

    const hasMultipleChargePoints = (array) => array.some((obj) => {
      // If evse_id is already in the Set, return true (duplicate found)
      if (set.has(obj.evse_id)) {
        // Add evse_id to list with duplicates
        duplicates.push(obj.evse_id);
        return true;
      }
      // Otherwise, add evse_id to the Set
      set.add(obj.evse_id);
      return false;
    });

    if (hasMultipleChargePoints(props.data) && duplicates.includes(props.data.evse_id)) {
      if (socketId !== null && socketId !== undefined) return `_${socketId}`;
    }

    return '';
  };

  return (
    <div
      style={{
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {
        props.data
          .filter((item) => props.filter.includes('all') || props.filter.includes(item.activity))
          .filter((item) => (props.group ? props.group.includes(item.evse_id) : true))
          .filter(textSearch(props.search, [
            'evse_id',
            'name',
            'model_type',
            'chargepoint_type',
            'location.street',
            'location.city',
            'location.country',
            'location.zipcode',
          ]))
          .sort((a, b) => getSortBy.indexOf(a.activity) - getSortBy.indexOf(b.activity))
          .map((event, index) => (
            <ChargePointItem
              key={event.evse_id + handleSocketId(event.socket_id)}
              event={event}
              index={index}
              evse_id={event.evse_id + handleSocketId(event.socket_id)}
            />
          ))
      }
    </div>
  );
}
