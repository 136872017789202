import * as React from 'react';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';

import {
  Colors,
  Breakpoint,
  Lang,

  useDimensions,
  useSweetName,
  truncate,
  useCurrencyFormat,

  Text,
  Icon,
  Tooltip,
} from '@bluecurrent/web-component-lib';

export default function Item(props) {
  const { _, i18n } = Lang.useTranslation();
  const size = useDimensions();
  const sweetName = useSweetName(_);
  const currencyFormat = useCurrencyFormat(i18n.language);
  const navigate = useNavigate();

  const [hover, setHover] = React.useState(false);
  const reasonNoSettlementRef = React.useRef(0);

  const [reasonNoSettlementTooltip, setReasonNoSettlementTooltip] = React.useState(false);
  const [reasonNoSettlementWidth, setReasonNoSettlementWidth] = React.useState(32);

  const startDate = DateTime.fromFormat(props.data?.started_at, 'dd-MM-yyyy TT').setLocale(i18n.language);
  const endDate = DateTime.fromFormat(props.data?.end_time, 'dd-MM-yyyy TT').setLocale(i18n.language);

  const renderedCurrency = props.data.total_costs === undefined ? '-' : currencyFormat(
    props.data.reason_no_settlement ? 0 : props.data.total_costs_ex_vat,
    props.data.currency,
  );

  const getReasonNoSettlement = React.useCallback(() => {
    switch (props.data.reason_no_settlement) {
      case 'USAGE TOO LOW':
        return _('reasonNoSettlement.usageTooLow', { ns: 'finance' });
      case 'INVALID CHARGECARD':
        return _('reasonNoSettlement.invalidChargecard', { ns: 'finance' });
      case 'PRICE TOO LOW':
        return _('reasonNoSettlement.priceTooLow', { ns: 'finance' });
      case 'STARTED WITH APP':
        return _('reasonNoSettlement.startedWithApp', { ns: 'finance' });
      case 'STARTED WITH PLUG AND CHARGE':
        return _('reasonNoSettlement.startedWithPlugAndCharge', { ns: 'finance' });
      case 'CARD IS FREE':
        return _('reasonNoSettlement.cardIsFree', { ns: 'finance' });
      case 'STATION IS FREE':
        return _('reasonNoSettlement.stationIsFree', { ns: 'finance' });
      default:
        /* When we don't know, don't show a message */
        return null;
    }
  }, [props.data.reason_no_settlement]);

  React.useEffect(() => {
    if (
      props.data.reason_no_settlement
      && reasonNoSettlementRef.current
      && (reasonNoSettlementRef.current.clientWidth !== undefined)
    ) {
      setReasonNoSettlementWidth(reasonNoSettlementRef.current.clientWidth + 7.5);
    }
  }, [props.data.reason_no_settlement, reasonNoSettlementRef.current?.clientWidth]);

  return (
    <button
      type="button"
      className="Hover"
      onClick={() => {
        navigate(`/transactions/${props.data.transaction_id}`);
      }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      style={{
        height: 60,
        width: '100%',
        backgroundColor: hover ? Colors.LIGHT_GREY : Colors.WHITE,
        display: 'flex',
        flexDirection: 'row',
        borderBottom: props.last ? 'solid 0px transparent' : `solid 2px ${Colors.MEDIUM_WHITE}`,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 5,
        paddingRight: 5,
        borderLeft: 'solid 0px transparent',
        borderRight: 'solid 0px transparent',
        borderTop: 'solid 0px transparent',
        fontSize: '1em',
        margin: 0,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: props.align === 'vertical' ? 'column' : 'row',
          width: size.width < 345 ? '90%' : '100%',
        }}
      >
        <div
          style={{
            width: '100%',
            minWidth: 130,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Text>{truncate(props.data.evse_name ? props.data.evse_name : `${sweetName(props.data.chargepoint_type)}`, size.width < Breakpoint.fd ? 14 : 20)}</Text>
          <div
            style={{
              paddingTop: 3,
            }}
          >
            <Text
              fontSize="0.9em"
              color={Colors.MEDIUM_GREY}
            >
              {props.data.chargepoint_id}
            </Text>
          </div>
        </div>
        {
          (props.type !== 'small')
          && size.width >= Breakpoint.sm
          && (
            <div
              style={{
                width: '100%',
                minWidth: 130,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Text>
                {
                  truncate(props.data.card_name || (
                    props.data.card_id === 'QR_SESSION'
                      ? _('qrPayment', { ns: 'ui' })
                      : props.data.card_id === 'BCU_HOME_USE'
                        ? _('noPlugChargeChargeCard', { ns: 'ui' })
                        : props.data.card_id === 'BCU-APP' || props.data.card_id === ''
                          ? _('noChargeCard', { ns: 'ui' })
                          : props.data.card_id
                  ), size.width < 1200 ? 7 : size.width >= Breakpoint.xxl ? 20 : 10)
                }
              </Text>
              <div
                style={{
                  paddingTop: 3,
                }}
              >
                <Text
                  fontSize="0.9em"
                  color={Colors.MEDIUM_GREY}
                >
                  {
                    props.data.card_name && (
                      props.data.card_id === 'QR_SESSION'
                        ? _('qrPayment', { ns: 'ui' })
                        : props.data.card_id === 'BCU_HOME_USE'
                          ? _('noPlugChargeChargeCard', { ns: 'ui' })
                          : props.data.card_id === 'BCU-APP' || props.data.card_id === ''
                            ? _('noChargeCard', { ns: 'ui' })
                            : props.data.card_id
                    )
                  }
                </Text>
              </div>
            </div>
          )
        }
        <div
          style={{
            width: props.type === 'small' ? '100%' : 180,
            minWidth: props.type === 'small' ? 'auto' : 180,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingTop: 0,
          }}
        >
          <Text>
            {
              props.type === 'small'
                ? new Date(startDate).toLocaleString(i18n.language === 'nl' ? 'nl-NL' : 'en-GB', {
                  month: 'short',
                  day: 'numeric',
                })
                : new Date(startDate).toLocaleString(i18n.language === 'nl' ? 'nl-NL' : 'en-GB', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })
            }
          </Text>
          <div
            style={{
              paddingTop: 3,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text
              fontSize="0.9em"
              color={Colors.MEDIUM_GREY}
              whiteSpace="nowrap"
            >
              {`${startDate.toFormat('HH:mm')} - ${endDate.toFormat('HH:mm')}`}
            </Text>
          </div>
        </div>
        {
          (props.type !== 'small')
          && size.width >= Breakpoint.xs
          && (
            <div
              style={{
                display: 'flex',
                flexDirection: size.width < 1400 ? 'column' : 'row',
              }}
            >
              <div
                style={{
                  width: size.width < 1400 ? 100 : 150,
                  minWidth: size.width < 1400 ? 100 : 150,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <Text
                  fontSize={size.width < 1400 ? '1.1em' : '1.25em'}
                >
                  {`${props.data.kwh} ${_('units.kwh', { ns: 'chargepoint' })}`}
                </Text>
              </div>
            </div>
          )
        }
        {
          size.width >= Breakpoint.sm && (
            <div
              style={{
                width: props.type === 'small' ? '100%' : 130,
                minWidth: props.type === 'small' ? 'auto' : 130,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
                {
                  props.data.reason_no_settlement
                    ? (
                      <div
                        onMouseEnter={() => {
                          setReasonNoSettlementTooltip(true);
                        }}
                        onMouseLeave={() => {
                          setReasonNoSettlementTooltip(false);
                        }}
                        style={{
                          height: 25,
                          width: 'auto',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          {
                            getReasonNoSettlement() && (
                              <Tooltip
                                text={getReasonNoSettlement()}
                                visible={reasonNoSettlementTooltip}
                                left={reasonNoSettlementWidth}
                                top={props.last ? 0 : undefined}
                              />
                            )
                          }
                          <div
                            ref={reasonNoSettlementRef}
                            style={{
                              width: 'auto',
                            }}
                          >
                            <Text
                              textAlign="right"
                            >
                              {_('free', { ns: 'ui' })}
                            </Text>
                          </div>
                        </div>
                        {
                          getReasonNoSettlement() && (
                          <div
                            style={{
                              height: 30,
                              width: 30,
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                              paddingTop: 1.5,
                            }}
                          >
                            <Icon
                              name="InfoCircle"
                              height={17}
                              width={17}
                              color={Colors.GREY}
                              iconSet="FA"
                            />
                          </div>
                          )
                        }
                      </div>
                    )
                    : (
                      <Text
                        textAlign="right"
                      >
                        {renderedCurrency}
                      </Text>
                    )
                }
            </div>
          )
        }
      </div>
      {
        size.width < Breakpoint.sm && (
          <div
            style={{
              height: 40,
              width: 90,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-end',
            }}
          >
            <Icon
              name="ChevronRight"
              height={25}
              width={25}
              color={Colors.GREY}
              iconSet="Feather"
            />
          </div>
        )
      }

    </button>
  );
}
