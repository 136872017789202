import * as React from 'react';
import {
  Lang,
  Colors,
  Breakpoint,

  useSweetName,
  useDimensions,

  Item,
  Text,
} from '@bluecurrent/web-component-lib';
import { useDispatch } from 'react-redux';
import { SET_PUBLIC_CHARGING } from '../../../../api/types/ApiMessages';

const PublicChargingSetting = (props) => {
  const { _ } = Lang.useTranslation();
  const size = useDimensions();
  const dispatch = useDispatch();
  const sweetName = useSweetName(_);

  const [updatedSettings] = props.useUpdatedSettings;

  const value = updatedSettings.public_charging ?? props.evseSettings.public_charging?.value;
  const permission = props.evseSettings.public_charging?.permission;

  const onSwitch = () => {
    const payload = {
      public_charging: {
        value: !value,
        permission: props.evseSettings.public_charging?.permission,
      },
    };

    props.simpleValueChange(
      SET_PUBLIC_CHARGING,
      'public_charging',
      !value,
      true,
      (success) => {
        if (success) {
          dispatch({
            type: 'UPDATE_SETTINGS',
            id: props.evseSettings.evse_id,
            payload,
          });
        }
      },
    );
  };

  if (permission === 'none') return null;

  return (
    <>
      <div
        className="DisableSelection"
        style={{
          padding: 10,
          height: 'auto',
          minHeight: 55,
          backgroundColor: Colors.LIGHT_GREY,
          border: `solid 2px ${Colors.MEDIUM_WHITE}`,
          borderRadius: 10,
          marginTop: 10,
        }}
      >
        <Item
          type="boolean"
          title={_('settings.publicCharging', { ns: 'chargepoint' })}
          value={!value}
          noPermission={permission !== 'write'}
          onSwitch={onSwitch}
          disabled={props.evseSettings.activity === 'charging'}
          tooltip={{
            error: _('failedToLoadSetting', { ns: 'settings' }),
            permission: size.width < Breakpoint.sm ? false : _('noPermissionToChangeSetting', { ns: 'settings' }),
          }}
          customTooltip={{
            visible: props.evseSettings.activity === 'charging',
            text: _('settingDisabledDuringChargeSession', { ns: 'chargepoint' }),
          }}
        />
      </div>
      {
        props.evseSettings.activity !== 'charging' && (
          <div
            style={{
              marginTop: 5,
              marginBottom: 20,
            }}
          >
            <Text
              fontSize="0.9em"
              color={Colors.DARK_GREY}
            >
              {
                !value
                  ? _('info.publicChargingEnabled', { ns: 'chargepoint', model: sweetName(props.evseSettings.chargepoint_type) })
                  : _('info.publicChargingDisabled', { ns: 'chargepoint', model: sweetName(props.evseSettings.chargepoint_type) })
              }
            </Text>
          </div>
        )
      }
      {
        size.width < Breakpoint.sm && permission !== 'write' && (
          <div
            style={{
              marginTop: props.evseSettings.activity !== 'charging' ? -15 : 5,
              marginBottom: 20,
            }}
          >
            <Text
              fontSize="0.9em"
              color={Colors.DARK_GREY}
            >
              {_('noPermissionToChangeSetting', { ns: 'settings' })}
            </Text>
          </div>
        )
      }
    </>
  );
};

export default PublicChargingSetting;
