import * as React from 'react';

import {
  Lang,
  Colors,

  Input,
  Button,
  Text,
} from '@bluecurrent/web-component-lib';

import SYMBOLS from '../../../Groups/icons';

export default function Information({
  isVisible,
  name,
  setName,
  symbol,
  setSymbol,
}) {
  const { _ } = Lang.useTranslation();

  return isVisible && (
    <div
      style={{
        paddingLeft: 15,
        paddingRight: 15,
        overflowY: 'scroll',
        height: '100%',
        paddingTop: 30,
        display: 'flex',
        flexDirection: 'column',
        gap: 30,
      }}
    >
      <Input
        type="text"
        label={_('name', { ns: 'ui' })}
        name="group-name"
        placeholder={_('garage', { ns: 'ui' })}
        styling
        required
        value={name}
        onChange={(element) => setName(element.target.value)}
      />
      <div>
        <Text
          fontSize="1em"
          fontWeight={500}
        >
          {_('symbol', { ns: 'ui' })}
        </Text>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: 10,
            marginTop: 7.5,
          }}
        >
          {
            SYMBOLS.map((item) => (
              <Button
                type="icon"
                icon={{
                  name: item.name,
                  width: item.width,
                  height: item.height,
                  color: symbol === item.name ? Colors.WHITE : Colors.GREY,
                  iconSet: 'FA',
                }}
                border={symbol === item.name ? `2px solid ${Colors.BLUE}` : undefined}
                onClick={() => setSymbol(item.name)}
                colorScheme={symbol === item.name ? 'blue' : 'white'}
                styling
              />
            ))
          }
        </div>
      </div>
    </div>
  );
}
