import * as React from 'react';
import {
  useNavigate,
} from 'react-router-dom';

import {
  Breakpoint,

  useDimensions,
} from '@bluecurrent/web-component-lib';

/* eslint-disable-next-line import/no-cycle */
import pages from '../config/Pages.config';

import Account from '../components/Sections/Settings/Account';
import Preferences from '../components/Sections/Settings/Preferences';
import Privacy from '../components/Sections/Settings/Privacy';
// import Contract from '../components/Sections/Settings/Contract/index';
import Developers from '../components/Sections/Settings/Developers';

export default function Settings() {
  const size = useDimensions();
  const navigate = useNavigate();

  const [menuWidth, setMenuWidth] = React.useState(0);

  const menuRef = React.useRef(0);

  const getMenuWidth = () => {
    if (menuRef.current.clientWidth !== null) setMenuWidth(menuRef.current.clientWidth);
  };

  React.useEffect(() => {
    getMenuWidth();
  }, [menuWidth]);

  React.useEffect(() => {
    window.addEventListener('resize', getMenuWidth);

    return () => window.removeEventListener('resize', getMenuWidth);
  }, []);

  React.useEffect(() => {
    if (window.location.pathname === pages.find((e) => e.key === 'agreement').path) {
      // <Contract />
      navigate('/');
    }
  }, []);

  return (
    <div
      style={{
        marginTop: 40,
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <div
        style={{
          width: size.width < Breakpoint.sm ? '100%' : '75%',
          height: '100%',
          minHeight: 300,
        }}
      >
        <div
          style={{
            width: size.width < Breakpoint.lg
              ? '100%'
              : '60%',
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          {
            window.location.pathname === pages.find((e) => e.key === 'account').path && (
              <Account />
            )
          }
          {
            window.location.pathname === pages.find((e) => e.key === 'settings').path && (
              <>
                <Preferences />
                <Privacy />
              </>
            )
          }
          {
            window.location.pathname === pages.find((e) => e.key === 'developers').path && (
              <Developers />
            )
          }
        </div>
      </div>
    </div>
  );
}
