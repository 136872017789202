import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  AnimatePresence,
  motion,
} from 'framer-motion';

import {
  Toast,
} from '@bluecurrent/web-component-lib';

export default function Notifications() {
  const notifications = useSelector((state) => state.Notifications.notifications);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const visibleNotifications = notifications.filter((i) => i.visible);

    if (visibleNotifications.length > 0) {
      const firstToExpire = visibleNotifications[0];

      if (visibleNotifications.length >= 5) {
        dispatch({
          type: 'REMOVE_NOTIFICATION',
        });
      }

      const timeout = setTimeout(() => {
        dispatch({
          type: 'REMOVE_NOTIFICATION',
        });
      }, firstToExpire.expire - new Date().getTime());

      return () => clearTimeout(timeout);
    }

    return null;
  }, [notifications]);

  return (
    <div
      style={{
        position: 'fixed',
        right: 0,
        top: -10,
        zIndex: 2147483005,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        paddingTop: 15,
        paddingBottom: 15,
        visibility: notifications.length <= 0 ? 'hidden' : 'visible',
      }}
    >
      {
        notifications.map(({
          id,
          text,
          colorScheme,
          type,
          visible,
        }, index) => (
          <AnimatePresence key={id}>
            {
              visible && (
                <motion.div
                  style={{
                    position: 'relative',
                    maxWidth: 300,
                    width: 300,
                  }}
                  initial={{
                    height: 0,
                    right: -430,
                  }}
                  animate={{
                    height: 'auto',
                    right: 15,
                  }}
                  exit={{
                    height: 0,
                    right: notifications.length - 1 === index ? -300 : 0,
                  }}
                  transition={{
                    duration: 0.240,
                    ease: 'linear',
                  }}
                >
                  <Toast
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={index}
                    text={text}
                    colorScheme={colorScheme ?? 'white'}
                    type={type}
                    visible={visible}
                    onClose={() => dispatch({ type: 'REMOVE_NOTIFICATION' })}
                  />
                </motion.div>
              )
            }
          </AnimatePresence>
        ))
      }
    </div>
  );
}
