import * as React from 'react';
import { useNavigate } from 'react-router-dom';

/* eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved */
import mapboxgl from '!mapbox-gl';

/* eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member */
import useStorage from '../../../../hooks/useStorage';

import 'mapbox-gl/dist/mapbox-gl.css';

import Hidden from '../../../../assets/img/hidden.png';
import UMOVE from '../../../../assets/img/umove.png';
import NanoCharge from '../../../../assets/img/nanocharge.png';

export default function Map(props) {
  const navigate = useNavigate();
  const storage = useStorage();

  const mapContainerRef = React.useRef(null);
  const mapRef = React.useRef();

  const [markers, setMarkers] = React.useState([]);
  const [lng, setLng] = React.useState(5.106);
  const [lat, setLat] = React.useState(52.064);
  const [zoom, setZoom] = React.useState(14);
  const [firstLoad, setFirstLoad] = React.useState(true);

  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

  const icons = {
    HIDDEN: Hidden,
    UMOVE,
    NANO: NanoCharge,
  };
  const MAP_MARKER_TRESHOLD = 11;

  React.useEffect(() => {
    if (storage.getItem('cookies_accepted') === 'true') {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/light-v10',
        center: [lng, lat],
        zoom,
      });

      mapRef.current.addControl(new mapboxgl.NavigationControl());

      mapRef.current.on('move', () => {
        setLng(mapRef.current.getCenter().lng.toFixed(4));
        setLat(mapRef.current.getCenter().lat.toFixed(4));
        setZoom(mapRef.current.getZoom().toFixed(2));
      });
    }

    return () => mapRef.current.remove();
  }, []);

  React.useEffect(() => {
    markers.forEach((marker) => marker.remove());

    let currentZoom = zoom;
    const newMarkers = [];

    const chargePointsWithCoords = props.data
      .filter(({ location: { x_coord: xCoord, y_coord: yCoord } }) => xCoord && yCoord);

    if (firstLoad) {
      setFirstLoad(false);

      if (chargePointsWithCoords.length > 1) {
        mapRef.current.fitBounds(
          chargePointsWithCoords.map(
            ({ location: { x_coord: longitude, y_coord: latitude } }) => [
              latitude,
              longitude,
            ],
          ),
          { animate: false },
        );

        const getZoom = mapRef.current.getZoom();
        currentZoom = getZoom - 1;
        mapRef.current.setZoom(getZoom - 1);
      } else if (chargePointsWithCoords.length === 1) {
        const { x_coord: longitude, y_coord: latitude } = chargePointsWithCoords[0];

        if (latitude && longitude) {
          mapRef.current.setZoom(18);
          currentZoom = 18;
          mapRef.current.setCenter([latitude, longitude]);
        }
      }
    }

    chargePointsWithCoords
      .filter((i) => (
        props.group === null
          ? true
          : props.group?.includes(i.evse_id)
      ))
      .filter(({ activity }) => props.filter.includes(activity) || props.filter.includes('all'))
      .forEach((item) => {
        if (currentZoom >= MAP_MARKER_TRESHOLD) {
          const el = document.createElement('div');
          const width = 80;
          const height = 80;
          el.className = 'marker';
          el.style.backgroundImage = `url(${icons[item.chargepoint_type]})`;
          el.style.width = `${width}px`;
          el.style.height = `${height}px`;
          el.style.backgroundSize = '100%';

          el.addEventListener('click', () => {
            navigate(`/chargepoints/${item.evse_id}`);
          });

          const marker = new mapboxgl.Marker(el)
            .setLngLat(
              [item.location.y_coord, item.location.x_coord],
            )
            .addTo(mapRef.current);

          newMarkers.push(marker);
        } else {
          const marker = new mapboxgl.Marker()
            .setLngLat(
              [item.location.y_coord, item.location.x_coord],
            )
            .addTo(mapRef.current);

          marker.getElement().addEventListener('click', () => {
            mapRef.current.flyTo({
              center: [item.location.y_coord, item.location.x_coord],
              zoom: 18,
            });
          });

          newMarkers.push(marker);
        }
      });

    setZoom(currentZoom);
    setMarkers(newMarkers);
  }, [props.filter, props.group, firstLoad, zoom]);

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <div
        ref={mapContainerRef}
        className="map-container"
        style={{
          height: '75vh',
          borderRadiusBottomLeft: 5,
          borderRadiusBottomRight: 5,
        }}
      />
    </div>
  );
}
