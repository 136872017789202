import {
  Colors,
} from '@bluecurrent/web-component-lib';

export default [
  {
    type: 'list',
    title: ['list', { ns: 'ui' }],
    icon: {
      name: 'List',
      height: 20,
      width: 20,
      color: Colors.GREY,
      iconSet: 'Feather',
    },
  },
  // {
  //   type: 'symbols',
  //   title: ['symbols', { ns: 'ui' }],
  //   icon: {
  //     name: 'Grid',
  //     height: 20,
  //     width: 20,
  //     color: Colors.GREY,
  //     iconSet: 'Feather',
  //   },
  // },
  {
    type: 'map',
    title: ['map', { ns: 'ui' }],
    icon: {
      name: 'Map',
      height: 20,
      width: 20,
      color: Colors.GREY,
      iconSet: 'Feather',
    },
  },
];
