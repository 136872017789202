import useCommands from '../useCommands';
import { ResponseType } from '../../api/SocketMessage';
import addNotification from '../../redux/creators/notifications';

const handleUpdate = (dispatch, _) => {
  const commands = useCommands();

  commands.on(ResponseType.CH_STATUS, (d) => {
    dispatch({
      type: 'SET_CHARGE_POINT_STATUS',
      evseId: d.data.evse_id,
      payload: d,
    });
    dispatch({
      type: 'UPDATE_SETTINGS',
      id: d.data.evse_id,
      payload: {
        notConfirmedSession: null,
      },
    });
  });

  commands.on(ResponseType.ERROR, () => {
    dispatch(addNotification('failed', _('errors.somethingWrong', { ns: 'ui' }), 'red'));
  });
};

export default handleUpdate;
