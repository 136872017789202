import * as React from 'react';

import {
  Colors,
  Breakpoint,
  Lang,

  useDimensions,

  Text,
} from '@bluecurrent/web-component-lib';

import ListLoader from '../../../Modules/ListLoader';
import PlaceholderTransactions from '../../../Modules/Placeholders/Transactions';

import Item from './Item';
import PaginationButton from './Pagination/Button';
import PaginationArrow from './Pagination/Arrow';

export default function List(props) {
  const size = useDimensions();
  const { _ } = Lang.useTranslation();

  const empty = props.data.length < 1;

  return (
    <>
      <div
        style={{
          height: 49,
          width: (size.width < Breakpoint.sm && size.width > Breakpoint.xs) ? 'auto' : props.width,
          backgroundColor: Colors.WHITE,
          position: 'fixed',
          marginTop: size.width < Breakpoint.sm
            ? 55
            : 60,
          borderBottom: `solid 2px ${Colors.MEDIUM_WHITE}`,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          paddingBottom: 5,
          paddingLeft: 5,
          paddingRight: 5,
          zIndex: 1,
        }}
      >
        <div
          style={{
            width: (size.width < Breakpoint.xs)
              ? (props.width - 262)
              : (size.width < Breakpoint.sm)
                ? (props.width - 370)
                : '100%',
            minWidth: 110,
          }}
        >
          <Text
            fontSize="1em"
            fontWeight={500}
            color={Colors.MEDIUM_GREY}
          >
            { `${_('chargePoint', { ns: 'ui' })}:` }
          </Text>
        </div>
        {
          size.width >= Breakpoint.sm && (
            <div
              style={{
                width: '100%',
                minWidth: 130,
              }}
            >
              <Text
                fontSize="1em"
                fontWeight={500}
                color={Colors.MEDIUM_GREY}
              >
                { `${_('chargeCard', { ns: 'ui' })}:` }
              </Text>
            </div>
          )
        }
        <div
          style={{
            width: 180,
            minWidth: 180,
          }}
        >
          <Text
            fontSize="1em"
            fontWeight={500}
            color={Colors.MEDIUM_GREY}
          >
            { `${_('dateTime', { ns: 'dates' })}:` }
          </Text>
        </div>
        {
          size.width >= Breakpoint.xs && (
            <div
              style={{
                width: size.width < 1400 ? 100 : 150,
                minWidth: size.width < 1400 ? 100 : 150,
              }}
            >
              <Text
                fontSize="1em"
                fontWeight={500}
                color={Colors.MEDIUM_GREY}
              >
                { `${_('charged', { ns: 'chargepoint' })}:` }
              </Text>
            </div>
          )
        }
        {
          size.width >= Breakpoint.sm && (
            <div
              style={{
                width: size.width < Breakpoint.sm ? '100%' : 130,
                minWidth: size.width < Breakpoint.sm ? '100%' : 130,
              }}
            >
              <Text
                fontSize="1em"
                fontWeight={500}
                color={Colors.MEDIUM_GREY}
                textAlign="right"
              >
                { `${_('totalCost', { ns: 'ui' })}:` }
              </Text>
            </div>
          )
        }
        {
          size.width >= Breakpoint.xs && size.width < Breakpoint.sm && (
            <div
              style={{
                width: 90,
              }}
            />
          )
        }
      </div>
      <div
        style={{
          width: '100%',
          height: 'auto',
          minHeight: 'calc(100vh - 235px)',
          paddingTop: size.width < Breakpoint.sm
            ? 100
            : 108,
          position: 'relative',
          paddingBottom: size.width < Breakpoint.md
            ? size.width < Breakpoint.sm
              ? 0
              : 78
            : 58,
        }}
      >
        <div
          id="Tour_Step6"
          style={{
            minHeight: 50,
            height: '100%',
          }}
        >
          {
            props.data.map((item, index) => (
              <Item
                /* eslint-disable-next-line react/no-array-index-key */
                key={item.chargepoint_id + index}
                data={item}
                index={index}
              />
            ))
          }
        </div>
        {
          !props.loading && props.data.length < 1 && (
            <div
              id="Tour_Step6"
              style={{
                width: props.width,
                height: '100%',
                minHeight: 50,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <PlaceholderTransactions />
            </div>
          )
        }
        {
          props.loading && (
            <div
              style={{
                width: props.width,
                height: 'calc(100vh - 235px)',
                position: 'fixed',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 0,
                top: '17.5%',
              }}
            >
              <ListLoader />
            </div>
          )
        }
      </div>
      <div
        style={{
          height: 60,
          width: size.width < Breakpoint.sm ? '100%' : props.width,
          marginBottom: size.width < Breakpoint.md
            ? size.width < Breakpoint.sm ? -26 : 30
            : 10,
          marginTop: size.width < Breakpoint.sm ? 30 : 0,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: size.width < Breakpoint.sm ? 'center' : 'flex-start',
          alignItems: 'center',
          backgroundColor: Colors.WHITE,
          position: size.width < Breakpoint.sm ? 'relative' : 'fixed',
          zIndex: 100,
          bottom: size.width < Breakpoint.sm ? 30 : 35,
          borderTop: `solid ${size.width < Breakpoint.sm ? '0px transparent' : `2px ${Colors.MEDIUM_WHITE}`}`,
        }}
      >
        <div
          style={{
            marginRight: 15,
          }}
        >
          <PaginationArrow
            previous
            disabled={
              empty
                ? true
                : props.page === 1
            }
            onClick={() => props.loadTransactions(props.page - 1)}
          />
        </div>
        <PaginationButton
          index={
            empty || props.page === 1
              ? 1
              : props.page - 1
          }
          active={
            empty
              ? true
              : props.page === 1
          }
          onClick={() => props.loadTransactions(props.page === 1 ? 1 : props.page - 1)}
        />
        <PaginationButton
          index={
            empty
              ? 2
              : props.page === 1
                ? props.page + 1
                : props.page
          }
          active={
            empty
              ? false
              : props.page !== 1 && !(!props.nextPage && props.page > 2)
          }
          disabled={
            empty
              ? true
              : !props.nextPage && props.page === 1
          }
          onClick={
            () => props.loadTransactions(props.page === 1 ? props.page + 1 : props.page)
          }
        />
        <PaginationButton
          index={
            props.data.length < 1
              ? 3
              : props.page === 1
                ? props.page + 2
                : props.page + 1
          }
          active={
            empty
              ? false
              : !props.nextPage && props.page > 2
          }
          disabled={
            empty
              ? true
              : (!props.nextPage && props.page < 3)
                || (props.page === 1
                  ? props.page + 2
                  : props.page + 1) > props.totalPages
          }
          onClick={
            () => props.loadTransactions(
              props.page === 1
                ? props.page + 2
                : props.page + 1,
            )
          }
        />
        <div
          style={{
            marginLeft: 15,
          }}
        >
          <PaginationArrow
            next
            disabled={!props.nextPage}
            onClick={() => props.loadTransactions(props.nextPage)}
          />
        </div>
      </div>
    </>
  );
}
