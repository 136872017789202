import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { useDispatch } from 'react-redux';

import {
  Colors,
  Lang,

  Input,
  Text,
  Button,
} from '@bluecurrent/web-component-lib';

import { SET_CAPACITY_TARIFF } from '../../../api/types/ApiMessages';

import executeCommand from '../../../api/executeCommand';

import addNotification from '../../../redux/creators/notifications';

export default function CapacityTariff(props) {
  const { _ } = Lang.useTranslation();
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(
    props.evseSettings.capacity_tariff?.capacitytariffmaxkwh ?? 0.01,
  );
  const [isLoading, setLoading] = React.useState(false);
  const [isInputInError, setInputInError] = React.useState(false);

  const onSave = () => {
    setLoading(true);

    setTimeout(() => {
      if (value >= 0.01 && value <= 80) {
        const payload = {
          capacity_tariff: {
            value: true,
            capacitytariffmaxkwh: value,
            permission: props.evseSettings.capacity_tariff?.permission,
          },
        };

        executeCommand(SET_CAPACITY_TARIFF, {
          body: {
            evse_id: props.evseSettings.evse_id,
            value: true,
            capacitytariffmaxkwh: value,
          },
        })
          .then((success) => {
            setLoading(false);
            if (success) {
              dispatch({
                type: 'UPDATE_SETTINGS',
                id: props.evseSettings.evse_id,
                payload,
              });

              dispatch(addNotification('success', _('capacityTariff.successNotification', { ns: 'chargepoint', value }), 'green'));
              setLoading(false);
              props.onClose();
            }
          })
          .catch((err) => {
            Sentry.captureException(err);
            dispatch(addNotification('failed', _('errors.somethingWrong', { ns: 'ui' }), 'red'));
            setLoading(false);
          });
      } else {
        setInputInError(true);
        setLoading(false);
      }
    }, 1000);
  };

  return (
    <>
      <div
        style={{
          height: '100%',
          width: '100%',
          padding: 15,
        }}
      >
        <Input
          type="number"
          label={_('maxKwh', { ns: 'ui' })}
          value={value}
          onChange={(e) => {
            setInputInError(false);
            setValue(e.target.value);
          }}
          min={1}
          max={1000}
          required
          styling
          error={isInputInError}
          suffix={(
            <Text
              fontSize="1.1em"
              color={Colors.DARK_GREY}
            >
              {_('units.kwh', { ns: 'chargepoint' })}
            </Text>
          )}
        />
        <div
          style={{
            marginTop: 5,
          }}
        >
          <Text
            color={isInputInError ? Colors.RED : Colors.DARK_GREY}
            fontSize="1em"
          >
            {
              _(isInputInError ? 'capacityTariff.minMaxError' : 'capacityTariff.minMaxInfo', { ns: 'chargepoint' })
            }
          </Text>
        </div>
      </div>
      <div
        style={{
          height: 'auto',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          padding: 15,
          borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
        }}
      >
        <div
          style={{
            width: 'auto',
          }}
        >
          <Button
            text={_('back', { ns: 'ui' })}
            colorScheme="white"
            onClick={props.onClose}
          />
        </div>
        <div
          style={{
            width: 'auto',
          }}
        >
          <Button
            text={_('save', { ns: 'ui' })}
            colorScheme="blue"
            onClick={() => onSave()}
            loading={isLoading}
          />
        </div>
      </div>
    </>
  );
}
