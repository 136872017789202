import useCommands from './useCommands';
import { SocketMessage, MessageType, ResponseType } from '../api/SocketMessage';
/* eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member */
import useStorage from './useStorage';

const useLoggedIn = (dispatch) => () => new Promise((resolve, reject) => {
  setTimeout(() => reject(new Error('useLoggedIn timed out')), 10000);

  const storage = useStorage();
  const commands = useCommands();

  const handleError = (data) => {
    if (data.error === 1) { /* Invalid Token */
      storage.clear();
      resolve({ logged_in: false });
    }
  };

  commands.once('ERROR', handleError);

  if (storage.getOriginalState('token') === null) {
    resolve({ logged_in: false });
    return;
  }

  const msg = new SocketMessage(MessageType.GET_CHARGE_POINTS, {});

  commands.send(msg);

  commands.once(ResponseType.CHARGE_POINTS, (d) => {
    if (d.data.length < 1) resolve({ logged_in: false });

    dispatch({
      type: 'SET_CHARGE_POINTS',
      payload: d.data,
    });

    const newMsg = new SocketMessage(MessageType.GET_ACCOUNT, {
      evse_id: d.data[0].evse_id,
    });

    commands.send(newMsg);

    commands.once(ResponseType.ACCOUNT, (e) => {
      dispatch({
        type: 'USER_LOGIN',
        payload: {
          logged_in: true,
          full_name: e.full_name,
          email: e.email,
          tel: e.tel,
          first_login_app: e.first_login_app,
          login: e.login,
          hubspot_user_identity: e.hubspot_user_identity,
          developer_mode_enabled: e.developer_mode_enabled,
          marketing_target: e.marketing_target,
        },
      });

      commands.off('ERROR', handleError);

      resolve({ ...d, ...e, logged_in: true });
    });

    return null;
  });
});

export default useLoggedIn;
