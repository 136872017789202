import * as React from 'react';

import {
  Colors,

  Text,
} from '@bluecurrent/web-component-lib';

export default function HrEnd() {
  return (
    <div
      style={{
        height: 2,
        width: '100%',
        backgroundColor: Colors.MEDIUM_GREY,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <div
        style={{
          width: 30,
          height: 30,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          backgroundColor: Colors.LIGHT_GREY,
          marginTop: -3,
        }}
      >
        <Text
          fontSize={30}
        >
          =
        </Text>
      </div>
    </div>
  );
}
