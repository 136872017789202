import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from './index';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  reducers,
  compose(
    applyMiddleware(sagaMiddleware),
    window.devToolsExtension
      ? window.devToolsExtension()
      : (f) => f,
    /* eslint-disable-next-line no-underscore-dangle */
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? composeWithDevTools()
      : (f) => f,
  ),
);

export default store;
