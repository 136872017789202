import * as React from 'react';

import {
  Lang,
  Colors,
  Breakpoint,

  useDimensions,
  truncate,

  Icon,
  Text,
} from '@bluecurrent/web-component-lib';

export default function Selector(props) {
  const size = useDimensions();

  const { _ } = Lang.useTranslation();

  const [hover, setHover] = React.useState(false);

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <button
        className="Hover"
        type="button"
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        onClick={props.onClick}
        style={{
          width: '100%',
          height: 50,
          border: `solid 2px ${Colors.MEDIUM_WHITE}`,
          borderRadius: 7,
          backgroundColor: hover ? Colors.LIGHT_GREY : Colors.WHITE,
          textAlign: 'left',
          paddingTop: 0,
          paddingBottom: 0,
          paddingRight: 5,
          fontSize: '1em',
          color: Colors.GREY,
          fontFamily: 'Museo Sans',
          fontWeight: 500,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: size.width < Breakpoint.sm ? 'center' : 'flex-start',
            gap: size.width < Breakpoint.sm ? 0 : 5,
          }}
        >
          <div
            style={{
              height: 30,
              width: 30,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Icon
              name="UMOVE"
              height={25}
              width={25}
              color={Colors.GREY}
              iconSet="BlueCurrent"
            />
          </div>
          <Text>
            {
              !props.chargePointFilter
                ? _('allChargePoints', { ns: 'ui' })
                : truncate(
                  props.chargePointFilter.join(', '),
                  size.width < 1200 ? 16 : 24,
                )
            }
          </Text>
        </div>
        <div
          style={{
            height: 30,
            width: 30,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            transform: `rotate(${props.active ? 180 : 0}deg)`,
          }}
        >
          <Icon
            name="AngleDown"
            height={25}
            width={25}
            color={Colors.GREY}
            iconSet="FA"
          />
        </div>
      </button>
    </div>
  );
}
