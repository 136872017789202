import React from 'react';

import {
  Colors,
} from '@bluecurrent/web-component-lib';

export default function WindmillBlades(props) {
  const WindmillBladesRef = React.useRef(null);

  React.useEffect(() => (
    WindmillBladesRef.current.animate([
      { transform: `rotate(${props.start ?? 0}deg)` },
      { transform: `rotate(${props.end ?? -360}deg)` },
    ], {
      duration: 15000,
      iterations: Infinity,
    })
  ), []);

  return (
    <svg
      ref={WindmillBladesRef}
      width={props.width ?? '100%'}
      height={props.height ?? '100%'}
      viewBox="0 0 2500 1840"
      xmlSpace="preserve"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
        marginLeft: props.marginLeft ?? 0,
      }}
    >
      <g>
        <g
          id="Blades"
        >
          <use
            id="Blade"
            x="1194.75"
            y="863.495"
            width="697.19px"
            height="643.499px"
            transform="matrix(0.998839,0,0,0.999223,0,0)"
          />
          <path
            id="Blade1"
            d="M1252.7,945.331c35.369,40.37 60.503,119.861 65.475,136.402c0.579,1.983 1.698,3.765 3.234,5.147c155.314,139.769 320.914,260.973 498.632,361.452c4.338,2.449 9.796,1.549 13.12,-2.163c0,0 0.001,-0 0.001,-0.001c3.944,-4.404 3.611,-11.162 -0.746,-15.158c-0,0 -531.397,-485.611 -557.3,-508.801c-2.142,-1.918 -4.959,-2.907 -7.831,-2.748c-2.871,0.159 -5.562,1.452 -7.48,3.594c-2.291,2.559 -4.807,5.37 -7.042,7.866c-3.665,4.095 -3.692,10.283 -0.063,14.41Z"
            style={{
              fill: Colors.WHITE,
              stroke: Colors.DARK_WHITE,
              strokeWidth: 2,
            }}
          />
          <use
            id="Blade2"
            x="1189.75"
            y="103.997"
            width="303.387px"
            height="873.172px"
            transform="matrix(0.997984,0,0,0.999053,0,0)"
          />
          <path
            id="Blade3"
            d="M1281.55,911.907c16.513,-51.07 71.863,-113.42 83.51,-126.17c1.405,-1.514 2.36,-3.389 2.759,-5.415c40.318,-205.018 59.424,-409.343 54.524,-613.44c-0.123,-4.98 -3.695,-9.204 -8.586,-10.153c-0.001,-0.001 -0.001,-0.001 -0.002,-0.001c-5.804,-1.126 -11.434,2.626 -12.629,8.416c0,-0 -144.302,705.25 -150.923,739.38c-0.547,2.824 0.049,5.749 1.658,8.132c1.609,2.384 4.099,4.03 6.922,4.578c3.372,0.654 7.076,1.373 10.364,2.01c5.396,1.047 10.722,-2.104 12.403,-7.337Z"
            style={{
              fill: Colors.WHITE,
              stroke: Colors.DARK_WHITE,
              strokeWidth: 2,
            }}
          />
          <use
            id="Blade4"
            x="436.976"
            y="821.59"
            width="871.103px"
            height="335.363px"
            transform="matrix(0.998971,0,0,0.998104,0,0)"
          />
          <path
            id="Blade5"
            d="M1235.93,910.681c-53.454,4.836 -132.513,-21.627 -148.812,-27.343c-1.943,-0.699 -4.044,-0.843 -6.064,-0.415c-204.407,43.31 -399.858,105.862 -585.685,190.406c-4.534,2.067 -7.018,7.009 -5.973,11.88c-0.001,0.001 -0.001,0.002 -0,0.002c1.24,5.781 6.899,9.488 12.694,8.318c-0,-0 705.348,-143.824 739.341,-151.118c2.811,-0.603 5.268,-2.299 6.83,-4.714c1.562,-2.414 2.1,-5.351 1.497,-8.163c-0.721,-3.358 -1.512,-7.046 -2.215,-10.322c-1.153,-5.373 -6.141,-9.037 -11.613,-8.531Z"
            style={{
              fill: Colors.WHITE,
              stroke: Colors.DARK_WHITE,
              strokeWidth: 2,
            }}
          />
          <use
            id="Rotor"
            x="1199.57"
            y="868.071"
            width="107.692px"
            height="107.692px"
            transform="matrix(0.997151,0,0,0.997151,0,0)"
          />
          <circle
            id="Rotor1"
            cx="1250"
            cy="919.444"
            r="27.778"
            style={{
              fill: Colors.WHITE,
              stroke: Colors.DARK_WHITE,
              strokeWidth: 2,
            }}
          />
        </g>
      </g>
    </svg>
  );
}
