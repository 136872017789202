import * as React from 'react';
import {
  AnimatePresence,
  motion,
} from 'framer-motion';

import {
  Colors,
  Lang,

  useDimensions,

  Text,
  Button,
} from '@bluecurrent/web-component-lib';

export default function TourHint(props) {
  const size = useDimensions();
  const { _ } = Lang.useTranslation();

  const {
    left,
    right,
    top,
    bottom,
  } = props.spacing;

  const {
    title,
    text,
    image,
  } = props.content;

  const width = size.width < 900 ? 330 : 375;
  const lastActive = props.active + 1 === props.length;

  const onNextHandler = () => {
    if (lastActive) {
      return props.onFinish();
    }
    return props.onNext();
  };

  return (
    <motion.div
      id={`Tour_Hint${props.active}`}
      style={{
        height: 'auto',
        width: props.fullscreen ? '100%' : width,
        maxWidth: props.fullscreen ? '100%' : width,
        left,
        right,
        top,
        bottom,
        position: 'absolute',
        backgroundColor: Colors.BLUE,
        zIndex: 200,
        borderRadius: props.fullscreen ? 0 : 10,
        boxShadow: '0px 0px 7px 0px rgba(0,0,0,0.3)',
        overflow: 'hidden',
        transition: 'all .1s linear',
      }}
      initial={{
        opacity: props.fullscreen ? 1 : 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: props.fullscreen ? 1 : 0,
      }}
      transition={{
        duration: props.fullscreen ? 0 : 0.3,
        delay: props.fullscreen ? 0 : 0.5,
      }}
    >
      {
        image && props.image && (
          <div
            style={{
              height: props.fullscreen ? '70%' : 230,
              width: '100%',
              backgroundColor: Colors.GRADIENT,
            }}
          >
            <div
              style={{
                height: '100%',
                width: '100%',
                backgroundImage: `url('${image}')`,
                backgroundPosition: 'bottom',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
              }}
            />
          </div>
        )
      }
      <div
        style={{
          padding: 15,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: props.fullscreen ? '30%' : 'auto',
        }}
      >
        <AnimatePresence>
          {
            props.visible && (
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
                exit={{
                  opacity: 0,
                }}
              >
                <Text
                  fontWeight={500}
                  color={Colors.WHITE}
                >
                  {Array.isArray(title) ? _(...title) : title}
                </Text>
                <div
                  style={{
                    marginTop: 7.5,
                  }}
                >
                  <Text
                    color={Colors.WHITE}
                    fontWeight={100}
                  >
                    {Array.isArray(text) ? _(...text) : text}
                  </Text>
                </div>
              </motion.div>
            )
          }
        </AnimatePresence>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            width: '100%',
            marginTop: 30,
          }}
        >
          <div>
            <Text
              color={Colors.WHITE}
            >
              {
                `${props.active + 1}/${props.length}`
              }
            </Text>
          </div>
          <div
            style={{
              width: 'auto',
            }}
          >
            <Button
              text={
                lastActive
                  ? _('gotIt', { ns: 'ui' })
                  : _('next', { ns: 'ui' })
              }
              colorScheme="white-alt"
              onClick={onNextHandler}
              color={Colors.BLUE}
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
}
