import * as React from 'react';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import { Lang } from '@bluecurrent/web-component-lib';
import Modal from '../Modal/index';

const ChargepointSettings = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { _ } = Lang.useTranslation();
  const {
    useRebootDialog: [rebootDialog, setRebootDialog],
    setRebootFunc,
  } = useOutletContext();

  const [visible, setVisible] = React.useState(true);

  const onClose = () => {
    setVisible(false);
    setTimeout(() => navigate('../'), 500);
  };

  return (
    <Modal
      type="chargePointSettings"
      serial={id}
      onClose={onClose}
      visible={visible}
      title={_('chargePoint', { ns: 'ui' })}
      subTitle={id}
      useRebootDialog={[rebootDialog, setRebootDialog]}
      setRebootFunc={setRebootFunc}
    />
  );
};

export default ChargepointSettings;
