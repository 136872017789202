import * as React from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Outlet,
} from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import {
  Colors,
  Lang,
  Breakpoint,

  useDimensions,

  Input,
  Button,
  ConfirmDialog,
} from '@bluecurrent/web-component-lib';

import {
  GET_CHARGE_CARDS_PAGED,
} from '../api/types/ApiMessages';
import executeCommand from '../api/executeCommand';

import ChargeCardLayout from '../components/Sections/ChargeCards/Layout';

import RequestChargeCard from '../components/Sections/ChargeCards/RequestChargeCard';

import useCommands from '../hooks/useCommands';

export default function ChargeCards() {
  const size = useDimensions();
  const dispatch = useDispatch();
  const { _ } = Lang.useTranslation();
  const commands = useCommands();

  const user = useSelector((state) => state.User);

  const layoutWrapperRef = React.useRef(0);
  const observer = React.useRef();

  const deleteCardFuncRef = React.useRef();

  const [width, setWidth] = React.useState('100%');
  const [data, setData] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const [mode, setMode] = React.useState('N');
  const [nextPage, setNextPage] = React.useState(1);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);

  const [deleteCardDialog, setDeleteCardDialog] = React.useState(false);
  const [isDropdownVisible, setDropdownVisible] = React.useState(false);

  const handleDuplicates = (array) => array.filter((item, index) => {
    const value = JSON.stringify(item);

    return index === array.findIndex((object) => JSON.stringify(object) === value);
  });

  const loadData = (
    p = nextPage,
    reset = false,
    list = mode,
  ) => {
    setLoading(true);
    executeCommand(GET_CHARGE_CARDS_PAGED, {
      params: {
        page: p,
        max_per_page: 25,
        other: list,
      },
    })
      .then(({ data: response }) => {
        setLoading(false);
        setMode(list);
        setPage(response.current_page);
        setNextPage(response.next_page);
        setTotalPages(response.total_pages);

        if (reset) {
          setData(handleDuplicates(response.cards));
        } else {
          setData(handleDuplicates([...data, ...response.cards]));
        }
      })
      .catch((err) => Sentry.captureException(err));
  };

  React.useEffect(() => {
    loadData();
  }, []);

  const lastElement = React.useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && nextPage) {
          setLoading(true);
          loadData(nextPage);
        }
      });
      if (node) observer.current.observe(node);
    },
    [nextPage, isLoading],
  );

  // Handle layout wrapper width
  const getDimensions = () => {
    if (layoutWrapperRef.current.clientWidth !== null) {
      setWidth(layoutWrapperRef.current.clientWidth);
    }
  };

  React.useEffect(() => {
    getDimensions();
  }, [width]);

  const onChargeCardUpdate = ({ id, name }) => {
    setData((prev) => prev.map((item) => {
      if (item.id !== id) {
        return item;
      }

      return {
        ...item,
        name,
      };
    }));
  };

  const onChargeCardDelete = ({ id }) => {
    setData((prev) => prev.filter((item) => item.id !== id));
  };

  const onChargeCardCreate = ({ id, name, uid }) => {
    setData((prev) => [
      ...prev,
      {
        id,
        uid,
        name,
        valid: 1,
      },
    ]);
  };

  React.useEffect(() => {
    window.addEventListener('resize', getDimensions);
    commands.on('CHARGE_CARD_UPDATE', onChargeCardUpdate);
    commands.on('CHARGE_CARD_DELETE', onChargeCardDelete);
    commands.on('CHARGE_CARD_CREATE', onChargeCardCreate);

    return () => {
      window.removeEventListener('resize', getDimensions);
      commands.off('CHARGE_CARD_UPDATE', onChargeCardUpdate);
      commands.off('CHARGE_CARD_DELETE', onChargeCardDelete);
      commands.off('CHARGE_CARD_CREATE', onChargeCardCreate);
    };
  }, []);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: size.width < 1200 ? 'column' : 'row',
          justifyContent: 'flex-start',
          paddingLeft: 10,
          paddingRight: 10,
          minHeight: '80vh',
          position: 'relative',
          zIndex: 1,
        }}
      >
        <div
          ref={layoutWrapperRef}
          className="FadeIn"
          style={{
            width: (user.marketing_target !== 'bluecurrent') || size.width < 1200
              ? '100%'
              : 'calc(100% - 350px)',
            height: 'auto',
            minHeight: 0,
            position: 'relative',
            backgroundColor: Colors.WHITE,
          }}
        >
          <div
            style={{
              height: size.width < 1200 ? 65 : 85,
              width,
              backgroundColor: Colors.WHITE,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              position: 'fixed',
              zIndex: 2,
              borderBottom: `solid 2px ${Colors.MEDIUM_WHITE}`,
              gap: 5,
            }}
          >
            <div
              style={{
                width: '100%',
                marginTop: 5,
              }}
            >
              <Input
                type="dropdown"
                text={
                  mode === 'N'
                    ? _('myChargeCards', { ns: 'ui' })
                    : _('linkedThirdPartyChargeCards', { ns: 'ui' })
                }
                size="large"
                colorScheme="white"
                onClick={() => {
                  setDropdownVisible(!isDropdownVisible);
                }}
                items={[
                  {
                    id: 0,
                    text: _('myChargeCards', { ns: 'ui' }),
                    onClick: () => {
                      setDropdownVisible(false);
                      loadData(1, 'N');
                      setMode('N');
                    },
                    selected: mode === 'N',
                  },
                  {
                    id: 1,
                    text: _('linkedThirdPartyChargeCards', { ns: 'ui' }),
                    onClick: () => {
                      setDropdownVisible(false);
                      loadData(1, 'Y');
                      setMode('Y');
                    },
                    selected: mode === 'Y',
                  },
                ]}
                fullWidth
                maxWidth={300}
                paddingRight="0"
                fontSize="1.1em"
              />
            </div>
            {
              (size.width >= Breakpoint.xs && mode === 'N') && (
                <div
                  style={{
                    width: 'auto',
                    minWidth: 250,
                  }}
                >
                  <Button
                    text={_('addChargeCard', { ns: 'ui' })}
                    type="button"
                    styling
                    colorScheme="blue"
                    borderRadius={15}
                    onClick={() => dispatch({
                      type: 'OVERLAY',
                      payload: {
                        display: true,
                        layout: 'chargeCardSettings',
                        data: null,
                      },
                    })}
                  />
                </div>
              )
            }
            {
              (size.width < Breakpoint.xs && mode === 'N') && (
                <Button
                  type="icon"
                  styling
                  colorScheme="white"
                  borderRadius={15}
                  icon={{
                    name: 'Plus',
                    width: 20,
                    height: 20,
                    color: Colors.GREY,
                    iconSet: 'FA',
                  }}
                  onClick={() => dispatch({
                    type: 'OVERLAY',
                    payload: {
                      display: true,
                      layout: 'chargeCardSettings',
                      data: null,
                    },
                  })}
                />
              )
            }
          </div>
          <div
            style={{
              marginTop: size.width < 1200 ? 65 : 85,
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              flex: 'auto',
              height: '100%',
            }}
          >
            <ChargeCardLayout
              width={width}
              data={data}
              loading={isLoading}
              lastElement={lastElement}
              mode={mode}
              page={page}
              loadData={loadData}
              nextPage={nextPage}
              totalPages={totalPages}
            />
          </div>
        </div>
        {
          (user.marketing_target === 'bluecurrent')
          && size.width >= 1200
          && (
            <div
              className="FadeIn"
              style={{
                position: 'fixed',
                marginLeft: width,
                marginTop: 25,
                paddingLeft: 15,
                height: 'calc(100vh - 175px)',
                width: 350,
                paddingBottom: 0,
                zIndex: 0,
              }}
            >
              <RequestChargeCard />
            </div>
          )
        }
      </div>
      <ConfirmDialog
        visible={deleteCardDialog}
        title={_('delete', { ns: 'ui' })}
        description={_('deleteCardDialog', { ns: 'chargecard' })}
        accept={{
          text: _('delete', { ns: 'ui' }),
          onClick: () => {
            setDeleteCardDialog(false);
            deleteCardFuncRef.current();
          },
          colorScheme: 'red',
        }}
        cancel={{
          onClick: () => setDeleteCardDialog(false),
        }}
      />
      <Outlet
        context={{
          data,
          useDeleteCardDialog: [deleteCardDialog, setDeleteCardDialog],
          setDeleteCardFunc: (func) => {
            deleteCardFuncRef.current = func;
          },
        }}
      />
    </>
  );
}
