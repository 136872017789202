import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  AnimatePresence,
  motion,
} from 'framer-motion';

import {
  Colors,
  Lang,
  Breakpoint,

  truncate,
  useDimensions,

  Icon,
  Button,
  Text,
} from '@bluecurrent/web-component-lib';

export default function Intro(props) {
  const { _ } = Lang.useTranslation();
  const size = useDimensions();

  const videoWrapperRef = React.useRef();
  const videoRef = React.useRef();

  const user = useSelector((state) => state.User);

  const [hoverSkipButton, setHoverSkipButton] = React.useState(false);
  const [hoverPauseButton, setHoverPauseButton] = React.useState(false);
  const [video, setVideo] = React.useState({
    height: 0,
    width: 0,
  });
  const [preview, setPreview] = React.useState(true);
  const [isPlaying, setIsPlaying] = React.useState(false);

  const getUserName = () => {
    if (user.full_name === null || user.full_name === '') {
      return '';
    }

    return truncate(user.full_name.split(' ')[0], 25);
  };

  const getDimensions = () => {
    if (videoWrapperRef.current?.clientWidth) {
      setVideo({
        width: videoWrapperRef.current.clientWidth,
        height: videoWrapperRef.current.clientHeight,
      });
    }
  };

  React.useEffect(() => {
    getDimensions();
  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', getDimensions);

    return () => {
      window.removeEventListener('resize', getDimensions);
    };
  }, []);

  React.useEffect(() => {
    const onPause = () => setIsPlaying(false);
    const onPlay = () => setIsPlaying(true);

    if (videoRef.current) {
      videoRef.current.addEventListener('pause', onPause);
      videoRef.current.addEventListener('play', onPlay);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('pause', onPause);
        videoRef.current.removeEventListener('play', onPlay);
      }
    };
  }, [videoRef.current]);

  const onPlay = () => {
    if (videoRef.current !== undefined) videoRef.current.play();
    setIsPlaying(true);
  };

  const onPause = () => {
    if (videoRef.current !== undefined) videoRef.current.pause();
    setIsPlaying(false);
  };

  const onReset = () => {
    setPreview(true);
  };

  return (
    <motion.div
      style={{
        height: props.fullscreen ? '100vh' : 'auto',
        width: props.fullscreen ? '100%' : 700,
        backgroundColor: Colors.BLUE,
        borderRadius: props.fullscreen ? 0 : 10,
        overflow: 'hidden',
        position: 'relative',
      }}
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
      transition={{
        duration: 0.5,
      }}
    >
      <div
        ref={videoWrapperRef}
        style={{
          height: props.fullscreen ? '70%' : 'auto',
          width: '100%',
          backgroundColor: Colors.GRADIENT,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          aspectRatio: props.fullscreen ? false : '16 / 9',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <AnimatePresence>
          {
            preview && (
              <motion.button
                type="button"
                className="Hover"
                style={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'transparent',
                  backgroundImage: 'url("https://cdn.sanity.io/images/pnajo2cq/production/2d31eeab419db75a22e45f9386d753ed2faa3b2c-1000x800.jpg?q=100")',
                  backgroundPosition: props.fullscreen ? 'top center' : 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  border: 'solid 0px transparent',
                  padding: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                  zIndex: 2,
                }}
                onClick={() => {
                  setPreview(false);
                  setTimeout(() => {
                    onPlay();
                  }, 100);
                }}
                intial={{
                  opacity: 1,
                }}
                animate={{
                  opacity: 1,
                }}
                exit={{
                  opacity: 0,
                }}
                transition={{
                  duration: 0.3,
                }}
              >
                <Icon
                  name="Play"
                  height={45}
                  width={45}
                  color={Colors.WHITE}
                  iconSet="FA"
                />
              </motion.button>
            )
          }
        </AnimatePresence>
        { /* eslint-disable-next-line jsx-a11y/media-has-caption */ }
        <video
          ref={videoRef}
          preload="metadata"
          height={props.fullscreen && video.height}
          width={video.width + 10}
          onEnded={() => {
            onReset();
          }}
          style={{
            objectFit: 'cover',
          }}
        >
          <source src="https://26281745.fs1.hubspotusercontent-eu1.net/hubfs/26281745/my.bluecurrent.nl/Video%20files/tour_intro.webm" type="video/webm" />
        </video>
        {
          !preview && (
            <button
              type="button"
              className="Hover"
              style={{
                height: '100%',
                width: '100%',
                backgroundColor: 'transparent',
                position: 'absolute',
                border: 'solid 0px transparent',
                padding: 15,
                margin: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: props.fullscreen ? 'flex-start' : 'center',
                justifyContent: props.fullscreen ? 'flex-end' : 'center',
              }}
              onMouseEnter={() => {
                setHoverPauseButton(true);
              }}
              onMouseLeave={() => {
                setHoverPauseButton(false);
              }}
              onClick={() => {
                if (!isPlaying) {
                  onPlay();
                } else onPause();
              }}
            >
              <AnimatePresence>
                {
                  (props.fullscreen || hoverPauseButton) && (
                    <motion.div
                      intial={{
                        opacity: 0,
                      }}
                      animate={{
                        opacity: 1,
                      }}
                      exit={{
                        opacity: 0,
                      }}
                      transition={{
                        duration: 0.2,
                      }}
                    >
                      <Icon
                        name={isPlaying ? 'Pause' : 'Play'}
                        height={props.fullscreen ? 23 : 45}
                        width={props.fullscreen ? 23 : 45}
                        color={Colors.WHITE}
                        iconSet="FA"
                      />
                    </motion.div>
                  )
                }
              </AnimatePresence>
            </button>
          )
        }
      </div>
      <div
        style={{
          padding: 15,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: 'auto',
          minHeight: props.fullscreen ? '30%' : 0,
        }}
      >
        <Text
          fontWeight={500}
          color={Colors.WHITE}
        >
          {`${_('welcome', { ns: 'ui', name: getUserName() })}!`}
        </Text>
        <div
          style={{
            display: 'flex',
            flexDirection: size.width < Breakpoint.xs ? 'column-reverse' : 'row',
            justifyContent: 'space-between',
            alignItems: size.width < Breakpoint.xs ? 'flex-start' : 'flex-end',
            marginTop: 30,
          }}
        >
          <button
            type="button"
            onMouseEnter={() => {
              setHoverSkipButton(true);
            }}
            onMouseLeave={() => {
              setHoverSkipButton(false);
            }}
            className="Hover"
            onClick={props.onSkip}
            style={{
              backgroundColor: 'transparent',
              border: 'solid 0px transparent',
              fontSize: '1em',
              color: hoverSkipButton ? Colors.GRADIENT : Colors.WHITE,
              marginTop: size.width < Breakpoint.xs ? 7.5 : 0,
              marginBottom: size.width < Breakpoint.xs ? 0 : 5,
            }}
          >
            {_('skipTour', { ns: 'ui' })}
          </button>
          <div
            style={{
              width: size.width < Breakpoint.xs ? '100%' : 'auto',
            }}
          >
            <Button
              text={_('next', { ns: 'ui' })}
              onClick={props.onNext}
              colorScheme="white-alt"
              color={Colors.BLUE}
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
}
