import { useNavigate } from 'react-router-dom';
import {
  useSelector,
} from 'react-redux';
import {
  Lang,
} from '@bluecurrent/web-component-lib';

import {
  softReset,
  startSession,
  stopSession,
} from '../../../../hooks/commands/index';

export default function getItemMenu(data) {
  const { _ } = Lang.useTranslation();
  const navigate = useNavigate();

  const permissions = useSelector((state) => state.Permissions);

  return [
    {
      id: 0,
      title: _('settings', { ns: 'settings' }),
      onClick: () => navigate(`/chargepoints/${data.evse_id}`),
    },
    {
      id: 1,
      title: _('linkedChargeCards', { ns: 'ui' }),
      onClick: () => navigate(`/chargepoints/${data.evse_id}/linked-cards`),
    },
    {
      id: 2,
      title: _('transactions', { ns: 'ui' }),
      onClick: () => navigate(`/transactions?chargepoints=${data.evse_id}`),
      permissions: permissions.transactions,
    },
    {
      id: 3,
      hr: true,
    },
    {
      id: 4,
      title: data.activity === 'charging'
        ? _('actions.stopSession', { ns: 'chargepoint' })
        : _('actions.startSession', { ns: 'chargepoint' }),
      disabled:
        data.activity === 'offline'
        || data.activity === 'error'
        || data.activity === 'unavailable',
      onClick: () => {
        if (data.activity === 'charging') {
          stopSession(data.evse_id, _);
        } else {
          startSession(data.evse_id, _, data.vehicle_status);
        }
      },
    },
    {
      id: 5,
      title: _('actions.reset', { ns: 'chargepoint' }),
      disabled: data.activity === 'offline',
      onClick: () => softReset(data.evse_id, _),
    },
  ];
}
