import * as React from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';

import {
  Colors,
  Lang,

  OverlaySearch,
  Button,
  Text,
  Loader,
  Toast,

  useDidMountEffect,
} from '@bluecurrent/web-component-lib';

import {
  GET_BY_POSTAL_CODE,
  GET_DECLARATION_OVERVIEW,
  BASE_URI, GET_DECLARATION_SETTINGS, EDIT_DECLARATION_SETTINGS, GET_CARDS_DECLARATION_OVERVIEW,
} from '../../../../api/types/ApiMessages';
import executeCommand from '../../../../api/executeCommand';

import useTextSearch from '../../../../hooks/useTextSearch';

import Information from './Information';
import Period from './Period';

/* eslint-disable-next-line import/no-named-as-default-member, import/no-named-as-default */
import useStorage from '../../../../hooks/useStorage';
import PlaceholderChargeCards from '../../../Modules/Placeholders/ChargeCards';
import addNotification from '../../../../redux/creators/notifications';

export default function DeclarationForm(props) {
  const { _ } = Lang.useTranslation();
  const storage = useStorage();
  const textSearch = useTextSearch();
  const dispatch = useDispatch();

  const chargePoints = useSelector((state) => state.ChargePoints);
  const chargeCards = useSelector((state) => state.ChargeCards.chargeCards);

  const [page, setPage] = React.useState(1);
  const [fullLoader, setFullLoader] = React.useState(false);

  const [editAddress, setEditAddress] = React.useState(false);
  const [addressLoading, setAddressLoading] = React.useState(false);

  const [company, setCompany] = React.useState('');
  const [street, setStreet] = React.useState('');
  const [houseNumber, setHouseNumber] = React.useState('');
  const [houseNumberExtension, setHouseNumberExtension] = React.useState('');
  const [place, setPlace] = React.useState('');
  const [zipCode, setZipCode] = React.useState('');
  const [country, setCountry] = React.useState('');
  const [reference, setReference] = React.useState('');
  const [rate, setRate] = React.useState('');

  const [declarationPeriod, setDeclarationPeriod] = React.useState('M');
  const [month, setMonth] = React.useState(null);
  const [quarter, setQuarter] = React.useState(null);
  const [year, setYear] = React.useState(null);

  const [selectedChargePoints, setSelectedChargePoints] = React.useState([]);
  const [selectedChargeCards, setSelectedChargeCards] = React.useState([]);

  const [chargePointSearch, setChargePointSearch] = React.useState('');
  const [chargeCardSearch, setChargeCardSearch] = React.useState('');

  const [changeRateTimeout, setChangeRateTimeout] = React.useState(null);
  const [postalCodeTimeout, setPostalCodeTimeout] = React.useState(null);

  const [loading, setLoading] = React.useState(false);

  const [chargeCardsLoading, setChargeCardsLoading] = React.useState(false);
  const [declarationCards, setDeclarationCards] = React.useState([]);

  React.useEffect(() => {
    if (page === 4) {
      setChargeCardsLoading(true);
      executeCommand(GET_CARDS_DECLARATION_OVERVIEW, {
        params: {
          period: declarationPeriod,
          month: month ? month.getMonth() + 1 < 10 ? `0${month.getMonth() + 1}` : month.getMonth() + 1 : null,
          year: declarationPeriod === 'M' ? month.getFullYear() : year.getFullYear(),
          quarter,
          chargepoints: selectedChargePoints.length === 0 ? null : selectedChargePoints.join(';'),
        },
      })
        .then((data) => {
          setDeclarationCards(data.data.cards);
        })
        .catch((err) => {
          Sentry.captureException(err);
          dispatch(addNotification('failed', _('error.somethingWrong', { ns: 'chargepoint' }), 'red'));
        })
        .finally(() => setChargeCardsLoading(false));
    }
  }, [page]);

  React.useEffect(() => {
    setFullLoader(true);
    executeCommand(GET_DECLARATION_SETTINGS)
      .then(({ data }) => {
        setPlace(data.declaration_settings.city);
        setCompany(data.declaration_settings.company);
        setHouseNumber(data.declaration_settings.housenumber);
        setHouseNumberExtension(data.declaration_settings.housenumber_extension);
        setRate(data.declaration_settings.rate);
        setReference(data.declaration_settings.reference);
        setStreet(data.declaration_settings.street);
        setZipCode(data.declaration_settings.zipcode);
        setCountry(data.declaration_settings.country_code);
        setFullLoader(false);
      })
      .catch(() => {});
  }, []);

  const nextFromFirstPage = () => {
    setPage((prev) => prev + 1);

    executeCommand(EDIT_DECLARATION_SETTINGS, {
      body: {
        company,
        street,
        housenumber: houseNumber,
        zipcode: zipCode,
        city: place,
        reference,
        rate,
        country_code: country,
        housenumber_extension: houseNumberExtension,
      },
    })
      .then(() => {})
      .catch(() => {});
  };

  useDidMountEffect(() => {
    if (postalCodeTimeout) clearTimeout(postalCodeTimeout);

    setPostalCodeTimeout(setTimeout(() => {
      if (zipCode && houseNumber && !fullLoader) {
        if (!fullLoader) {
          setAddressLoading(true);
        }

        const rege = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
        if (rege.test(zipCode)) {
          executeCommand(GET_BY_POSTAL_CODE, {
            params: {
              zipcode: zipCode,
              housenumber: houseNumber,
            },
          })
            .then(({ data }) => {
              setAddressLoading(false);
              if (data.success) {
                setStreet(data.address_based_on_zip.street);
                setPlace(data.address_based_on_zip.city);
                setHouseNumber(data.address_based_on_zip.housenumber);
                setZipCode(data.address_based_on_zip.zipcode);
                setCountry('NL');
              } else {
                setEditAddress(true);
                setStreet('');
                setPlace('');
                setCountry('NL');
              }
            });
        } else {
          setAddressLoading(false);
          setEditAddress(true);
        }
      }
    }, 1000));
  }, [zipCode, houseNumber]);

  // const dataChargePoints = chargePoints.map((i) => ({
  //   name: i.name,
  //   serial: i.evse_id,
  //   type: 'chargepoint',
  //   selected: selectedChargePoints.includes(i.evse_id),
  //   onClick: () => (selectedChargePoints.includes(i.evse_id)
  //     ? setSelectedChargePoints((prev) => prev.filter((p) => i.evse_id !== p))
  //     : setSelectedChargePoints((prev) => [...prev, i.evse_id])),
  //   icon: {
  //     name: 'UMOVE',
  //     height: 50,
  //     width: 50,
  //     color: Colors.GREY,
  //     iconSet: 'BlueCurrent',
  //   },
  // }));

  const dataChargeCards = chargeCards.map((i) => ({
    name: i.name,
    serial: i.id,
    type: 'chargecard',
    selected: selectedChargeCards.includes(i.uid),
    onClick: () => (selectedChargeCards.includes(i.uid)
      ? setSelectedChargeCards((prev) => prev.filter((p) => i.uid !== p))
      : setSelectedChargeCards((prev) => [...prev, i.uid])),
    icon: {
      name: 'UMOVE',
      height: 50,
      width: 50,
      color: Colors.GREY,
      iconSet: 'BlueCurrent',
    },
  }));

  dataChargeCards.unshift({
    name: 'No settlement',
    serial: 'BCU-APP',
    type: 'chargecard',
    selected: selectedChargeCards.includes('BCU-APP'),
    onClick: () => (selectedChargeCards.includes('BCU-APP')
      ? setSelectedChargeCards((prev) => prev.filter((p) => p !== 'BCU-APP'))
      : setSelectedChargeCards((prev) => [...prev, 'BCU-APP'])),
    icon: {
      name: 'UMOVE',
      height: 50,
      width: 50,
      color: Colors.GREY,
      iconSet: 'BlueCurrent',
    },
  });

  const onChangeRate = (value) => {
    if (changeRateTimeout) clearTimeout(changeRateTimeout);

    setChangeRateTimeout(setTimeout(() => {
      if (!Number.isNaN(Number.parseFloat(value)) && value !== '') setRate(Number.parseFloat(value).toFixed(2));
    }, 1000));
  };

  const download = () => {
    if (loading) return;

    setLoading(true);
    axios.get(BASE_URI + GET_DECLARATION_OVERVIEW.url, {
      responseType: 'blob',
      params: {
        company,
        street,
        housenumber: houseNumber,
        housenumber_extension: houseNumberExtension,
        zipcode: zipCode,
        city: place,
        reference,
        rate,
        period: declarationPeriod,
        month: month ? month.getMonth() + 1 < 10 ? `0${month.getMonth() + 1}` : month.getMonth() + 1 : null,
        year: declarationPeriod === 'M' ? month.getFullYear() : year.getFullYear(),
        quarter,
        chargepoints: selectedChargePoints.length === 0 ? null : selectedChargePoints.join(';'),
        chargecards: setSelectedChargeCards.length === 0 ? null : selectedChargeCards.join(';'),
      },
      headers: {
        Authorization: `Token ${storage.getOriginalState('token')}`,
      },
    })
      .then((response) => {
        const fileUrl = URL.createObjectURL(response.data);

        // create <a> tag dinamically
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;

        // it forces the name of the downloaded file
        fileLink.download = 'Declaratieoverzicht.pdf';

        // triggers the click event
        fileLink.click();

        props.onClose();
      });
  };

  const resetDateValues = () => {
    setYear(null);
    setMonth(null);
    setQuarter(null);
  };

  if (fullLoader) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Loader />
      </div>
    );
  }

  const filteredChargePoints = chargePoints
    .filter(textSearch(chargePointSearch, ['evse_id', 'name', 'model_type', 'chargepoint_type']));
  const filteredChargecards = declarationCards
    .filter(textSearch(chargeCardSearch, ['uid', 'id', 'name']));

  const onClickChargePoint = (id) => {
    setSelectedChargePoints((prev) => {
      if (prev.includes(id)) {
        return prev.filter((i) => i !== id);
      }
      return [...prev, id];
    });
  };

  const onClickChargeCard = (uid) => {
    setSelectedChargeCards((prev) => {
      if (prev.includes(uid)) {
        return prev.filter((i) => i !== uid);
      }
      return [...prev, uid];
    });
  };

  const checkName = (i) => {
    switch (i.uid) {
      case 'BCU-APP':
        return _('noChargeCard', { ns: 'ui' });
      case 'BCU_HOME_USE':
        return _('noPlugChargeChargeCard', { ns: 'ui' });
      default:
        return i.name || i.id;
    }
  };

  const getFieldsToFill = () => {
    let count = 0;
    let total = 3;

    if (rate !== '') count += 1;
    if (zipCode) count += 1;
    if (houseNumber) count += 1;

    if (editAddress) {
      total = 6;

      if (street) count += 1;
      if (place) count += 1;
      if (country) count += 1;
    }

    return total - count;
  };

  const fieldsToFill = getFieldsToFill();

  return (
    <>
      {
        page === 1 && (
          <div
            style={{
              paddingTop: (page === 1) && (fieldsToFill > 0)
                ? 0
                : 30,
              paddingLeft: 15,
              paddingRight: 15,
              overflowY: 'scroll',
              height: '100%',
            }}
          >
            {
              (page === 1)
              && (fieldsToFill > 0)
              && (
                <div
                  style={{
                    marginBottom: 15,
                  }}
                >
                  <Toast
                    text="Postcode niet gevonden"
                    colorScheme="red"
                    visible
                  />
                </div>
              )
            }
            <Information
              editAddress={editAddress}
              setEditAddress={setEditAddress}
              company={company}
              onChangeCompany={(e) => setCompany(e.target.value)}
              street={street}
              onChangeStreet={(e) => setStreet(e.target.value)}
              houseNumber={houseNumber}
              onChangeHouseNumber={(e) => setHouseNumber(e.target.value)}
              houseNumberExtension={houseNumberExtension}
              onChangeHouseNumberExtension={(e) => setHouseNumberExtension(e.target.value)}
              place={place}
              setPlace={(e) => setPlace(e.target.value)}
              zipCode={zipCode}
              onChangeZipCode={(e) => setZipCode(e.target.value)}
              country={country}
              onChangeCountry={(e) => setCountry(e.target.value)}
              reference={reference}
              onChangeReference={(e) => setReference(e.target.value)}
              rate={rate}
              addressLoading={addressLoading}
              onChangeRate={
                (e) => {
                  // Check if rate contains ',' or '.'
                  const checkRate = /^[0-9,.]*$/g.test(e.target.value);

                  if (checkRate) {
                    setRate(e.target.value.replace(',', '.'));
                    onChangeRate(e.target.value.replace(',', '.'));
                  }
                }
              }
            />
          </div>
        )
      }
      {
        page === 2 && (
          <Period
            declarationPeriod={declarationPeriod}
            onClickMonthly={() => {
              setDeclarationPeriod('M');
              resetDateValues();
            }}
            onClickQuarterly={() => {
              setDeclarationPeriod('Q');
              resetDateValues();
            }}
            onClickYearly={() => {
              setDeclarationPeriod('Y');
              resetDateValues();
            }}
            onChangeMonth={(e) => setMonth(e)}
            quarter={quarter}
            month={month}
            year={year}
            onClickQuarterQ1={() => setQuarter('Q1')}
            onClickQuarterQ2={() => setQuarter('Q2')}
            onClickQuarterQ3={() => setQuarter('Q3')}
            onClickQuarterQ4={() => setQuarter('Q4')}
            onChangeYear={(e) => setYear(e)}
          />
        )
      }
      {
        page === 3 && (
          <OverlaySearch
            onSearch={(e) => setChargePointSearch(e.target.value)}
            data={
              filteredChargePoints
                .map((i) => ({
                  id: i.evse_id,
                  type: 'chargepoint',
                  name: i.name || i.evse_id,
                  serial: i.name && i.evse_id,
                  onClick: () => onClickChargePoint(i.evse_id),
                  selected: selectedChargePoints.includes(i.evse_id),
                }))
            }
            selectAll={
              chargePoints.length !== selectedChargePoints.length
                ? () => setSelectedChargePoints(chargePoints.map((i) => i.evse_id))
                : false
            }
            deselectAll={
              chargePoints.length === selectedChargePoints.length
                ? () => setSelectedChargePoints([])
                : false
            }
          />
        )
      }
      {
        page === 4 && (
          <OverlaySearch
            loading={chargeCardsLoading}
            empty={{
              condition: !chargeCardsLoading && declarationCards.length < 1,
              component: <PlaceholderChargeCards
                text={_('noChargeCardsFoundInSelection', { ns: 'ui' })}
                button={{
                  text: _('backToDateSelection', { ns: 'ui' }),
                  action: () => setPage(2),
                }}
              />,
            }}
            onSearch={(e) => setChargeCardSearch(e.target.value)}
            data={
              filteredChargecards
                .map((i) => ({
                  id: i.uid + i.id,
                  name: checkName(i),
                  serial: i.name && i.id,
                  type: i.uid === 'BCU-APP' || i.uid === 'BCU_HOME_USE' ? 'chargepoint' : 'chargecard',
                  onClick: () => onClickChargeCard(i.uid),
                  selected: selectedChargeCards.includes(i.uid),
                }))
            }
            selectAll={
              filteredChargecards.length + 1 !== selectedChargeCards.length
                ? () => setSelectedChargeCards([...filteredChargecards.map((i) => i.uid), 'BCU-APP'])
                : false
            }
            deselectAll={
              filteredChargecards.length + 1 === selectedChargeCards.length
                ? () => setSelectedChargeCards([])
                : false
            }
          />
        )
      }
      <div
        style={{
          height: 'auto',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: page === 1 && fieldsToFill === 0 ? 'flex-end' : 'space-between',
          padding: 15,
          borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
        }}
      >
        {
          page > 1 && (
            <div
              style={{
                width: 'auto',
              }}
            >
              <Button
                text={_('back', { ns: 'ui' })}
                colorScheme="white"
                onClick={() => setPage(page - 1)}
              />
            </div>
          )
        }
        {
          (page === 1 && fieldsToFill > 0) && (
            <div
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Text>
                {
                  fieldsToFill > 1
                    ? _('requiredAmountFieldsEmpty', { ns: 'ui', amount: fieldsToFill })
                    : _('requiredFieldEmpty', { ns: 'ui' })
                }
              </Text>
            </div>
          )
        }
        <div
          style={{
            width: 'auto',
          }}
        >
          {
            page === 4 ? (
              <Button
                text={_('download', { ns: 'ui' })}
                disabled={selectedChargeCards.length === 0}
                colorScheme={selectedChargeCards.length === 0 ? 'grey' : 'blue'}
                loading={loading}
                onClick={download}
              />
            ) : page === 1 ? (
              <Button
                text={_('continue', { ns: 'ui' })}
                colorScheme={
                    street === ''
                    || houseNumber === ''
                    || place === ''
                    || zipCode === ''
                    || country === ''
                    || rate === ''
                    || addressLoading
                      ? 'grey'
                      : 'blue'
                  }
                disabled={
                    street === ''
                    || houseNumber === ''
                    || place === ''
                    || zipCode === ''
                    || country === ''
                    || rate === ''
                    || addressLoading
                  }
                onClick={nextFromFirstPage}
              />
            )
              : page === 2 ? (
                <Button
                  text={_('continue', { ns: 'ui' })}
                  colorScheme={
                      (
                        declarationPeriod === 'M'
                          ? !month
                          : declarationPeriod === 'Q'
                            ? !year || !quarter
                            : declarationPeriod === 'Y'
                              ? !year
                              : true
                      ) ? 'grey' : 'blue'
                    }
                  disabled={
                      declarationPeriod === 'M'
                        ? !month
                        : declarationPeriod === 'Q'
                          ? !year || !quarter
                          : declarationPeriod === 'Y'
                            ? !year
                            : true
                    }
                  onClick={() => setPage(page + 1)}
                />
              ) : (
                <Button
                  text={_('continue', { ns: 'ui' })}
                  colorScheme={selectedChargePoints.length === 0 ? 'grey' : 'blue'}
                  disabled={selectedChargePoints.length === 0}
                  onClick={() => setPage(page + 1)}
                />
              )
          }
        </div>
      </div>
    </>
  );
}
