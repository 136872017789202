import * as React from 'react';

export default function Cards() {
  return (
    <svg
      viewBox="0 0 200 150"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeMiterlimit: 1.5,
        height: '100%',
        width: '100%',
      }}
    >
      <path
        d="M181.192 31.717c0-1.916-1.055-3.473-2.356-3.473H20.018c-1.301 0-2.356 1.557-2.356 3.473v27.194c0 1.917 1.055 3.473 2.356 3.473h158.818c1.301 0 2.356-1.556 2.356-3.473V31.717Z"
        style={{
          fill: '#fff',
          stroke: '#e6e6e6',
          strokeWidth: '1.13px',
        }}
        transform="matrix(1.03326 0 0 .70096 9.834 4.018)"
      />
      <path
        d="M117.002 19.832c0-.457-.521-.829-1.164-.829H44.653c-.643 0-1.164.372-1.164.829v6.608c0 .458.521.829 1.164.829h71.185c.643 0 1.164-.371 1.164-.829v-6.608Z"
        style={{
          fill: '#e6e6e6',
        }}
        transform="matrix(.66926 0 0 .93983 25.978 8.654)"
      />
      <path
        d="M117.002 19.833c0-.458-.601-.83-1.342-.83H44.831c-.741 0-1.342.372-1.342.83v6.606c0 .458.601.83 1.342.83h70.829c.741 0 1.342-.372 1.342-.83v-6.606Z"
        style={{
          fill: '#f3f3f3',
        }}
        transform="matrix(.58146 0 0 .93983 29.796 19.422)"
      />
      <path
        d="M38.673 20.455c0-.829-.776-1.502-1.731-1.502H23.018c-.956 0-1.731.673-1.731 1.502v12.08c0 .829.775 1.502 1.731 1.502h13.924c.955 0 1.731-.673 1.731-1.502v-12.08Z"
        style={{
          fill: '#e6e6e6',
        }}
        transform="matrix(.86276 0 0 .99443 15.718 9.435)"
      />
      <path
        d="M181.192 31.717c0-1.916-1.055-3.473-2.356-3.473H20.018c-1.301 0-2.356 1.557-2.356 3.473v27.194c0 1.917 1.055 3.473 2.356 3.473h158.818c1.301 0 2.356-1.556 2.356-3.473V31.717Z"
        style={{
          fill: '#fff',
          stroke: '#e6e6e6',
          strokeWidth: '1.13px',
        }}
        transform="matrix(1.03326 0 0 .70096 1.549 83.035)"
      />
      <path
        d="M117.002 19.832c0-.457-.349-.829-.779-.829H44.268c-.43 0-.779.372-.779.829v6.608c0 .458.349.829.779.829h71.955c.43 0 .779-.371.779-.829v-6.608Z"
        style={{
          fill: '#e6e6e6',
        }}
        transform="matrix(1 0 0 .93983 3.309 87.67)"
      />
      <path
        d="M117.002 19.833c0-.458-.431-.83-.961-.83H44.45c-.53 0-.961.372-.961.83v6.606c0 .458.431.83.961.83h71.591c.53 0 .961-.372.961-.83v-6.606Z"
        style={{
          fill: '#f3f3f3',
        }}
        transform="matrix(.8115 0 0 .93983 11.506 98.439)"
      />
      <path
        d="M38.673 20.455c0-.829-.776-1.502-1.731-1.502H23.018c-.956 0-1.731.673-1.731 1.502v12.08c0 .829.775 1.502 1.731 1.502h13.924c.955 0 1.731-.673 1.731-1.502v-12.08Z"
        style={{
          fill: '#e6e6e6',
        }}
        transform="matrix(.86276 0 0 .99443 7.432 88.452)"
      />
      <path
        d="M181.192 31.717c0-1.916-1.055-3.473-2.355-3.473H20.017c-1.3 0-2.355 1.557-2.355 3.473v27.194c0 1.917 1.055 3.473 2.355 3.473h158.82c1.3 0 2.355-1.556 2.355-3.473V31.717Z"
        style={{
          fill: '#fff',
          stroke: '#e6e6e6',
          strokeWidth: '1.13px',
        }}
        transform="matrix(1.03345 0 0 .70096 -9.976 43.237)"
      />
      <path
        d="M117.002 19.832c0-.457-.531-.829-1.186-.829H44.675c-.655 0-1.186.372-1.186.829v6.608c0 .458.531.829 1.186.829h71.141c.655 0 1.186-.371 1.186-.829v-6.608Z"
        style={{
          fill: '#e6e6e6',
        }}
        transform="matrix(.65673 0 0 .93983 6.716 47.872)"
      />
      <path
        d="M117.002 19.833a.843.843 0 0 0-.855-.83H44.344a.843.843 0 0 0-.855.83v6.606c0 .458.383.83.855.83h71.803a.843.843 0 0 0 .855-.83v-6.606Z"
        style={{
          fill: '#f3f3f3',
        }}
        transform="matrix(.91217 0 0 .93983 -4.393 58.64)"
      />
      <path
        d="M38.673 20.455c0-.829-.776-1.502-1.731-1.502H23.018c-.956 0-1.731.673-1.731 1.502v12.08c0 .829.775 1.502 1.731 1.502h13.924c.955 0 1.731-.673 1.731-1.502v-12.08Z"
        style={{
          fill: '#e6e6e6',
        }}
        transform="matrix(.86276 0 0 .99443 -4.09 48.653)"
      />
    </svg>
  );
}
