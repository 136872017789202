import {
  OVERLAY,
  USER_LOGOUT,
} from '../actionTypes';

const initialState = {
  display: false,
  layout: false,
  data: [],
  title: false,
  subTitle: false,
  search: '',
};

// eslint-disable-next-line default-param-last
const update = (state = initialState, action) => {
  switch (action.type) {
    case OVERLAY:
      return {
        display: false,
        layout: false,
        ...state,
        ...action.payload,
      };

    case USER_LOGOUT:
      return initialState;

    default: return state;
  }
};

export default update;
