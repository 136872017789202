import MessageType from './types/MessageType';
import ResponseType from './types/ResponseType';

import useStorage from '../hooks/useStorage';

const storage = useStorage();

class SocketMessage {
  constructor(command, data = {}, object = false) {
    if (object) {
      this.object = command;
    } else {
      this.command = command;
    }

    if (storage.getOriginalState('token') && !object) this.Authorization = `Token ${storage.getOriginalState('token')}`;

    Object.keys(data).forEach((key) => {
      this[key] = data[key];
    });
  }
}

export {
  SocketMessage,
  MessageType,
  ResponseType,
};
