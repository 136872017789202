import React from 'react';
import {
  Lang,
  Colors,

  Text,
  Input,
} from '@bluecurrent/web-component-lib';

export default function SelectionItem(props) {
  const { _ } = Lang.useTranslation();

  return (
    <button
      type="button"
      className="Hover"
      onClick={() => {
        if (!props.isSelected && !props.isReadOnly) props.onClick();
      }}
      style={{
        backgroundColor: !props.isReadOnly && props.isSelected
          ? Colors.LIGHT_BLUE
          : Colors.LIGHT_GREY,
        border: `solid 2px ${!props.isReadOnly && props.isSelected ? Colors.BLUE : Colors.MEDIUM_WHITE}`,
        borderRadius: 10,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 10,
        }}
      >
        <Input
          type="radio"
          selected={props.isSelected}
          isReadOnly={props.isReadOnly}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
            width: '100%',
          }}
        >
          <Text
            fontSize="1.4em"
            fontWeight={300}
            color={
              props.isReadOnly
                ? Colors.MEDIUM_GREY
                : props.isSelected ? Colors.BLUE : Colors.GREY
            }
          >
            {props.title}
          </Text>
          <div
            style={{
              width: '80%',
            }}
          >
            <Text
              fontSize="1.2em"
              fontWeight={300}
              color={
              props.isReadOnly
                ? Colors.MEDIUM_GREY
                : props.isSelected ? Colors.BLUE : Colors.DARK_GREY
            }
            >
              {props.subTitle}
            </Text>
          </div>
        </div>
      </div>
      {
        (!props.isReadOnly && props.isSelected) && (
          <Text
            color={Colors.BLUE}
            textAlign="right"
          >
            {_('selected', { ns: 'ui' })}
          </Text>
        )
      }
      {
        props.isReadOnly && (
          <Text
            color={Colors.MEDIUM_GREY}
            textAlign="right"
          >
            {_('disabled', { ns: 'ui' })}
          </Text>
        )
      }
    </button>
  );
}
