import { useNavigate } from 'react-router-dom';
import {
  Lang,
} from '@bluecurrent/web-component-lib';

export default function getItemMenu(data, mode) {
  const { _ } = Lang.useTranslation();
  const navigate = useNavigate();

  return [
    {
      id: 0,
      title: _('settings', { ns: 'settings' }),
      onClick: () => navigate(`/chargecards/${data.id}`),
      disabled: mode === 'Y',
    },
    {
      id: 1,
      title: _('linkedChargePoints', { ns: 'ui' }),
      onClick: () => navigate(`/chargecards/${data.id}/linked-chargepoints`),
    },
    {
      id: 2,
      title: _('excludedOnChargePoints', { ns: 'chargecard' }),
      onClick: () => navigate(`/chargecards/${data.id}/excluded-on`),
    },
  ];
}
