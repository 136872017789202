import { lazy } from 'react';

import ChargepointSettings from '../components/Sections/Outlet/ChargepointSettings';
import LinkedChargeCards from '../components/Sections/Outlet/LinkedChargeCards';
import ChargeCardSettings from '../components/Sections/Outlet/ChargeCardSettings';
import LinkedChargePoints from '../components/Sections/Outlet/LinkedChargePoints';
import DeclarationOverview from '../components/Sections/Outlet/DeclarationOverview';
import Transaction from '../components/Sections/Outlet/Transaction';
import ExcludedOnChargePoints from '../components/Sections/Outlet/ExcludedOnChargePoints';

import ParkingPlace from '../components/Sections/Parking';

import Dashboard from '../pages/dashboard';
import ChargePoints from '../pages/chargepoints';
import Transactions from '../pages/transactions';
import ChargeCards from '../pages/chargecards';

/* eslint-disable-next-line import/no-cycle */
import Settings from '../pages/settings';

const Login = lazy(() => import('../pages/login'));
const ForgotPassword = lazy(() => import('../pages/forgotPassword'));
const FirstTime = lazy(() => import('../pages/firstTime'));
/* eslint-disable-next-line import/no-cycle */
const Parking = lazy(() => import('../pages/parking'));

export default [
  {
    key: 'login',
    name: '',
    path: '/login',
    component: Login,
    authenticated: false,
    showInSidebar: false,
    showInOverlay: false,
    icon: null,
    colorScheme: 'blue',
    footer: false,
  },
  {
    key: 'forgotPassword',
    name: '',
    path: '/forgot-password',
    component: ForgotPassword,
    authenticated: false,
    showInSidebar: false,
    showInOverlay: false,
    icon: null,
    colorScheme: 'blue',
    footer: false,
  },
  {
    key: 'firstTime',
    name: '',
    path: '/first-time',
    component: FirstTime,
    authenticated: false,
    showInSidebar: false,
    showInOverlay: false,
    icon: null,
    colorScheme: 'blue',
    footer: false,
  },
  {
    key: 'dashboard',
    name: ['dashboard', { ns: 'ui' }],
    path: '/',
    component: Dashboard,
    authenticated: true,
    showInSidebar: true,
    showInOverlay: true,
    icon: {
      name: 'House',
      height: 35,
      width: 35,
      iconSet: 'FA',
    },
    colorScheme: 'purple',
    footer: 'relative',
  },
  {
    key: 'settings',
    name: ['settings', { ns: 'settings' }],
    path: '/settings',
    component: Settings,
    authenticated: true,
    showInSidebar: false,
    showInOverlay: true,
    icon: {
      name: 'Cog',
      height: 35,
      width: 35,
      iconSet: 'FA',
    },
    colorScheme: 'orange',
    footer: 'fixed',
  },
  {
    key: 'agreement',
    name: ['agreement', { ns: 'settings' }],
    path: '/agreement',
    component: Settings,
    authenticated: true,
    showInSidebar: false,
    showInOverlay: true,
    icon: {
      name: 'Scroll',
      height: 35,
      width: 35,
      iconSet: 'FA',
    },
    colorScheme: 'orange',
    footer: 'fixed',
  },
  {
    key: 'account',
    name: ['account', { ns: 'authentication' }],
    path: '/account',
    component: Settings,
    authenticated: true,
    showInSidebar: false,
    showInOverlay: true,
    icon: {
      name: 'User',
      height: 35,
      width: 35,
      iconSet: 'FA',
    },
    colorScheme: 'orange',
    footer: 'fixed',
  },
  {
    key: 'developers',
    name: ['developers', { ns: 'settings' }],
    path: '/developers',
    component: Settings,
    authenticated: true,
    showInSidebar: false,
    showInOverlay: true,
    icon: {
      name: 'Cog',
      height: 35,
      width: 35,
      iconSet: 'FA',
    },
    colorScheme: 'yellow',
    footer: 'fixed',
  },
  {
    key: 'chargePoints',
    name: ['chargePoints', { ns: 'ui' }],
    path: '/chargepoints',
    component: ChargePoints,
    authenticated: true,
    showInSidebar: true,
    showInOverlay: true,
    icon: {
      name: 'UMOVE',
      height: 37,
      width: 37,
      iconSet: 'BlueCurrent',
    },
    colorScheme: 'green',
    children: [
      {
        key: 'chargepoint_settings',
        path: '/:id',
        component: ChargepointSettings,
      },
      {
        key: 'linked_charge_cards',
        path: '/:id/linked-cards',
        component: LinkedChargeCards,
      },
    ],
    footer: 'fixed',
  },
  {
    key: 'chargecards',
    name: ['chargeCards', { ns: 'ui' }],
    path: '/chargecards',
    component: ChargeCards,
    authenticated: true,
    showInSidebar: true,
    showInOverlay: true,
    icon: {
      name: 'Card',
      height: 30,
      width: 30,
      iconSet: 'FA',
    },
    colorScheme: 'orange',
    children: [
      {
        key: 'charge_card_settings',
        path: '/:id',
        component: ChargeCardSettings,
      },
      {
        key: 'linked_charge_points',
        path: '/:id/linked-chargepoints',
        component: LinkedChargePoints,
      },
      {
        key: 'excluded_on_chargepoints',
        path: '/:id/excluded-on',
        component: ExcludedOnChargePoints,
      },
    ],
    footer: 'fixed',
  },
  {
    key: 'transactions',
    name: ['transactions', { ns: 'ui' }],
    path: '/transactions',
    component: Transactions,
    authenticated: true,
    showInSidebar: true,
    showInOverlay: true,
    permission: 'transactions',
    icon: {
      name: 'Ballot',
      height: 30,
      width: 30,
      iconSet: 'FA',
    },
    colorScheme: 'yellow',
    children: [
      {
        key: 'transaction',
        path: '/:id',
        component: Transaction,
      },
      {
        key: 'declaration_overview',
        path: '/declaration-overview',
        component: DeclarationOverview,
      },
    ],
    footer: 'fixed',
  },
  {
    key: 'parking',
    name: '',
    path: '/parking',
    component: Parking,
    authenticated: false,
    showInSidebar: false,
    showInOverlay: false,
    icon: null,
    colorScheme: 'blue',
    children: [
      {
        key: 'street',
        path: '/:id',
        component: ParkingPlace,
      },
    ],
    footer: false,
  },
];
