import {
  UPDATE_CHARGE_CARD,
  ADD_CHARGE_CARD,
  USER_LOGOUT,
  EDIT_CHARGE_CARD,
  REMOVE_CHARGE_CARD,
} from '../actionTypes';

const initialState = {
  chargeCards: [],
  defaultChargeCard: null,
};

// eslint-disable-next-line default-param-last
const update = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CHARGE_CARD:
      return { ...state, ...action.payload };
    case ADD_CHARGE_CARD:
      return { ...state, chargeCards: [...state.chargeCards, action.payload] };
    case EDIT_CHARGE_CARD:
      return {
        ...state,
        chargeCards: state.chargeCards.map((i) => {
          if (i.id === action.id) {
            return {
              ...i,
              ...action.payload,
            };
          }
          return i;
        }),
      };
    case REMOVE_CHARGE_CARD:
      return {
        ...state,
        chargeCards: state.chargeCards.filter((i) => i.id !== action.id),
      };

    case USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default update;
