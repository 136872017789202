import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Lang,
  Colors,

  Button,
} from '@bluecurrent/web-component-lib';

import executeCommand from '../../../../api/executeCommand';
import {
  REMOVE_CHARGE_CARD,
  ADD_CHARGE_CARD,
  SET_CHARGE_CARD_NAME,
} from '../../../../api/types/ApiMessages';

import addNotification from '../../../../redux/creators/notifications';

import AddChargeCard from './addChargeCard';
import useCommands from '../../../../hooks/useCommands';

export default function ChargeCardSettings(props) {
  const { _ } = Lang.useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const commands = useCommands();

  const [, setDeleteCardDialog] = props.useDeleteCardDialog ?? [null, null];

  const chargeCard = useSelector(
    (state) => state.ChargeCards.chargeCards.find((i) => i.id === props.data),
  );

  const [max, setMax] = React.useState(false);
  const [maxTimeout, setMaxTimeout] = React.useState(null);

  const [loading, setLoading] = React.useState(false);
  const [deleteLoading, setDeleteLoading] = React.useState(false);

  const [name, setName] = React.useState(chargeCard?.name ?? '');
  const [serial, setSerial] = React.useState(chargeCard?.id ?? props.data ?? '');

  const [onErrorSerial, setErrorSerial] = React.useState(null);
  const [savedName, setSavedName] = React.useState(false);

  React.useEffect(() => {
    if (chargeCard) {
      props.setTitle(chargeCard.name || chargeCard.id);
      props.setSubTitle(_('editChargeCard', { ns: 'ui' }));

      if (!name) {
        setName(chargeCard.name);
      }
    } else {
      props.setTitle(_('addChargeCard', { ns: 'ui' }));
      props.setSubTitle(null);
    }
  }, [chargeCard]);

  const timer = (callback) => {
    if (maxTimeout) {
      clearTimeout(maxTimeout);
      setMaxTimeout(null);
    }
    setMax(true);

    setMaxTimeout(setTimeout(() => {
      setMax(false);
      if (callback) callback();
    }, 1500));
  };

  const addChargeCard = () => {
    setLoading(true);
    executeCommand(ADD_CHARGE_CARD, {
      id: serial,
    })
      .then(({ result }) => {
        if (name) {
          executeCommand(SET_CHARGE_CARD_NAME, {
            id: serial,
            name,
          })
            .then(() => {
              commands.emit('CHARGE_CARD_CREATE', {
                id: result.data.id,
                uid: result.data.uid,
                name,
              });
              dispatch({
                type: 'ADD_CHARGE_CARD',
                payload: {
                  uid: result.data.uid,
                  id: result.data.id,
                  name,
                  valid: 1,
                },
              });
              setLoading(false);
              navigate(`/chargecards/${result.data.id}/linked-chargepoints?s=new`);
              props.onClose();
            })
            .catch((err) => {
              Sentry.captureException(err);
              setErrorSerial(_('errors.somethingWrong', { ns: 'ui' }));
            });
        } else {
          commands.emit('CHARGE_CARD_CREATE', {
            id: result.data.id,
            uid: result.data.uid,
            name: '',
          });
          dispatch({
            type: 'ADD_CHARGE_CARD',
            payload: {
              uid: result.data.uid,
              id: result.data.id,
              name: '',
              valid: 1,
            },
          });
          setLoading(false);
          navigate(`/chargecards/${result.data.id}/linked-chargepoints?s=new`);
          props.onClose();
        }
      })
      .catch(({ result }) => {
        setLoading(false);
        switch (result.result?.result) {
          case 0:
            setErrorSerial(_('invalidCardNumber', { ns: 'chargecard' }));
            break;
          case 2:
            setErrorSerial(_('duplicateCard', { ns: 'chargecard' }));
            break;
          default:
            setErrorSerial(_('errors.somethingWrong', { ns: 'ui' }));
        }
      });
  };

  const editChargeCard = (e) => {
    setSavedName(true);
    commands.emit('CHARGE_CARD_UPDATE', {
      id: chargeCard.id,
      name,
    });

    executeCommand(SET_CHARGE_CARD_NAME, {
      name: e,
      id: chargeCard.id,
    })
      .then(() => {
        dispatch({
          type: 'EDIT_CHARGE_CARD',
          id: chargeCard.id,
          payload: {
            name: e,
          },
        });
      })
      .catch((err) => {
        Sentry.captureException(err);
        dispatch(addNotification('failed', _('errors.somethingWrong', { ns: 'ui' }), 'red'));
      });
  };

  const deleteCard = () => {
    setDeleteLoading(true);
    executeCommand(REMOVE_CHARGE_CARD, {
      id: chargeCard.id,
    })
      .then(() => {
        dispatch({
          type: 'REMOVE_CHARGE_CARD',
          id: chargeCard.id,
        });
        commands.emit('CHARGE_CARD_DELETE', {
          id: chargeCard.id,
        });
        setDeleteLoading(false);
        props.onClose();
      })
      .catch((err) => {
        Sentry.captureException(err);
        dispatch(addNotification('failed', _('errors.somethingWrong', { ns: 'ui' }), 'red'));
      });
  };

  React.useEffect(() => {
    if (chargeCard) {
      props.setDeleteCardFunc(deleteCard);
    }
  }, [chargeCard]);

  const onChangeName = (event) => {
    setSavedName(false);
    setName(event.target.value.slice(0, 20));
    if (chargeCard) {
      timer(() => {
        props.setTitle(event.target.value ? event.target.value.slice(0, 20) : chargeCard.id);
        props.setSubTitle(_('editChargeCard', { ns: 'ui' }));
        editChargeCard(event.target.value.slice(0, 20));
      });
    } else {
      timer();
    }
  };

  return (
    <>
      <AddChargeCard
        chargeCard={{
          name,
          serial,
          data: chargeCard,
        }}
        onChange={{
          name: onChangeName,
          serial: (e) => setSerial(e.target.value),
          max,
        }}
        error={{
          serial: onErrorSerial,
        }}
        savedName={savedName}
      />
      <div
        style={{
          paddingTop: 15,
          paddingBottom: 15,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: chargeCard ? 'flex-start' : 'flex-end',
          paddingLeft: 15,
          paddingRight: 15,
          borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
          marginTop: -2,
          position: 'relative',
          zIndex: 3,
          backgroundColor: Colors.WHITE,
        }}
      >
        {
          chargeCard && (
            <Button
              type="icon"
              colorScheme="white"
              onClick={() => setDeleteCardDialog(true)}
              loading={deleteLoading}
              icon={{
                name: 'Bin',
                height: 20,
                width: 20,
                color: Colors.RED,
                iconSet: 'FA',
              }}
              border={`2px solid ${Colors.RED}`}
            />
          )
        }
        {
          !chargeCard && (
            <div
              style={{
                width: 'auto',
              }}
            >
              <Button
                type="submit"
                text={chargeCard ? _('save', { ns: 'ui' }) : _('add', { ns: 'ui' })}
                colorScheme={
                  (chargeCard ? (chargeCard.id === serial && chargeCard.name === name) : serial === '')
                    ? 'grey'
                    : 'blue'
                }
                onClick={chargeCard ? editChargeCard : addChargeCard}
                disabled={
                  chargeCard ? (chargeCard.id === serial && chargeCard.name === name) : serial === ''
                }
                loading={loading}
              />
            </div>
          )
        }
      </div>
    </>
  );
}
