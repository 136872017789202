import * as Sentry from '@sentry/browser';

import store from '../../redux/store';
import addNotification from '../../redux/creators/notifications';

import executeCommand from '../../api/executeCommand';
import {
  START_SESSION,
} from '../../api/types/ApiMessages';

import DefaultChargeCard from '../../config/DefaultChargeCard.config';

const startSessionHandler = (selectedChargePoint, _, uid) => {
  store.dispatch({
    type: 'OVERLAY',
    payload: {
      display: false,
      layout: 'chargecards',
      title: false,
      subTitle: false,
    },
  });

  store.dispatch(
    addNotification(
      'info',
      _('updates.startCharging', { ns: 'chargepoint', id: selectedChargePoint.evse_id }),
      'blue',
    ),
  );

  store.dispatch({
    type: 'UPDATE_SETTINGS',
    id: selectedChargePoint.evse_id,
    payload: {
      notConfirmedSession: 'START_SESSION',
    },
  });

  executeCommand(START_SESSION, { evse_id: selectedChargePoint.evse_id, session_token: uid })
    .then(() => {
      if (selectedChargePoint.delayed_charging.value) {
        store.dispatch(
          addNotification(
            'success',
            _('updates.startDelayedChargingSuccess', { ns: 'chargepoint', id: selectedChargePoint.evse_id, time: selectedChargePoint.delayed_charging.start_time }),
            'green',
          ),
        );
      } else {
        store.dispatch(
          addNotification(
            'success',
            _('updates.startChargingSuccess', { ns: 'chargepoint', id: selectedChargePoint.evse_id }),
            'green',
          ),
        );
      }
    })
    .catch((err) => {
      Sentry.captureException(err);
      store.dispatch(
        addNotification(
          'failed',
          _('error.startChargingFailed', { ns: 'chargepoint', id: selectedChargePoint.evse_id }),
          'red',
        ),
      );
    });
};

const startSession = (selectedChargePoint, _) => {
  if (selectedChargePoint.vehicle_status !== 'B' && selectedChargePoint.vehicle_status !== 'C') {
    store.dispatch(
      addNotification(
        'failed',
        _('error.vehicleNotFound', { ns: 'chargepoint', id: selectedChargePoint.evse_id }),
        'red',
      ),
    );
    return;
  }

  const data = [...store.getState().ChargeCards.chargeCards];

  data.unshift({
    name: _(...DefaultChargeCard.NAME),
    id: DefaultChargeCard.ID,
    uid: DefaultChargeCard.UID,
  });

  store.dispatch({
    type: 'OVERLAY',
    payload: {
      display: true,
      layout: 'overlaySearch',
      data: data.map(({
        name,
        id,
        uid,
      }) => ({
        id,
        name,
        type: 'chargecard',
        serial: id,
        onClick: () => {
          startSessionHandler(selectedChargePoint, _, uid);
        },
      })),
      title: _('chargeCards', { ns: 'ui' }),
      subTitle: false,
    },
  });
};

export default startSession;
