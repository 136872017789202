import * as React from 'react';

import {
  Button,
  Lang,

  Text,
} from '@bluecurrent/web-component-lib';

import PlaceholderCards from '../../../assets/svg/Placeholders/Cards';

export default function ChargeCards({ text, button }) {
  const { _ } = Lang.useTranslation();

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      <div
        style={{
          height: '50%',
          width: '100%',
        }}
      >
        <PlaceholderCards />
      </div>
      <div
        style={{
          height: 'auto',
          marginBottom: 30,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <Text
          fontWeight={500}
          fontSize="1.3em"
          textAlign="center"
          lineHeight={1.2}
        >
          {text ?? _('noChargeCardsFound', { ns: 'ui' })}
        </Text>
      </div>
      {
        button && (
          <div
            style={{
              width: '80%',
            }}
          >
            <Button
              type="button"
              text={button.text}
              colorScheme="blue"
              onClick={button.action}
            />
          </div>
        )
      }
    </div>
  );
}
