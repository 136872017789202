import * as React from 'react';
import {
  motion,
} from 'framer-motion';

export default function LeftTire(props) {
  return (
    <motion.g
      initial={{
        rotate: props.data !== null && props.data.car.tires
          ? props.data.car.tires.initial.rotate
          : '0deg',
      }}
      animate={{
        rotate: props.data !== null && props.data.car.tires
          ? props.data.car.tires.animate.rotate
          : '0deg',
      }}
      exit={{
        rotate: props.data !== null && props.data.car.tires
          ? props.data.car.tires.exit.rotate
          : '0deg',
      }}
      transition={{
        duration: props.data !== null && props.data.car.tires
          ? props.data.car.tires.transition.duration
          : 0,
        delay: props.data !== null && props.data.car.tires
          ? props.data.car.tires.transition.delay
          : 0,
        transition: props.data !== null && props.data.car.tires
          ? props.data.car.tires.transition.transition
          : 'linear',
      }}
    >
      <circle
        cx={873.299}
        cy={1534.4}
        r={291.667}
        style={{
          fill: '#444',
        }}
      />
      <circle
        cx={873.299}
        cy={1534.4}
        r={227.778}
        style={{
          fill: '#939393',
        }}
      />
      <circle
        cx={873.299}
        cy={1534.4}
        r={208.223}
        style={{
          fill: '#7d7d7d',
        }}
      />
      <path
        d="M905.511 1380.32a77.815 77.815 0 0 0 101.764 74.04l62.992-20.373 19.745 61.048-65.006 21.025a77.723 77.723 0 0 0-49.771 49.236 77.724 77.724 0 0 0 10.014 69.29l39.298 56.145-52.564 36.793-35.111-50.16a77.898 77.898 0 0 0-127.638 0l-35.111 50.16-52.564-36.793 38.27-54.675a77.836 77.836 0 0 0-39.256-118.509l-67.851-22.512 19.745-61.048 66.744 22.138a77.682 77.682 0 0 0 102.138-73.732v-63.594h64.162v61.521Z"
        style={{
          fill: '#939393',
        }}
      />
      <circle
        cx={873.299}
        cy={1534.4}
        r={80.484}
        style={{
          fill: '#7d7d7d',
        }}
      />
      <ellipse
        cx={817.912}
        cy={1478.74}
        rx={16.565}
        ry={16.667}
        style={{
          fill: '#1a1a1a',
        }}
      />
      <ellipse
        cx={928.686}
        cy={1478.74}
        rx={16.565}
        ry={16.667}
        style={{
          fill: '#1a1a1a',
        }}
      />
      <ellipse
        cx={928.686}
        cy={1590.06}
        rx={16.565}
        ry={16.667}
        style={{
          fill: '#1a1a1a',
        }}
      />
      <ellipse
        cx={817.912}
        cy={1590.06}
        rx={16.565}
        ry={16.667}
        style={{
          fill: '#1a1a1a',
        }}
      />
    </motion.g>
  );
}
