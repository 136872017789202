import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Replay } from '@sentry/replay';

import { Provider } from 'react-redux';

import store from './redux/store';

import Setup from './config/Setup.config';

/* eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member */
import useStorage from './hooks/useStorage';

import App from './App';

const storage = useStorage();

if (Setup.USE_SENTRY) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: process.env.REACT_APP_VERSION,

    // Beta version Replays
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      new Integrations.BrowserTracing(),
      new Replay(),
    ],

    beforeSend(event) {
      // Check if it is an exception and if it's fatal, and if so, show the report dialog
      if (event.level === 'fatal' && event.exception) {
        Sentry.showReportDialog({
          eventId: event.event_id,
          lang: 'NL-nl',
        });
      }
      return event;
    },
  });
}

if (
  storage.getItem('analytics_accepted') === 'true'
  && storage.getItem('cookies_accepted') === 'true'
  && (
    process.env.NODE_ENV !== 'development'
    || process.env.NODE_ENV === 'preview-deployment'
  )
) {
  ReactGA.initialize(process.env.REACT_APP_GA_KEY);
}

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root'),
);
