import * as React from 'react';

import {
  Colors,

  Text,
  Icon,

  Lang,
} from '@bluecurrent/web-component-lib';

export default function NotAFan(props) {
  const { _ } = Lang.useTranslation();

  return (
    <button
      type="button"
      onClick={() => window.open(`https://6awv6ksanw9.typeform.com/to/seaRFi0A${props.email ? `?#email=${props.email}` : ''}`, '_self')}
      className="Hover"
      style={{
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 15,
        paddingBottom: 15,
        border: `solid 2px ${Colors.MEDIUM_WHITE}`,
        backgroundColor: Colors.LIGHT_GREY,
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: '1em',
        width: '100%',
      }}
    >
      <div
        style={{
          height: 50,
          width: 50,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Icon
          name="WindTurbine"
          height={45}
          width={45}
          color={Colors.DARK_WHITE}
          iconSet="FA"
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingLeft: 13,
          gap: 2.5,
        }}
      >
        <Text
          color={Colors.DARK_GREY}
          fontWeight={100}
          fontSize="1em"
          lineHeight={1.2}
        >
          {_('notAFan', { ns: 'ui' })}
        </Text>
      </div>
    </button>
  );
}
