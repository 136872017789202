import * as React from 'react';

import {
  Colors,
  Lang,

  useDimensions,

  Text,
  Icon,
} from '@bluecurrent/web-component-lib';

export default function PaginationArrow(props) {
  const { _ } = Lang.useTranslation();
  const size = useDimensions();

  return (
    <button
      type="button"
      className={props.disabled ? 'DisableSelection' : 'Hover'}
      style={{
        height: 45,
        width: 'auto',
        backgroundColor: props.active ? Colors.MEDIUM_WHITE : 'transparent',
        border: 'solid 0px transparent',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1em',
        borderRadius: props.active ? 5 : 0,
      }}
      onClick={props.disabled ? null : props.onClick}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {
          props.previous && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                height: 45,
                width: 30,
              }}
            >
              <Icon
                name="ChevronLeft"
                height={20}
                width={20}
                color={props.disabled ? Colors.MEDIUM_GREY : Colors.GREY}
                iconSet="Feather"
              />
            </div>
          )
        }
        {
          size.width >= 500 && (
            <Text
              color={props.disabled ? Colors.MEDIUM_GREY : Colors.GREY}
            >
              {props.previous && _('previous', { ns: 'ui' })}
              {props.next && _('next', { ns: 'ui' })}
            </Text>
          )
        }
        {
          props.next && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                height: 45,
                width: 30,
              }}
            >
              <Icon
                name="ChevronRight"
                height={20}
                width={20}
                color={props.disabled ? Colors.MEDIUM_GREY : Colors.GREY}
                iconSet="Feather"
              />
            </div>
          )
        }
      </div>
    </button>
  );
}
