import * as React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import './ErrorBar.css';

import {
  Colors,
  Backgrounds,
  Breakpoint,

  useDimensions,

  Text,
} from '@bluecurrent/web-component-lib';

import useCommands from '../../../hooks/useCommands';

export default function ErrorBar({ getErrorBarData }) {
  const size = useDimensions();
  const commands = useCommands();

  const [type, setType] = React.useState(false);
  const [attempts, setAttempts] = React.useState(0);

  React.useEffect(() => {
    commands.on('WS_ATTEMPT_CONNECT', () => {
      setAttempts((i) => i + 1);
    });

    commands.on('WS_OPEN', () => {
      setAttempts(0);
      setType('success');
      setTimeout(() => setType(false), 2000);
    });

    commands.on('WS_CLOSE', (e) => {
      setType('warn');
      if (e.retries === e.maxRetries) setType('error');
    });
  }, []);

  const attemptReconnect = () => {
    setType('warn');
    setAttempts(0);
    setTimeout(() => {
      commands.emit('WS_FORCE_OPEN', {});
    }, 1000);
  };

  React.useEffect(() => {
    getErrorBarData(type);
  });

  return (
    <AnimatePresence>
      {
        type !== false && (
          <motion.div
            style={{
              background: type === 'error'
                ? Backgrounds.RED
                : type === 'warn'
                  ? Backgrounds.ORANGE
                  : Backgrounds.GREEN,
              width: size.width < Breakpoint.sm ? '100%' : `calc(100% - ${size.width >= 1700 ? 240 : 70}px)`,
              height: 30,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: Colors.WHITE,
              position: 'fixed',
              left: 0,
              zIndex: 1000,
              marginLeft: size.width < Breakpoint.sm ? 0 : size.width >= 1700 ? 240 : 70,
            }}
            initial={{ top: -30 }}
            animate={{ top: 0 }}
            exit={{ top: -30 }}
            transition={{
              duration: 0.3,
            }}
          >
            <Text
              color={Colors.WHITE}
              fontSize="1em"
            >
              {
                type === 'error'
                  ? 'Het lukt niet te verbinden met onze server'
                  : type === 'warn'
                    ? `Connecting, attempt ${attempts + 1}`
                    : 'Connected!'
              }
              {
                type === 'warn' && (
                  <span className="ErrorBar_Text">
                    <span>.</span>
                    <span>.</span>
                    <span>.</span>
                  </span>
                )
              }
            </Text>
            {
              type === 'error' && (
                <button
                  onClick={attemptReconnect}
                  type="button"
                  className="Hover"
                  style={{
                    height: 20,
                    backgroundColor: 'transparent',
                    border: `solid 1px ${Colors.WHITE}`,
                    borderRadius: 5,
                    color: Colors.WHITE,
                    fontFamily: 'Museo-Sans',
                    marginLeft: 10,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 2,
                    fontSize: '0.8em',
                  }}
                >
                  Retry
                </button>
              )
            }
          </motion.div>
        )
      }
    </AnimatePresence>
  );
}
