import * as React from 'react';

import { SocketMessage } from './SocketMessage';
import useCommands from '../hooks/useCommands';
/* eslint-disable-next-line import/no-named-as-default-member, import/no-named-as-default */
import useStorage from '../hooks/useStorage';
import store from '../redux/store';

const storage = useStorage();
const commands = useCommands();

class Socket extends React.Component {
  static retryIn = 2000;

  static maxRetries = 3;

  static error() {
    const msg = new SocketMessage('WS_ERROR', {}, true);
    commands.emit('WS_ERROR', msg);
  }

  constructor() {
    super();

    this.state = {
      retries: 0,
      connectedAt: null,
      lastMessage: null,
      queue: [],
    };

    this.ws = null;

    this.sendMessage = this.sendMessage.bind(this);
    this.forceConnect = this.forceConnect.bind(this);
  }

  componentDidMount() {
    this.connectSocket();

    commands.on('sendMessage', this.sendMessage);
    commands.on('WS_FORCE_OPEN', this.forceConnect);
  }

  connectSocket() {
    this.ws = new WebSocket(process.env.REACT_APP_SOCKET_URI);

    const msg = new SocketMessage('WS_ATTEMPT_CONNECT', {}, true);
    commands.emit('WS_ATTEMPT_CONNECT', msg);

    window.ws = this.ws;

    this.ws.onopen = this.open.bind(this);
    this.ws.onclose = this.close.bind(this);
    this.ws.onerror = this.error;
    this.ws.onmessage = this.message.bind(this);
  }

  message(e) {
    let obj = null;

    try {
      obj = JSON.parse(e.data);

      this.setState({ lastMessage: Date.now() });

      const msg = new SocketMessage(obj.object, obj, true);

      commands.emit(obj.object, msg);
    } catch (err) {
      //
    }
  }

  open() {
    if (this.props.onFirstConnect && this.state.connectedAt === null) {
      this.props.onFirstConnect(this.state.connectedAt);
    }

    /* Send HELLO when authorized */
    if (storage.getOriginalState('token')) {
      this.sendMessage(new SocketMessage('HELLO', {
        gui: 'driverportal',
      }));

      commands.once('HELLO', (helloData) => {
        store.dispatch({
          type: 'PERMISSION_UPDATE',
          payload: {
            transactions: helloData.data.transactions.permission,
          },
        });
      });
    }

    this.setState({ connectedAt: Date.now(), retries: 0 });

    const msg = new SocketMessage('WS_OPEN', { connectedAt: this.state.connectedAt }, true);
    commands.emit('WS_OPEN', msg);

    this.state.queue.forEach((queuedMsg) => {
      this.sendMessage(queuedMsg);

      this.setState((prevState) => {
        const tempQueue = [...prevState.queue];
        tempQueue.shift();
        return { queue: tempQueue };
      });
    });
  }

  close(event) {
    const msg = new SocketMessage('WS_CLOSE', {
      maxRetries: Socket.maxRetries,
      retries: this.state.retries,
      retryIn: Socket.retryIn,
      lastMessage: this.state.lastMessage,
      data: event,
    }, true);
    commands.emit('WS_CLOSE', msg);

    if (this.state.retries < 3) {
      setTimeout(() => {
        this.setState((oldState) => ({ retries: oldState.retries + 1 }));

        this.connectSocket();
      }, Socket.retryIn);
    }
  }

  forceConnect() {
    /* Prevent reconnection attempts */
    this.setState(
      { retries: Socket.maxRetries },
      () => {
        this.connectSocket();
      },
    );
  }

  sendMessage(msg) {
    if (this.ws.readyState !== 1) {
      this.setState((prevState) => ({
        queue: [...prevState.queue, msg],
      }));
      return;
    }

    let json = null;

    try {
      json = JSON.stringify(msg);
    } catch (e) {
      //
    }

    this.ws.send(json);
  }

  render() {
    return null;
  }
}

export default Socket;
