/* eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member */
import useStorage from '../useStorage';
import useCommands from '../useCommands';
import { SocketMessage, MessageType, ResponseType } from '../../api/SocketMessage';
import {
  GET_CH_STATUS,
  // GET_GRIDS,
  GET_GROUPS,
} from '../../api/types/ApiMessages';
import executeCommand from '../../api/executeCommand';

const storage = useStorage();
const commands = useCommands();

const fetchStartData = (dispatch, chargePoints) => new Promise((resolve) => {
  chargePoints.forEach((point) => {
    executeCommand(GET_CH_STATUS, { params: { evse_id: point.evse_id } })
      .then((res) => {
        dispatch({
          type: 'SET_CHARGE_POINT_STATUS',
          evseId: res.data.data.evse_id,
          payload: res.data,
        });
      });
  });

  const msg = new SocketMessage(MessageType.GET_CHARGE_CARDS, {});

  commands.send(msg);

  commands.once(ResponseType.CHARGE_CARDS, (d) => {
    dispatch({
      type: 'UPDATE_CHARGE_CARD',
      payload: {
        chargeCards: d.cards,
      },
    });
  });

  if (storage.getItem('selected_chargepoint')) {
    dispatch({
      type: 'SET_SELECTED_CHARGEPOINT',
      payload: {
        id: storage.getItem('selected_chargepoint'),
      },
    });
  }

  // executeCommand(GET_GRIDS)
  //   .then((res) => {
  //     dispatch({
  //       type: 'UPDATE_GRIDS',
  //       payload: {
  //         grids: res.data.grids,
  //         selectedGrid: storage.getItem('selected_grid') || res.data.grids[0].id,
  //       },
  //     });
  //
  //     if (!storage.getItem('selected_grid')) storage.setItem('selected_grid',
  // res.data.grids[0].id);
  //   });

  executeCommand(GET_GROUPS)
    .then((res) => {
      dispatch({
        type: 'SET_GROUPS',
        payload: res.data.groups,
      });
      dispatch({
        type: 'SET_GROUPS_LOADING',
        payload: true,
      });
    });

  resolve();
});

export default fetchStartData;
