import * as React from 'react';

import {
  Colors,
  Lang,

  useCurrencyFormat,

  Input,
  Text,
  Button,
} from '@bluecurrent/web-component-lib';

import GroupedItemsDummy from './GroupedItems';
import DoubleItemDummy from './DoubleItem';

export default function ChargePointSettingsDummy() {
  const { _, i18n } = Lang.useTranslation();
  const currencyFormat = useCurrencyFormat(i18n.language);

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        backgroundColor: Colors.WHITE,
        padding: 15,
        overflow: 'hidden',
        textAlign: 'left',
      }}
    >
      <Text
        fontSize="1em"
        color={Colors.DARK_GREY}
      >
        {_('thisIsExample', { ns: 'ui' })}
      </Text>
      <div
        style={{
          marginTop: 10,
        }}
      >
        <Input
          type="text"
          label={_('chargePointName', { ns: 'ui' })}
          name="chargepoint-name"
          styling
          max={20}
        />
      </div>
      <div
        style={{
          marginTop: 30,
          width: '100%',
          height: 'auto',
        }}
      >
        <GroupedItemsDummy
          label={_('information', { ns: 'ui' })}
        />
      </div>
      <div
        style={{
          marginTop: 30,
        }}
      >
        <GroupedItemsDummy
          label={_('settings', { ns: 'settings' })}
        />
        <div>
          <DoubleItemDummy
            content={[
              {
                id: 0,
                title: _('paidCharging', { ns: 'finance' }),
                type: 'boolean',
                value: true,
              },
              {
                id: 1,
                title: _('currentTariff', { ns: 'finance' }),
                type: 'button',
                value: currencyFormat(0.07, 'EUR'),
              },
            ]}
          />
        </div>
        <GroupedItemsDummy />
      </div>
      <div
        style={{
          marginTop: 30,
        }}
      >
        <GroupedItemsDummy
          label={_('actions', { ns: 'ui' })}
        />
        <div
          style={{
            marginTop: 10,
          }}
        >
          <Button
            text={_('chargePoint', { ns: 'ui' })}
            subText={_('actions.reset', { ns: 'chargepoint' })}
            type="action"
            icon={{
              name: 'Sync',
              type: 'light',
              height: 30,
              width: 30,
              set: 'FA',
              transform: false,
            }}
            colorScheme="black"
          />
        </div>
        <div
          style={{
            marginTop: 10,
          }}
        >
          <Button
            text={_('chargePoint', { ns: 'ui' })}
            subText={_('actions.reboot', { ns: 'chargepoint' })}
            type="action"
            icon={{
              name: 'PowerOff',
              type: 'light',
              height: 30,
              width: 30,
              set: 'FA',
              transform: false,
            }}
            color={Colors.RED}
            colorScheme="red"
          />
        </div>
      </div>
    </div>
  );
}
