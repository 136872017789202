/* eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member */
import useStorage from '../../../../hooks/useStorage';

const selectFilter = (filter, selected, active) => {
  const storage = useStorage();

  let array;
  if (selected.includes(filter)) {
    if (filter !== 'all') {
      if (selected.length > 1) {
        array = selected.filter((i) => i !== filter);
      } else {
        array = ['all'];
      }
    } else {
      array = ['all'];
    }
  } else if (filter === 'all') {
    array = ['all'];
  } else {
    array = [...selected.filter((i) => i !== 'all'), filter];
  }

  storage.setItem('chargepoints_filter', array.join(','));
  return array.filter((i) => [...active, 'all'].includes(i));
};

export default selectFilter;
