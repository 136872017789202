import { useSelector } from 'react-redux';

const useDeveloperMode = () => {
  const enabled = useSelector((state) => state.User.developer_mode_enabled);

  return {
    enabled,
  };
};

export default useDeveloperMode;
