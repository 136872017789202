import * as React from 'react';
import { useSelector } from 'react-redux';

import {
  Text,
  Carousel,

  Breakpoint,
  Colors,
  Lang,
  Backgrounds,

  useDimensions,
  getColorScheme,
} from '@bluecurrent/web-component-lib';

function ChargePointStatusText(props) {
  const totalChargePoints = useSelector((state) => state.ChargePoints.length);

  return (
    <div
      style={{
        marginRight: 30,
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 15,
        paddingBottom: 15,
      }}
    >
      <Text
        color={Colors.WHITE}
        fontWeight={300}
        fontSize="1.3em"
      >
        {props.amount}
        <span
          style={{
            marginRight: 7,
            color: '#ccf4e8',
            fontSize: '0.7em',
            fontWeight: 500,
          }}
        >
          /
          {totalChargePoints}
        </span>
      </Text>
      <Text
        color={Colors.WHITE}
        fontWeight={300}
        fontSize="1.3em"
      >
        {props.text}
      </Text>
    </div>
  );
}

export default function ChargePointsStatus() {
  const size = useDimensions();
  const { _ } = Lang.useTranslation();
  const stats = useSelector((state) => state.ChargePoints.map(({ activity }) => activity));

  const getCount = (status) => stats.filter((x) => x === status).length;

  const responsive = () => {
    if (size.width < Breakpoint.fd) return 1;
    if (size.width < Breakpoint.xs) return 2;
    if (size.width < Breakpoint.sm) return 3;
    if (size.width < Breakpoint.md) return 4;
    if (size.width < 1530) return 4;
    if (size.width < Breakpoint.max) return 6;
    return 6;
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        minHeight: 60,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        background: Backgrounds.GREEN,
        borderRadius: 10,
        paddingLeft: size.width < Breakpoint.xs ? 10 : 20,
        paddingRight: size.width < Breakpoint.xs ? 10 : 20,
        overflow: 'hidden',
      }}
    >
      <Carousel
        height={50}
        maxItems={responsive()}
        colorScheme="green"
        fade
      >
        <div
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <ChargePointStatusText
            amount={getCount('available')}
            text={_('status.available', { ns: 'chargepoint' })}
            colorScheme={getColorScheme('green')}
          />
        </div>
        <div
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <ChargePointStatusText
            amount={getCount('charging')}
            text={_('status.charging', { ns: 'chargepoint' })}
            colorScheme={getColorScheme('green')}
          />
        </div>
        <div
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <ChargePointStatusText
            amount={getCount('error')}
            text={_('status.error', { ns: 'chargepoint' })}
            colorScheme={getColorScheme('green')}
          />
        </div>
        <div
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <ChargePointStatusText
            amount={getCount('offline')}
            text={_('status.offline', { ns: 'chargepoint' })}
            colorScheme={getColorScheme('green')}
          />
        </div>
      </Carousel>
    </div>
  );
}
