const modulesStatic = true;

const getLayouts = (showTransactions) => {
  const layoutLGMD = [
    {
      i: 'ChargePointStatus', x: 0, y: 0, w: 6.5, h: 1, static: modulesStatic,
    },
    {
      i: 'UpcomingEvent', x: 6.5, y: 0, w: 2.5, h: 1, static: modulesStatic,
    },
    {
      i: 'ChargePointData', x: 0, y: 1, w: 3, h: 6, static: modulesStatic,
    },
    {
      i: showTransactions ? 'LatestTransactions' : 'TotalEnergyUsage', x: 3, y: 1, w: 3.5, h: 6, static: modulesStatic,
    },
    {
      i: 'TotalCO2Emissions', x: 6.5, y: 1, w: 2.5, h: 3, static: modulesStatic,
    },
    {
      i: 'QuickAccess', x: 0, y: 7, w: 6.5, h: 3, static: modulesStatic,
    },
    {
      i: 'TotalTrees', x: 6.5, y: 4, w: 2.5, h: 3, static: modulesStatic,
    },
    {
      i: 'App', x: 6.5, y: 7, w: 2.5, h: 3, static: modulesStatic,
    },
  ];

  const layoutSMXS = [
    {
      i: 'ChargePointStatus', x: 0, y: 0, w: 6, h: 1, static: modulesStatic,
    },
    {
      i: 'UpcomingEvent', x: 4, y: 1, w: 3, h: 1, static: modulesStatic,
    },
    {
      i: 'ChargePointData', x: 0, y: 1, w: 3, h: 7, static: modulesStatic,
    },
    {
      i: showTransactions ? 'LatestTransactions' : 'TotalEnergyUsage', x: 4, y: 2, w: 3, h: 6, static: modulesStatic,
    },
    {
      i: 'TotalCO2Emissions', x: 0, y: 8, w: 3, h: 4, static: modulesStatic,
    },
    {
      i: 'QuickAccess', x: 0, y: 12, w: 7, h: 3, static: modulesStatic,
    },
    {
      i: 'TotalTrees', x: 4, y: 9, w: 3, h: 3, static: modulesStatic,
    },
    {
      i: 'App', x: 3, y: 8, w: 3, h: 1, static: modulesStatic,
    },
  ];

  const layoutFD = [
    {
      i: 'ChargePointStatus', x: 0, y: 0, w: 0, h: 0, static: modulesStatic,
    },
    {
      i: 'UpcomingEvent', x: 0, y: 7, w: 5, h: 1, static: modulesStatic,
    },
    {
      i: 'ChargePointData', x: 0, y: 0, w: 5, h: 7, static: modulesStatic,
    },
    {
      i: showTransactions ? 'LatestTransactions' : 'TotalEnergyUsage', x: 0, y: 8, w: 5, h: 4, static: modulesStatic,
    },
    {
      i: 'TotalCO2Emissions', x: 3, y: 22, w: 2.5, h: 3, static: modulesStatic,
    },
    {
      i: 'QuickAccess', x: 0, y: 12, w: 5, h: 9, static: modulesStatic,
    },
    {
      i: 'TotalTrees', x: 0, y: 22, w: 2.5, h: 3, static: modulesStatic,
    },
    {
      i: 'App', x: 0, y: 21, w: 5, h: 1, static: modulesStatic,
    },
  ];

  return {
    lg: layoutLGMD,
    md: layoutLGMD,
    sm: layoutSMXS,
    xs: layoutSMXS,
    xxs: layoutFD,
  };
};

export default getLayouts;
