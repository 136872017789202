import * as React from 'react';
import { Responsive as ResponsiveGridLayout } from 'react-grid-layout';
import { useSelector, useDispatch } from 'react-redux';
import * as Sentry from '@sentry/browser';

import {
  Breakpoint,
  Lang,
  Colors,

  useDimensions,
  useSweetName,

  Text,
  OverlaySearch,
  Modal,
} from '@bluecurrent/web-component-lib';

/* eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member */
import handleAccountName from '../functions/handleAccountName';
/* eslint-disable-next-line import/no-named-as-default-member, import/no-named-as-default */
import useStorage from '../hooks/useStorage';
import useBestAddress from '../hooks/useBestAddress';

import executeCommand from '../api/executeCommand';
import { GET_SUSTAINABILITY_STATUS } from '../api/types/ApiMessages';

import addNotification from '../redux/creators/notifications';

import Links from '../config/Links.config';

import UpcomingEvent from '../components/Sections/Dashboard/UpcomingEvent';
import TotalEnergyUsage from '../components/Sections/Dashboard/TotalEnergyUsage';
import TotalCO2Emissions from '../components/Sections/Dashboard/TotalC02Emissions';
import TotalTrees from '../components/Sections/Dashboard/TotalTrees';
import QuickAccess from '../components/Sections/Dashboard/QuickAccess';
import ChargePointData from '../components/Sections/Dashboard/ChargePointData/index';
import ChargePointsStatus from '../components/Sections/Dashboard/ChargePointsStatus';
import App from '../components/Sections/Dashboard/App/index';
import getLayouts from '../components/Sections/Dashboard/Data/layouts';
import LatestTransactions from '../components/Sections/Dashboard/LatestTransactions';
import ProfiledCharging from '../components/Sections/Modal/ProfiledCharging';

export default function Dashboard() {
  const size = useDimensions();
  const { _ } = Lang.useTranslation();
  const dispatch = useDispatch();
  const sweetName = useSweetName(_);
  const storage = useStorage();
  const getBestAddress = useBestAddress();
  const { firstname } = handleAccountName();

  const [trees, setTrees] = React.useState(0);
  const [CO2, setCO2] = React.useState(0);
  const [dashboardWidth, setDashboardWidth] = React.useState(0);

  const [chargePointFilter, setChargePointFilter] = React.useState('');
  const [selectChargePointModal, setSelectChargePointModal] = React.useState(false);
  const [isProfiledChargingModal, setProfiledChargingModal] = React.useState(false);

  const [selectGridModal, setSelectGridModal] = React.useState(false);
  const [gridFilter, setGridFilter] = React.useState('');

  const dashboardRef = React.useRef(0);

  const chargePoints = useSelector((state) => state.ChargePoints);
  const grids = useSelector((state) => state.Grids.grids);
  const selectedGrid = useSelector((state) => state.Grids.grids.find(({
    id,
  }) => id === state.Grids.selectedGrid));

  React.useEffect(() => {
    setDashboardWidth(dashboardRef.current.clientWidth);
  }, [dashboardRef.current.clientWidth]);

  React.useEffect(() => {
    executeCommand(GET_SUSTAINABILITY_STATUS)
      .then(({ result }) => {
        setTrees(result.trees);
        setCO2(result.co2);
      })
      .catch((err) => {
        Sentry.captureException(err);
        addNotification('failed', _('errors.somethingWrong', { ns: 'ui' }), 'red');
      });
  }, []);

  const showTransactions = true; /* grids.every((i) => !i.smart_charging); */

  /* TODO: Change when NanoCharge & Hidden are added */
  // const icons = {
  //   HIDDEN: 'Hidden',
  //   UMOVE: 'UMOVE',
  //   NANO: 'NanoCharge',
  // };

  return (
    <>
      <div
        ref={dashboardRef}
        style={{
          width: '100%',
        }}
      >
        <div
          ref={dashboardRef}
          style={{
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <div
            className="FadeIn"
            style={{
              height: 'auto',
              minHeight: 80,
              paddingTop: 25,
              paddingBottom: 10,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <div
              style={{
                marginLeft: 10,
                height: 'auto',
                minHeight: 80,
                paddingTop: 20,
                paddingBottom: 10,
                paddingRight: 10,
              }}
            >
              <Text
                fontSize={size.width < Breakpoint.fd ? '2em' : '3em'}
                fontWeight={700}
              >
                {`Hi, ${firstname}!`}
              </Text>
            </div>
          </div>
          <ResponsiveGridLayout
            className="Dashboard_GridLayout"
            layouts={getLayouts(showTransactions)}
            rowHeight={75}
            width={dashboardWidth}
            isBounded
            preventCollission
            breakpoints={{
              lg: Breakpoint.lg,
              md: Breakpoint.md,
              sm: Breakpoint.sm,
              xs: Breakpoint.xs,
              xxs: Breakpoint.fd,
            }}
            cols={{
              lg: 9,
              md: 9,
              sm: 6,
              xs: 6,
              xxs: 5,
            }}
          >
            {
              size.width > Breakpoint.xs && (
                <div
                  key="ChargePointStatus"
                  className="FadeIn"
                  style={{
                    backgroundColor: Colors.GREEN,
                    borderRadius: 10,
                  }}
                >
                  <div
                    style={{
                      height: '100%',
                      opacity: 0,
                      animation: 'FadeIn .2s ease-in .2s forwards',
                    }}
                  >
                    <ChargePointsStatus />
                  </div>
                </div>
              )
            }
            <div
              id="Tour_Step2"
              key="UpcomingEvent"
              style={{
                opacity: 0,
                animation: 'FadeIn .2s ease-in .4s forwards',
              }}
            >
              <UpcomingEvent />
            </div>
            <div
              id="Tour_Step1"
              key="ChargePointData"
              style={{
                opacity: 0,
                animation: 'FadeIn .2s ease-in .3s forwards',
              }}
            >
              <ChargePointData
                setSelectChargePointModal={() => setSelectChargePointModal(true)}
                setProfiledChargingModal={() => setProfiledChargingModal(true)}
              />
            </div>
            {
                showTransactions ? (
                  <div
                    key="LatestTransactions"
                    style={{
                      opacity: 0,
                      animation: 'FadeIn .2s ease-in .4s forwards',
                    }}
                  >
                    <LatestTransactions />
                  </div>
                ) : (
                  <div
                    key="TotalEnergyUsage"
                    style={{
                      opacity: 0,
                      animation: 'FadeIn .2s ease-in .4s forwards',
                    }}
                  >
                    <TotalEnergyUsage
                      setSelectGridModal={() => setSelectGridModal(true)}
                    />
                  </div>
                )
            }
            <div
              key="QuickAccess"
              style={{
                opacity: 0,
                animation: 'FadeIn .2s ease-in .5s forwards',
              }}
            >
              <QuickAccess />
            </div>
            <div
              key="TotalCO2Emissions"
              style={{
                opacity: 0,
                animation: 'FadeIn .2s ease-in .6s forwards',
              }}
            >
              <TotalCO2Emissions total={CO2} />
            </div>
            <div
              key="TotalTrees"
              style={{
                opacity: 0,
                animation: 'FadeIn .2s ease-in .7s forwards',
              }}
            >
              <a
                className="Hover"
                href={Links.ARTICLE_TREES_PLANTED_NL}
                target="_blank"
                rel="noreferrer"
                aria-label={_('totalTrees', { ns: 'ui' })}
              >
                <TotalTrees total={trees} />
              </a>
            </div>
            <div
              key="App"
              style={{
                opacity: 0,
                animation: 'FadeIn .2s ease-in .75s forwards',
              }}
            >
              <App />
            </div>
          </ResponsiveGridLayout>
        </div>
      </div>
      <Modal
        visible={selectChargePointModal}
        title={_('chargePoints', { ns: 'ui' })}
        onClose={() => setSelectChargePointModal(false)}
      >
        <OverlaySearch
          type="Search"
          onClose={() => setSelectChargePointModal(false)}
          onSearch={(s) => setChargePointFilter(s.target.value)}
          data={chargePoints
            .filter((evse) => (chargePointFilter === '' ? true : evse.evse_id.toLowerCase().includes(chargePointFilter.toLowerCase())
                  || (evse.name !== '' && evse.name?.toLowerCase().includes(chargePointFilter.toLowerCase()))
                  || chargePointFilter.toLowerCase().includes(evse.evse_id.toLowerCase())
                  || (evse.name !== '' && chargePointFilter.toLowerCase().includes(evse.name?.toLowerCase()))))
            .map(({
              evse_id: evseId, chargepoint_type: modelType, name, selected,
            }) => (
              {
                name: name || sweetName(modelType),
                selected,
                serial: evseId,
                type: 'chargepoint',
                icon: {
                  name: 'UMOVE',
                  color: Colors.GREY,
                  height: 50,
                  width: 50,
                  iconSet: 'BlueCurrent',
                },
                onClick: () => {
                  setSelectChargePointModal(false);
                  dispatch({
                    type: 'SET_SELECTED_CHARGEPOINT',
                    payload: {
                      id: evseId,
                    },
                  });
                  storage.setItem('selected_chargepoint', evseId);
                },
              }
            ))}
        />
      </Modal>
      <Modal
        visible={selectGridModal}
        title={_('grids', { ns: 'ui' })}
        onClose={() => setSelectGridModal(false)}
      >
        <OverlaySearch
          type="Search"
          onClose={() => setSelectGridModal(false)}
          onSearch={(s) => setGridFilter(s.target.value)}
          data={grids
            .filter((i) => (getBestAddress(i.address) ? (
              getBestAddress(i.address).toLowerCase().includes(gridFilter.toLowerCase())
            || gridFilter.toLowerCase().includes(getBestAddress(i.address).toLowerCase())
            ) : (
              i.id.toLowerCase().includes(gridFilter.toLowerCase())
            || gridFilter.toLowerCase().includes(i.id.toLowerCase())
            )))
            .map((i) => {
              const name = getBestAddress(i.address);
              return {
                id: i.id,
                name: name || i.id,
                serial: name && i.id,
                type: 'chargepoint',
                selected: i.id === selectedGrid?.id,
                disabled: !i.smart_charging,
                onClick: i.smart_charging ? () => {
                  dispatch({
                    type: 'SET_SELECTED_GRID',
                    payload: {
                      id: i.id,
                    },
                  });
                  storage.setItem('selected_grid', i.id);
                  setSelectGridModal(false);
                } : undefined,
              };
            })}
        />
      </Modal>
      <Modal
        visible={isProfiledChargingModal}
        title={_('chargingProfiles.profiledCharging', { ns: 'chargepoint' })}
        subTitle={_('selectProfile', { ns: 'ui' })}
        onClose={() => setProfiledChargingModal(false)}
      >
        <ProfiledCharging
          onClose={() => setProfiledChargingModal(false)}
        />
      </Modal>
    </>
  );
}
