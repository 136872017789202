class MessageType {
  static VALIDATE_PASSWORD = 'VALIDATE_PASSWORD';

  static GET_STATUS = 'GET_STATUS';

  static GET_CHARGE_CARDS = 'GET_CHARGE_CARDS';

  static GET_CHARGE_POINTS = 'GET_CHARGE_POINTS';

  static GET_SUSTAINABILITY_STATUS = 'GET_SUSTAINABILITY_STATUS';

  static START_SESSION = 'START_SESSION';

  static STOP_SESSION = 'STOP_SESSION';

  static GET_SESSIONS = 'GET_SESSIONS';

  static UNLOCK_CONNECTOR = 'UNLOCK_CONNECTOR';

  static SOFT_RESET = 'SOFT_RESET';

  static REBOOT = 'REBOOT';

  static SET_AVAILABLE = 'SET_AVAILABLE';

  static SET_PUBLIC_CHARGING = 'SET_PUBLIC_CHARGING';

  static SET_PLUG_AND_CHARGE = 'SET_PLUG_AND_CHARGE';

  static SET_PLUG_AND_CHARGE_NOTIFICATION = 'SET_PLUG_AND_CHARGE_NOTIFICATION';

  static SET_DELAYED_CHARGING = 'SET_DELAYED_CHARGING';

  static SET_PRICE_BASED_CHARGING = 'SET_PRICE_BASED_CHARGING';

  static SET_PRICE_BASED_SETTINGS = 'SET_PRICE_BASED_SETTINGS';

  static OVERRIDE_CHARGING_PROFILES = 'OVERRIDE_CHARGING_PROFILES';

  static SAVE_SCHEDULE_DELAYED_CHARGING = 'SAVE_SCHEDULE_DELAYED_CHARGING';

  static OVERRIDE_DELAYED_CHARGING_TIMEOUT = 'OVERRIDE_DELAYED_CHARGING_TIMEOUT';

  static SET_DELAYED_CHARGING_SCHEDULE = 'SET_DELAYED_CHARGING_SCHEDULE';

  static SET_DEFAULT_CHARGE_CARD = 'SET_DEFAULT_CHARGE_CARD';

  static SET_CHARGE_CARD_NAME = 'SET_CHARGE_CARD_NAME';

  static REMOVE_CHARGE_CARD = 'REMOVE_CHARGE_CARD';

  static ADD_CHARGE_CARD = 'ADD_CHARGE_CARD';

  static GET_CH_STATUS = 'GET_CH_STATUS';

  static GET_CH_SETTINGS = 'GET_CH_SETTINGS';

  static GET_GRID_STATUS = 'GET_GRID_STATUS';

  static GET_ACCOUNT = 'GET_ACCOUNT';

  static VALIDATE_API_TOKEN = 'VALIDATE_API_TOKEN';
}

Object.freeze(MessageType);

export default MessageType;
