import * as React from 'react';
import {
  Lang,
  Colors,
  Breakpoint,

  useDimensions,

  Item,
} from '@bluecurrent/web-component-lib';

const SmartChargingSetting = (props) => {
  const { _ } = Lang.useTranslation();
  const size = useDimensions();
  const value = props.evseSettings.smart_charging;

  return (
    <div
      className="DisableSelection"
      style={{
        padding: 10,
        height: 'auto',
        minHeight: 55,
        backgroundColor: Colors.LIGHT_GREY,
        border: `solid 2px ${Colors.MEDIUM_WHITE}`,
        borderRadius: 10,
        marginTop: 10,
      }}
    >
      <Item
        type="boolean"
        title={_('settings.smartCharging', { ns: 'chargepoint' })}
        value={value}
        tooltip={{
          error: _('failedToLoadSetting', { ns: 'settings' }),
          permission: size.width < Breakpoint.sm ? false : _('noPermissionToChangeSetting', { ns: 'settings' }),
        }}
        disabled
      />
    </div>
  );
};

export default SmartChargingSetting;
