import * as React from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  motion,
} from 'framer-motion';
import {
  Outlet, useNavigate,
  useSearchParams,
} from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import {
  Colors,
  Lang,
  Breakpoint,

  useDimensions,
  useDidMountEffect,

  ConfirmDialog,
  OverlaySearch,
  Modal as BaseModal,
  Input,
  Text, Button,
} from '@bluecurrent/web-component-lib';

import axios from 'axios';
import addNotification from '../redux/creators/notifications';
import executeCommand from '../api/executeCommand';
import {
  BASE_URI, GENERATE_TRANSACTIONS_OVERVIEW,
  GET_CARDS_TRANSACTION_OVERVIEW,
  GET_TRANSACTIONS,
} from '../api/types/ApiMessages';

import useTextSearch from '../hooks/useTextSearch';

import Groups from '../components/Sections/Groups/index';
import List from '../components/Sections/Transactions/List';
import Controls from '../components/Sections/Transactions/Controls';
import Modal from '../components/Sections/Modal';
import ChargePointSelector from '../components/Sections/ChargePoints/Selector';
import ChargeCardSelector from '../components/Sections/ChargeCards/Selector';
import PlaceholderChargeCards from '../components/Modules/Placeholders/ChargeCards';
/* eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member */
import useStorage from '../hooks/useStorage';

export default function Transactions() {
  const [searchParams, setSearchParams] = useSearchParams();
  const size = useDimensions();
  const dispatch = useDispatch();
  const { _ } = Lang.useTranslation();
  const textSearch = useTextSearch();
  const storage = useStorage();
  const navigate = useNavigate();

  const chargePoints = useSelector((state) => state.ChargePoints);
  const groups = useSelector((state) => state.Groups.groups);
  const permissions = useSelector((state) => state.Permissions);

  const layoutWrapperRef = React.useRef(0);
  const timeoutRef = React.useRef();

  const [width, setWidth] = React.useState('100%');
  const [isGroupModalOpen, setGroupModalOpen] = React.useState(false);

  const [group, setGroup] = React.useState(null);

  const [sortFieldOrder] = React.useState('DESC');
  const [sortField] = React.useState('stoppedtimestamp');

  const [isLoading, setLoading] = React.useState(true);
  const [transactions, setTransactions] = React.useState([]);
  const [page, setPage] = React.useState(searchParams.get('page') ?? 1);
  const [isNextPageAvailable, setNextPageAvailable] = React.useState(false);
  const [totalPages, setTotalPages] = React.useState(0);

  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(new Date());

  const [search, setSearch] = React.useState(searchParams.get('search') ?? '');
  const [isSearchActive, setSearchActive] = React.useState(false);
  const [isSearchBarFocused, setSearchBarFocused] = React.useState(false);

  const [isFilterModalOpen, setFilterModalOpen] = React.useState(false);
  const [isChargePointSelectorModalOpen, setChargePointSelectorModalOpen] = React.useState(false);
  const [isChargeCardSelectorModalOpen, setChargeCardSelectorModalOpen] = React.useState(false);

  const [isDeleteGroupDialogVisible, setDeleteGroupDialogVisible] = React.useState(false);
  const deleteGroupFuncRef = React.useRef();

  const [possibleChargeCardsForFilter, setPossibleChargeCardsForFilter] = React.useState(null);

  const [isDownloading, setDownloading] = React.useState(false);
  const [chargePointSearch, setChargePointSearch] = React.useState('');
  const [chargePointFilter, setChargePointFilter] = React.useState(() => {
    let val = null;
    try {
      val = searchParams.get('chargepoints').split(',');
    } catch (e) {
      // User supplied wrong format
    }
    return val;
  });

  const [chargeCardFilter, setChargeCardFilter] = React.useState(() => {
    let val = null;
    try {
      val = searchParams.get('chargecards').split(',');
    } catch (e) {
      // User supplied wrong format
    }
    return val;
  });

  const [tempSearch, setTempSearch] = React.useState(search);

  // Handle layout wrapper width
  const getDimensions = () => {
    if (layoutWrapperRef.current.clientWidth !== null) {
      setWidth(layoutWrapperRef.current.clientWidth);
    }
  };

  React.useEffect(() => {
    getDimensions();
  }, [width]);

  React.useEffect(() => {
    window.addEventListener('resize', getDimensions);

    return () => {
      window.removeEventListener('resize', getDimensions);
    };
  }, []);

  const loadTransactions = (p = page, evses = chargePointFilter, cards = chargeCardFilter) => {
    setLoading(true);
    executeCommand(GET_TRANSACTIONS, {
      params: {
        page: p,
        start_date: startDate && `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`,
        end_date: endDate && `${endDate.getDate()}-${endDate.getMonth() + 1}-${endDate.getFullYear()}`,
        sort_field_order: sortFieldOrder,
        sort_field: sortField,
        search_field: search,
      },
      body: {
        chargepoints: evses
          ? evses.map((cp) => ({ chargepoint_id: cp }))
          : chargePoints.map((cp) => ({ chargepoint_id: cp.evse_id })),
        chargecards: cards ? cards.map((c) => ({ id: c })) : [],
      },
    })
      .then(({ data }) => {
        setTransactions(data.data.transactions);
        setPage(data.data.current_page);
        setNextPageAvailable(data.data.next_page);
        setTotalPages(data.data.total_pages);
        setLoading(false);
      })
      .catch((err) => {
        Sentry.captureException(err);
        dispatch(addNotification('failed', _('errors.somethingWrong', { ns: 'ui' }), 'red'));
      });

    setSearchParams(() => {
      const newParams = {
        page: p,
      };

      if (evses) newParams.chargepoints = evses.join(',');
      if (cards) newParams.chargecards = cards.join(',');
      if (search) newParams.search = search;
      if (startDate) newParams.startDate = `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`;
      if (endDate) newParams.endDate = `${endDate.getDate()}-${endDate.getMonth() + 1}-${endDate.getFullYear()}`;

      return newParams;
    });
  };

  const generateDownload = () => {
    const formatDate = (date) => `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;

    setDownloading(true);
    axios.get(BASE_URI + GENERATE_TRANSACTIONS_OVERVIEW.url, {
      responseType: 'blob',
      params: {
        chargepoints: !chargePointFilter || chargePointFilter.length === 0 ? null : chargePointFilter.join(';'),
        chargecards: !chargeCardFilter || chargeCardFilter.length === 0 ? null : chargeCardFilter.join(';'),
        startdate: startDate && formatDate(startDate),
        enddate: endDate && formatDate(endDate),
      },
      headers: {
        Authorization: `Token ${storage.getOriginalState('token')}`,
      },
    })
      .then((response) => {
        const fileUrl = URL.createObjectURL(response.data);

        // create <a> tag dynamically
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;

        // it forces the name of the downloaded file
        fileLink.download = 'Transacties.xlsx';

        // triggers the click event
        fileLink.click();

        setDownloading(false);
      })
      .catch((err) => {
        dispatch(addNotification('failed', _('errors.somethingWrong', { ns: 'ui' }), 'red'));
        Sentry.captureException(err);
        setDownloading(false);
      });
  };

  const loadPossibleChargeCards = () => {
    if (!possibleChargeCardsForFilter) {
      executeCommand(GET_CARDS_TRANSACTION_OVERVIEW, {
        params: {
          chargepoints: chargePoints.map((i) => i.evse_id).join(';'),
        },
      })
        .then(({ data }) => {
          setPossibleChargeCardsForFilter(data.cards);
        })
        .catch((err) => {
          Sentry.captureException(err);
          dispatch(addNotification('failed', _('error.somethingWrong', { ns: 'chargepoint' }), 'red'));
        });
    }
  };

  React.useEffect(async () => {
    const urlChargePoints = searchParams.get('chargepoints');
    await loadTransactions(
      searchParams.get('page') ?? undefined,
      urlChargePoints && urlChargePoints.split(','),
    );
  }, []);

  useDidMountEffect(() => {
    loadTransactions();
  }, [startDate, endDate, search]);

  useDidMountEffect(() => {
    if (timeoutRef) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setSearch(tempSearch);
    }, 1000);
  }, [tempSearch]);

  if (permissions.transactions === 'none') {
    navigate('/');
    return null;
  }

  const resetFilters = () => {
    setStartDate(null);
    setEndDate(new Date());
    setChargePointFilter(null);
    setChargeCardFilter(null);
    loadTransactions(1, chargePointFilter, chargeCardFilter);
  };

  return (
    <>
      <div
        id="Tour_Step7"
        style={{
          width: size.width < 1200 ? width : width + 415,
          height: 74,
          backgroundColor: Colors.WHITE,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: size.width < 460 ? 'flex-end' : 'space-between',
          alignItems: 'flex-end',
          position: 'fixed',
          zIndex: 2,
          paddingBottom: 9,
          marginLeft: 10,
        }}
      >
        <div
          className="searchBar"
          style={{
            minWidth: size.width < 1200
              ? 'auto'
              : 400,
            maxWidth: size.width < Breakpoint.md ? '100%' : 400,
            marginRight: isSearchBarFocused ? 0 : size.width < 1200 ? 5 : 15,
            width: isSearchBarFocused ? '100%' : size.width < 460 ? (width - 140) : '100%',
            display: 'flex',
            flexDirection: 'row',
            zIndex: 2,
            transition: 'width 0.2s linear',
            position: size.width < 460 ? 'absolute' : undefined,
            left: size.width < 460 ? 0 : undefined,
          }}
        >
          <Input
            type="search"
            placeholder={_('search', { ns: 'ui' })}
            borderRadius={7}
            value={tempSearch}
            onChange={(event) => {
              setTempSearch(event.target.value);
            }}
            onFocus={() => {
              if (size.width >= Breakpoint.fd && size.width < Breakpoint.xs) {
                setSearchActive(true);
              }
              if (size.width < 460) {
                setSearchBarFocused(true);
              }
            }}
            onBlur={() => {
              setSearchActive(false);
              setSearchBarFocused(false);
              if (size.width < Breakpoint.fd) setSearchActive(false);
            }}
          />
        </div>
        <Controls
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          page={page}
          setPage={setPage}
          groupModal={isGroupModalOpen}
          setGroupModal={setGroupModalOpen}
          useSearch={[search, setSearch]}
          searchActive={isSearchActive}
          onOpenSearch={() => setSearchActive(true)}
          useFilterModalOpen={[isFilterModalOpen, setFilterModalOpen]}
          downloading={isDownloading}
          onClickDownload={generateDownload}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingLeft: 10,
          paddingRight: 10,
          width: '100%',
        }}
      >
        {
          size.width >= 1200 && (
            <div
              style={{
                width: 400,
                marginBottom: 15,
                marginTop: 80,
                marginRight: 15,
                position: 'relative',
              }}
            >
              <div
                className="FadeIn"
                style={{
                  position: 'fixed',
                  width: 400,
                }}
              >
                <Groups
                  selected={group}
                  onClickGroup={(id, name, evses) => {
                    setGroup(id);
                    setChargePointFilter(evses);
                    loadTransactions(1, evses);
                  }}
                  filter={chargePointFilter}
                  setEdit={() => setGroupModalOpen(true)}
                />
              </div>
            </div>
          )
        }
        <motion.div
          ref={layoutWrapperRef}
          style={{
            width: size.width < 1200 ? '100%' : 'calc(100% - 415px)',
            height: 'auto',
            position: 'relative',
          }}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 1,
          }}
        >
          <List
            width={width}
            data={transactions}
            loading={isLoading}
            page={page}
            loadTransactions={loadTransactions}
            nextPage={isNextPageAvailable}
            totalPages={totalPages}
          />
        </motion.div>
        <BaseModal
          visible={isFilterModalOpen}
          title={_('filter', { ns: 'ui' })}
          onClose={() => setFilterModalOpen(false)}
        >
          <div
            style={{
              paddingLeft: 15,
              paddingRight: 15,
              overflowY: 'scroll',
              height: '100%',
              paddingTop: 30,
            }}
          >
            <Text
              fontSize="1em"
              color={Colors.DARK_GREY}
              fontWeight={500}
            >
              {_('chargePoints', { ns: 'ui' })}
            </Text>
            <div
              style={{
                marginTop: 10,
                marginBottom: 15,
              }}
            >
              <ChargePointSelector
                onClick={() => setChargePointSelectorModalOpen(true)}
                chargePointFilter={chargePointFilter}
              />
            </div>
            <Text
              fontSize="1em"
              color={Colors.DARK_GREY}
              fontWeight={500}
            >
              {_('chargeCards', { ns: 'ui' })}
            </Text>
            <div
              style={{
                marginTop: 10,
                marginBottom: 15,
              }}
            >
              <ChargeCardSelector
                onClick={() => {
                  loadPossibleChargeCards();
                  setChargeCardSelectorModalOpen(true);
                }}
                chargePointFilter={chargeCardFilter}
              />
            </div>
            <Text
              fontSize="1em"
              color={Colors.DARK_GREY}
              fontWeight={500}
            >
              {_('date', { ns: 'dates' })}
            </Text>
            <div
              style={{
                marginTop: 15,
              }}
            >
              <Input
                type="fromToDate"
                align="vertical"
                start={{
                  value: startDate,
                  maxDate: new Date(),
                  onChange: (date) => {
                    setStartDate(date);
                  },
                }}
                end={{
                  value: endDate,
                  maxDate: new Date(),
                  minDate: startDate,
                  onChange: (date) => {
                    setEndDate(date);
                  },
                }}
                onClear={() => {
                  setStartDate(null);
                  setEndDate(new Date());
                }}
              />
            </div>
          </div>
          <div
            style={{
              paddingTop: 15,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 15,
              borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
              marginTop: -2,
              position: 'relative',
              zIndex: 3,
              backgroundColor: Colors.WHITE,
              gap: 15,
            }}
          >
            <Button
              type="icon"
              icon={{
                name: 'Sync',
                color: Colors.GREY,
                height: 20,
                width: 20,
                iconSet: 'FA',
              }}
              text={_('reset', { ns: 'ui' })}
              colorScheme="white"
              onClick={() => {
                resetFilters();
              }}
              width="auto"
              fontSize="1.3em"
              color={Colors.GREY}
            />
            {
              size.width < Breakpoint.fd ? (
                <Button
                  type={
                    size.width < Breakpoint.fd
                      ? 'icon'
                      : 'submit'
                  }
                  text={
                    size.width < Breakpoint.fd
                      ? false
                      : _('apply', { ns: 'ui' })
                  }
                  icon={{
                    name: 'ArrowRight',
                    height: 20,
                    width: 20,
                    color: Colors.BLUE,
                    iconSet: 'FA',
                  }}
                  colorScheme="blue"
                  onClick={() => {
                    setFilterModalOpen(false);
                    loadTransactions(1, chargePointFilter, chargeCardFilter);
                  }}
                  width="auto"
                />
              ) : (
                <Button
                  type="submit"
                  text={_('apply', { ns: 'ui' })}
                  colorScheme="blue"
                  onClick={() => {
                    setFilterModalOpen(false);
                    loadTransactions(1, chargePointFilter, chargeCardFilter);
                  }}
                  width="auto"
                />
              )
            }
          </div>
        </BaseModal>
        <BaseModal
          visible={isChargePointSelectorModalOpen}
          title={_('chargePoints', { ns: 'ui' })}
          onClose={() => setChargePointSelectorModalOpen(false)}
        >
          <OverlaySearch
            type="Search"
            onSearch={(e) => setChargePointSearch(e.target.value)}
            data={
              [
                {
                  id: 0,
                  type: 'chargepoint',
                  name: _('allChargePoints', { ns: 'ui' }),
                  selected: chargePointFilter === null,
                  icon: {
                    name: 'UMOVE',
                    color: Colors.GREY,
                    height: 50,
                    width: 50,
                    iconSet: 'BlueCurrent',
                  },
                  onClick: () => {
                    setChargePointFilter(null);
                    setGroup(null);
                  },
                },
                ...chargePoints
                  .filter(textSearch(chargePointSearch, ['evse_id', 'name', 'model_type', 'chargepoint_type']))
                  .map((i) => ({
                    id: i.evse_id,
                    type: 'chargepoint',
                    name: i.name || i.evse_id,
                    serial: i.name && i.evse_id,
                    selected: chargePointFilter && chargePointFilter.includes(i.evse_id),
                    icon: {
                      name: 'UMOVE',
                      color: Colors.GREY,
                      height: 50,
                      width: 50,
                      iconSet: 'BlueCurrent',
                    },
                    onClick: () => {
                      if (chargePointFilter && chargePointFilter.includes(i.evse_id)) {
                        setGroup(null);
                        setChargePointFilter(
                          (prev) => (
                            prev.length === 1 ? null : prev.filter((x) => x !== i.evse_id)
                          ),
                        );
                      } else {
                        setGroup(null);
                        setChargePointFilter((prev) => [...(prev || []), i.evse_id]);
                      }
                    },
                  })),
              ]
            }
          />
          <div
            style={{
              paddingTop: 15,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: page > 1 ? 'space-between' : 'flex-end',
              paddingLeft: 15,
              paddingRight: 15,
              borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
              marginTop: -2,
              position: 'relative',
              zIndex: 3,
              backgroundColor: Colors.WHITE,
            }}
          >
            <div
              style={{
                width: 'auto',
                marginBottom: 15,
              }}
            >
              <Button
                type="submit"
                text={_('continue', { ns: 'ui' })}
                colorScheme="blue"
                onClick={() => setChargePointSelectorModalOpen(false)}
              />
            </div>
          </div>
        </BaseModal>
        <BaseModal
          visible={isChargeCardSelectorModalOpen}
          title={_('chargeCards', { ns: 'ui' })}
          onClose={() => setChargeCardSelectorModalOpen(false)}
        >
          <OverlaySearch
            type="Search"
            onSearch={(e) => setChargePointSearch(e.target.value)}
            data={
              [
                {
                  type: 'chargecard',
                  name: _('allChargeCards', { ns: 'ui' }),
                  selected: chargeCardFilter === null,
                  onClick: () => setChargeCardFilter(null),
                },
                ...(possibleChargeCardsForFilter
                  ? possibleChargeCardsForFilter
                    .filter(textSearch(chargePointSearch, ['id', 'uid', 'name']))
                    .map((i, index) => ({
                      id: i.id + index,
                      type: 'chargecard',
                      name: i.id === 'BCU-APP'
                        ? _('noChargeCard', { ns: 'ui' })
                        : i.name || i.id,
                      serial: i.name && i.id,
                      selected: chargeCardFilter && chargeCardFilter.includes(i.id),
                      onClick: () => {
                        if (chargeCardFilter && chargeCardFilter.includes(i.id)) {
                          setChargeCardFilter((prevState) => prevState.filter((x) => x !== i.id));
                        } else {
                          setChargeCardFilter((prevState) => [...(prevState ?? []), i.id]);
                        }
                      },
                    }))
                  : []),
              ]
            }
            loading={possibleChargeCardsForFilter === null}
            empty={{
              condition: possibleChargeCardsForFilter !== null
                && possibleChargeCardsForFilter.length < 1,
              component: <PlaceholderChargeCards />,
            }}
          />
          <div
            style={{
              paddingTop: 15,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: page > 1 ? 'space-between' : 'flex-end',
              paddingLeft: 15,
              paddingRight: 15,
              borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
              marginTop: -2,
              position: 'relative',
              zIndex: 3,
              backgroundColor: Colors.WHITE,
            }}
          >
            <div
              style={{
                width: 'auto',
                marginBottom: 15,
              }}
            >
              <Button
                type="submit"
                text={_('continue', { ns: 'ui' })}
                colorScheme="blue"
                onClick={() => setChargeCardSelectorModalOpen(false)}
              />
            </div>
          </div>
        </BaseModal>
        <Modal
          visible={isGroupModalOpen}
          title={_('group.groups', { ns: 'ui' })}
          onClose={() => setGroupModalOpen(false)}
          type="groupsList"
          onClickGroup={(id, name, evses) => {
            setGroup(id);
            setChargePointFilter(evses);
            loadTransactions(1, evses);
          }}
          data={groups}
          useDeleteGroupDialog={[isDeleteGroupDialogVisible, setDeleteGroupDialogVisible]}
          setDeleteGroupFunc={(func) => {
            deleteGroupFuncRef.current = func;
          }}
        />
        <ConfirmDialog
          visible={isDeleteGroupDialogVisible}
          title={_('delete', { ns: 'ui' })}
          description={_('group.deleteConfirmDialog', { ns: 'ui' })}
          accept={{
            text: _('delete', { ns: 'ui' }),
            onClick: () => {
              setDeleteGroupDialogVisible(false);
              deleteGroupFuncRef.current(isDeleteGroupDialogVisible);
            },
            colorScheme: 'red',
          }}
          cancel={{
            onClick: () => setDeleteGroupDialogVisible(false),
          }}
        />
        <Outlet />
      </div>
    </>
  );
}
