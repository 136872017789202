import * as React from 'react';
import {
  motion,
  AnimatePresence,
} from 'framer-motion';

import LeftTire from './LeftTire';
import RightTire from './RightTire';
import Tree from '../ChristmasTree/Tree';

export default function Sedan(props) {
  return (
    <AnimatePresence>
      {
        props.data !== null && props.data.car.car && (
          <motion.g
            id="Car"
            initial={{
              translateX: props.data !== null ? props.data.car.initial.translateX : 0,
              translateY: props.data !== null ? props.data.car.initial.translateY : 0,
              opacity: props.data !== null ? props.data.car.initial.opacity : 1,
            }}
            animate={{
              translateX: props.data !== null ? props.data.car.animate.translateX : 0,
              translateY: props.data !== null ? props.data.car.animate.translateY : 0,
              opacity: props.data !== null ? props.data.car.animate.opacity : 1,
            }}
            exit={{
              translateX: props.data !== null ? props.data.car.exit.translateX : 0,
              translateY: props.data !== null ? props.data.car.exit.translateY : 0,
              opacity: props.data !== null ? props.data.car.exit.opacity : 1,
            }}
            transition={{
              duration: props.data !== null ? props.data.car.transition.duration : 0,
              delay: props.data !== null ? props.data.car.transition.delay : 0,
            }}
          >
            <path
              d="M3624.35 1740.88a12.251 12.251 0 0 0-4.579-23.615H282.351a7.2 7.2 0 0 0-7.104 6.022 7.2 7.2 0 0 0 4.778 7.994l285.564 97.459a144.9 144.9 0 0 0 46.808 7.768h2763.83c7.15 0 14.234-1.374 20.866-4.047l227.255-91.581Z"
              style={{
                fill: '#798187',
                fillOpacity: 0.37,
              }}
            />
            {
              /*
                Christmas Tree
              */
            }
            { new Date().getMonth() === 11 && <Tree data={props.data} />}
            {
              /*
                Car Body
              */
            }
            <path
              d="M3532.96 958.825a75.04 75.04 0 0 0-41.953-29.15c-444.876-118.256-810.899-173.172-1126.44-187.558h-355.555c-301.173 20.169-557.573 116.263-774.079 280.009a226.7 226.7 0 0 1-112.004 45.061c-451.977 51.677-760.343 122.989-873.677 221.32a133.96 133.96 0 0 0-45.309 100.431c-.592 55.244-.592 171.339-.592 255.636a98.749 98.749 0 0 0 98.75 98.749h3045.35c171.869 2.249 277.17-132.829 332.614-370.759a111.452 111.452 0 0 0 2.683-31.806c-7.726-118.061-52.7-244.291-149.794-381.933Z"
              style={{
                fill: '#bbb',
              }}
            />
            {
              /*
                Window Middle
              */
            }
            <path
              d="M2466.62 794.98a5.555 5.555 0 0 0-5.295-5.55c-142.834-6.651-282.235-6.873-417.843.011a5.557 5.557 0 0 0-5.228 4.845l-31.946 250.245a5.558 5.558 0 0 0 5.511 6.259h449.246a5.555 5.555 0 0 0 5.555-5.555V794.98Z"
              style={{
                fill: '#527974',
              }}
            />
            <clipPath id="ChargingFlow_WindowMiddle">
              <path d="M2466.62 794.98a5.555 5.555 0 0 0-5.295-5.55c-142.834-6.651-282.235-6.873-417.843.011a5.557 5.557 0 0 0-5.228 4.845l-31.946 250.245a5.558 5.558 0 0 0 5.511 6.259h449.246a5.555 5.555 0 0 0 5.555-5.555V794.98Z" />
            </clipPath>
            <g clipPath="url(#ChargingFlow_WindowMiddle)">
              <path
                d="m2487.36 1007.72-502.374 8.969.911 51.024 502.374-8.968-.911-51.025Z"
                style={{
                  fill: '#07393b',
                  fillOpacity: 0.1,
                }}
              />
              <path
                d="M2513.78 753.693 2310.737 612.4l-327.222 470.228 203.044 141.293 327.221-470.228Z"
                style={{
                  fill: '#fff',
                  fillOpacity: 0.1,
                }}
              />
            </g>
            {
              /*
                Window Left
              */
            }
            <path
              d="M2004.67 796.263a5.558 5.558 0 0 0-5.807-6.252c-394.932 20.979-641.24 197.742-711.713 254.644a9.466 9.466 0 0 0 6.131 16.799c121.565-1.887 619.753-9.732 674.082-10.588a5.555 5.555 0 0 0 5.424-4.851l31.883-249.752Z"
              style={{
                fill: '#527974',
              }}
            />
            <clipPath id="ChargingFlow_WindowLeft">
              <path d="M2004.67 796.263a5.558 5.558 0 0 0-5.807-6.252c-394.932 20.979-641.24 197.742-711.713 254.644a9.466 9.466 0 0 0 6.131 16.799c121.565-1.887 619.753-9.732 674.082-10.588a5.555 5.555 0 0 0 5.424-4.851l31.883-249.752Z" />
            </clipPath>
            <g clipPath="url(#ChargingFlow_WindowLeft)">
              <path
                d="m1984.29 1016.37-700.912 12.513.693 38.83 700.912-12.513-.693-38.83Z"
                style={{
                  fill: '#07393b',
                  fillOpacity: 0.1,
                }}
              />
              <path
                d="m2004.72 764.279-233.47-162.466-327.221 470.228 233.469 162.466 327.222-470.228Z"
                style={{
                  fill: '#fff',
                  fillOpacity: 0.1,
                }}
              />
            </g>
            {
            /*
              Window Right
            */
          }
            <path
              d="M3022.5 920.654a22.303 22.303 0 0 0-7.874-39.424c-191.181-47.548-267.788-74.253-478.073-91.551-2.514-.206-14.242.039-21.712.076a5.55 5.55 0 0 0-5.822 5.547c-.002 34.367-.002 215.757-.002 249.933a5.555 5.555 0 0 0 5.555 5.555h21.981l271.827-24.339a128.233 128.233 0 0 0 25.523-4.927c68.873-21.166 130.496-57.359 188.597-100.87Z"
              style={{
                fill: '#527974',
              }}
            />
            <clipPath id="ChargingFlow_WindowRight">
              <path d="M3022.5 920.654a22.303 22.303 0 0 0-7.874-39.424c-191.181-47.548-267.788-74.253-478.073-91.551-2.514-.206-14.242.039-21.712.076a5.55 5.55 0 0 0-5.822 5.547c-.002 34.367-.002 215.757-.002 249.933a5.555 5.555 0 0 0 5.555 5.555h21.981l271.827-24.339a128.233 128.233 0 0 0 25.523-4.927c68.873-21.166 130.496-57.359 188.597-100.87Z" />
            </clipPath>
            <g clipPath="url(#ChargingFlow_WindowRight)">
              <path
                d="M2982.43 898.436a856.667 856.667 0 0 1-404.151 109.574l-135.606 2.421.911 51.024 364.351-6.505 293.316-164.709-24.987-44.497-93.834 52.692Z"
                style={{
                  fill: '#07393b',
                  fillOpacity: 0.1,
                }}
              />
            </g>
            {
              /*
                Front Light
              */
            }
            <path
              d="M329.852 1338.77a16.757 16.757 0 0 1-12.427-26.7c41.064-53.84 113.371-88.49 215.168-105.013a38.614 38.614 0 0 1 44.646 34.133 9649.993 9649.993 0 0 1 4.19 39.891 38.432 38.432 0 0 1-1.564 15.47l-.001.002a53.564 53.564 0 0 1-36.477 35.47c-40.118 10.492-105.483 14.392-213.535 6.747Z"
              style={{
                fill: '#e2e1e9',
              }}
            />
            <path
              d="M381.382 1319.23a16.748 16.748 0 0 1-10.6-28.886c30.235-28.126 77.859-46.713 142.074-56.147a37.73 37.73 0 0 1 28.337 7.183 37.731 37.731 0 0 1 14.698 25.27c.357 2.263.642 4.465.911 6.534a62.523 62.523 0 0 1-2.872 28.349 16.75 16.75 0 0 1-9.408 10.025c-26.553 9.481-74.544 13.654-163.14 7.672Z"
              style={{
                fillOpacity: 0.08,
              }}
            />
            {
              /*
                Charging Socket
              */
            }
            <g
              style={{
                transform: 'translateX(2295px) translateY(-55px)',
              }}
            >
              <path
                d="M1092.33 1131.57a16.923 16.923 0 0 0-15.681-23.292H971.777a16.797 16.797 0 0 0-15.563 10.477l-41.462 102.098a16.534 16.534 0 0 0 15.319 22.756h105.651c6.716 0 12.762-4.07 15.289-10.293l41.319-101.746Z"
                style={{
                  fill: '#909090',
                }}
              />
              <motion.g
                initial={{
                  opacity: props.data !== null ? props.data.charge_socket.initial.opacity : 0,
                }}
                animate={{
                  opacity: props.data !== null ? props.data.charge_socket.animate.opacity : 0,
                }}
                exit={{
                  opacity: props.data !== null ? props.data.charge_socket.exit.opacity : 0,
                }}
                transition={{
                  duration: props.data !== null ? props.data.charge_socket.transition.duration : 0,
                  delay: props.data !== null ? props.data.charge_socket.transition.delay : 0,
                  transition: props.data !== null ? props.data.charge_socket.transition.transition : 'linear',
                }}
              >
                <path
                  d="M920.835 1115.32a7.414 7.414 0 0 0-7.675-7.143l-27.764.999 4.802 133.555 27.912-1.003a7.264 7.264 0 0 0 7-7.522l-4.275-118.886Z"
                  style={{
                    fill: '#1a1a1a',
                  }}
                />
                <path
                  d="m885.404 1109.12-6.095.219a5.53 5.53 0 0 0-5.327 5.724l4.411 122.671a5.363 5.363 0 0 0 5.552 5.167l6.26-.225-4.801-133.556Z"
                  style={{
                    fill: '#909090',
                  }}
                />
                <path
                  d="M1083.79 1132.01a11.15 11.15 0 0 0-10.33-15.345h-98.301a14.112 14.112 0 0 0-13.074 8.802l-38.501 94.807a11.252 11.252 0 0 0 10.423 15.483h98.448a13.672 13.672 0 0 0 12.666-8.528l38.669-95.219Z"
                  style={{
                    fill: '#63605f',
                  }}
                />
              </motion.g>
            </g>
            <RightTire
              data={props.data}
            />
            <LeftTire
              data={props.data}
            />
          </motion.g>
        )
      }
    </AnimatePresence>
  );
}
