import ChargePointStatus from '../assets/img/Tour/ChargePointStatus.png';
import UpcomingEvent from '../assets/img/Tour/UpcomingEvent.png';
import ChargePoints from '../assets/img/Tour/ChargePoints.png';
import ChargePointSettings from '../assets/img/Tour/ChargePointSettings.png';
import ChargeCards from '../assets/img/Tour/ChargeCards.png';
import Transactions from '../assets/img/Tour/Transactions.png';
import TransactionsFilters from '../assets/img/Tour/TransactionsFilters.png';

const Tour = [
  {
    target: 'Tour_Step1',
    location: {
      url: '/',
      type: 'url',
    },
    interactive: false,
    content: {
      title: ['dashboard', { ns: 'ui' }],
      text: ['tour.step1', { ns: 'ui' }],
      image: ChargePointStatus,
    },
  },
  {
    target: 'Tour_Step2',
    location: {
      url: '/',
      type: 'url',
    },
    interactive: false,
    content: {
      title: ['dashboard', { ns: 'ui' }],
      text: ['tour.step2', { ns: 'ui' }],
      image: UpcomingEvent,
    },
  },
  {
    target: 'Tour_Step3',
    location: {
      url: '/chargepoints',
      type: 'url',
    },
    interactive: false,
    content: {
      title: ['chargePoints', { ns: 'ui' }],
      text: ['tour.step3', { ns: 'ui' }],
      image: ChargePoints,
    },
  },
  {
    target: 'Tour_Step4',
    location: {
      url: '/chargepoints/:id',
      type: 'evse_id',
    },
    interactive: false,
    content: {
      title: ['chargePoints', { ns: 'ui' }],
      text: ['tour.step4', { ns: 'ui' }],
      image: ChargePointSettings,
    },
  },
  {
    target: 'Tour_Step5',
    location: {
      url: '/chargecards',
      type: 'url',
    },
    interactive: false,
    content: {
      title: ['chargeCards', { ns: 'ui' }],
      text: ['tour.step5', { ns: 'ui' }],
      image: ChargeCards,
    },
  },
  {
    target: 'Tour_Step6',
    location: {
      url: '/transactions',
      type: 'url',
    },
    interactive: false,
    permissionHandler: (permission) => permission.transactions,
    content: {
      title: ['transactions', { ns: 'ui' }],
      text: ['tour.step6', { ns: 'ui' }],
      image: Transactions,
    },
  },
  {
    target: 'Tour_Step7',
    location: {
      url: '/transactions',
      type: 'url',
    },
    interactive: false,
    permissionHandler: (permission) => permission.transactions,
    content: {
      title: ['transactions', { ns: 'ui' }],
      text: ['tour.step7', { ns: 'ui' }],
      image: TransactionsFilters,
    },
  },
];

export default Tour;
