import * as React from 'react';

import {
  Colors,

  Loader,
} from '@bluecurrent/web-component-lib';

export default function ListLoader() {
  return (
    <div
      style={{
        height: 80,
        width: 80,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
        backgroundColor: Colors.MEDIUM_WHITE,
      }}
    >
      <Loader />
    </div>
  );
}
