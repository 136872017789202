import * as React from 'react';

import {
  Lang,

  Text,
} from '@bluecurrent/web-component-lib';

export default function Description() {
  const { _ } = Lang.useTranslation();

  return (
    <Text>{_('roamingTariff.description', { ns: 'chargepoint' })}</Text>
  );
}
