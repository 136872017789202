import * as React from 'react';
import {
  motion,
} from 'framer-motion';

export default function RightTire(props) {
  return (
    <motion.g
      initial={{
        rotate: props.data !== null && props.data.car.tires
          ? props.data.car.tires.initial.rotate
          : '0deg',
      }}
      animate={{
        rotate: props.data !== null && props.data.car.tires
          ? props.data.car.tires.animate.rotate
          : '0deg',
      }}
      exit={{
        rotate: props.data !== null && props.data.car.tires
          ? props.data.car.tires.exit.rotate
          : '0deg',
      }}
      transition={{
        duration: props.data !== null && props.data.car.tires
          ? props.data.car.tires.transition.duration
          : 0,
        delay: props.data !== null && props.data.car.tires
          ? props.data.car.tires.transition.delay
          : 0,
        transition: props.data !== null && props.data.car.tires
          ? props.data.car.tires.transition.transition
          : 'linear',
      }}
    >
      <path
        d="M3426.63 1287.56c132.802 90.977 166.758 272.657 75.781 405.458-90.978 132.802-272.657 166.758-405.459 75.781-132.801-90.977-166.757-272.657-75.78-405.458 90.977-132.802 272.657-166.758 405.458-75.781Z"
        style={{
          fill: '#444',
        }}
      />
      <path
        d="M3390.52 1340.26c103.711 71.049 130.229 212.932 59.18 316.644-71.048 103.711-212.931 130.229-316.643 59.181-103.711-71.049-130.229-212.932-59.181-316.644 71.049-103.711 212.932-130.229 316.644-59.181Z"
        style={{
          fill: '#939393',
        }}
      />
      <path
        d="M3379.47 1356.4c94.808 64.949 119.049 194.651 54.1 289.459-64.949 94.808-194.651 119.05-289.459 54.1-94.808-64.949-119.05-194.651-54.1-289.459 64.949-94.808 194.651-119.05 289.459-54.1Z"
        style={{
          fill: '#7d7d7d',
        }}
      />
      <path
        d="M3375.45 1419.27a77.815 77.815 0 0 0 42.108 118.595l63.482 18.793-18.213 61.523-65.511-19.393a77.726 77.726 0 0 0-99.786 75.312l.69 68.528-64.159.646-.616-61.224a77.902 77.902 0 0 0-105.3-72.137l-57.314 21.538-22.57-60.061 62.472-23.477a77.831 77.831 0 0 0 47.407-51.293 77.833 77.833 0 0 0-12.815-68.66l-43.254-56.919 50.792-39.204 42.551 55.984a77.683 77.683 0 0 0 125.932-3.103l35.941-52.463 52.932 36.262-34.769 50.753Z"
        style={{
          fill: '#939393',
        }}
      />
      <path
        d="M3307.28 1461.78c36.646 25.105 46.016 75.238 20.911 111.884-25.105 36.646-75.238 46.016-111.884 20.911-36.646-25.105-46.016-75.238-20.911-111.884 25.104-36.646 75.238-46.016 111.884-20.911Z"
        style={{
          fill: '#7d7d7d',
        }}
      />
      <path
        d="M3256.97 1437.21c7.542 5.167 9.445 15.523 4.246 23.112-5.198 7.588-15.543 9.554-23.085 4.387-7.543-5.167-9.445-15.523-4.247-23.111 5.199-7.589 15.543-9.555 23.086-4.388ZM3348.36 1499.81c7.542 5.167 9.445 15.523 4.246 23.112-5.198 7.588-15.543 9.554-23.085 4.387-7.543-5.167-9.446-15.523-4.247-23.111 5.199-7.589 15.543-9.555 23.086-4.388ZM3285.45 1591.65c7.542 5.167 9.445 15.523 4.246 23.111-5.198 7.589-15.542 9.555-23.085 4.388-7.542-5.167-9.445-15.523-4.247-23.112 5.199-7.588 15.543-9.554 23.086-4.387ZM3194.06 1529.04c7.542 5.167 9.445 15.523 4.247 23.111-5.199 7.589-15.543 9.555-23.086 4.388-7.542-5.167-9.445-15.523-4.246-23.112 5.198-7.588 15.542-9.554 23.085-4.387Z"
        style={{
          fill: '#1a1a1a',
        }}
      />
    </motion.g>
  );
}
