import * as React from 'react';

import {
  Colors,
  Lang,

  Button,
  Text,
  Input,
} from '@bluecurrent/web-component-lib';

import PublicCharging from './PublicCharging';
import PlugAndCharge from './PlugAndCharge';
import LedInteraction from './LedInteraction';
import PlugAndChargeNotification from './PlugAndChargeNotification';
import PublishLocation from './PublishLocation';
import Tariff from './Tariff';

export default function AreYouSure(props) {
  const { _ } = Lang.useTranslation();

  const [password, setPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const [passwordError, setPasswordError] = props.useError;

  const renderType = () => {
    switch (props.content) {
      case 'public_charging':
        return (
          <PublicCharging
            currentChargePoint={props.currentChargePoint}
            value={props.value}
          />
        );
      case 'plug_and_charge':
        return (
          <PlugAndCharge
            currentChargePoint={props.currentChargePoint}
            value={props.value}
          />
        );
      case 'led_interaction':
        return (
          <LedInteraction
            currentChargePoint={props.currentChargePoint}
            value={props.value}
          />
        );
      case 'plug_and_charge_notification':
        return (
          <PlugAndChargeNotification
            currentChargePoint={props.currentChargePoint}
            value={props.value}
          />
        );
      case 'publish_location':
        return (
          <PublishLocation
            currentChargePoint={props.currentChargePoint}
            value={props.value}
          />
        );
      case 'tariff':
        return (
          <Tariff
            currentChargePoint={props.currentChargePoint}
          />
        );
      default:
        return null;
    }
  };

  /* When the password is invalid, reset the loading */
  React.useEffect(() => {
    if (passwordError) setLoading(false);
  }, [passwordError]);

  return (
    <>
      {
        renderType()
      }
      <div
        style={{
          height: 'auto',
          width: '100%',
          padding: 15,
          borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
          paddingTop: 15,
        }}
      >
        <Text
          fontWeight={100}
        >
          {`${_('confirmEnterPassword', { ns: 'authentication' })}.`}
        </Text>
        <div
          style={{
            paddingTop: 15,
            paddingBottom: 15,
          }}
        >
          <Input
            type="password"
            label={_('password', { ns: 'authentication' })}
            name="password"
            styling
            required
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setPasswordError(null);
            }}
            error={passwordError}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              width: 'auto',
            }}
          >
            <Button
              text={_('cancel', { ns: 'ui' })}
              colorScheme="white"
              onClick={props.onCancel}
            />
          </div>
          <div
            style={{
              width: 'auto',
            }}
          >
            <Button
              text={_('confirm', { ns: 'ui' })}
              colorScheme={password === '' ? 'grey' : 'blue'}
              loading={loading}
              disabled={password === ''}
              onClick={() => {
                setLoading(true);
                props.onConfirm(password);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
