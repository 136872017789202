import * as React from 'react';

import {
  Lang,

  Text,
  Input,
} from '@bluecurrent/web-component-lib';

const Monthly = (item) => (
  <Input
    type="date"
    title={item.title}
    onChange={item.onChange}
    maxDate={new Date()}
    maxDetail="year"
    value={item.value}
    borderRadius={7}
    format={{
      month: 'long',
      year: 'numeric',
    }}
  />
);

const Quarterly = (item) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      gap: 15,
    }}
  >
    <div
      style={{
        width: '100%',
      }}
    >
      <div
        style={{
          marginBottom: 7,
        }}
      >
        <Text
          fontSize="1em"
          fontWeight={500}
        >
          {item.title.quarter}
        </Text>
      </div>
      <Input
        type="dropdown"
        id="declaration-quarter"
        text={item.value.quarter}
        items={[
          {
            id: 0,
            text: 'Q1',
            onClick: item.onClickQuarterQ1,
          },
          {
            id: 1,
            text: 'Q2',
            onClick: item.onClickQuarterQ2,
          },
          {
            id: 2,
            text: 'Q3',
            onClick: item.onClickQuarterQ3,
          },
          {
            id: 3,
            text: 'Q4',
            onClick: item.onClickQuarterQ4,
          },
        ]}
      />
    </div>
    <Input
      type="date"
      title={item.title.year}
      onChange={item.onChange}
      maxDate={new Date()}
      maxDetail="decade"
      value={item.value.year}
      borderRadius={7}
      format={{
        year: 'numeric',
      }}
      align="right"
    />
  </div>
);

const Yearly = (item) => (
  <Input
    type="date"
    title={item.title}
    onChange={item.onChange}
    maxDate={new Date()}
    maxDetail="decade"
    value={item.value}
    borderRadius={7}
    format={{
      year: 'numeric',
    }}
  />
);

export default function Period(props) {
  const { _ } = Lang.useTranslation();

  const getSelected = () => {
    switch (props.declarationPeriod) {
      case 'M':
        return _('monthly', { ns: 'dates' });
      case 'Q':
        return _('quarterly', { ns: 'dates' });
      case 'Y':
        return _('yearly', { ns: 'dates' });
      default:
        return _('monthly', { ns: 'dates' });
    }
  };

  return (
    <div
      style={{
        paddingTop: 30,
        paddingLeft: 15,
        paddingRight: 15,
        overflowY: 'scroll',
        height: '100%',
      }}
    >
      <div
        style={{
          marginBottom: 7,
        }}
      >
        <Text
          fontSize="1em"
          fontWeight={500}
        >
          {_('period', { ns: 'dates' })}
        </Text>
      </div>
      <Input
        type="dropdown"
        text={getSelected()}
        id="declaration-period"
        items={[
          {
            id: 0,
            text: `${_('monthly', { ns: 'dates' })}`,
            onClick: props.onClickMonthly,
          },
          {
            id: 1,
            text: `${_('quarterly', { ns: 'dates' })}`,
            onClick: props.onClickQuarterly,
          },
          {
            id: 2,
            text: `${_('yearly', { ns: 'dates' })}`,
            onClick: props.onClickYearly,
          },
        ]}
        onClickClose
      />
      <div
        style={{
          marginTop: 15,
          marginBottom: 15,
        }}
      >
        {
          props.declarationPeriod === 'M' && (
            <Monthly
              title={_('month', { ns: 'dates' })}
              onChange={props.onChangeMonth}
              value={props.month}
            />
          )
        }
        {
          props.declarationPeriod === 'Q' && (
            <Quarterly
              title={{
                quarter: _('quarter', { ns: 'dates' }),
                year: _('year', { ns: 'dates' }),
              }}
              value={{
                quarter: props.quarter,
                year: props.year,
              }}
              onClickQuarterQ1={props.onClickQuarterQ1}
              onClickQuarterQ2={props.onClickQuarterQ2}
              onClickQuarterQ3={props.onClickQuarterQ3}
              onClickQuarterQ4={props.onClickQuarterQ4}
              onChange={props.onChangeYear}
            />
          )
        }
        {
          props.declarationPeriod === 'Y' && (
            <Yearly
              title={_('year', { ns: 'dates' })}
              onChange={props.onChangeYear}
              value={props.year}
            />
          )
        }
      </div>
    </div>
  );
}
